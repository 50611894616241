'use strict';
import sxQuery from './sxQuery/sxQuery';
import DefaultConfig from './siteSearch/configuration/DefaultConfig';
import ConfigurationHelper from './siteSearch/configuration/ConfigurationHelper';
import Core from './siteSearch/core';
import Helper from './siteSearch/utils/Helper';
import NavigatorHelper from './siteSearch/utils/NavigatorHelper';
import SubConfigApplier from './siteSearch/utils/SubConfigApplier';
import Logger from './global/Logger';
import SxQueryUtils from './sxQuery/SxQueryUtils';
import FilterPosition from './siteSearch/enums/FilterPosition';
import CallbackEnhanceType from './siteSearch/enums/CallbackEnhanceType';
import SearchResultType from './siteSearch/enums/SearchResultType';
let IS_ZOOVU = ('zoovuMasterSearchConfig' in window) || ('zoovuSearchConfig' in window) || ('zoovuSearchConfigs' in window) || ('zoovuImmersiveSearchConfig' in window);
if (IS_ZOOVU && ('ss360Config' in window) && ('zoovuSearchConfig' in window)) {
    // @ts-ignore
    IS_ZOOVU = 'ss360' === 'z-search';
}
const enhanceCallbacks = (settings, core) => {
    const callbacks = settings.callbacks;
    callbacks.enter = Helper.enhanceCallback(callbacks.enter, core.showResults.bind(core), CallbackEnhanceType.Enter, core.getContext());
    callbacks.focus = Helper.enhanceCallback(callbacks.focus, core.focus.bind(core), CallbackEnhanceType.Focus, core.getContext());
    callbacks.blur = Helper.enhanceCallback(callbacks.blur, core.blur.bind(core), CallbackEnhanceType.Blur, core.getContext());
    callbacks.enterResult = Helper.enhanceCallback(callbacks.enterResult, core.followLink.bind(core), CallbackEnhanceType.EnterResult, core.getContext());
};
const initializeUnibox = (pluginSettings, core) => {
    (function (sxQuery) {
        sxQuery.fn.unibox = function (useFullUnibox, callback, isTrigger = false) {
            const hasMultipleSearchBoxes = this.length > 1;
            if (!useFullUnibox) {
                return this.map((idx, searchBox) => {
                    searchBox = sxQuery(searchBox);
                    searchBox.off('keyup.uniboxDummy, keydown.uniboxDummy, focus.uniboxDummy, focusout.uniboxDummy');
                    searchBox.on('keyup.uniboxDummy', (event) => {
                        event = event || window.event;
                        const keyCode = event.keyCode || event.which;
                        if (keyCode === 13 && !pluginSettings.results.ignoreEnter) {
                            pluginSettings.callbacks.enter(searchBox.val());
                        }
                    });
                    if (pluginSettings.callbacks.type !== undefined) {
                        searchBox.on('keydown.uniboxDummy', undefined, (e) => {
                            pluginSettings.callbacks.type.call(searchBox.get()[0], e, searchBox.val());
                        });
                    }
                    if (pluginSettings.callbacks.focus !== undefined) {
                        searchBox.on('focus.uniboxDummy', undefined, (e) => {
                            pluginSettings.callbacks.focus.call(searchBox.get()[0], e, searchBox.val());
                        });
                    }
                    if (pluginSettings.callbacks.blur !== undefined) {
                        searchBox.on('focusout.uniboxDummy', undefined, (e) => {
                            pluginSettings.callbacks.blur.call(searchBox.get()[0], e, searchBox.val(), true);
                        });
                    }
                    if (pluginSettings.searchBox.placeholder !== undefined && searchBox.data('ss360KeepPlaceholder') !== 'true') {
                        searchBox.attr('placeholder', pluginSettings.searchBox.placeholder);
                    }
                    searchBox.getText = () => searchBox.val();
                    searchBox.updateSuggestUrl = () => { }; // dummy
                    return searchBox;
                });
            }
            // @ts-ignore
            import(/* webpackChunkName: "unibox" */ './unibox/unibox').then(({ default: UniBox }) => {
                const boxesArray = this.map((idx, searchBox) => {
                    searchBox = sxQuery(searchBox);
                    searchBox.off('keyup.uniboxDummy, keydown.uniboxDummy, focus.uniboxDummy, focusout.uniboxDummy');
                    const individualUnibox = new UniBox(isTrigger ? undefined : searchBox, core.getContext(), hasMultipleSearchBoxes, isTrigger ? searchBox : undefined);
                    individualUnibox.init();
                    return individualUnibox;
                });
                if (callback !== undefined) {
                    callback(boxesArray.length === 1 ? boxesArray[0] : boxesArray);
                }
            });
            return undefined;
        };
    }(sxQuery));
};
const handleQueryParams = (opts, pluginConfiguration) => {
    const { searchQueryParamName, filtersEnabled, searchBoxSelector, highlightSearchTerms, shouldPopulateSearchBox, core } = opts;
    const { queryTerm } = pluginConfiguration;
    const queryDict = NavigatorHelper.buildQueryDict();
    // have we gotten redirected here? maybe we need to highlight something?
    const highlightedTerm = queryDict.ss360SearchTerm || queryDict.zSearchTerm;
    if (highlightedTerm !== undefined && highlightSearchTerms) {
        try {
            const HIGHLIGHT_CLASS_NAME = 'ss360-search-term-highlight';
            const targetElements = queryTerm.highlightContext === undefined
                ? sxQuery('div, section, main')
                : sxQuery(queryTerm.highlightContext, true).find('div, section, main');
            const highlightTerms = [highlightedTerm];
            if (queryTerm.tokenize) {
                const queryParts = highlightedTerm.split(' ');
                if (queryParts.length > 1) {
                    for (let tokenLength = queryParts.length - 1; tokenLength > 0; tokenLength--) {
                        for (let i = 0; i <= queryParts.length - tokenLength; i++) {
                            const tokenParts = [];
                            for (let j = 0; j < tokenLength; j++) {
                                tokenParts.push(queryParts[j + i]);
                            }
                            highlightTerms.push(tokenParts.join(' '));
                        }
                    }
                }
            }
            highlightTerms.forEach((term) => {
                targetElements.highlight(term, HIGHLIGHT_CLASS_NAME);
            });
            // only scroll there if we find exactly one match
            let highlightedPart = sxQuery(`.${HIGHLIGHT_CLASS_NAME}`);
            if (highlightedPart.length === 0 && highlightedTerm.indexOf('+') !== -1) { // try replacing all plus signs with a space
                targetElements.highlight(highlightedTerm.replace(/\+/g, ' '), HIGHLIGHT_CLASS_NAME);
                highlightedPart = sxQuery(`.${HIGHLIGHT_CLASS_NAME}`);
            }
            if (queryTerm.faqContext !== undefined && queryDict.qa !== undefined) {
                highlightedPart = sxQuery(queryTerm.faqContext).find(`.${HIGHLIGHT_CLASS_NAME}`);
                if (highlightedPart.length === 0) {
                    highlightedPart = sxQuery(`.${HIGHLIGHT_CLASS_NAME}`);
                }
            }
            if (highlightedPart.length > 1) {
                const visibleNodes = highlightedPart.get().filter((node) => {
                    return sxQuery(node).isVisible() && (node.getBoundingClientRect === undefined || node.getBoundingClientRect().height > 0 || node.getBoundingClientRect().width > 0);
                });
                highlightedPart = visibleNodes.length > 0 ? sxQuery(visibleNodes) : highlightedPart;
            }
            const isFAQHighlight = queryDict.qa !== undefined && highlightedPart.length > 0;
            if (highlightedPart.length === 1 || (queryTerm.scrollOnMultiMatch && highlightedPart.length > 0)) {
                const block = queryTerm.scrollIntoViewBlock;
                const isRedirectHighlight = queryDict.zSource === 'redirect' || queryDict.ss360Source === 'redirect';
                const preventScroll = block === 'none' || (isRedirectHighlight && !queryTerm.scrollOnRedirect);
                if (!preventScroll) {
                    const node = highlightedPart.get()[0];
                    node.scrollIntoView({ behavior: queryTerm.scrollIntoViewBehavior, block, inline: 'nearest' });
                    // should we try to expand accordion?
                    const parent = highlightedPart.parent();
                    if (parent.get()[0].nodeName !== 'A' || (parent.attr('href') === null || parent.attr('href').length === 0 || parent.attr('href').indexOf('#') === 0)) {
                        parent.get()[0].click();
                    }
                }
            }
            if (isFAQHighlight && pluginConfiguration.callbacks.faqHighlight !== undefined) {
                try {
                    pluginConfiguration.callbacks.faqHighlight(highlightedPart.get());
                }
                catch (err) {
                    console.error(err);
                }
            }
        }
        catch (e) {
            Logger.info(e);
        }
    }
    // is this a search page deep link? Also should be triggered if the user presses back
    let query;
    if (opts.semanticMode) {
        query = NavigatorHelper.getSemanticModeQuery(opts.embedUrl, opts.semanticModeParamName, pluginConfiguration.results);
    }
    else if (queryDict[searchQueryParamName] !== undefined &&
        // shall we prevent matching the query param?
        (core.getSearchResultType() !== SearchResultType.Embed || opts.matchEverywhere || window.location.href.indexOf(opts.embedUrl) !== -1)) {
        query = (queryDict[searchQueryParamName] || core.context.readCookie('LastQuery'));
        if (query !== undefined && query !== null) {
            if (core.getSearchResultType() === SearchResultType.Fullscreen) {
                core.context.layer.get().getSearchBox().val(query);
                if (core.context.layer.get().css('top') !== '0px') {
                    core.showFullscreenLayer();
                }
            }
            if (core.getSearchResultType() === SearchResultType.Embed && query.indexOf('+') !== -1 && window.location.href.indexOf(`${searchQueryParamName}=${query}`) !== -1) { // check if the search param is properly encoded (e.g. personello.de 'photo pillow')
                query = query.replace(/\+/g, ' ');
            }
        }
    }
    if (query !== undefined) {
        if (shouldPopulateSearchBox) {
            sxQuery(searchBoxSelector).val(query);
        }
        let filters;
        if (filtersEnabled) {
            filters = NavigatorHelper.getFilters(pluginConfiguration.results);
            filters = filters !== undefined && filters.length === 0 ? undefined : filters;
        }
        let sort;
        let activeGuidedQuestions = [];
        const sortParamName = NavigatorHelper.escapeQueryParam(pluginConfiguration.results.sortingParamName);
        if (queryDict[sortParamName] !== undefined) {
            sort = NavigatorHelper.unescapeQueryParam(queryDict[sortParamName]);
        }
        if (queryDict['ss360-qa-flow']) {
            try {
                activeGuidedQuestions = JSON.parse(decodeURIComponent(queryDict['ss360-qa-flow']));
            }
            catch (err) {
                // ccl
            }
        }
        ;
        core.showResults(query, {
            filters,
            shouldPushState: false,
            submitSource: 'pageload',
            sort,
            activeGuidedQuestions
        });
    }
};
const getPublicInstance = (core) => {
    return {
        changeConfig: core.changeConfig.bind(core),
        setSiteId: core.setSiteId.bind(core),
        init: core.init.bind(core),
        showResults: core.showResults.bind(core),
        showFullscreenLayer: core.showFullscreenLayer.bind(core),
        closeLayer: core.closeLayer.bind(core),
        showLoading: core.showLoading.bind(core),
        hideLoading: core.hideLoading.bind(core),
        isInitialized: core.isInitialized.bind(core),
        setBaseUrl: core.setBaseUrl.bind(core),
        setSuggestUrl: core.setSuggestUrl.bind(core),
        renderResults: core.renderResults.bind(core),
        _renderStaticData: core.renderStaticData.bind(core),
        openTab: core.openTab.bind(core),
        hideSearchResults: core.hideSearchResults.bind(core),
        destroy: core.destroy.bind(core),
        reloadResults: core.reloadResults.bind(core),
        startComparison: core.startComparison.bind(core),
        openMiniPDP: core.openMiniPDP.bind(core),
        closeAllDrawers: core.closeAllDrawers.bind(core),
        closeFilters: core.closeFilters.bind(core),
        invalidateZoe: core.invalidateZoe.bind(core),
        getVersion() {
            return core.VERSION;
        },
        getSettings() {
            return core.getSettings();
        }
    };
};
const loadTranslations = (lang, callback) => {
    if (lang === undefined || lang === null) {
        callback({});
        return;
    }
    lang = lang.toString().trim().toLowerCase();
    if (lang === 'en' || lang.length === 0) {
        callback({});
        return;
    }
    // @ts-ignore
    import(/* webpackChunkName: "translations" */ './siteSearch/configuration/ConfigTranslations').then(({ default: ConfigTranslations }) => {
        const languageMap = {
            fr: ConfigTranslations.FR,
            de: ConfigTranslations.DE,
            nl: ConfigTranslations.NL,
            tr: ConfigTranslations.TR,
            it: ConfigTranslations.IT,
            pl: ConfigTranslations.PL,
            es: ConfigTranslations.ES,
            'es-mx': ConfigTranslations.ES_MX,
            'es_mx': ConfigTranslations.ES_MX,
            pt: ConfigTranslations.PT,
            da: ConfigTranslations.DA,
            fi: ConfigTranslations.FI,
            sv: ConfigTranslations.SV,
            no: ConfigTranslations.NO,
            lt: ConfigTranslations.LT,
            ro: ConfigTranslations.RO,
            lv: ConfigTranslations.LV,
            el: ConfigTranslations.EL,
            hu: ConfigTranslations.HU,
            cz: ConfigTranslations.CZ,
            ru: ConfigTranslations.RU,
            is: ConfigTranslations.IS,
            ja: ConfigTranslations.JA,
            ko: ConfigTranslations.KO
        };
        const translations = languageMap[lang];
        callback(translations);
    });
};
const init = (config, isDefaultInstance = true, callback = () => { }) => {
    if (config === undefined) {
        Logger.warn(`${IS_ZOOVU ? 'zoovuSearchConfig' : 'ss360Config'} object is not defined. The document.location.host will be used as siteId and '#searchBox' as search box selector.`);
        config = {};
    }
    if (config.siteId === undefined) {
        config.siteId = document.location.host;
    }
    if (config.disableInfoLogs === true) {
        if (config.loggers === undefined) {
            config.loggers = {};
        }
        if (config.loggers.info === undefined) {
            config.loggers.info = () => { };
        }
    }
    if (config.loggers !== undefined) {
        if (config.loggers.info !== undefined) {
            Logger.set('info', config.loggers.info);
        }
        if (config.loggers.warn !== undefined) {
            Logger.set('warn', config.loggers.warn);
        }
        if (config.loggers.error !== undefined) {
            Logger.set('error', config.loggers.error);
        }
    }
    let defaults = Helper.copyObject(DefaultConfig);
    const always = () => {
        // @ts-ignore
        const pluginSettings = SxQueryUtils.extend(defaults, config);
        if (pluginSettings.filters.enabled && config.filters.position === undefined && pluginSettings.results.embedConfig === undefined && pluginSettings.results.fullScreenConfig === undefined) { // filter position top, if layover
            pluginSettings.filters.position = FilterPosition.Top;
        }
        if (config.layout !== undefined && config.layout.masonryCols !== undefined) {
            pluginSettings.layout.masonryCols = Helper.copyObject(config.layout.masonryCols);
        }
        if (pluginSettings.style.defaultCss) {
            // eslint-disable-next-line global-require
            const css = require('../sass/siteSearch/critical.scss'); // load the default css
            const $target = pluginSettings.style.targetNode ? sxQuery(pluginSettings.style.targetNode) : sxQuery(document);
            const $appendNode = $target.find('head').length > 0 ? $target.find('head') : $target;
            $appendNode.find('#ss360custom-search-input').remove();
            $appendNode.append(`<style id="ss360custom-search-input" type="text/css">${css}</style>`);
        }
        const appliedPageSizeString = pluginSettings.results.showPageSizeSelector ? SxQueryUtils.readCookie(IS_ZOOVU ? 'zoovupageSize' : 'ss360pageSize') : null;
        if (appliedPageSizeString !== null) {
            pluginSettings.results.moreResultsPagingSize = parseInt(appliedPageSizeString, 10);
        }
        ConfigurationHelper.assert(config, pluginSettings);
        const core = new Core(pluginSettings, isDefaultInstance, IS_ZOOVU);
        SubConfigApplier.init(core.getContext());
        SubConfigApplier.apply(core.getContext());
        // make sure all calbacks work as expected
        enhanceCallbacks(pluginSettings, core);
        core.setIsZoovu(IS_ZOOVU);
        // expose public methods
        const publicInstance = getPublicInstance(core);
        if (isDefaultInstance && !IS_ZOOVU) {
            window.SS360 = publicInstance;
        }
        else if (isDefaultInstance && IS_ZOOVU) {
            window.ZOOVU_SEARCH = publicInstance;
        }
        else if (pluginSettings.alias !== undefined) {
            window[pluginSettings.alias] = publicInstance;
        }
        if (pluginSettings.relatedContent !== undefined && (pluginSettings.relatedContent.active === undefined || pluginSettings.relatedContent.active === true)) {
            // @ts-ignore
            import(/* webpackChunkName: "relatedContent" */ './relatedContent/relatedContent').then(({ default: RelatedContent }) => {
                const relatedContent = new RelatedContent(Object.assign(Object.assign({}, pluginSettings.relatedContent), { themeColor: pluginSettings.style.accentColor, siteId: pluginSettings.siteId, ecom: pluginSettings.ecom, ecomDev: pluginSettings.ecomDev, ecomStage: pluginSettings.ecomStage, baseUrl: pluginSettings.baseUrl, _cacheQueries: pluginSettings._cacheQueries, _logQueries: pluginSettings.tracking.logQueries }), core.getContext().getInsights(), IS_ZOOVU);
                sxQuery(document).ready(() => {
                    relatedContent.loadAndRender();
                });
            });
        }
        // init
        sxQuery(document).ready(() => {
            initializeUnibox(pluginSettings, core, config);
            core.init();
            if (pluginSettings.style.defaultCss) {
                // @ts-ignore
                import(/* webpackChunkName: "styles" */ './siteSearch/styles/Styles').then(({ default: Styles }) => {
                    Styles.renderDefaultStyles(pluginSettings, core.context.layerId, isDefaultInstance ? '' : core.getContext().alias);
                });
            }
            const shouldPopulateSearchBox = pluginSettings.results.embedConfig === undefined
                || pluginSettings.results.embedConfig.populateSearchBoxOnRedirect === undefined
                || pluginSettings.results.embedConfig.populateSearchBoxOnRedirect !== false;
            const embedUrl = pluginSettings.results.embedConfig !== undefined ? pluginSettings.results.embedConfig.url : undefined;
            const opts = {
                searchQueryParamName: pluginSettings.results.searchQueryParamName,
                filtersEnabled: pluginSettings.filters.enabled,
                searchBoxSelector: pluginSettings.searchBox.selector,
                highlightSearchTerms: pluginSettings.queryTerm.highlight,
                semanticMode: pluginSettings.results.semanticMode,
                semanticModeParamName: pluginSettings.results.semanticModeParamName,
                embedUrl,
                matchEverywhere: pluginSettings.results.embedConfig === undefined || pluginSettings.results.embedConfig.matchOnEveryPage !== false || embedUrl === undefined || embedUrl.length === 0,
                shouldPopulateSearchBox,
                core
            };
            handleQueryParams(opts, pluginSettings);
            if (callback) {
                callback(core);
            }
        });
    };
    if (config.callbacks !== undefined && config.callbacks.preInit !== undefined) {
        try {
            config.callbacks.preInit(config);
        }
        catch (err) {
            Logger.error(err);
        }
    }
    loadTranslations(config.language, (translations) => {
        if (translations !== undefined && Object.keys(translations).length > 0) {
            defaults = SxQueryUtils.extend(defaults, translations);
            const noResultsPageTranslations = translations.noResultsPage;
            // @ts-ignore
            if (defaults.noResultsPage && (defaults.noResultsPage.content || []).length > 0) {
                // @ts-ignore
                const noResultsPageContent = defaults.noResultsPage.content;
                noResultsPageContent.forEach((entry) => {
                    if (noResultsPageTranslations[entry.type] !== undefined) {
                        entry.title = noResultsPageTranslations[entry.type];
                    }
                    if (entry.fallback && noResultsPageTranslations[entry.fallback.type] !== undefined) {
                        entry.fallback.title = noResultsPageTranslations[entry.fallback.type];
                    }
                });
            }
        }
        always();
    });
};
window.sxQuery = sxQuery; // make sxQuery public
const initializeSs360 = function (config) {
    config = config || (IS_ZOOVU ? window.zoovuSearchConfig : window.ss360Config);
    let firstConfig = config;
    if (config instanceof Array) {
        firstConfig = config[0];
        config.forEach((singleConfig, idx) => {
            if (idx !== 0 && singleConfig.alias === undefined) {
                singleConfig.alias = `${IS_ZOOVU ? 'ZOOVU_SEARCH' : 'SS360'}_${idx}`;
            }
            init(singleConfig, idx === 0);
        });
    }
    else if (config !== undefined || !(('ss360Configs' in window) || ('zoovuSearchConfigs' in window))) {
        init(config, true);
    }
    if (('ss360Configs' in window) || ('zoovuSearchConfigs' in window)) {
        const configs = ('ss360Configs' in window) ? window.ss360Configs : window.zoovuSearchConfigs;
        Object.keys(configs).forEach((key) => {
            const singleConfig = configs[key];
            if (firstConfig === undefined || firstConfig === null) {
                firstConfig = singleConfig;
            }
            if (singleConfig.alias === undefined) {
                singleConfig.alias = key;
            }
            init(singleConfig, false);
        });
    }
    const landingPageNode = document.getElementById('zoovu-search-landing-page-config') || document.getElementById('ss360-search-landing-page-config');
    if (landingPageNode !== null) {
        try {
            let landingPageConfig = JSON.parse(landingPageNode.innerText);
            if (landingPageConfig.query || landingPageConfig.breadcrumb) {
                landingPageConfig = SxQueryUtils.extend(Helper.deepCopyObject(firstConfig || {}), landingPageConfig);
                loadTranslations(landingPageConfig.language, (translations) => {
                    let defaults = Helper.deepCopyObject(DefaultConfig);
                    if (translations !== undefined && Object.keys(translations).length > 0) {
                        defaults = SxQueryUtils.extend(defaults, translations);
                    }
                    landingPageConfig = SxQueryUtils.extend(defaults, landingPageConfig);
                    // @ts-ignore
                    landingPageConfig.searchBox = landingPageConfig.searchBox || {};
                    landingPageConfig.searchBox._preventBind = true;
                    landingPageConfig.searchBox.selector = undefined;
                    landingPageConfig.searchBox.searchButton = undefined;
                    // @ts-ignore
                    landingPageConfig.results = landingPageConfig.results || {};
                    landingPageConfig.results.searchQueryParamName = 'ss360-landing-page-q';
                    landingPageConfig.alias = 'ss360LandingPageSearch';
                    let query = landingPageConfig.query;
                    const queryDict = NavigatorHelper.buildQueryDict();
                    if (query === undefined || query === null || query.length === 0) {
                        query = landingPageConfig.breadcrumb.length > 0 ? `_#${landingPageConfig.breadcrumb.join('#')}` : '_#';
                    }
                    let filters;
                    if (landingPageConfig.filters && landingPageConfig.filters.enabled) {
                        filters = NavigatorHelper.getFilters(landingPageConfig.results);
                        filters = filters !== undefined && filters.length === 0 ? undefined : filters;
                        landingPageConfig.results.mergePresetFilters = true;
                    }
                    let sort;
                    let activeGuidedQuestions = [];
                    const sortParamName = NavigatorHelper.escapeQueryParam(landingPageConfig.results.sortingParamName);
                    if (queryDict[sortParamName] !== undefined) {
                        sort = NavigatorHelper.unescapeQueryParam(queryDict[sortParamName]);
                    }
                    if (queryDict['ss360-qa-flow']) {
                        try {
                            activeGuidedQuestions = JSON.parse(decodeURIComponent(queryDict['ss360-qa-flow']));
                        }
                        catch (err) {
                            // ccl
                        }
                    }
                    ;
                    init(landingPageConfig, false, (core) => {
                        // @ts-ignore
                        window[landingPageConfig.alias] = getPublicInstance(core);
                        const requestOptions = {
                            shouldPushState: false,
                            submitSource: 'landing-page',
                            filters,
                            sort,
                            activeGuidedQuestions
                        };
                        if ('history' in window) {
                            window.history.replaceState({
                                query,
                                requestOptions
                            }, document.title, `${window.location.pathname}${window.location.search}`);
                        }
                        core.showResults(query, requestOptions);
                    });
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    }
};
if (!IS_ZOOVU) {
    window.initializeSs360 = initializeSs360;
}
else {
    window.initializeZoovuSearch = initializeSs360;
}
if ('ss360MasterConfig' in window) {
    window.ss360Config = window.ss360MasterConfig;
    if ('ss360Configs' in window) {
        window.ss360Configs = {};
    }
}
if ('zoovuMasterSearchConfig' in window) {
    window.zoovuSearchConfig = window.zoovuMasterSearchConfig;
    if ('zoovuSearchConfigs' in window) {
        window.zoovuSearchConfigs = {};
    }
}
/** SS360 ends here **/
const initSearch = (alias) => {
    if (!(alias in window) || window[alias] === undefined || window[alias].autoInit !== false) {
        if (alias in window || `${alias}s` in window) {
            initializeSs360();
        }
        else {
            sxQuery(document).ready(() => {
                initializeSs360();
            });
        }
    }
};
initSearch(IS_ZOOVU ? 'zoovuSearchConfig' : 'ss360Config');
