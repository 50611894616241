'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import Icons from './Icons';
import SearchButtonIcon from '../enums/SearchButtonIcon';
import UiColors from '../styles/UiColors';
const applySearchBoxStyles = (searchResultType, searchBoxConfig, styleConfig) => {
    const sbs = styleConfig.searchBox;
    if (sbs === undefined) {
        return;
    }
    const searchBoxSelector = searchBoxConfig.selector;
    const searchButtonSelector = searchBoxConfig.searchButton;
    const searchBox = sxQuery(searchBoxSelector);
    const sbut = sxQuery(searchButtonSelector);
    applySearchBoxTextStyles(sbs.text, searchBox, sbut);
    applySearchBoxBorderStyles(sbs.border, searchBox, sbut);
    applyPadding(searchBox, sbs.padding);
    applyPadding(sbut, sbs.padding);
    const iconPaddingLeft = sbs.padding ? sbs.padding : '5px';
    const defaultColor = UiColors.DIM_GREY;
    for (let i = 0; i < searchBox.length; i++) {
        addSearchBoxIcon(sxQuery(searchBox.get()[i]), sbs.icon, iconPaddingLeft, defaultColor, searchResultType);
    }
    if (sbs.button !== undefined && sbut.length > 0) {
        const sbutStyle = sbs.button;
        applyStyle(sbut, 'cursor', 'pointer');
        if (sbutStyle.icon === 'magnifier') {
            sbutStyle.icon = {
                image: SearchButtonIcon.Magnifier,
                color: sbutStyle.color
            };
        }
        if (sbutStyle.text !== undefined && sbutStyle.text.length > 0) {
            sbut.val(sbutStyle.text);
            sbut.html(sbutStyle.text);
            applyColor(sbut, sbutStyle.textColor);
        }
        else if (!sbut.hasClass('ss360-custom-search__searchbutton') && (sbutStyle.icon || {}).image === 'magnifier') {
            sbut.val(' ');
            sbut.html(' ');
        }
        addSearchButtonIcon(sbut, sbutStyle.icon, iconPaddingLeft, (sbutStyle.icon || {}).color || sbutStyle.color || defaultColor, sbutStyle.iconPadding);
    }
    const backgroundColor = sbs.background ? sbs.background.color : undefined;
    const searchButtonBackgroundColor = (sbs.button !== undefined && sbs.button.backgroundColor !== undefined ? sbs.button.backgroundColor : undefined) || styleConfig.accentColor;
    applyBackground(searchBox, backgroundColor);
    applyBackground(sbut, searchButtonBackgroundColor);
};
const applySearchSuggestionStyles = (styleConfiguration, uniboxId) => {
    const sbs = styleConfiguration.suggestions;
    if (sbs === undefined) {
        return;
    }
    const sugBox = sxQuery(`#${uniboxId}`);
    applyPadding(sugBox, sbs.padding);
    applyStyle(sugBox, 'margin-top', sbs.distanceFromTop);
    if (sbs.text !== undefined) {
        applyColor(sugBox, sbs.text.color);
    }
    if (sbs.background !== undefined) {
        applyBackground(sugBox, sbs.background.color);
    }
    if (sbs.border !== undefined) {
        applyBorderRadius(sugBox, sbs.border.radius);
        applyBorderColor(sugBox, sbs.border.color);
    }
};
const applySearchBoxTextStyles = (style, sb, sbut) => {
    if (style === undefined) {
        return;
    }
    applyColor(sb, style.color);
    applyColor(sbut, style.color);
    applySize(sb, style.size);
    applySize(sbut, style.size);
};
const applySearchBoxBorderStyles = (style, sb, sbut) => {
    if (style === undefined) {
        return;
    }
    applyBorderRadius(sb, style.radius);
    applyBorderRadius(sbut, style.radius);
    applyBorderColor(sb, style.color);
    applyBorderColor(sbut, style.color);
};
const applyColor = (elem, color) => {
    applyStyle(elem, 'color', color);
};
const applySize = (elem, size) => {
    applyStyle(elem, 'font-size', size);
};
const applyBorderRadius = (elem, radius) => {
    if (radius === undefined) {
        return;
    }
    applyStyle(elem, 'border', '1px solid');
    applyStyle(elem, 'border-radius', radius);
};
const applyBorderColor = (elem, color) => {
    applyStyle(elem, 'border-color', color);
};
const applyPadding = (elem, padding) => {
    applyStyle(elem, 'padding', padding);
};
const applyBackground = (elem, color) => {
    applyStyle(elem, 'background-color', color);
};
const applyStyle = (elem, key, value) => {
    if (value !== undefined) {
        elem.css(key, value);
    }
};
const addSearchBoxIcon = (sb, icon, iconPaddingLeft, defaultColor, searchResultType) => {
    if (icon === undefined || icon.image !== 'magnifier') {
        return;
    }
    const isFullScreenField = searchResultType === 'fullscreen' && sb.attr('id') === 'ss360-query';
    const sbHeight = isFullScreenField ? 54 : sb.outerHeight();
    let positionTop = 0;
    let size = 0;
    size = (sbHeight - 18); // set to css value because the input is not fully visible at this point
    positionTop = (sbHeight - size) / 2;
    const sIcon = Icons.getBase64EncodedIcon(Icons.MAGNIFIER, icon.color || defaultColor);
    sb.css('background-image', `url('${sIcon}')`);
    sb.css('background-repeat', 'no-repeat');
    sb.css('background-position-x', iconPaddingLeft);
    sb.css('background-position-y', `${positionTop}px`);
    sb.css('background-size', `${size}px ${size}px`);
    sb.css('padding-left', `${size + 1 * (parseInt(iconPaddingLeft.replace('px', ''), 10)) + 2}px`);
};
const addSearchButtonIcon = (sbut, icon, iconPaddingLeft, color, iconPadding) => {
    if (icon === undefined || icon.image !== 'magnifier') {
        return;
    }
    let yPadding = 9;
    if (iconPadding !== undefined) {
        yPadding = parseInt(iconPadding, 10);
    }
    const sbutIc = Icons.getBase64EncodedIcon(Icons.MAGNIFIER, color);
    sbut.each((but) => {
        const $but = sxQuery(but);
        const size = ($but.outerHeight() - 2 * yPadding);
        const isButton = but.nodeName === 'BUTTON';
        if (isButton) {
            const sbutStyle = `width:${size}px; height:${size}px`;
            $but.html(`<img role="presentation" alt="" style="${sbutStyle}" src="${sbutIc}"/>`);
        }
        else {
            const paddingTop = ($but.outerHeight() - size) / 2;
            $but.css('background', `url('${sbutIc}') no-repeat ${iconPadding !== undefined ? iconPadding : iconPaddingLeft} ${paddingTop}px`);
            $but.css('background-size', `${size}px`);
        }
        $but.css('min-width', `${$but.outerHeight()}px`);
    });
};
const StyleApplier = {
    apply(searchResultType, searchBoxConfiguration, styleConfiguration) {
        try {
            applySearchBoxStyles(searchResultType, searchBoxConfiguration, styleConfiguration);
        }
        catch (e) {
            Logger.warn(e);
        }
    },
    applyOnSuggestions(styleConfiguration, uniboxId) {
        try {
            applySearchSuggestionStyles(styleConfiguration, uniboxId);
        }
        catch (e) {
            Logger.warn(e);
        }
    }
};
export default StyleApplier;
