'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import StringHelper from '../../global/StringHelper';
import NavigatorHelper from '../utils/NavigatorHelper';
import UiHelper from '../ui/UiHelper';
import ImageHandler from '../utils/ImageHandler';
import SearchResponse from '../model/SearchResponse';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import Helper from '../utils/Helper';
import Logger from '../../global/Logger';
const Results = {
    fetch(url, context, callback, failCallback, siteId, noResultsLoadedCallback) {
        const { sanitizeResults } = context.pluginConfiguration.results;
        const { ignoreShopSysHeader } = context.pluginConfiguration;
        const dataCallback = (data) => {
            if (data && data.suggests && Object.keys(data.suggests).length === 1 && data.suggests['*'] !== undefined
                && (data.searchResults || []).reduce((acc, searchResult) => acc || (searchResult.type === 'all' && (searchResult.placements || []).length > 0), false)) {
                delete data.suggests['*'];
            }
            let searchResponse = new SearchResponse(data, sanitizeResults);
            let retry = noResultsLoadedCallback !== undefined && NavigatorHelper.getFirstResult(searchResponse) === undefined;
            const { _noResultsGroups } = context.pluginConfiguration.results;
            if (!retry && _noResultsGroups.length > 0 && noResultsLoadedCallback !== undefined) {
                retry = true;
                for (let i = 0; i < _noResultsGroups.length; i++) {
                    if ((searchResponse.getSuggestsArray(_noResultsGroups[i]) || []).length > 0) {
                        retry = false;
                        break;
                    }
                }
            }
            if (retry) {
                url = noResultsLoadedCallback(data);
                if (url !== undefined) {
                    const innerCallback = (data2) => {
                        searchResponse = new SearchResponse(data2, sanitizeResults);
                        if (callback !== undefined) {
                            callback(searchResponse);
                        }
                    };
                    if (Helper.shouldSearchPost(context, url)) {
                        const postOptions = Helper.convertToPost(url);
                        SxQueryUtils.post(postOptions.url, postOptions.data, innerCallback, 'application/json', !ignoreShopSysHeader, failCallback, context.pluginConfiguration.additionalHeaderParams);
                    }
                    else {
                        SxQueryUtils.get(url, innerCallback, failCallback, undefined, !ignoreShopSysHeader, context.pluginConfiguration.additionalHeaderParams);
                    }
                }
                else {
                    callback(searchResponse);
                }
            }
            else if (callback !== undefined) {
                callback(searchResponse);
            }
        };
        if (Helper.shouldSearchPost(context, url)) {
            const postOptions = Helper.convertToPost(url);
            SxQueryUtils.post(postOptions.url, postOptions.data, dataCallback, 'application/json', !ignoreShopSysHeader, failCallback, context.pluginConfiguration.additionalHeaderParams);
        }
        else {
            SxQueryUtils.get(url, dataCallback, failCallback, undefined, !ignoreShopSysHeader, context.pluginConfiguration.additionalHeaderParams);
        }
    },
    getOrFetch(url, context, callback, failCallback, siteId, noResultsLoadedCallback) {
        const cachedResult = context.readObject(context.getIsZoovu() ? 'zoovu_last_query_result' : 'ss360_last_query_result');
        if (cachedResult !== undefined && cachedResult.timestampMs !== undefined && cachedResult.siteId !== undefined
            && cachedResult.locationHref === window.location.href && (cachedResult.timestampMs + 5 * 60 * 1000) > new Date().getTime() && cachedResult.siteId === siteId) { // window location must match and the result must not be older than 5 minutes
            if (cachedResult.suggests !== undefined && cachedResult.suggests['*'] !== undefined) {
                delete cachedResult.suggests['*'];
            }
            callback(new SearchResponse(cachedResult, context.pluginConfiguration.results.sanitizeResults));
        }
        else {
            Results.fetch(url, context, callback, failCallback, siteId, noResultsLoadedCallback);
        }
    },
    prefetchAndRender(s, rest, uiBuilder, callback, context) {
        const safeKey = StringHelper.getSafeKey(s.contentGroup);
        const groupParent = context.layer.get().getResultGroups(safeKey);
        const resultTarget = groupParent.find(`.ss360-list`);
        let skippedResults = 0;
        if (groupParent.data('skippedResults') !== undefined) {
            skippedResults += parseInt(groupParent.data('skippedResults'), 10);
        }
        const queryUrl = context.core.buildQueryUrl({
            limit: s.limit,
            include: s.contentGroup && (s.groupResults === undefined || s.groupResults) ? [s.contentGroup] : context.pluginConfiguration.contentGroups.include,
            exclude: s.contentGroup && (s.groupResults === undefined || s.groupResults) ? [] : context.pluginConfiguration.contentGroups.exclude,
            tracking: false,
            query: s.query,
            offset: s.offset + skippedResults,
            limitPerGroup: false,
            isPlain: false,
            filters: s.filters,
            group: s.groupResults !== undefined ? s.groupResults : undefined,
            sort: s.sort,
            sortComplex: s.sortComplex
        });
        Results.fetch(queryUrl, context, (data) => {
            const results = data.getSuggestsArray(s.contentGroup);
            const hiddenParts = UiHelper.getHiddenParts(context.pluginConfiguration.layout);
            const callbacks = context.pluginConfiguration.callbacks;
            let wereResultsRendered = false;
            if (results !== undefined) {
                results.forEach((suggest, idx) => {
                    if (UiHelper.hasVisibleContent(suggest, hiddenParts)) {
                        const $item = uiBuilder.buildSuggestItem(suggest, s.query, s.contentGroup, rest <= idx, undefined);
                        if (callbacks.resultLine !== undefined) {
                            try {
                                callbacks.resultLine(suggest.suggest, $item.get()[0]);
                            }
                            catch (ex) {
                                console.warn(ex);
                                // ccl
                            }
                        }
                        resultTarget.append($item);
                        wereResultsRendered = true;
                    }
                    else {
                        skippedResults++;
                    }
                });
                if (skippedResults !== 0) {
                    groupParent.data('skippedResults', skippedResults);
                }
                if (!wereResultsRendered && s.offset + skippedResults < context.pluginConfiguration.results.num) { // we try to fetch and render the next portion of results
                    Results.prefetchAndRender(s, rest, uiBuilder, callback, context);
                    return;
                }
                if (context.pluginConfiguration.callbacks.resultsPreloaded !== undefined) {
                    context.pluginConfiguration.callbacks.resultsPreloaded(data.plain);
                }
            }
            if (callback !== undefined && typeof callback === 'function') {
                callback(results, data.plain);
            }
            if (context.pluginConfiguration.callbacks.preloadedResultsRendered !== undefined) {
                context.pluginConfiguration.callbacks.preloadedResultsRendered(data.plain);
            }
        });
    },
    wasBackPressed(query, context) {
        return context.isUseCookiesForOffsets() ? context.readCookie('-query--c') === query : window.location.search.indexOf(`${context.getOffsetQueryParam()}=`) !== -1;
    },
    handleBackPress(navigation, query, searchResultType, context) {
        const queryDict = NavigatorHelper.buildQueryDict();
        const pageSize = context.pluginConfiguration.results.moreResultsPagingSize;
        const offset = parseInt(context.isUseCookiesForOffsets() ? context.readCookie('-offset--c') : queryDict[context.getOffsetQueryParam()], 10);
        let cg = context.isUseCookiesForOffsets() ? context.readCookie('-cg--c') : queryDict[context.getContentGroupQueryParam()];
        if (cg === null || cg === undefined) {
            cg = '_';
        }
        const cgKey = StringHelper.getSafeKey(cg);
        const groupWrapper = context.layer.get().getResultGroups(cg ? cgKey : '_');
        const performScroll = () => {
            // switch content group if necessary
            navigation.focusTab(cgKey);
            // perform the scroll
            const $visibleSuggests = groupWrapper.find('.ss360-suggests:not(.ss360-suggests--hidden)');
            const relevantResult = sxQuery($visibleSuggests.get()[offset]);
            navigation.scrollTo(relevantResult);
            setTimeout(() => {
                relevantResult.find('.ss360-suggests__link').focus();
            }, 5);
            if (context.pluginConfiguration.results.pageDescriptionLabel) {
                groupWrapper.find('.ss360-pagination__visible').text('Intl' in window ? new Intl.NumberFormat().format($visibleSuggests.length) : $visibleSuggests.length.toString());
                const $total = groupWrapper.find('.ss360-pagination__total');
                if ($total.length > 0) {
                    Helper.updatePaginationProgress(groupWrapper.find('.ss360-pagination__progress'), $visibleSuggests.length, parseInt($total.data('num').toString(), 10));
                }
            }
        };
        const filterOptions = NavigatorHelper.getFilters(context.pluginConfiguration.results);
        const callMoreResultsCallback = (data) => {
            const moreResultsCallback = context.pluginConfiguration.callbacks.moreResults;
            try {
                if (moreResultsCallback) {
                    let maxResult = undefined;
                    if (data) {
                        maxResult = new SearchResponse(data, false).totalResultsPerContentGroup[cg];
                    }
                    moreResultsCallback(groupWrapper.find('.ss360-suggests:not(.ss360-suggests--hidden)').length, maxResult, cgKey, data);
                }
            }
            catch (err) {
                Logger.error(err);
            }
        };
        if (offset < groupWrapper.find('.ss360-suggests:not(.ss360-suggests--hidden)').length) { // the relevant result is visible
            performScroll();
        }
        else if (offset < groupWrapper.find('.ss360-suggests').length) {
            const newOffset = groupWrapper.find('.ss360-suggests').length;
            const callback = (_arr, data) => {
                if (groupWrapper.find('.ss360-suggests--hidden').length === 0) { // the relevant result is not visible but is loaded
                    groupWrapper.find('.ss360-more-results').remove();
                }
                ImageHandler(context);
                callMoreResultsCallback(data);
            };
            context.core.prefetchResults({
                offset: newOffset,
                contentGroup: cg,
                query,
                callback,
                filters: filterOptions
            }); // prefetch more results
            const pagesToReveal = Math.ceil(offset / pageSize) - 1;
            const hidden = groupWrapper.find('.ss360-suggests.ss360-suggests--hidden');
            const resultsToReveal = pagesToReveal * pageSize;
            for (let i = 0; i < resultsToReveal && i < hidden.length; i++) {
                sxQuery(hidden.get(i)).removeClass('ss360-suggests--hidden');
            }
            performScroll();
        }
        else { // the relevant result has to be fetched
            UiHelper.showLoadingAnimation();
            const toPreloadCount = (offset + 1) - groupWrapper.find('.ss360-suggests').length;
            const pagesToPreload = Math.ceil(toPreloadCount / pageSize) + 1;
            const overrides = {
                num: offset + pagesToPreload * pageSize,
                pageSize: pagesToPreload * pageSize
            };
            groupWrapper.find('.ss360-suggests').removeClass('ss360-suggests--hidden');
            const loadedResultCount = groupWrapper.find('.ss360-suggests').length;
            const callback = (_arr, data) => {
                const expectedResultCount = pageSize * pagesToPreload + loadedResultCount;
                const allExpectedResultsLoaded = groupWrapper.find('.ss360-suggests').length >= expectedResultCount;
                const allSuggests = groupWrapper.find('.ss360-suggests');
                for (let i = 0; i < allSuggests.length; i++) {
                    const $suggest = sxQuery(allSuggests.get()[i]);
                    if (!allExpectedResultsLoaded || i < (allSuggests.length - pageSize)) {
                        $suggest.removeClass('ss360-suggests--hidden');
                    }
                    else {
                        $suggest.addClass('ss360-suggests--hidden');
                    }
                }
                if (groupWrapper.find('.ss360-suggests--hidden').length === 0) {
                    groupWrapper.find('.ss360-more-results').remove();
                }
                UiHelper.hideLoadingAnimation();
                performScroll();
                ImageHandler(context);
                callMoreResultsCallback(data);
            };
            context.core.prefetchResults({
                offset: loadedResultCount,
                contentGroup: cg,
                query,
                callback,
                overrides,
                filters: filterOptions
            });
        }
        // cleanup
        if (!context.isUseCookiesForOffsets()) {
            delete queryDict[context.getContentGroupQueryParam()];
            delete queryDict[context.getOffsetQueryParam()];
            delete queryDict[context.getPageLinkQueryParam()];
            NavigatorHelper.replaceState(queryDict, context.pluginConfiguration);
        }
        else {
            context.createCookie('-cg--c', '', 1 / 24);
            context.createCookie('-offset--c', '-1', 1 / 24);
            context.createCookie('-query--c', '', 1 / 24);
        }
    }
};
export default Results;
