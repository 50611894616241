'use strict';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;'
};
const inverseEntityMap = Object.keys(entityMap).reduce((acc, key) => { acc[entityMap[key]] = key; return acc; }, {});
const highlightSearchWords = (string, searchString) => {
    if (string === undefined || searchString === undefined) {
        return string;
    }
    const words = searchString.replace(/[^a-zA-Z0-9äöüÄÖÜßçâêîôûàèùëïěščřžýáíéťňÇÂÊÎÔÛÀÈÙËÏĚŠČŘŽÝÁÍÉŤŇ]|\s+|\r?\n|\r/gmi, ' ')
        .replace(/[^a-zA-Z0-9äöüÄÖÜßçâêîôûàèùëïěščřžýáíéťňÇÂÊÎÔÛÀÈÙËÏĚŠČŘŽÝÁÍÉŤŇ]/g, ' ').split(' ');
    // sort words by length, longest first
    words.sort((a, b) => b.length - a.length);
    const markers = {};
    SxQueryUtils.each(words, (idx, word) => {
        if (word.length < 2) {
            return;
        }
        const matches = string.match(new RegExp(`((${word})(?!#<##|-\\d+#<##))(?!.*\\1)`, 'gi'));
        if (matches != null) {
            for (let i = 0; i < matches.length; i++) {
                const match = matches[i];
                const matchEsc = match.replace(/[-[\]/{}()*+?.^$|]/g, '\\$&');
                string = string.replace(new RegExp(`(${matchEsc})(?!#<##|-\\d+#<##)`, 'g'), `##>#${idx}-${i}#<##`);
                markers[`##>#${idx}-${i}#<##`] = `<span class="unibox__highlight">${match}</span>`;
            }
        }
    });
    const reversedMarkerKeys = Object.keys(markers).reverse();
    for (let i = 0; i < reversedMarkerKeys.length; i++) {
        const singleMarker = reversedMarkerKeys[i];
        const replacement = markers[singleMarker];
        string = string.replace(new RegExp(singleMarker, 'gi'), replacement);
    }
    return string;
};
const StringHelper = {
    escapeHtml(string) {
        return String(string).replace(/[&<>"'/]/g, (s) => entityMap[s]);
    },
    unescapeHtml(string) {
        string = String(string);
        Object.keys(inverseEntityMap).forEach((entity) => {
            string = string.replace(new RegExp(entity, 'g'), inverseEntityMap[entity]);
        });
        return string;
    },
    getSafeKey(key) {
        return (key.toString()).replace(/[ "§$%&/(){}+*,.;|:!]/g, '_').replace(/#/g, '__').replace(/'/g, '---').toLowerCase();
    },
    highlightSearchWords
};
export default StringHelper;
