import DisplayType from '../../../relatedContent/enums/DisplayType';
import DomElementBuilder from '../../../sxQuery/DomElementBuilder';
import sxQuery from '../../../sxQuery/sxQuery';
import SxQueryUtils from '../../../sxQuery/SxQueryUtils';
import Icons from '../../components/Icons';
import MiniPDP from '../../components/MiniPDP';
import Score from '../../components/Score';
import SearchResultType from '../../enums/SearchResultType';
import CtaPosition from '../../interface/CtaPosition';
import UiColors from '../../styles/UiColors';
import Helper from '../../utils/Helper';
import NavigatorHelper from '../../utils/NavigatorHelper';
import SimilarContent from '../SimilarContent';
import UiHelper from '../UiHelper';
import AbstractResultRenderer from './AbstractResultRenderer';
class ProductResultRenderer extends AbstractResultRenderer {
    constructor() {
        super(...arguments);
        this.getPriceDataPoint = Helper.getDataPoint;
    }
    openMiniPDP(suggest, $suggestItem, query, context, focusRelations = false, focusReviews = false) {
        NavigatorHelper.replaceStatePlain(undefined, 'open-mini-pdp');
        let focus = '';
        if (focusRelations) {
            focus = 'relations';
        }
        else if (focusReviews) {
            focus = 'reviews';
        }
        new MiniPDP(this.context, suggest, $suggestItem, query).show(focus);
        if (context.hasInsights()) {
            const cgItems = $suggestItem.parent().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
            const layoutFrame = context.layer.get();
            const allItems = layoutFrame.getVisibleResults().get();
            const itemEl = $suggestItem.get(0);
            const contentGroupPosition = SxQueryUtils.indexInNodeList(itemEl, cgItems) + 1;
            const position = SxQueryUtils.indexInNodeList(itemEl, allItems) + 1;
            context.getInsights().trackMiniPDPOpen(query, itemEl, allItems.length, position, contentGroupPosition, suggest.getLink(), context.isSmart404() ? SearchResultType.Smart404 : context.getSearchResultType(), suggest.getIdentifier(), suggest.getIdentifier(), suggest.contentGroup, suggest.getOriginalContentGroup());
        }
    }
    getCtas(suggest, resultGroup) {
        const ctas = super.getCtas(suggest, resultGroup);
        const { similarContent, results } = this.pluginConfiguration;
        let renderSimilarContentTrigger = similarContent !== undefined && similarContent.active;
        if (renderSimilarContentTrigger && similarContent.isRelationSearch && similarContent.includeTag !== undefined) {
            renderSimilarContentTrigger = suggest.getRelationTypes() !== undefined && (suggest.getRelationTypes().tags || []).length > 0 && suggest.getRelationTypes().tags.reduce((acc, tag) => {
                if (acc) {
                    return true;
                }
                return (tag.relations || []).reduce((acc, relation) => relation.exists, false);
            }, false);
        }
        if (renderSimilarContentTrigger) {
            const context = this.context;
            const query = this.query;
            const self = this;
            ctas.push({
                text: similarContent.buttonLabel,
                renderAsButton: true,
                position: CtaPosition.Stretch,
                icon: 'none',
                clickCallback(e) {
                    const $suggestItem = sxQuery(e.target).parents('.ss360-suggests', true);
                    if (context.pluginConfiguration.miniPDP.enabled) {
                        self.openMiniPDP(suggest, $suggestItem, query, context, true);
                    }
                    else {
                        if (similarContent.displayType === DisplayType.Inline) {
                            const $wrap = sxQuery('.ss360-similar-content-block');
                            if ($wrap.length > 0 && $wrap.data('identifier') === suggest.getIdentifier()) {
                                $wrap.get(0).scrollIntoViewIfNeeded();
                                return;
                            }
                        }
                        SimilarContent.handleClick(sxQuery(e.target), $suggestItem, context, suggest, query, self);
                        context.emitEvent('relation-request', {
                            product: suggest.suggest
                        });
                    }
                }
            });
            SimilarContent.preload();
        }
        const addToCartLink = results.addToCartLink;
        const addToCartCallback = this.pluginConfiguration.callbacks.addToCart;
        const renderAddToCart = (addToCartLink !== undefined && addToCartLink !== null && addToCartLink !== '') || (addToCartCallback !== undefined && addToCartCallback !== null);
        if (renderAddToCart) {
            ctas.push({
                text: results.addToCartLabel,
                renderAsButton: true,
                position: CtaPosition.Stretch,
                icon: 'zoovu:shopping-cart',
                primary: true,
                clickCallback: () => {
                    this.context.emitEvent('add-to-cart', {
                        product: suggest.suggest
                    });
                    if (this.context.getInsights()) {
                        const priceDataPoint = this.getPriceDataPoint(suggest, this.pluginConfiguration.results.priceDataPoint, 'newPrice');
                        let price;
                        let priceUnit;
                        if (priceDataPoint !== undefined) {
                            const priceData = Helper.getPriceAndCurrency(priceDataPoint);
                            price = priceData.price;
                            priceUnit = priceData.currency;
                        }
                        this.context.getInsights().trackAddToCart(suggest.getIdentifier(), suggest.getIdentifier(), suggest.getLink(), 1, price, priceUnit, !suggest.isRelation, false, suggest.isRelation);
                    }
                    if (addToCartCallback !== undefined && addToCartCallback !== null) {
                        addToCartCallback(suggest.getIdentifier(), (suggest.plain instanceof Array ? suggest.plain[0] : suggest.plain));
                    }
                    else {
                        window.location.href = addToCartLink.replace(/#RESULT_LINK#/g, this.getLink(suggest)).replace(/#IDENTIFIER#/g, suggest.getIdentifier());
                    }
                }
            });
        }
        return ctas;
    }
    getBadges(suggest) {
        const badges = super.getBadges(suggest);
        const maxNumberOfBadges = this.getMaxNumberOfBadges();
        if (badges.length >= maxNumberOfBadges) {
            return badges;
        }
        const isOnlyNumberOrBoolean = (value) => {
            if (value === true || value === false || value === 'true' || value === 'false') {
                return true;
            }
            if (value === undefined || value === null || value.length === 0) {
                return false;
            }
            try {
                value = value.replace(/,/g, '').replace(/\./g, '').replace(/\d/g, '').trim();
            }
            catch (err) {
                // ccl
            }
            return value.length === 0;
        };
        const priceDataPoint = this.getPriceDataPoint(suggest, this.pluginConfiguration.results.priceDataPoint, 'newPrice');
        const oldPriceDataPoint = this.getPriceDataPoint(suggest, this.pluginConfiguration.results.oldPriceDataPoint, 'oldPrice');
        const addedBadges = badges.map((b) => b.value);
        for (let i = 0; i < suggest.getDataPoints().length; i++) {
            const dp = suggest.getDataPoints()[i];
            if ((priceDataPoint !== undefined && dp.key === priceDataPoint.key) || (oldPriceDataPoint !== undefined && dp.key === oldPriceDataPoint.key)) {
                continue;
            }
            if (((dp.type === 'FILTER' || dp.type === 'SORTING' || dp.type === 'GUIDED_QUESTION') && !isOnlyNumberOrBoolean(dp.value)) && dp.value && addedBadges.indexOf(dp.value) === -1) {
                badges.push({
                    value: dp.value
                });
                addedBadges.push(dp.value);
            }
            if (badges.length >= maxNumberOfBadges) {
                break;
            }
        }
        return badges;
    }
    createComparisonAction(suggest) {
        const { comparison } = this.pluginConfiguration;
        const showComparison = suggest.getIdentifier() && comparison && comparison.enabled;
        if (!showComparison) {
            return undefined;
        }
        return DomElementBuilder.createElement({
            tag: 'label',
            classList: ['ss360-actions__compare'],
            children: [
                DomElementBuilder.createElement({
                    tag: 'input',
                    classList: ['ss360-actions__compare-checkbox'],
                    attrs: {
                        type: 'checkbox'
                    }
                }),
                DomElementBuilder.createElement({
                    tag: 'span',
                    classList: ['ss360-actions__compare-checkmark'],
                    attrs: {
                        role: 'presentation'
                    }
                }),
                DomElementBuilder.createElement({
                    tag: 'span',
                    classList: ['ss360-actions__compare-label'],
                    textContent: comparison.buttonLabel
                }),
            ]
        });
    }
    createZoeChatAction(suggest) {
        const { zoe } = this.pluginConfiguration;
        const zoeShowChat = suggest.getIdentifier() && zoe && zoe.apiKey && zoe.showChat;
        if (!zoeShowChat) {
            return undefined;
        }
        return DomElementBuilder.createElement({
            tag: 'button',
            classList: ['ss360-n-button', 'ss360-actions__action', `ss360-actions__action--chat`],
            attrs: {
                type: 'button'
            },
            children: [
                Icons.getSvgIconNode(Icons.CHAT, UiColors.GREY_TEXT, 'ss360-actions__action-icon'),
                DomElementBuilder.createElement({
                    tag: 'span',
                    classList: ['ss360-actions__action-label'],
                    html: zoe.chatLabel
                })
            ]
        });
    }
    renderActions(wrapper, suggest) {
        const comparison = this.createComparisonAction(suggest);
        const zoe = this.createZoeChatAction(suggest);
        const children = [];
        if (comparison !== undefined) {
            children.push(comparison);
        }
        if (zoe != undefined) {
            children.push(zoe);
        }
        if (children.length > 0) {
            const node = DomElementBuilder.createElement({
                tag: 'div',
                classList: ['ss360-actions', children.length !== 1 ? null : 'ss360-actions--single'],
                children
            });
            wrapper.appendChild(node);
            return node;
        }
        return undefined;
    }
    findDataPoint(suggest, dataPointName) {
        const dataPoints = suggest.getDataPoints();
        for (let i = 0; i < dataPoints.length; i++) {
            const dataPoint = dataPoints[i];
            if (dataPoint.key === dataPointName) {
                return dataPoint;
            }
        }
        return undefined;
    }
    getStockDataPoint(suggest) {
        return this.findDataPoint(suggest, this.pluginConfiguration.results.stockDataPoint);
    }
    getRatingDataPoint(suggest) {
        const dp = suggest.getRatingDataPoint();
        if (dp !== undefined) {
            return dp;
        }
        return this.findDataPoint(suggest, this.pluginConfiguration.results.ratingDataPoint);
    }
    getRatingCountDataPoint(suggest) {
        const dp = suggest.getRatingCountDataPoint();
        if (dp !== undefined) {
            return dp;
        }
        return this.findDataPoint(suggest, this.pluginConfiguration.results.ratingCountDataPoint);
    }
    getDataPoints(suggest) {
        let dataPoints = super.getDataPoints(suggest);
        const priceDataPoint = this.getPriceDataPoint(suggest, this.pluginConfiguration.results.priceDataPoint, 'newPrice');
        const oldPriceDataPoint = this.getPriceDataPoint(suggest, this.pluginConfiguration.results.oldPriceDataPoint, 'oldPrice');
        const stockDataPoint = this.getStockDataPoint(suggest);
        const ratingDataPoint = this.getRatingDataPoint(suggest);
        const forbiddenArr = [oldPriceDataPoint, priceDataPoint, stockDataPoint, ratingDataPoint, this.getRatingCountDataPoint(suggest)];
        this.context.pluginConfiguration.results.scoreDataPoints.forEach((score) => {
            forbiddenArr.push({
                key: score.dataPoint,
                value: '',
                show: false
            });
        });
        forbiddenArr.forEach((forbidden) => {
            if (forbidden === undefined) {
                return;
            }
            dataPoints = dataPoints.filter((dp) => dp.key !== forbidden.key);
        });
        return dataPoints;
    }
    renderPrice(wrapper, suggest, modifier, attachItemProps = true) {
        const priceDataPoint = this.getPriceDataPoint(suggest, this.pluginConfiguration.results.priceDataPoint, 'newPrice');
        if (priceDataPoint === undefined) {
            return undefined;
        }
        const price = priceDataPoint.value;
        const oldPriceDataPoint = this.getPriceDataPoint(suggest, this.pluginConfiguration.results.oldPriceDataPoint, 'oldPrice');
        const oldPrice = oldPriceDataPoint !== undefined ? oldPriceDataPoint.value : undefined;
        const priceWrapper = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__price-wrap', `ss360-suggests__price-wrap--${modifier}`],
            attrs: attachItemProps ? {
                itemprop: 'offers',
                itemscope: 'itemscope',
                itemtype: 'http://schema.org/Offer'
            } : {}
        });
        const priceAndCurrency = Helper.getPriceAndCurrency(priceDataPoint);
        priceWrapper.appendChild(DomElementBuilder.createElement({
            tag: 'span',
            classList: ['ss360-suggests__price', 'ss360-suggests__price--new'],
            textContent: price,
            attrs: attachItemProps ? {
                itemprop: 'price',
                content: `${priceAndCurrency.price}`
            } : {}
        }));
        if (priceAndCurrency.currency !== undefined && attachItemProps) {
            priceWrapper.appendChild(DomElementBuilder.createElement({
                tag: 'meta',
                attrs: attachItemProps ? {
                    itemprop: 'priceCurrency',
                    content: `${priceAndCurrency.currency}`
                } : {}
            }));
        }
        if (oldPrice !== undefined) {
            priceWrapper.appendChild(DomElementBuilder.createElement({
                tag: 'span',
                classList: ['ss360-suggests__price', 'ss360-suggests__price--old'],
                textContent: oldPrice
            }));
        }
        wrapper.appendChild(priceWrapper);
        return priceWrapper;
    }
    renderStock(contentWrap, suggest) {
        const stockDataPoint = this.getStockDataPoint(suggest);
        if (stockDataPoint === undefined) {
            return;
        }
        let icon, text, fill, colorClassName;
        let isInStock = undefined;
        if (stockDataPoint.parsedValue === undefined) {
            text = stockDataPoint.value;
        }
        else if (stockDataPoint.parsedValue === 0 || stockDataPoint.parsedValue === false) {
            icon = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 11.0023C20 15.9729 15.9706 20.0023 11 20.0023C6.02944 20.0023 2 15.9729 2 11.0023C2 6.03176 6.02944 2.00232 11 2.00232C15.9706 2.00232 20 6.03176 20 11.0023ZM22 11.0023C22 17.0775 17.0751 22.0023 11 22.0023C4.92487 22.0023 0 17.0775 0 11.0023C0 4.92719 4.92487 0.00231934 11 0.00231934C17.0751 0.00231934 22 4.92719 22 11.0023ZM13.83 6.75969C14.2205 6.36916 14.8537 6.36916 15.2442 6.75969C15.6347 7.15021 15.6347 7.78338 15.2442 8.1739L12.4153 11.0029L15.2432 13.8308C15.6337 14.2213 15.6337 14.8544 15.2432 15.245C14.8526 15.6355 14.2195 15.6355 13.829 15.245L11.0011 12.4171L8.17315 15.245C7.78262 15.6355 7.14946 15.6355 6.75893 15.245C6.36841 14.8544 6.36841 14.2213 6.75893 13.8308L9.58684 11.0029L6.75789 8.1739C6.36736 7.78338 6.36736 7.15021 6.75789 6.75969C7.14841 6.36917 7.78158 6.36917 8.1721 6.75969L11.0011 9.58864L13.83 6.75969Z" fill="${UiColors.RED}"/></svg>`;
            text = this.pluginConfiguration.results.outOfStockLabel;
            fill = UiColors.RED;
            colorClassName = 'ss360-suggests__stock--out-of-stock';
            isInStock = false;
        }
        else {
            icon = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.97074 9.15869C1.87947 5.43179 4.95261 2.33952 8.83479 2.25191C9.86067 2.22875 10.8384 2.4173 11.7243 2.77527C12.122 2.93595 12.5801 2.75672 12.7475 2.37495C12.9148 1.99317 12.7281 1.55342 12.3305 1.39273C11.2452 0.954246 10.0487 0.724095 8.79807 0.752321C4.05318 0.859408 0.297123 4.63885 0.408672 9.19394C0.520221 13.749 4.45714 17.3549 9.20203 17.2478C13.047 17.161 16.2408 14.6633 17.2561 11.2972C17.4944 10.5071 17.6126 9.66988 17.5914 8.80614C17.5813 8.39204 17.2234 8.06424 16.792 8.07398C16.3607 8.08371 16.0192 8.4273 16.0294 8.8414C16.0467 9.55043 15.9498 10.2351 15.7553 10.88C14.9242 13.6353 12.3091 15.6772 9.1653 15.7482C5.28312 15.8358 2.06201 12.8856 1.97074 9.15869Z" fill="${UiColors.GREEN}"/><path d="M16.2496 5.45767C16.7073 5.01834 16.7073 4.30603 16.2496 3.86669C15.792 3.42735 15.05 3.42735 14.5923 3.86669L7.90144 10.2899L5.87364 8.34325C5.41599 7.90391 4.674 7.90391 4.21636 8.34325C3.75871 8.78259 3.75871 9.4949 4.21636 9.93424L6.79659 12.4113C7.40678 12.997 8.3961 12.997 9.0063 12.4113L16.2496 5.45767Z" fill="${UiColors.GREEN}"/></svg>`;
            text = this.pluginConfiguration.results.inStockLabel;
            colorClassName = 'ss360-suggests__stock--in-stock';
            isInStock = false;
        }
        const stock = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__stock', colorClassName],
            children: [
                icon ? DomElementBuilder.createElement({
                    tag: 'span',
                    classList: ['ss360-suggests__stock-icon'],
                    html: icon
                }) : undefined,
                isInStock !== undefined ? DomElementBuilder.createElement({
                    tag: 'link',
                    attrs: {
                        href: isInStock ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'
                    }
                }) : undefined,
                DomElementBuilder.createElement({
                    tag: 'span',
                    classList: ['ss360-suggests__stock-text'],
                    textContent: text
                })
            ]
        });
        contentWrap.appendChild(stock);
        return stock;
    }
    renderRating(contentWrap, suggest) {
        const ratingDataPoint = this.getRatingDataPoint(suggest);
        const ratingCountDataPoint = this.getRatingCountDataPoint(suggest);
        const rcValue = (ratingCountDataPoint !== undefined ? ratingCountDataPoint.parsedValue : 0);
        const clickable = this.context.pluginConfiguration.miniPDP.enabled;
        const ratingWrapper = UiHelper.renderRating(ratingDataPoint, ratingCountDataPoint, rcValue === undefined || rcValue > 1 ? this.context.pluginConfiguration.results.ratingCountLabel : this.context.pluginConfiguration.results.ratingCountLabelSingular, this.context.pluginConfiguration.results.maxRating, 'ss360-suggests', true, clickable ? 'button' : undefined);
        if (ratingWrapper !== undefined) {
            contentWrap.appendChild(ratingWrapper);
            if (clickable) {
                ratingWrapper.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const $suggestItem = sxQuery(e.target).parents('.ss360-suggests', true);
                    this.openMiniPDP(suggest, $suggestItem, this.query, this.context, false, true);
                });
            }
        }
        return ratingWrapper;
    }
    isHasVariantsLabel(suggest) {
        const variantsCount = suggest.variants.length;
        return !this.isHasVariantImages(suggest) && variantsCount > 0;
    }
    isHasVariantImages(suggest) {
        if (!this.pluginConfiguration.results.showVariants) {
            return false;
        }
        return suggest.getUniqueVariantImageCount(this.context.pluginConfiguration.results.variantGroupingAttribute) > 1;
    }
    getVariantImages(suggest) {
        if (!this.pluginConfiguration.results.showVariants) {
            return [];
        }
        return suggest.getAllVariantImages(this.context.pluginConfiguration.results.variantGroupingAttribute);
    }
    renderVariantsLabel(wrapper, suggest) {
        if (this.hiddenParts.title === 'all') {
            return undefined;
        }
        if (this.isHasVariantsLabel(suggest)) {
            const variantsCount = suggest.variants.length;
            const resultsConfiguration = this.pluginConfiguration.results;
            const label = variantsCount === 1 ? resultsConfiguration.variantsCountLabelSingular : resultsConfiguration.variantsCountLabel;
            const variantsLabel = DomElementBuilder.createElement({
                tag: 'span',
                classList: ['ss360-suggests__variants-count'],
                html: label.replace(/#COUNT#/g, variantsCount.toString())
            });
            wrapper.appendChild(variantsLabel);
            return variantsLabel;
        }
        return undefined;
    }
    createWrapperElement(suggest, isHidden, resultGroup) {
        const wrapper = super.createWrapperElement(suggest, isHidden, resultGroup);
        if (this.context.pluginConfiguration.miniPDP.enabled) {
            wrapper.className = `${wrapper.className} ss360-suggests--has-mini-pdp`;
        }
        return wrapper;
    }
    createResultNode(suggest) {
        const wrapper = super.createResultNode(suggest);
        wrapper.setAttribute('itemtype', 'http://schema.org/Product');
        wrapper.appendChild(DomElementBuilder.createElement({
            tag: 'meta',
            attrs: {
                itemprop: 'sku',
                content: suggest.getIdentifier()
            }
        }));
        if (this.context.pluginConfiguration.miniPDP.enabled) {
            wrapper.className = `${wrapper.className} ss360-suggests__wrap--has-mini-pdp`;
        }
        return wrapper;
    }
    renderScore(wrapper, suggest) {
        const scoreDataPoints = this.pluginConfiguration.results.scoreDataPoints;
        const scoreDataPointKeys = scoreDataPoints.map((score) => score.dataPoint);
        suggest.getDataPoints().forEach((dp) => {
            if (dp.origin === 'CLASSIFIER' && dp.parsedValue && scoreDataPointKeys.indexOf(dp.key) === -1) {
                const cleanLabel = dp.key.replace('_raw_score', '').split('_').join(' ');
                scoreDataPoints.push({
                    dataPoint: dp.key,
                    max: dp.maxScore !== undefined ? dp.maxScore : 10,
                    label: `${cleanLabel.substring(0, 1).toUpperCase()}${cleanLabel.substring(1)}`
                });
            }
        });
        if (scoreDataPoints.length === 0) {
            return undefined;
        }
        const el = Score.createScore(suggest, scoreDataPoints);
        if (el !== undefined) {
            el.className = `${el.className} ss360-suggests__score`;
            wrapper.appendChild(el);
        }
        return el;
    }
    renderContent(wrapper, suggest, resultGroup) {
        const img = this.createImages(suggest);
        if (img !== undefined) {
            wrapper.appendChild(img);
        }
        this.renderPrice(wrapper, suggest, 'grid');
        const contentWrap = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__content']
        });
        wrapper.appendChild(contentWrap);
        this.renderHeader(contentWrap, suggest);
        this.renderPrice(contentWrap, suggest, 'list', false);
        this.renderVariantsLabel(contentWrap, suggest);
        this.renderLink(contentWrap, suggest);
        this.renderSnippet(contentWrap, suggest);
        this.renderScore(contentWrap, suggest);
        this.renderDataPoints(contentWrap, suggest);
        this.renderStock(contentWrap, suggest);
        this.renderRating(contentWrap, suggest);
        this.renderCtas(contentWrap, suggest, resultGroup);
        this.renderActions(contentWrap, suggest);
        return contentWrap;
    }
}
export { ProductResultRenderer };
