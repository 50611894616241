'use strict';
import sxQuery from "../../sxQuery/sxQuery";
import NoResultsPageBlockType from "../enums/NoResultsPageBlockType";
import { AssistantEnvironment } from "../interface/NoResultsPageConfigurationBlock";
import Icons from "./Icons";
import Results from "./Results";
import ApiConfig from "../api/ApiConfig";
import SxQueryUtils from "../../sxQuery/SxQueryUtils";
import SearchResponse from "../model/SearchResponse";
import ImageHandler from "../utils/ImageHandler";
import SearchResultType from "../enums/SearchResultType";
import RelatedQueries from "./RelatedQueries";
import StringHelper from "../../global/StringHelper";
class NoResultsPage {
    constructor(context, query, uiBuilder, relatedQueries) {
        this.context = context;
        this.query = query;
        this.uiBuilder = uiBuilder;
        this.baseUrl = context.getSearchBase();
        this.relatedQueries = relatedQueries;
    }
    render(layoutFrame) {
        const { query, context } = this;
        const resultHeadingLevel = context.pluginConfiguration.accessibility.resultTopHeadingLevel;
        const { noResultsPage } = context.pluginConfiguration;
        if (noResultsPage.title || noResultsPage.description) {
            layoutFrame.detachTopHeadingFrame();
            const header = sxQuery('<header class="ss360-n-section ss360-no-results__header"></header>');
            const headerText = sxQuery('<div class="ss360-no-results__header-text"></div>');
            header.append(`<i role="presentation" class="ss360-no-results__icon">${Icons.getNoResultsIcon('')}</i>`);
            if (noResultsPage.title) {
                headerText.append(`<h${resultHeadingLevel} id="${context.searchResultHeadingId}" class="ss360-layer__heading ss360-no-results__title">
                                        <a class="ss360-layer__heading-anchor" tabindex="-1" href="#" role="status">${noResultsPage.title.replace(/#QUERY#/g, query)}</a>
                                    </h${resultHeadingLevel}>`);
            }
            if (noResultsPage.description) {
                headerText.append(`<p class="ss360-no-results__description">${noResultsPage.description.replace(/#QUERY#/g, query)}</p>`);
            }
            header.append(headerText);
            layoutFrame.appendToNoResultsFrame(header);
        }
        let renderedBlocks = 0;
        noResultsPage.content.forEach((block) => {
            this.appendBlock(layoutFrame, block, undefined, () => {
                renderedBlocks++;
                if (noResultsPage.content.length === renderedBlocks && context.pluginConfiguration.callbacks.noResultsPageRendered) {
                    context.pluginConfiguration.callbacks.noResultsPageRendered();
                }
            });
        });
    }
    appendBlock(layoutFrame, block, existingBlock, renderCallback) {
        renderCallback = renderCallback || function () { };
        const pluginConfiguration = this.context.pluginConfiguration;
        const headingLevel = pluginConfiguration.accessibility.resultTopHeadingLevel;
        const blockWrap = existingBlock !== undefined ? existingBlock : sxQuery(`<div class="ss360-no-results__block ss360-no-results__block--${block.type}"></div>`);
        if (existingBlock !== undefined) {
            existingBlock.html('');
        }
        let title = block.title;
        if (block.type === NoResultsPageBlockType.RelatedQueries) {
            title = this.context.pluginConfiguration.results.relatedQueriesTitle;
            if (title[title.length - 1] === ':') {
                title = title.substring(0, title.length - 1);
            }
        }
        if (title) {
            blockWrap.append(`<h${headingLevel} class="ss360-no-results__subtitle">${title}</h${headingLevel}>`);
        }
        if (block.type === NoResultsPageBlockType.CustomHtml) {
            blockWrap.html(block.value);
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.SearchBox) {
            const ss360SearchWrapper = this.uiBuilder.buildSearchField('', true, false, '--no-results');
            ss360SearchWrapper.addClass('ss360-no-results__search');
            ss360SearchWrapper.find('.ss360-custom-search__searchbox').addClass('ss360-no-results__search-box');
            blockWrap.append(ss360SearchWrapper);
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.FuzzyResults) {
            blockWrap.hide();
            Results.fetch(this.context.core.buildQueryUrl({
                limit: 3,
                include: pluginConfiguration.contentGroups.include,
                exclude: pluginConfiguration.contentGroups.exclude,
                tracking: false,
                query: StringHelper.unescapeHtml(this.query),
                offset: 0,
                limitPerGroup: false,
                isPlain: false,
                sort: "",
                group: false,
                fuzziness: this.context.isEcom() ? 3 : 5
            }), this.context, (data) => {
                if (data.totalResults === 0 && block.fallback !== undefined) {
                    blockWrap.removeClass(`ss360-no-results__block--${block.type}`).addClass(`ss360-no-results__block--${block.fallback.type}`);
                    this.appendBlock(layoutFrame, block.fallback, blockWrap, renderCallback);
                }
                else if (data.totalResults === 0) {
                    blockWrap.remove();
                    renderCallback();
                }
                else {
                    this.renderResults(data.getFlatSuggests().splice(0, 6), blockWrap);
                    blockWrap.show();
                    renderCallback();
                }
            });
        }
        else if (block.type === NoResultsPageBlockType.PopularResults) {
            blockWrap.hide();
            const siteId = pluginConfiguration.siteId;
            const limit = 3;
            const queryUrl = this.context.isEcom() ? `${this.baseUrl}/search/top-products?site=${siteId}&projectId=${siteId}&limit=${limit * 10}` : `${this.baseUrl}/sites/suggest/popularResults?site=${siteId}&limit=${limit}`;
            SxQueryUtils.get(queryUrl, (data) => {
                const response = new SearchResponse(data);
                const flatSuggests = response.getFlatSuggests().splice(0, limit);
                if (flatSuggests.length === 0) {
                    blockWrap.remove();
                }
                else {
                    this.renderResults(flatSuggests, blockWrap);
                    blockWrap.show();
                }
                renderCallback();
            });
        }
        else if (block.type === NoResultsPageBlockType.PopularQueries) {
            blockWrap.hide();
            let suggestUrl = new ApiConfig(this.baseUrl, this.context.isEcom(), pluginConfiguration.siteId).suggestBaseUrl;
            if (suggestUrl.indexOf('?') === -1) {
                suggestUrl = `${suggestUrl}?`;
            }
            else {
                suggestUrl = `${suggestUrl}&`;
            }
            SxQueryUtils.get(`${suggestUrl}log=false&maxQuerySuggestions=9&limit=9&site=${pluginConfiguration.siteId}&query=`, (data) => {
                const response = new SearchResponse(data);
                const flatSuggests = response.getFlatSuggests();
                if (flatSuggests.length === 0) {
                    blockWrap.remove();
                }
                else {
                    const suggestsList = sxQuery('<ul class="ss360-no-results__popular-queries"></ul>');
                    response.getFlatSuggests().forEach((suggest) => {
                        let href = this.context.getSearchResultType() === SearchResultType.Embed ? pluginConfiguration.results.embedConfig.url : '';
                        href = href || '';
                        if (href.indexOf('?') === -1) {
                            href = `${href}?`;
                        }
                        else {
                            href = `${href}&`;
                        }
                        href = `${href}${encodeURIComponent(pluginConfiguration.results.searchQueryParamName)}=${encodeURIComponent(suggest.getName())}`;
                        const icon = `<i class="ss360-no-results__popular-query-icon" role="presentation">${Icons.getSvgIcon(Icons.MAGNIFIER, pluginConfiguration.style.accentColor, undefined, 18, 18)}</i>`;
                        const $li = sxQuery(`<li class="ss360-no-results__popular-queries-item"><a href="${href}" class="ss360-no-results__popular-query">${icon}${suggest.getName()}</a></li>`);
                        $li.find('.ss360-no-results__popular-query').on('click', (e) => {
                            if (!e.ctrlKey) {
                                e.stopPropagation();
                                e.preventDefault();
                                this.context.core.showResults(suggest.getName());
                            }
                        });
                        suggestsList.append($li);
                    });
                    blockWrap.append(suggestsList);
                    blockWrap.show();
                }
            });
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.TextList) {
            const $ul = sxQuery('<ul class="ss360-no-results__list"></ul>');
            const values = block.value instanceof Array ? block.value : [block.value];
            values.forEach((value) => {
                $ul.append(`<li class="ss360-no-results__list-item">${value}</li>`);
            });
            blockWrap.append($ul);
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.RelatedQueries) {
            if (this.relatedQueries === undefined || this.relatedQueries.length === 0) {
                blockWrap.hide();
            }
            else {
                new RelatedQueries(this.relatedQueries, this.context).render(undefined, false, blockWrap);
            }
            renderCallback();
        }
        else if (block.type === NoResultsPageBlockType.Assistant) {
            const assistantWrap = sxQuery('<div id="zoovu-assistant"></div>');
            blockWrap.append(assistantWrap);
            let base = 'api-tiger.zoovu.com';
            if (block.assistantEnvironment) {
                switch (block.assistantEnvironment) {
                    case AssistantEnvironment.Barracuda:
                        base = "api-barracuda.zoovu.com";
                        break;
                    case AssistantEnvironment.Tiger:
                        base = "api-tiger.zoovu.com";
                        break;
                    case AssistantEnvironment.Orca:
                        base = "orca-api.zoovu.com/advisor-fe-web";
                        break;
                    case AssistantEnvironment.QA10:
                        base = "qa10-api.dev.zoovu.io/advisor-fe-web";
                        break;
                }
            }
            const localeQuery = (block.assistantLocale || '').length > 0 ? `?locale=${block.assistantLocale}` : '';
            const script = `https://${base}/api/v1/advisors/${block.assistantId}/js-loader${localeQuery}`;
            const scriptElement = document.createElement('script');
            scriptElement.setAttribute('src', script);
            blockWrap.append(scriptElement);
            renderCallback();
        }
        if (existingBlock === undefined) {
            layoutFrame.appendToNoResultsFrame(blockWrap);
        }
    }
    renderResults(suggests, wrap) {
        const resultsWrap = sxQuery('<ul class="ss360-no-results__alternative-results ss360-list ss360-grid ss360-grid--lg ss360-grid--sm"></ul>');
        const hasImages = suggests.some((suggest) => suggest.getImage() !== undefined && suggest.getImage() !== null && suggest.getImage() !== '');
        suggests.forEach((suggest) => {
            const $item = this.uiBuilder.buildSuggestItem(suggest, this.query, '_', false, hasImages, undefined, true);
            resultsWrap.append($item);
        });
        wrap.append(resultsWrap);
        ImageHandler(this.context);
    }
}
export default NoResultsPage;
