'use strict';
import StringHelper from "../../global/StringHelper";
import sxQuery from "../../sxQuery/sxQuery";
import UiColors from "../styles/UiColors";
import Icons from "./Icons";
class RelatedQueries {
    constructor(queries, context) {
        this.queries = queries || [];
        this.context = context;
    }
    render(layoutFrame, renderTitle = true, renderTarget) {
        if (this.queries.length === 0) {
            return;
        }
        let title = this.context.pluginConfiguration.results.relatedQueriesTitle;
        const $ul = sxQuery('<ul class="ss360-related-queries__list"></ul>');
        if (renderTitle && title) {
            const $titleItem = sxQuery('<li class="ss360-related-queries__item ss360-related-queries__item--title"></li>');
            const $titleSpan = sxQuery('<span class="ss360-related-queries__title"></span>');
            $titleSpan.text(title);
            $titleItem.append($titleSpan);
            $ul.append($titleItem);
        }
        else if (title) {
            if (title.endsWith(':')) {
                title = title.substring(0, title.length - 1);
            }
            $ul.attr('aria-label', title);
        }
        this.queries.forEach((query) => {
            const $li = sxQuery('<li class="ss360-related-queries__item"></li>');
            const $a = sxQuery(`<button class="ss360-related-queries__term ss360-n-button">${Icons.getSvgIcon(Icons.MAGNIFIER, UiColors.ACCENT_DARK, 'ss360-related-queries__icon', 16, 16)}${StringHelper.escapeHtml(query)}</button>`);
            $a.attr('aria-label', `Search for ${query}`);
            $a.on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.context.core.showResults(query, {
                    submitSource: 'relatedQueries'
                });
                layoutFrame.getSearchBox().val(query);
            });
            $li.append($a);
            $ul.append($li);
        });
        if (renderTarget !== undefined) {
            renderTarget.append($ul);
        }
        else {
            layoutFrame.appendToRelatedQueriesFrame($ul, this.context.pluginConfiguration.results.relatedQueriesPosition);
        }
    }
}
export default RelatedQueries;
