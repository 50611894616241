import sxQuery from "../../../sxQuery/sxQuery";
import { ContentResultRenderer } from "./ContentResultRenderer";
class CustomResultRenderer extends ContentResultRenderer {
    constructor() {
        super(...arguments);
        this.scripts = [];
    }
    resetScripts() {
        this.scripts = [];
    }
    getScripts() {
        return this.scripts;
    }
    getWrapperClassList() {
        return [' ss360-custom-result'];
    }
    renderContent(wrapper, suggest) {
        const html = suggest.getHtml();
        if (html !== undefined && html.indexOf('<script') !== -1) {
            try {
                sxQuery(html).each((node) => {
                    const $node = sxQuery(node);
                    if ($node.get()[0].tagName === 'SCRIPT') {
                        this.scripts.push($node);
                    }
                    else {
                        $node.find('script').each((script) => {
                            this.scripts.push(sxQuery(script));
                        });
                    }
                });
            }
            catch (ex) {
                // ccl
            }
        }
        wrapper.innerHTML = html;
        return wrapper;
    }
}
export default CustomResultRenderer;
