'use strict';
import SuggestType from '../enums/SuggestType';
import SearchSuggest from '../model/SearchSuggest';
import Helper from './Helper';
export default {
    buildAllResultsTab(data) {
        // check placements
        const allResultsPlacements = (((data.searchResults || []).filter((group) => group.type === 'all' && group.placements !== undefined))[0] || {}).placements || [];
        const allResultPlacementMap = {};
        const allResultPlacementIds = allResultsPlacements.reduce((acc, placement) => {
            if (placement.id !== undefined) {
                acc[placement.id] = placement.position;
            }
            return acc;
        }, {});
        const hasAllResultsPlacementIds = Object.keys(allResultPlacementIds).length > 0;
        const hasAllResultPlacements = allResultsPlacements.length > 0;
        // build all results tab
        const groupNames = data.getResultGroupNames();
        let productsResultGroup;
        let allResultsTab = groupNames.reduce((acc, group) => {
            let groupSuggests = data.getSuggestsArray(group);
            if (hasAllResultsPlacementIds) {
                groupSuggests = groupSuggests.filter((suggest) => {
                    const firstSuggest = suggest instanceof Array ? suggest[0] : suggest;
                    const setOriginalContentGroup = () => {
                        if (firstSuggest !== undefined) {
                            try {
                                firstSuggest.setOriginalContentGroup(group);
                            }
                            catch (err) {
                                // ccl
                            }
                        }
                    };
                    if (productsResultGroup === undefined && firstSuggest && firstSuggest.getIdentifier()) {
                        productsResultGroup = group;
                    }
                    const id = firstSuggest.getId();
                    if (allResultPlacementIds[id] !== undefined) {
                        allResultPlacementMap[id] = allResultPlacementIds[id] !== -1 ? suggest : undefined;
                        setOriginalContentGroup();
                        return false;
                    }
                    const groupId = firstSuggest.getGroupId(); // fallback to group id, but id is always preferred
                    if (groupId !== undefined && allResultPlacementIds[groupId] !== undefined && allResultPlacementMap[groupId] === undefined) {
                        allResultPlacementMap[groupId] = allResultPlacementIds[id] !== -1 ? suggest : undefined;
                        setOriginalContentGroup();
                        return false;
                    }
                    return true;
                });
            }
            groupSuggests.forEach((suggest) => {
                suggest.setOriginalContentGroup(group);
            });
            acc = acc.concat(groupSuggests);
            return acc;
        }, []).sort((a, b) => {
            if (a instanceof Array) { // product search
                a = a[0];
            }
            if (b instanceof Array) { // product search
                b = b[0];
            }
            if (a.pinned && b.pinned && !hasAllResultPlacements) {
                return 0;
            }
            if (a.pinned && !hasAllResultPlacements) {
                return -1;
            }
            if (b.pinned && !hasAllResultPlacements) {
                return 1;
            }
            let aVal = a.relevance;
            let bVal = b.relevance;
            let isSorting = false;
            if (data.sorting !== undefined && data.sorting !== '') {
                aVal = parseFloat(((a.getDataPoints() || []).filter((dp) => dp.key === data.sorting)[0] || {}).value);
                bVal = parseFloat(((b.getDataPoints() || []).filter((dp) => dp.key === data.sorting)[0] || {}).value);
                isSorting = true;
            }
            if (aVal === undefined && bVal === undefined) {
                return 0;
            }
            if (isSorting && Helper.isNaN(aVal) && Helper.isNaN(bVal)) {
                return 0;
            }
            if (aVal === undefined || (isSorting && Helper.isNaN(aVal))) {
                return 1;
            }
            if (bVal === undefined || (isSorting && Helper.isNaN(bVal))) {
                return -1;
            }
            if (isSorting && data.sortingOrder === 'ASC') {
                return aVal - bVal;
            }
            return bVal - aVal; // descending (relevance or DESC sorting)
        });
        // inject placements
        if (allResultsPlacements.length > 0 && allResultsTab !== undefined) {
            const placements = allResultsPlacements.reduce((acc, placement) => {
                if (placement.id !== undefined && allResultPlacementMap[placement.id] !== undefined) {
                    acc[placement.position] = allResultPlacementMap[placement.id];
                }
                else {
                    const suggest = new SearchSuggest(placement);
                    if (suggest.getIdentifier() && productsResultGroup) {
                        suggest.setOriginalContentGroup(productsResultGroup);
                    }
                    acc[placement.position] = suggest;
                }
                return acc;
            }, {});
            Object.keys(placements).sort((a, b) => parseInt(a, 10) - parseInt(b, 10)).forEach((position) => {
                const nPosition = parseInt(position, 10);
                allResultsTab.splice(nPosition, 0, placements[nPosition]);
            });
        }
        // deduplicate custom results
        const addedCustomResults = [];
        allResultsTab = allResultsTab.filter((suggest) => {
            if (suggest.getType() !== SuggestType.HTML) {
                return true;
            }
            for (let i = 0; i < addedCustomResults.length; i++) {
                if (addedCustomResults[i].equals(suggest)) {
                    return false;
                }
            }
            addedCustomResults.push(suggest);
            return true;
        });
        allResultsTab = allResultsTab.filter((suggest) => {
            return suggest.suggest === undefined || suggest.suggest.position !== -1;
        });
        data.addResultGroup('*', undefined, allResultsTab);
        data.additionalResults = allResultsPlacements.filter((placement) => placement !== undefined && placement.position !== -1
            && placement.type !== undefined && placement.type === 'HTML' || placement.type === 'custom' || placement.type === 'CUSTOM').length;
    }
};
