'use strict';
import SxQueryObject, { _constructz } from './SxQueryObject';
import SxQueryUtils from './SxQueryUtils';
const sxQuery = _constructz;
Object.keys(SxQueryUtils).forEach((key) => {
    sxQuery[key] = SxQueryUtils[key];
});
const handler = {
    set(obj, prop, value) {
        SxQueryObject.prototype[prop] = value;
        return true;
    }
};
try {
    sxQuery.fn = new Proxy({}, handler);
}
catch (e) { // proxy not supported --> IE
    sxQuery.fn = SxQueryObject.prototype;
}
export default sxQuery;
