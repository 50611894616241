'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import UiColors from '../styles/UiColors';
import Icons from './Icons';
let isAvailable = ('webkitSpeechRecognition' in window);
if (isAvailable) { // doesn't work on Chrome on iOS
    try {
        const userAgent = ('navigator' in window) && window.navigator.userAgent ? window.navigator.userAgent : '';
        if (userAgent.match(/iPhone OS/)) {
            isAvailable = userAgent.match(/CriOS/) === null && userAgent.match(/FxiOS/) === null;
        }
    }
    catch (err) {
        // ccl
    }
}
class VoiceSearch {
    constructor(searchBox, callback, voiceSearchConfig, isCustomSearchBox = false) {
        if (!isAvailable) {
            return;
        }
        this.searchBox = searchBox;
        this.callback = callback;
        this.config = voiceSearchConfig;
        const isCustomOrInjected = searchBox.parent().hasClass('ss360-section');
        const clearButton = searchBox.parent().find('.ss360-custom-search__clear-button');
        const lang = voiceSearchConfig.lang;
        const color = voiceSearchConfig.color;
        const repositionTrigger = voiceSearchConfig.repositionTrigger;
        const autoPosition = voiceSearchConfig.autoPosition;
        const voiceButton = sxQuery(`<button type="button" aria-label='Search by speech' class='ss360-voice-search ss360-flex ss360-flex--align-center ss360-flex--justify-center ss360-n-button'
			${autoPosition ? " style='display: none;position:absolute'" : ''}>${Icons.getSvgIcon(Icons.MICROPHONE, color)}</button>'`);
        let timeoutId = -1;
        // position
        if (isCustomSearchBox) {
            voiceButton.show();
        }
        if (autoPosition && !isCustomSearchBox && !isCustomOrInjected) {
            const resizeCallback = function () {
                const sbHeight = searchBox.outerHeight();
                let voicePadding = 0;
                if (sbHeight >= 40) {
                    voicePadding = 10;
                }
                else if (sbHeight >= 30) {
                    voicePadding = sbHeight - 30;
                }
                const voiceButtonDimension = sbHeight - voicePadding;
                const topOffset = searchBox.offset().top - searchBox.parent().offset().top;
                const leftOffset = searchBox.offset().left - searchBox.parent().offset().left;
                const sbPadding = voiceButtonDimension + (voicePadding / 2);
                // @ts-ignore
                const stylesheetPadding = parseInt((searchBox.css('paddingRight') || '0').replace('px', ''), 10);
                voiceButton.css('position', 'absolute');
                voiceButton.css('top', topOffset + (voicePadding / 2));
                voiceButton.css('height', voiceButtonDimension);
                voiceButton.css('width', voiceButtonDimension);
                voiceButton.css('left', leftOffset + searchBox.outerWidth() - voiceButtonDimension - (voicePadding / 2));
                if (searchBox.css('boxSizing') !== 'border-box') {
                    // @ts-ignore
                    searchBox.css('width', parseFloat((searchBox.css('width') || '').replace('px', '')) - sbPadding + stylesheetPadding);
                }
                searchBox.css('paddingRight', sbPadding);
                voiceButton.show();
            };
            const resizeVoiceButton = function () {
                try {
                    clearTimeout(timeoutId);
                    searchBox.get()[0].style.paddingRight = null;
                    searchBox.get()[0].style.width = null;
                    // @ts-ignore
                    timeoutId = setTimeout(resizeCallback, 250);
                }
                catch (ex) {
                    // ccl
                }
            };
            resizeCallback();
            resizeVoiceButton();
            sxQuery(window).on('resize', resizeVoiceButton);
            if (repositionTrigger !== undefined) {
                sxQuery(repositionTrigger, true).on('click', resizeVoiceButton);
            }
            this.reposition = resizeVoiceButton;
        }
        else {
            if (clearButton.length > 0 && clearButton.css('display') !== 'none') {
                searchBox.css('paddingRight', '70px');
                clearButton.addClass('ss360-custom-search__clear-button--with-voice');
            }
            else {
                searchBox.css('paddingRight', '35px');
            }
        }
        // recognition
        let recognition;
        const stop = function () {
            voiceButton.removeClass('ss360-voice-search--recording');
            if (recognition !== undefined) {
                recognition.stop();
                recognition = undefined;
            }
        };
        // bind listener and render
        voiceButton.click((e) => {
            e.preventDefault();
            e.stopPropagation();
            if (recognition !== undefined) {
                stop();
            }
            else {
                /* eslint-disable */
                // @ts-ignore
                recognition = new webkitSpeechRecognition();
                /* eslint-enable */
                recognition.continuous = false;
                recognition.interimResults = false;
                recognition.lang = lang;
                recognition.onstart = function () {
                    voiceButton.addClass('ss360-voice-search--recording');
                    if (voiceButton.find('svg').find('line').length > 0) {
                        const svgCopy = sxQuery(voiceButton.find('svg').get()[0].outerHTML);
                        svgCopy.find('line').remove();
                        voiceButton.get()[0].innerHTML = svgCopy.get()[0].outerHTML;
                    }
                };
                recognition.onresult = function (event) {
                    const query = event.results[0][0].transcript;
                    sxQuery(searchBox).val(query);
                    callback(query, {
                        shouldPushState: true,
                        sbRef: sxQuery(voiceButton)
                    });
                    stop();
                };
                recognition.onerror = function (error) {
                    const err = error.error;
                    console.error(err);
                    if (err === 'not-allowed' || err === 'service-not-allowed') {
                        if (voiceButton.find('svg').find('line').length === 0) {
                            const svgCopy = sxQuery(voiceButton.find('svg').get()[0].outerHTML);
                            svgCopy.append(`<line y1="24" x2="24.5" y2="0" stroke="${UiColors.RED}" x1="-0.5" stroke-width="3"></line>`);
                            voiceButton.get()[0].innerHTML = svgCopy.get()[0].outerHTML;
                        }
                        voiceButton.attr('title', 'Permission to use microphone is blocked. Please go to your browser settings to enable microphone usage.');
                    }
                    stop();
                };
                recognition.onend = function () {
                    stop();
                };
                recognition.start();
            }
        });
        searchBox.parent().append(voiceButton);
    }
}
export default VoiceSearch;
