'use strict';
import Logger from '../../global/Logger';
import TrackingProvider from '../enums/TrackingProvider';
const setPageGtm = (currentUrl) => {
    // @ts-ignore
    if (window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            event: 'VirtualPageview',
            category: 'search',
            virtualPageURL: currentUrl,
            virtualPageTitle: document.title
        });
    }
};
class Reporter {
    constructor(config) {
        this.config = config;
    }
    setPage(currentUrl) {
        const providers = this.config.tracking.providers;
        // now inform tracking systems
        if (providers.indexOf(TrackingProvider.GA) > -1) {
            this.setPageGa(currentUrl);
        }
        if (providers.indexOf(TrackingProvider.GTM) > -1) {
            setPageGtm(currentUrl);
        }
    }
    getGa() {
        if (this.config.tracking.gaAlias !== undefined) {
            // @ts-ignore
            return window[this.config.tracking.gaAlias];
        }
        // @ts-ignore
        return window.ga;
    }
    // Google Analytics
    setPageGa(currentUrl) {
        const gaTracker = this.getGa();
        if (gaTracker) {
            gaTracker('set', 'page', currentUrl);
        }
    }
    shouldTrack(context) {
        if (this.shouldTrackCache === undefined) {
            this.shouldTrackCache = this.config.tracking.logQueries && context.readCookie('-tracking') !== '0';
        }
        return this.shouldTrackCache;
    }
    reportSerp(query, context) {
        if (!this.shouldTrack(context)) {
            return;
        }
        let shouldReport = true;
        const trackingConfig = this.config.tracking;
        const searchCallback = trackingConfig.searchCallback;
        if (searchCallback !== undefined && typeof searchCallback === 'function') {
            try {
                shouldReport = searchCallback.call(this, query) !== false;
            }
            catch (err) {
                Logger.warn(err);
            }
        }
        if (!shouldReport) {
            return;
        }
        // build URL
        let queryParamName = this.config.results.searchQueryParamName;
        let urlBase = window.location.href.split(window.location.origin)[1] || '';
        if (urlBase === '/') {
            urlBase = '';
        }
        if (queryParamName === undefined || queryParamName === '') {
            queryParamName = 'ss360Query';
        }
        try {
            urlBase = urlBase.replace(new RegExp(`${queryParamName}=[^&]+`, 'g'), '').replace(/\?&/g, '?').replace(/&&/g, '&');
        }
        catch (ex) {
            // ccl
        }
        if (urlBase.indexOf('?') === urlBase.length - 1) {
            urlBase = urlBase.substring(0, urlBase.length - 1);
        }
        const queryChar = urlBase.indexOf('?') === -1 ? '?' : '&';
        const currentUrl = trackingConfig.ignoreQueryParam ? urlBase : `${urlBase}${queryChar}${queryParamName}=${encodeURI(query).toLowerCase()}`;
        this.setPage(currentUrl);
        const gaTracker = this.getGa();
        if (trackingConfig.providers.indexOf(TrackingProvider.GA) > -1 && (!!gaTracker)) {
            gaTracker('send', 'pageview');
            gaTracker((tracker) => {
                if (tracker === undefined) {
                    gaTracker.getAll().forEach((tr) => {
                        tr.set('page', currentUrl);
                        tr.send('pageview');
                    });
                }
            });
        }
    }
}
export default Reporter;
