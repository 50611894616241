'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import { AllResultsStrategy, NavigationType } from '../interface/LayoutConfiguration';
class Navigation {
    constructor(data, context, sorting = false) {
        this.sorting = false;
        this.data = data;
        this.context = context;
        this.sorting = sorting;
        const pluginConfiguration = context.pluginConfiguration;
        const { navigation } = pluginConfiguration.layout;
        this.position = navigation.position;
        this._isTabbed = navigation.type === NavigationType.Tabs;
        this.entryCount = this._getEntries().length;
        this.pluginConfiguration = pluginConfiguration;
        this.nav = null;
        this.headings = {};
        this.keyToName = {};
        this.keys = [];
    }
    _getEntries() {
        if (this.sorting) {
            return this.data.sortingOptions.map((sortingOption) => (typeof sortingOption === 'string') ? sortingOption : sortingOption.name);
        }
        return this.data.getResultGroupNames();
    }
    getPosition() { return this.position; }
    isTabbed() { return this._isTabbed; }
    setHeading(heading) {
        if (!this.pluginConfiguration.layout.navigation.forceCaption && !this.sorting) {
            this.getHeadingLink().html(heading);
        }
    }
    bindClickHandler(navEntry, safeKey, heading, searchQuery, contentGroupName) {
        navEntry.find('button').on('click', () => {
            if (this.sorting) {
                if (this.sortingCallback !== undefined) {
                    this.sortingCallback(safeKey);
                }
                return;
            }
            const layoutFrame = this.context.layer.get();
            const isGroupedAllResults = safeKey === 'ss360_all_results' && this.pluginConfiguration.layout.navigation.allResultsStrategy === AllResultsStrategy.Grouped;
            const group = isGroupedAllResults ? layoutFrame.getResultGroups() : layoutFrame.getResultGroups(safeKey);
            const $toFocus = sxQuery(group.find('.ss360-suggests:first .ss360-suggests__link').get(0));
            // toggle tab if tabbed
            if (this._isTabbed) {
                if (sxQuery(navEntry).hasClass('ss360-nav__menu-item--active')) { // same tab
                    return;
                }
                const $nav = this.nav;
                layoutFrame.getResultGroups().removeClass('ss360-group--active');
                $nav.find('.ss360-nav__menu-item').removeClass('ss360-nav__menu-item--active');
                group.addClass('ss360-group--active');
                navEntry.addClass('ss360-nav__menu-item--active');
                $nav.find('.ss360-nav__entry').removeClass(this.getTabActiveClassName());
                navEntry.find('.ss360-nav__entry').addClass(this.getTabActiveClassName());
                this.setHeading(heading);
            }
            if (!this._isTabbed) {
                this.scrollTo(group);
            }
            else {
                this.persistOpenTab(searchQuery, safeKey);
            }
            this._addClassNameToLayer(safeKey);
            this.callNavigationClickCallback(contentGroupName);
            // focus first result
            if (this.pluginConfiguration.results.focusResultBlock) {
                setTimeout(() => {
                    $toFocus.focus();
                }, 5);
            }
        });
    }
    highlightSortingEntry(idx) {
        const $nav = this.nav;
        $nav.find('.ss360-nav__menu-item').removeClass('ss360-nav__menu-item--active');
        $nav.find('.ss360-nav__entry').removeClass(this.getTabActiveClassName());
        const $el = sxQuery($nav.find('.ss360-nav__menu-item').get(idx));
        $el.addClass('ss360-nav__menu-item--active');
        $el.find('.ss360-nav__entry').addClass(this.getTabActiveClassName());
    }
    getTabActiveClassName() {
        return 'ss360-nav__entry--active';
    }
    _addClassNameToLayer(safeKey) {
        if (this.sorting) {
            return;
        }
        const layoutFrame = this.context.layer.get();
        this.keys.forEach((key) => {
            layoutFrame.removeClass(`ss360-layer--group-${key}`);
        });
        if (safeKey !== undefined) {
            layoutFrame.addClass(`ss360-layer--group-${safeKey}`);
        }
    }
    _buildNav(position) {
        if (position !== 'left' && position !== 'top') { // invalid position
            return null;
        }
        const $node = sxQuery('<ul class="ss360-nav__menu" role="menubar"></ul>');
        return $node;
    }
    build(layer) {
        if (this.entryCount < 2)
            return;
        this.nav = this._buildNav(this.position);
        if (this.nav === null) {
            return;
        }
        if (this.position !== 'top') {
            layer.getLayerContent().addClass('ss360-layer__content--right');
        }
        if (this._isTabbed) {
            layer.getLayerContent().addClass('ss360-layer__content--tabs');
        }
        layer.appendToNavFrame(this.nav, this.position === 'left');
    }
    scrollTo(el) {
        el.get(0).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    focusTab(contentGroupKey) {
        if (this.nav === null || !this._isTabbed || this.sorting) {
            return;
        }
        const isGroupedAllResults = contentGroupKey === 'ss360_all_results' && this.pluginConfiguration.layout.navigation.allResultsStrategy === AllResultsStrategy.Grouped;
        const layoutFrame = this.context.layer.get();
        const targetGroup = isGroupedAllResults ? layoutFrame.getResultGroups() : layoutFrame.getResultGroups(contentGroupKey);
        if (targetGroup.length === 0) {
            return;
        }
        const $nav = this.nav;
        $nav.find('.ss360-nav__menu-item').removeClass('ss360-nav__menu-item--active');
        $nav.find('.ss360-nav__entry').removeClass(this.getTabActiveClassName());
        const $activeEl = $nav.find(`.ss360-nav__menu-item[data-cgkey='${contentGroupKey}']`);
        $activeEl.addClass('ss360-nav__menu-item--active');
        $activeEl.find('.ss360-nav__entry').addClass(this.getTabActiveClassName());
        layoutFrame.getResultGroups().removeClass('ss360-group--active');
        targetGroup.addClass('ss360-group--active');
        this.setHeading(this.headings[contentGroupKey]);
    }
    getHeadingLink() {
        return this.context.layer.get().getHeadingFrame().find('a');
    }
    addEntry(viewKey, safeKey, query, resultCount, idx, originalKey) {
        if (this.nav === null || viewKey === undefined || viewKey.length === 0) {
            return;
        }
        const aid = Math.round(10000 * Math.random());
        const { navigation } = this.pluginConfiguration.layout;
        let heading = '';
        // heading
        if (!this.sorting) {
            // @ts-ignore
            const headingTemplate = navigation.tabTitle || this.getHeadingLink().text();
            const rawHeadingString = safeKey !== 'ss360_all_results' ? headingTemplate : (navigation.allResultsTabTitle || '');
            heading = rawHeadingString.replace(/#COUNT#/g, resultCount.toString()).replace(/#NAME#/g, viewKey).replace(/#QUERY#/g, query);
            this.headings[safeKey] = heading;
            this.keyToName[safeKey] = viewKey;
        }
        this.keys.push(safeKey);
        const navEntry = this.createNavigationEntry(aid, viewKey, safeKey, navigation.showGroupResultCount, resultCount);
        // is current entry active?
        if (idx === 0 && this._isTabbed) {
            navEntry.addClass('ss360-nav__menu-item--active');
            navEntry.find('.ss360-nav__entry').addClass(this.getTabActiveClassName());
            if (heading) {
                this.setHeading(heading);
            }
        }
        this.bindClickHandler(navEntry, safeKey, heading, query, originalKey);
        this.nav.append(navEntry);
    }
    setSortingCallback(callback) {
        this.sortingCallback = callback;
    }
    isSorting() {
        return this.sorting;
    }
    onResultsRendered(isPopState = false) {
        if (this.sorting) {
            return;
        }
        if (this._isTabbed) {
            if (this.keys.length === 1) { // ensure the first tab is focused
                this.focusTab(this.keys[0]);
                this._addClassNameToLayer(undefined);
            }
            else {
                const lastOpenTab = this.context.readLastOpenTab();
                const { navigation } = this.pluginConfiguration.layout;
                const query = this.data.query;
                if (lastOpenTab !== null && lastOpenTab !== undefined && (lastOpenTab.split('<#>')[0] === query || isPopState || navigation.keepOpenTab)) { // should we reopen a certain tab, e.g. after a page refresh? in case of a popstate we also keep the current tab open
                    const groupName = lastOpenTab.split('<#>')[1];
                    this.focusTab(groupName);
                    this._addClassNameToLayer(this.keys.length > 0 ? groupName : undefined);
                }
                else {
                    this._addClassNameToLayer(this.keys.length > 0 ? this.keys[0] : undefined);
                }
            }
        }
    }
    persistOpenTab(searchQuery, safeKey) {
        if (this.sorting) {
            return;
        }
        const cookieValue = `${searchQuery}<#>${safeKey}`;
        if (this.pluginConfiguration.allowCookies) { // store open tab
            this.context.createCookie('-open-tab', cookieValue, 1 / 24);
        }
        this.context.setLastOpenTab(cookieValue);
    }
    callNavigationClickCallback(contentGroupName) {
        if (this.sorting) {
            return;
        }
        const { navigationClick } = this.pluginConfiguration.callbacks;
        if (navigationClick !== undefined && (typeof navigationClick) === 'function') {
            navigationClick(contentGroupName);
        }
        this.context.notify('navigationChange', contentGroupName);
    }
    createNavigationEntry(aid, viewKey, safeKey, shouldShowCount, count) {
        const entry = sxQuery(`<li class="ss360-nav__menu-item ss360-n-li" data-cgkey="${safeKey}" role="none"></li>`);
        const btn = sxQuery(`<button type="button" id="navelement${aid}" class="ss360-nav__entry ss360-n-button" role="menuitem">${viewKey}</button>`);
        this.getTabActiveClassName();
        entry.append(btn);
        if (shouldShowCount && count !== undefined) {
            entry.find('button').append(`<span class="ss360-nav__result-count">${count}</span>`);
        }
        return entry;
    }
}
export default Navigation;
