'use strict';
export var NavigationType;
(function (NavigationType) {
    NavigationType["Tabs"] = "tabs";
    NavigationType["Scroll"] = "scroll";
})(NavigationType || (NavigationType = {}));
export var AllResultsStrategy;
(function (AllResultsStrategy) {
    AllResultsStrategy["Mixed"] = "mixed";
    AllResultsStrategy["Grouped"] = "grouped";
})(AllResultsStrategy || (AllResultsStrategy = {}));
