'use strict';
const getBaseUrl = (url) => {
    if (url.indexOf('?') === -1) {
        return `${url}?`;
    }
    if (url[url.length - 1] !== '&' && url[url.length - 1] !== '?') {
        return `${url}&`;
    }
    return url;
};
const getDomain = (url) => {
    return url.split('/').splice(0, 3).join('/');
};
class UrlBuilder {
    constructor(apiConfig, configuration) {
        this.apiConfig = apiConfig;
        this.searchBaseUrl = getBaseUrl(apiConfig.baseUrl);
        this.suggestBaseUrl = getBaseUrl(apiConfig.suggestBaseUrl);
        this.pluginConfiguration = configuration;
    }
    buildQueryUrl(params) {
        const { limit, sort, include, exclude, tracking, query, offset, limitPerGroup, groupResults, filters, isPlain, includeQueries, sortComplex, addQueries, excludeQueries, ignoreOther, disableAutoCorrect, sessionId, additionalRequestParams, activeGuidedQuestions, presetFilters, partialFilterMatch } = params;
        let queryUrl = `${this.searchBaseUrl}site=${this.pluginConfiguration.siteId}&includeContent=true&limit=${limit}`;
        if (sort !== undefined) {
            queryUrl += `&sort=${encodeURIComponent(sort)}`;
        }
        if (groupResults === false) {
            queryUrl += '&groupResults=false';
        }
        if (limitPerGroup) {
            queryUrl += '&limitPerGroup=true';
        }
        const { results } = this.pluginConfiguration;
        if (results.highlightQueryTerms === true) {
            queryUrl += '&highlightQueryTerms=true';
        }
        if (include !== undefined) {
            queryUrl += `&includeContentGroups=${encodeURIComponent(JSON.stringify(include))}`;
        }
        if (exclude !== undefined || ignoreOther) {
            const excludeCopy = [...(exclude || [])];
            if (ignoreOther && excludeCopy.indexOf('_') === -1) {
                excludeCopy.push('_');
            }
            queryUrl += `&excludeContentGroups=${encodeURIComponent(JSON.stringify(excludeCopy))}`;
        }
        if (filters !== undefined) {
            queryUrl += `&filters=${encodeURIComponent(JSON.stringify(filters))}`;
        }
        if (presetFilters && presetFilters.length > 0) {
            queryUrl += `&preSetFilters=${encodeURIComponent(JSON.stringify(presetFilters))}`;
        }
        queryUrl += `&log=${tracking}`;
        if (this.pluginConfiguration.filters.enabled) {
            queryUrl += '&filterOptions=true';
        }
        queryUrl += `&offset=${offset}`;
        queryUrl += `&query=${encodeURIComponent(query)}`;
        const cacheSettings = this.pluginConfiguration._cacheQueries;
        if (cacheSettings !== undefined && (cacheSettings === true || cacheSettings === false)) {
            queryUrl += `&cache=${cacheSettings}&caching=${cacheSettings}`;
        }
        if (!isPlain) {
            queryUrl += '&plain=false';
        }
        if (results.nameParsing && filters !== undefined) {
            queryUrl += '&nameParsing=true';
        }
        if (results.fuzziness !== undefined || params.fuzziness !== undefined) {
            queryUrl += `&fuzziness=${results.fuzziness || params.fuzziness}`;
        }
        if (includeQueries !== undefined && includeQueries.length > 0) {
            queryUrl += `&includeQueries=${encodeURIComponent(JSON.stringify(includeQueries))}`;
        }
        if (addQueries !== undefined && addQueries.length > 0) {
            queryUrl += `&addQueries=${encodeURIComponent(JSON.stringify(addQueries))}`;
        }
        if (excludeQueries !== undefined && excludeQueries.length > 0) {
            queryUrl += `&excludeQueries=${encodeURIComponent(JSON.stringify(excludeQueries))}`;
        }
        if (sortComplex !== undefined) {
            queryUrl += `&sortComplex=${encodeURIComponent(JSON.stringify(sortComplex))}`;
        }
        if (disableAutoCorrect) {
            queryUrl += '&autocorrect=false';
        }
        if (this.pluginConfiguration.catalogId !== undefined) {
            queryUrl += `&catalogId=${encodeURIComponent(this.pluginConfiguration.catalogId)}`;
        }
        if (this.pluginConfiguration.userGroup !== undefined) {
            queryUrl += `&userGroup=${encodeURIComponent(this.pluginConfiguration.userGroup)}`;
        }
        if (this.pluginConfiguration.queryTag !== undefined) {
            queryUrl += `&queryTag=${encodeURIComponent(this.pluginConfiguration.queryTag)}`;
        }
        if (this.pluginConfiguration.similarContent.active && this.pluginConfiguration.similarContent.includeTag !== undefined) {
            queryUrl += `&includeTags=${encodeURIComponent(JSON.stringify([this.pluginConfiguration.similarContent.includeTag]))}`;
        }
        if (sessionId) {
            queryUrl += `&sessionId=${encodeURIComponent(sessionId)}`;
        }
        if (params.source) {
            queryUrl += `&source=${encodeURIComponent(params.source)}`;
        }
        if (additionalRequestParams) {
            Object.keys(additionalRequestParams).forEach((key) => {
                queryUrl = `${queryUrl}&${encodeURIComponent(key)}=${encodeURIComponent(additionalRequestParams[key])}`;
            });
        }
        if (activeGuidedQuestions && activeGuidedQuestions.length > 0) {
            queryUrl = `${queryUrl}&activeGuidedQuestions=${encodeURIComponent(JSON.stringify(activeGuidedQuestions))}`;
        }
        if (partialFilterMatch) {
            queryUrl = `${queryUrl}&filterMatchType=ALLOW_PARTIAL_FILTER_MATCH`;
        }
        queryUrl += '&kvtable=false';
        queryUrl += '&complexFilterFormat=true';
        return queryUrl;
    }
    buildSuggestionUrl(tracking) {
        const { suggestions } = this.pluginConfiguration;
        if (!suggestions.show) {
            return '';
        }
        let queryUrl = `${this.suggestBaseUrl}log=${tracking}`;
        if (suggestions.equalSearch) {
            queryUrl = queryUrl.replace('/sites/suggest', '/sites');
            queryUrl += '&includeContent=true';
        }
        if (this.pluginConfiguration.results.group === false) {
            queryUrl += '&groupResults=false';
        }
        const cacheSettings = this.pluginConfiguration._cacheQueries;
        if (cacheSettings !== undefined && (cacheSettings === true || cacheSettings === false)) {
            queryUrl += `&cache=${cacheSettings}&caching=${cacheSettings}`;
        }
        const { fuzziness } = suggestions;
        if (fuzziness !== undefined) {
            queryUrl += `&fuzziness=${fuzziness}`;
        }
        return queryUrl;
    }
    buildProductDataUrl(identifier, siteId) {
        return `${getDomain(this.apiConfig.baseUrl)}/product-service?identifier=${encodeURIComponent(identifier)}&projectId=${encodeURIComponent(siteId)}`;
    }
    buildReloadResultsUrl(sessionId, activeGuidedQuestions, fingerprint) {
        const siteId = this.pluginConfiguration.siteId;
        let queryUrl = `${getDomain(this.searchBaseUrl)}/search/result-set/${encodeURIComponent(sessionId)}?site=${encodeURIComponent(siteId)}&projectId=${encodeURIComponent(siteId)}`;
        if (this.pluginConfiguration.similarContent.active && this.pluginConfiguration.similarContent.includeTag !== undefined) {
            queryUrl += `&includeTags=${encodeURIComponent(JSON.stringify([this.pluginConfiguration.similarContent.includeTag]))}`;
        }
        if (activeGuidedQuestions && activeGuidedQuestions.length > 0) {
            queryUrl = `${queryUrl}&activeGuidedQuestions=${encodeURIComponent(JSON.stringify(activeGuidedQuestions))}`;
        }
        if (fingerprint) {
            queryUrl = `${queryUrl}&requestFingerprint=${encodeURIComponent(fingerprint)}`;
        }
        return queryUrl;
    }
    setSearchBase(url) {
        this.searchBaseUrl = getBaseUrl(url);
    }
    setSuggestBase(url) {
        this.suggestBaseUrl = getBaseUrl(url);
    }
    buildMiniPdpUrl(identifier, includeTag, includeRelation) {
        const additionalQueryParams = [];
        if (this.pluginConfiguration.userGroup) {
            additionalQueryParams.push(`userGroup=${encodeURIComponent(this.pluginConfiguration.userGroup)}`);
        }
        if (includeTag) {
            additionalQueryParams.push(`includeTags=${encodeURIComponent(JSON.stringify([includeTag]))}`);
        }
        if (includeRelation) {
            additionalQueryParams.push(`includeRelations=${encodeURIComponent(JSON.stringify([includeRelation]))}`);
        }
        return `${getDomain(this.searchBaseUrl)}/products/search/article/mini-pdp?identifier=${encodeURIComponent(identifier)}&projectId=${this.pluginConfiguration.siteId}${additionalQueryParams.length > 0 ? '&' : ''}${additionalQueryParams.join('&')}`;
    }
}
export default UrlBuilder;
