'use strict';
import sxQuery from '../sxQuery/sxQuery';
import UiHelper from './ui/UiHelper';
import StringHelper from '../global/StringHelper';
import NavigatorHelper from './utils/NavigatorHelper';
import UiBuilder from './ui/UiBuilder';
import ConfigurationHelper from './configuration/ConfigurationHelper';
import Helper from './utils/Helper';
import Handlers from './ui/Handlers';
import Smart404 from './components/Smart404';
import StyleApplier from './components/StyleApplier';
import VoiceSearch from './components/VoiceSearch';
import Results from './components/Results';
import Navigation from './components/Navigation';
import Sorting from './components/Sorting';
import Skelet from './ui/Skelet';
import ImageHandler from './utils/ImageHandler';
import Masonry from './layout/Masonry';
import SubConfigApplier from './utils/SubConfigApplier';
import Logger from '../global/Logger';
import Layer from './components/Layer';
import ContentGroupHelper from '../global/ContentGroupHelper';
import ErrorScreen from './components/ErrorScreen';
import { createContext } from './Context';
import ApiConfig, { SEARCH_BASE, ECOM_DEV_SEARCH_BASE, ECOM_SEARCH_BASE, ECOM_ZOOVU_SEARCH_BASE, ECOM_STAGE_SEARCH_BASE } from './api/ApiConfig';
import SearchResponse from './model/SearchResponse';
import SearchResultType from './enums/SearchResultType';
import SxQueryObject from '../sxQuery/SxQueryObject';
import Icons from './components/Icons';
import Plan from './enums/Plan';
import SearchSuggest from './model/SearchSuggest';
import CategorySearch from './components/CategorySearch';
import FilterType from './enums/FilterType';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
import ListGridToggle from './components/ListGridToggle';
import SearchContext from './enums/SearchContext';
import LayoutType from './enums/LayoutType';
import RelatedQueries from './components/RelatedQueries';
import GuidedQuestions from './components/GuidedQuestions';
import AnimatedPlaceholder from './ui/AnimatedPlaceholder';
import FilterPosition from './enums/FilterPosition';
import PageSizeSelector from './model/PageSizeSelector';
import MiniPDP, { closeAllMiniPDPs, isMiniPDPOpen } from './components/MiniPDP';
import Comparison from './components/Comparison';
import BackToTop from './components/BackToTop';
const mergeWithoutFilters = (filters = []) => {
    const withoutFilterMap = {};
    filters.forEach((filter) => {
        try {
            if (filter.without && filter.values && filter.name && filter.values.length > 0 && filter.values.reduce((acc, val) => {
                if (!acc) {
                    return false;
                }
                return val.value;
            }, true)) {
                if (withoutFilterMap[filter.name] === undefined) {
                    withoutFilterMap[filter.name] = [];
                }
                filter.values.forEach((val) => {
                    if (withoutFilterMap[filter.name].indexOf(val.value) === -1) {
                        withoutFilterMap[filter.name].push(val.value);
                    }
                });
            }
        }
        catch (err) {
            // ccl
        }
    });
    Object.keys(withoutFilterMap).forEach((key) => {
        filters = filters.filter((filter) => {
            if (filter.without) {
                return filter.name !== key;
            }
            return true;
        });
        filters.push({
            name: key,
            without: true,
            values: withoutFilterMap[key].map((value) => ({ value }))
        });
    });
    return filters;
};
class SiteSearch360Core {
    constructor(pluginSettings, isDefaultInstance = true, isZoovu = false) {
        this.lastActiveGuidedQuestions = [];
        this.filterSubmitLeft = false;
        this.partialFilterMatch = false;
        this.filterBlocks = []; // unknown to prevent importing
        // @ts-ignore
        this.VERSION = SS360_VERSION;
        this.initContext(pluginSettings, isDefaultInstance, isZoovu);
        this.initTracking();
    }
    initContext(pluginSettings, isDefaultInstance = true, isZoovu = false) {
        const siteId = pluginSettings.siteId;
        this.siteId = siteId;
        const ecom = pluginSettings.ecom;
        const ecomDev = pluginSettings.ecomDev;
        const ecomStage = pluginSettings.ecomStage;
        let baseUrl = SEARCH_BASE;
        let suggestBaseUrl;
        let isFullBaseUrl = false;
        if (ecomDev) {
            baseUrl = ECOM_DEV_SEARCH_BASE;
        }
        else if (ecom) {
            baseUrl = isZoovu ? ECOM_ZOOVU_SEARCH_BASE : ECOM_SEARCH_BASE;
        }
        else if (ecomStage) {
            baseUrl = ECOM_STAGE_SEARCH_BASE;
        }
        if (pluginSettings.baseUrl !== undefined) {
            baseUrl = pluginSettings.baseUrl;
            isFullBaseUrl = true;
        }
        if (pluginSettings.suggestBaseUrl !== undefined) {
            suggestBaseUrl = pluginSettings.suggestBaseUrl;
            isFullBaseUrl = true;
        }
        const layer = new Layer();
        this.context = createContext(new ApiConfig(baseUrl, ecom || ecomDev || ecomStage, siteId, isFullBaseUrl, suggestBaseUrl), pluginSettings, this, layer, isDefaultInstance);
        layer.setContext(this.context);
        this.context.setIsZoovu(isZoovu);
        if (ecom || ecomDev) {
            const ecomBase = isZoovu ? ECOM_ZOOVU_SEARCH_BASE : ECOM_SEARCH_BASE;
            const insightsBase = `${ecom ? ecomBase : ECOM_DEV_SEARCH_BASE}/insights`;
            const insightsParams = { projectId: siteId };
            pluginSettings.tracking.enhancedBaseUrl = insightsBase;
            pluginSettings.tracking.enhancedParams = insightsParams;
        }
        if (pluginSettings.baseUrl !== undefined) {
            baseUrl = pluginSettings.baseUrl;
            suggestBaseUrl = pluginSettings.suggestBaseUrl;
            isFullBaseUrl = true;
        }
        const pluginConfiguration = this.context.pluginConfiguration;
        this.pluginConfiguration = pluginConfiguration;
        this.trackingConfiguration = pluginConfiguration.tracking;
        this.resultsConfiguration = pluginConfiguration.results;
        this.filtersConfiguration = pluginConfiguration.filters;
        this.callbacksConfiguration = pluginConfiguration.callbacks;
        this.searchBoxConfiguration = pluginConfiguration.searchBox;
        this.contentGroupsConfiguration = pluginConfiguration.contentGroups;
        this.searchResultType = this.context.getSearchResultType();
        this.searchBoxSelector = pluginConfiguration.searchBox.selector;
        this.trackingEnabled = this.trackingConfiguration.logQueries && this.context.readCookie('-tracking') !== '0';
        this.wereSuggestGroupsModified = false;
        this.areResultsVisible = false;
        this.fullScreenOpen = false;
        this.is404 = false;
        this.isLandingPageSearch = false;
        this.autoBlurTime = -1;
        this.searchBoxes = [];
        this.lastSearchTerm = '';
        this.hiddenParts = UiHelper.getHiddenParts(pluginConfiguration.layout);
        this.uiBuilder = this.createUiBuilder();
    }
    initTracking() {
        if (this.trackingEnabled) {
            this.enableInsights();
        }
        if (this.trackingConfiguration.logQueries) {
            sxQuery(window).on('keydown.ss360TrackingDisabler', undefined, (e) => {
                if (e.ctrlKey && e.shiftKey && e.key !== undefined && e.key.toLowerCase() === 'e') {
                    this.trackingEnabled = !this.trackingEnabled;
                    const cookieVal = this.trackingEnabled ? '1' : '0';
                    const prefix = this.context.getIsZoovu() ? 'zoovu-search' : 'ss360';
                    if (this.trackingEnabled) {
                        this.enableInsights();
                        Logger.info(`${prefix}: tracking enabled`);
                    }
                    else {
                        this.disableInsights();
                        Logger.info(`${prefix}: tracking disabled`);
                    }
                    this.context.createCookie('-tracking', cookieVal, 365);
                }
            });
        }
    }
    enableInsights() {
        this.context.createInsights();
        sxQuery(this.searchBoxSelector, true).on('focus.ss360insights', undefined, (e) => {
            this.context.getInsights().trackSearchBoxFocus(e.target);
        });
    }
    disableInsights() {
        this.context.disableInsights();
        sxQuery(this.searchBoxSelector, true).off('focus.ss360insights');
    }
    changeConfig(key, value) {
        this.updateConfig(key, value);
    }
    updateConfig(key, value) {
        ConfigurationHelper.updateConfig(this.pluginConfiguration, key, value);
        if (key === 'siteId') {
            this.setSiteId(value);
        }
        else if (key === 'results.embedConfig') {
            this.searchResultConfig = value;
            if (value !== undefined && this.searchResultType === SearchResultType.Layover) {
                this.context.layer.get().getCloseLayerButton().remove();
                if (!this.pluginConfiguration.results.showSearchBoxEmbed) {
                    this.context.layer.get().detachSearchBarFrame();
                }
            }
        }
        else if (key === 'results.fullscreenConfig.caption' || key === 'results.fullScreenConfig.caption') {
            this.context.layer.get().setFullscreenTitle(value);
        }
        else if (key === 'searchBox.placeholder') {
            this.context.layer.get().getSearchBox().attr('placeholder', value);
        }
        else if (key.indexOf('contentGroups.') === 0 || key.indexOf('suggestions.') === 0 || key.indexOf('results.group') === 0) {
            this.updateSuggestionUrl(this.buildSuggestionUrl());
        }
        else if (key === 'baseUrl') {
            this.setBaseUrl(value);
        }
        else if (key === 'suggestBaseUrl') {
            this.setSuggestUrl(value);
        }
        this.searchResultType = this.context.getSearchResultType();
        if (key.indexOf('layout') !== -1) {
            this.hiddenParts = UiHelper.getHiddenParts(this.pluginConfiguration.layout);
        }
        if (key.indexOf('layout') !== -1 || key.indexOf('results') !== -1) {
            this.uiBuilder = this.createUiBuilder();
        }
        if (key.indexOf('masonryCols') !== -1) {
            Masonry.notifyConfigChange(this.pluginConfiguration.layout);
        }
        if (key === 'activeSubConfigId') {
            SubConfigApplier.apply(this.context);
        }
        if (key === 'userGroup') {
            ConfigurationHelper.updateConfig(this.pluginConfiguration, 'tracking.userGroup', value);
        }
        if (key === 'tracking.userGroup' || key === 'tracking' || key === 'userGroup') {
            if (this.context.hasInsights()) {
                this.context.createInsights();
            }
        }
    }
    setSiteId(newSiteId) {
        if (this.suggestionUrl !== undefined) {
            this.updateSuggestionUrl(this.suggestionUrl.replace(`site=${this.siteId}`, `site=${newSiteId}`));
        }
        this.siteId = newSiteId;
        this.pluginConfiguration.siteId = this.siteId;
        if (this.context.hasInsights()) {
            this.context.createInsights();
        }
    }
    updateSuggestionUrl(newUrl) {
        this.suggestionUrl = newUrl;
        for (let i = 0; i < this.searchBoxes.length; i++) {
            try {
                this.searchBoxes[i].updateSuggestUrl(newUrl);
            }
            catch (err) {
                // ccl
            }
        }
        if (this.triggeredUniboxes !== undefined && this.triggeredUniboxes !== null) {
            for (let i = 0; i < this.triggeredUniboxes.length; i++) {
                try {
                    this.triggeredUniboxes[i].updateSuggestUrl(newUrl);
                }
                catch (err) {
                    // ccl
                }
            }
        }
    }
    createUiBuilder() {
        return new UiBuilder(this.context);
    }
    buildSuggestionUrl() {
        return this.context.urlBuilder.buildSuggestionUrl(this.context.reporter.shouldTrack(this.context));
    }
    addSearchField(lastQuery) {
        if (this.isLandingPageSearch) {
            return;
        }
        const isLayover = this.searchResultType === SearchResultType.Layover;
        if ((isLayover && !this.resultsConfiguration.showSearchBoxLayover)
            || (this.searchResultType === SearchResultType.Embed && !this.resultsConfiguration.showSearchBoxEmbed)) {
            return;
        }
        const layoutFrame = this.context.layer.get();
        if (layoutFrame.getSearchBox().length > 0) {
            return;
        }
        const ss360SearchWrapper = this.uiBuilder.buildSearchField(this.isLandingPageSearch ? '' : lastQuery);
        if (isLayover) {
            ss360SearchWrapper.addClass('ss360-custom-search--no-margin');
        }
        if (this.pluginConfiguration.voiceSearch.enabled === true) {
            new VoiceSearch(ss360SearchWrapper.find(`#${this.context.customSearchBoxId}`), (query, settings) => {
                this.showResults(query, settings);
            }, this.pluginConfiguration.voiceSearch, true);
        }
        layoutFrame.appendToSearchBarFrame(ss360SearchWrapper, isLayover);
    }
    init() {
        this.successInit = true;
        const { uiBuilder, searchResultType, pluginConfiguration, resultsConfiguration, searchBoxConfiguration } = this;
        if (searchBoxConfiguration.inject) {
            const $els = sxQuery(searchBoxConfiguration.injectTo);
            const ids = [];
            const searchButtonIds = [];
            $els.each((el, idx) => {
                // TODO: does clear button work?
                const $el = sxQuery(el);
                const postfix = `--appended-${idx}`;
                const id = `#${this.context.customSearchBoxId}${postfix}`;
                ids.push(id);
                searchButtonIds.push(`#${this.context.customSearchButtonId}${postfix}`);
                const $sb = uiBuilder.buildSearchField(this.lastSearchTerm || NavigatorHelper.buildQueryDict()[pluginConfiguration.results.searchQueryParamName] || '', false, true, postfix, true);
                if (searchBoxConfiguration.injectPosition === 'post') {
                    $el.append($sb);
                }
                else {
                    $el.prepend($sb);
                }
                if (this.pluginConfiguration.voiceSearch.enabled === true) {
                    new VoiceSearch($sb.find(`${id}`), (query, settings) => {
                        this.showResults(query, settings);
                    }, this.pluginConfiguration.voiceSearch, true);
                }
            });
            this.pluginConfiguration.searchBox.selector = ids.join(',');
            this.searchBoxSelector = this.pluginConfiguration.searchBox.selector;
            this.pluginConfiguration.searchBox.searchButton = searchButtonIds.join(',');
            this.searchBoxConfiguration.searchButton = this.pluginConfiguration.searchBox.searchButton;
        }
        uiBuilder.renderLoader();
        if (searchResultType === SearchResultType.Fullscreen) {
            uiBuilder.appendSearchConsole();
            this.addSearchField('');
            // attach event handler
            this.context.layer.get().getCloseLayerButton().click(() => {
                this.closeLayer();
            });
            const trigger = sxQuery(resultsConfiguration.fullScreenConfig.trigger, true);
            trigger.click((event) => {
                this.showFullscreenLayer(event);
            });
            if (trigger.length > 0 && trigger.get()[0].nodeName === 'INPUT' && trigger.attr('type') !== 'button' && trigger.attr('type') !== 'submit') {
                trigger.on('paste', undefined, (event) => {
                    setTimeout(() => {
                        this.showFullscreenLayer(event);
                        // @ts-ignore
                        sxQuery(searchBoxConfiguration.selector).val(trigger.val()).focus();
                    }, 0);
                });
                trigger.on('focus', undefined, (event) => {
                    this.showFullscreenLayer(event);
                });
            }
        }
        const layoverTrigger = pluginConfiguration.results.layoverTrigger;
        if (searchResultType === SearchResultType.Layover && layoverTrigger !== undefined) {
            sxQuery(layoverTrigger, true).on('click', undefined, (e) => {
                e.preventDefault();
                e.stopPropagation();
                const layoutFrame = this.context.layer.get();
                layoutFrame.clear();
                UiHelper.prepareLayoverLayer(layoutFrame, () => {
                    this.closeLayer();
                }, pluginConfiguration);
                this.addSearchField('');
                this.areResultsVisible = true;
            });
        }
        if (searchBoxConfiguration.searchButton !== undefined) {
            const searchButton = sxQuery(searchBoxConfiguration.searchButton);
            if (searchButton.length > 0 && searchButton.attr('disabled') !== null) {
                sxQuery(searchBoxConfiguration.selector).on('input', (e) => {
                    if ((e.target.value || '').length > 0) {
                        searchButton.attr('disabled', null);
                    }
                    else {
                        searchButton.attr('disabled', 'disabled');
                    }
                });
            }
        }
        // handle autofocus
        if (searchBoxConfiguration.autofocus && !searchBoxConfiguration._preventBind) {
            sxQuery(this.searchBoxSelector, true).on('focus.temp', undefined, (e) => {
                e.preventSuggests = true;
            });
            setTimeout(() => {
                sxQuery(this.searchBoxSelector, true).focus();
                sxQuery(this.searchBoxSelector, true).off('focus.temp');
            }, 200);
        }
        const animatedPlaceholder = this.pluginConfiguration.searchBox.animatedPlaceholder;
        if (animatedPlaceholder.enabled) {
            new AnimatedPlaceholder(this.context, sxQuery(this.searchBoxSelector, true), animatedPlaceholder.queries, animatedPlaceholder.maxRotations, animatedPlaceholder.onlyFirstVisit, this.buildSuggestionUrl());
        }
        // initialize uniboxes
        if (!searchBoxConfiguration._preventBind) {
            const uniboxes = sxQuery(this.searchBoxSelector, true).unibox(false);
            if (uniboxes instanceof Array) {
                this.searchBoxes = uniboxes;
            }
            else {
                this.searchBoxes = this.searchBoxes.concat(uniboxes);
            }
            sxQuery(this.searchBoxSelector, true).each((singleSearchBox) => {
                const $singleSearchBox = sxQuery(singleSearchBox);
                const $parent = sxQuery($singleSearchBox.parent());
                if (($parent.data('ss360') !== undefined && $parent.data('ss360').toString() === 'true') || ($parent.data('zoovuSearch') !== undefined && $parent.data('zoovuSearch').toString() === 'true')) {
                    const $searchButton = searchBoxConfiguration.searchButton !== undefined ? $parent.find(searchBoxConfiguration.searchButton) : undefined;
                    $singleSearchBox.addClass('ss360-custom-search__searchbox ss360-n-input');
                    if ($searchButton !== undefined) {
                        const hasLabel = searchBoxConfiguration.searchButtonLabel !== undefined;
                        const searchButtonIconColor = UiHelper.getSearchButtonIconColor(pluginConfiguration);
                        $searchButton.html(hasLabel ? searchBoxConfiguration.searchButtonLabel : Icons.getSvgIcon(Icons.MAGNIFIER, searchButtonIconColor));
                        $searchButton.addClass('ss360-custom-search__searchbutton ss360-custom-search__searchbutton--no-transition ss360-n-button');
                        if (!hasLabel) {
                            $searchButton.attr('aria-label', 'Search');
                        }
                        setTimeout(() => {
                            $searchButton.removeClass('ss360-custom-search__searchbutton--no-transition');
                        }, 100);
                    }
                    if ($singleSearchBox.attr('aria-label') === null && pluginConfiguration.accessibility.searchFieldLabel
                        && ($singleSearchBox.attr('id') === null || sxQuery(`label[for="${$singleSearchBox.attr('id')}"]`).length === 0)) {
                        $singleSearchBox.attr('aria-label', pluginConfiguration.accessibility.searchFieldLabel);
                    }
                    $parent.addClass('ss360-flex ss360-n-section ss360-custom-search ss360-custom-search--no-margin');
                }
            });
        }
        else {
            this.searchBoxes = [];
        }
        this.triggeredUniboxes = [];
        if (pluginConfiguration.suggestions.trigger !== undefined) {
            sxQuery(pluginConfiguration.suggestions.trigger).unibox(true, (unibox) => {
                this.triggeredUniboxes.push(unibox);
            }, true);
        }
        // lazy load unibox
        if (pluginConfiguration.suggestions.show && !searchBoxConfiguration._preventBind) {
            const plainSearchBoxes = sxQuery(this.searchBoxSelector, true);
            const loadUnibox = () => {
                plainSearchBoxes.unibox(true, (fullUniboxes) => {
                    this.searchBoxes = [];
                    if (fullUniboxes instanceof Array) {
                        this.searchBoxes = fullUniboxes;
                    }
                    else {
                        this.searchBoxes = this.searchBoxes.concat(fullUniboxes);
                    }
                    this.updateSuggestionUrl(this.buildSuggestionUrl());
                    StyleApplier.applyOnSuggestions(pluginConfiguration.style, this.context.uniboxId);
                });
            };
            loadUnibox();
        }
        // attach search to buttons?
        if (searchBoxConfiguration.searchButton !== undefined) {
            const eventKey = this.context.generateId('submit');
            sxQuery(searchBoxConfiguration.searchButton).removeAttribute('onclick').off(`click.${eventKey}`).on(`click.${eventKey}`, (event) => {
                this.lastSearchButtonClickTime = new Date().getTime();
                let relevantSearchBox = this.searchBoxes[0];
                if (this.searchBoxes.length > 1 && this.selectedSearchBox !== undefined) {
                    relevantSearchBox = (this.searchBoxes.reduce((acc, sb) => {
                        if (acc !== undefined) {
                            return acc;
                        }
                        const current = sb.getOriginalSearchBox !== undefined ? sb.getOriginalSearchBox() : sb;
                        if (current.get()[0] === this.selectedSearchBox.get()[0]) {
                            return sb;
                        }
                        return undefined;
                    }, undefined) || this.searchBoxes[0]);
                }
                Handlers.onSearchButtonClick(event, relevantSearchBox, pluginConfiguration.callbacks);
            });
        }
        // add/init the search layer
        this.context.layer.get();
        const htmlBody = sxQuery('body');
        // htmlBody.append(layer);
        // click handlers to close search layer
        htmlBody.keydown((e) => {
            Handlers.onBodyKeyDown(e, this.areResultsVisible, this.fullScreenOpen, searchResultType, this.context);
        });
        htmlBody.mousedown((e) => {
            Handlers.onBodyMouseDown(e, this.areResultsVisible, this.context);
        });
        htmlBody.mouseup(() => {
            Handlers.onBodyMouseUp(this.context);
        });
        const htmlLayer = this.context.layer.get();
        htmlLayer.on('click, mousedown', (event) => {
            if (searchResultType === SearchResultType.Embed)
                return;
            event.stopPropagation();
        });
        htmlLayer.on('mouseup', () => {
            Handlers.onLayerMouseUp(this.context);
        });
        // prepare config object
        this.searchResultConfig = resultsConfiguration.embedConfig;
        if (!(this.searchResultConfig instanceof Object)) {
            this.searchResultConfig = undefined;
        }
        // handle history
        sxQuery(window).on(`popstate.${this.context.generateId('ss360History')}`, (e) => {
            if (this.isLandingPageSearch && e.state && e.state.query && e.state.requestOptions) {
                this.showResults(e.state.query, e.state.requestOptions);
            }
            else if (e.state && e.state.options && e.state.options.type === 'mini-pdp') {
                const suggest = new SearchSuggest(e.state.options.suggest);
                const breadcrumb = (e.state.options.breadcrumb || []).map((b) => {
                    const suggest2 = new SearchSuggest(b.suggest);
                    const suggestItem = this.context.layer.get().getProductItem(suggest2) || new SxQueryObject([]);
                    return { name: b.name, suggest: suggest2, suggestItem };
                });
                const suggestItem = this.context.layer.get().getProductItem(suggest) || new SxQueryObject([]);
                new MiniPDP(this.context, suggest, suggestItem, e.state.options.query, breadcrumb).show('', true, true);
            }
            else if (e.state && e.state.operation === 'open-mini-pdp') {
                closeAllMiniPDPs();
                NavigatorHelper.replaceStatePlain(undefined, undefined);
            }
            else if (!Handlers.popstate(e, searchResultType, this.embedDomElementCache, this.context)) {
                // reset cache
                this.embedDomElementCache = undefined;
            }
        });
        // are we at 404?
        if (pluginConfiguration.smart404 !== undefined && document.querySelector('title') !== null) {
            this.is404 = Smart404.checkAndHandle(this.context);
        }
        CategorySearch.checkAndHandle(this.context);
        // apply styles
        const styleEventKey = this.context.generateId('ss360ApplyStyles');
        let areStylesBeingApplied = false;
        const reapply = () => {
            if (areStylesBeingApplied === false) {
                areStylesBeingApplied = true;
                setTimeout(() => {
                    StyleApplier.apply(searchResultType, pluginConfiguration.searchBox, pluginConfiguration.style);
                    areStylesBeingApplied = false;
                }, 200);
            }
        };
        reapply();
        sxQuery(window).off(`resize.${styleEventKey}, orientationchange.${styleEventKey}`).on(`resize.${styleEventKey}, orientationchange.${styleEventKey}`, reapply);
        if (pluginConfiguration.style.redrawTrigger !== undefined) {
            sxQuery(pluginConfiguration.style.redrawTrigger, true).off(`click.${styleEventKey}`).on(`click.${styleEventKey}`, reapply);
        }
        // voice search
        if (pluginConfiguration.voiceSearch.enabled === true && !searchBoxConfiguration._preventBind) {
            sxQuery(this.searchBoxSelector, true).get().forEach((sbNode) => new VoiceSearch(sxQuery(sbNode), (query, settings) => {
                this.showResults(query, settings);
            }, pluginConfiguration.voiceSearch));
        }
        // masonry grid
        Masonry.notifyConfigChange(pluginConfiguration.layout);
        // parenting form submitting
        if (searchBoxConfiguration.preventFormParentSubmit) {
            const $searchBoxes = sxQuery(searchBoxConfiguration.selector);
            $searchBoxes.each((searchBox) => {
                searchBox = sxQuery(searchBox);
                const directParent = searchBox.parent();
                let form;
                if (directParent.get()[0].nodeName === 'FORM') {
                    form = directParent;
                }
                else if (directParent.parent().get()[0].nodeName === 'FORM') {
                    form = directParent.parent();
                }
                if (form !== undefined && (form.attr('action') || '').indexOf('.aspx') === -1) {
                    form.on('submit', (e) => { e.preventDefault(); e.stopPropagation(); });
                }
            });
        }
        // check whether everything is setup correctly
        const isFullscreen = searchResultType === SearchResultType.Fullscreen;
        const isZoe = pluginConfiguration.searchSource !== 'ZOE';
        const errors = Helper.getInitializationErrors(pluginConfiguration, this.is404, this.isLandingPageSearch, this.context, isFullscreen, isZoe);
        this.successInit = errors.length === 0;
        const instanceName = this.context.getIsZoovu() ? 'Zoovu Search' : 'SiteSearch360';
        if (this.successInit) {
            Logger.info(`${instanceName} ${this.VERSION} initialized ${searchBoxConfiguration._preventBind || isFullscreen || isZoe ? '' : `to ${this.searchBoxSelector}`}`);
        }
        else {
            if (pluginConfiguration.showErrors === true) {
                UiHelper.showError(errors.join('<br/>'));
                UiHelper.hideLoadingAnimation();
            }
            Logger.error(`${instanceName} ${this.VERSION} FAILED to initialize to ${this.searchBoxSelector}${errors !== undefined ? `\n\t${errors.join('\n\t')}` : ''}`);
        }
        if (this.context.pluginConfiguration.filters.enabled && !SxQueryUtils.saveData()
            && (SxQueryUtils.getConnectionEffectiveType() === '3g' || SxQueryUtils.getConnectionEffectiveType() === '2g')) { // preload filter chunk to improve filter rendering time
            SxQueryUtils.requestAnimationFrame(() => {
                // @ts-ignore
                import(/* webpackChunkName: "filters" */ './components/Filter');
            });
        }
        this.clearOldCachedResult();
        // notify plugin initialization
        if (this.callbacksConfiguration.init !== undefined) {
            try {
                if (this.callbacksConfiguration.preSearch === undefined) {
                    setTimeout(() => {
                        this.callbacksConfiguration.init();
                    }, 0);
                }
                else {
                    this.callbacksConfiguration.init();
                }
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
    }
    buildQueryUrl(config) {
        const isUsePresetFilters = this.context.isEcom() && !this.resultsConfiguration._disablePresetFilters;
        let filters = config.filters || [];
        let presetFilters = [];
        if (this.resultsConfiguration.filters !== undefined && (this.resultsConfiguration.mergePresetFilters || config.filters === undefined || config.filters === null)) {
            if (isUsePresetFilters) {
                presetFilters = this.resultsConfiguration.filters;
            }
            else {
                filters = [...filters, ...this.resultsConfiguration.filters];
            }
        }
        if ((filters === undefined || filters === null) && this.filtersConfiguration.preSelect.length > 0) {
            filters = [];
        }
        const setFilterNames = (filters || []).filter((entry) => entry.name !== undefined && entry.name !== null).map((entry) => entry.name.toLowerCase().trim());
        this.filtersConfiguration.preSelect.forEach((entry) => {
            const lcName = (entry.name || '').toLocaleLowerCase().trim();
            if (setFilterNames.indexOf(lcName) === -1) {
                const filterEntry = { key: entry.key, name: entry.name };
                let optionsSet = false;
                if (entry.values !== undefined && entry.values.length > 0) {
                    optionsSet = true;
                    // @ts-ignore
                    filterEntry.values = entry.values.map((value) => ({ value }));
                }
                else if (entry.min !== undefined && entry.max !== undefined) {
                    // @ts-ignore
                    filterEntry.min = entry.min;
                    // @ts-ignore
                    filterEntry.max = entry.max;
                    optionsSet = true;
                }
                else if (entry.booleanValue !== undefined) {
                    // @ts-ignore
                    filterEntry.booleanValue = entry.booleanValue;
                    optionsSet = true;
                }
                if (optionsSet && !isUsePresetFilters) {
                    filters.push(filterEntry);
                }
                else if (optionsSet) {
                    presetFilters.push(filterEntry);
                }
            }
        });
        if (filters && filters.length > 0) {
            filters = mergeWithoutFilters(filters);
        }
        if (presetFilters && presetFilters.length > 0) {
            presetFilters = mergeWithoutFilters(presetFilters);
        }
        return this.context.urlBuilder.buildQueryUrl({
            limit: config.limit,
            sort: config.sort,
            include: config.include,
            exclude: config.exclude,
            tracking: config.tracking,
            query: config.query,
            offset: config.offset,
            limitPerGroup: config.limitPerGroup,
            groupResults: config.group !== undefined ? config.group : this.resultsConfiguration.group,
            filters,
            reporter: this.context.reporter,
            isPlain: config.isPlain,
            includeQueries: config.includeQueries,
            addQueries: config.addQueries,
            excludeQueries: config.excludeQueries,
            sortComplex: config.sortComplex ? config.sortComplex : this.resultsConfiguration.sortComplex,
            ignoreOther: this.contentGroupsConfiguration.ignoreOther,
            disableAutoCorrect: config.disableAutoCorrect,
            sessionId: this.context.hasInsights() ? this.context.getInsights().sessionId : undefined,
            additionalRequestParams: this.pluginConfiguration.additionalRequestParams,
            activeGuidedQuestions: config.activeGuidedQuestions,
            presetFilters,
            source: this.context.pluginConfiguration.searchSource,
            fuzziness: config.fuzziness,
            partialFilterMatch: this.partialFilterMatch
        });
    }
    isInitialized() {
        return this.successInit !== undefined && this.successInit;
    }
    showFullscreenLayer(event) {
        if (event !== undefined) {
            event.stopPropagation();
        }
        this.fullScreenOpen = true;
        this.context.layer.get().showFullscreenLayer(this.pluginConfiguration);
    }
    hideSearchResults() {
        const { searchResultType, pluginConfiguration } = this;
        if (searchResultType === SearchResultType.Fullscreen || searchResultType === SearchResultType.Layover) {
            this.closeLayer();
        }
        else {
            this.context.layer.get().fadeOut();
            if (this.embedDomElementCache !== undefined) {
                const $contentBlock = sxQuery(pluginConfiguration.results.embedConfig.contentBlock, true);
                $contentBlock.append(this.embedDomElementCache);
            }
            this.embedDomElementCache = undefined;
            NavigatorHelper.removeQueryParam(pluginConfiguration.results, pluginConfiguration.results.searchQueryParamName);
        }
    }
    closeLayer() {
        const wereResultsVisible = this.areResultsVisible;
        this.areResultsVisible = false;
        sxQuery('body').removeClass('ss360-u-hide-y-overflow');
        if (wereResultsVisible) {
            sxQuery(window).off(`beforeunload.${this.context.getInsightsEventKey()}`);
            if (this.context.hasInsights()) {
                const layoutFrame = this.context.layer.get();
                const allItems = layoutFrame.getVisibleResults().get();
                this.context.getInsights().trackSerpLeave(layoutFrame.getLayerContent().get()[0], allItems[0], this.lastSearchTerm, allItems.length, this.is404 ? SearchResultType.Smart404 : this.searchResultType, this.lastResultsAvailable);
            }
        }
        // fullscreen mode?
        if (this.searchResultType === SearchResultType.Fullscreen && this.fullScreenOpen) {
            this.fullScreenOpen = false;
            this.context.layer.get().hideFullscreenLayer(this.pluginConfiguration);
            NavigatorHelper.removeQueryParam(this.context.pluginConfiguration.results);
        }
        else if (this.searchResultType === SearchResultType.Layover) {
            const $hideEls = sxQuery(`#${this.context.layerId}, #ss360Darken`);
            $hideEls.removeClass('ss360-us ss360-fid');
            $hideEls.addClass('ss360-animated ss360-animated--fast ss360-fo');
            setTimeout(() => {
                $hideEls.removeClass('ss360-animated ss360-fo ss360-animated--fast');
                $hideEls.hide();
                sxQuery('#ss360Darken').remove();
            }, 150);
            NavigatorHelper.removeQueryParam(this.context.pluginConfiguration.results);
            if (this.filtersConfiguration.enabled) {
                NavigatorHelper.removeQueryParam(this.context.pluginConfiguration.results, 'ss360Filter');
            }
        }
        if (this.callbacksConfiguration.closeLayer !== undefined) {
            this.callbacksConfiguration.closeLayer.call(this);
        }
        this.context.layer.get().off('focusout.layoverfocus');
        sxQuery(window).off('keydown.layoverfocus');
    }
    blur(event, selectedText, logAbandon) {
        const lbctGetter = () => this.lastSearchButtonClickTime;
        Handlers.searchBoxBlur(event, selectedText, logAbandon, this.autoBlurTime, lbctGetter, this.context);
    }
    focus(event, query) {
        this.selectedSearchBox = sxQuery(event.target);
        const customGroups = ContentGroupHelper.get(sxQuery(this.selectedSearchBox), true, false, this.pluginConfiguration.allowCookies, this.context);
        if (customGroups.include !== undefined || customGroups.exclude !== undefined) {
            this.wereSuggestGroupsModified = true;
            const includeCache = this.contentGroupsConfiguration.include;
            const excludeCache = this.contentGroupsConfiguration.exclude;
            if (customGroups.include !== undefined) {
                this.contentGroupsConfiguration.include = customGroups.include;
            }
            if (customGroups.exclude !== undefined) {
                this.contentGroupsConfiguration.exclude = customGroups.exclude;
            }
            this.updateSuggestionUrl(this.buildSuggestionUrl());
            this.contentGroupsConfiguration.include = includeCache;
            this.contentGroupsConfiguration.exclude = excludeCache;
        }
        else if (this.wereSuggestGroupsModified) {
            this.updateSuggestionUrl(this.buildSuggestionUrl());
            this.wereSuggestGroupsModified = false;
        }
        // add focus layer
        const focusLayer = this.searchBoxConfiguration.focusLayer;
        if (focusLayer !== undefined && focusLayer) {
            UiHelper.addDarkenInputLayer(this.selectedSearchBox);
        }
    }
    followLink(selectedText, link, ctrlModifier, query) {
        if (link !== undefined && link.trim().length > 0) {
            if (ctrlModifier || this.pluginConfiguration.suggestions.linksOpenNewTab) {
                window.open(link, '_blank');
            }
            else {
                window.location.href = link;
            }
        }
        else {
            this.showResults(selectedText, {});
        }
    }
    prefetchResults(config) {
        const offset = config.offset;
        const contentGroup = config.contentGroup;
        const query = config.query;
        const callback = config.callback;
        const overrides = config.overrides;
        const filters = config.filters;
        let sort = config.sort !== undefined ? config.sort : this.resultsConfiguration.sorting;
        if (sort !== undefined && (sort instanceof Object)) {
            sort = JSON.stringify(sort);
        }
        // @ts-ignore
        if (config.sortComplex === undefined && (sort === undefined || sort.trim().length === 0)) {
            sort = '_relevance';
        }
        if (filters !== undefined) {
            filters.forEach((filter) => {
                try {
                    delete filter.conceptIdName;
                    if (filter.without && filter.name) {
                        delete filter.key;
                        delete filter.id;
                    }
                    if (filter.values) {
                        filter.values.forEach((val) => {
                            delete val.conceptIdName;
                            if (filter.without && val.value) {
                                delete val.key;
                                delete val.name;
                                delete val.id;
                            }
                        });
                    }
                }
                catch (err) {
                    // ccl
                }
            });
        }
        const safeKey = StringHelper.getSafeKey(contentGroup);
        const num = overrides !== undefined && overrides.num !== undefined ? overrides.num : this.resultsConfiguration.num;
        const pagingSize = overrides !== undefined && overrides.pageSize ? overrides.pageSize : this.resultsConfiguration.moreResultsPagingSize;
        const limit = Math.min(num - offset, pagingSize);
        const preloadedResultCount = config.preloaded !== undefined ? config.preloaded : offset - this.context.layer.get().getVisibleResults(safeKey).length;
        const rest = pagingSize - preloadedResultCount;
        Results.prefetchAndRender({
            limit,
            contentGroup,
            groupResults: config.group !== undefined ? config.group : this.resultsConfiguration.group,
            query,
            offset,
            filters: filters !== undefined ? filters : this.resultsConfiguration.filters,
            // @ts-ignore
            sort,
            sortComplex: config.sortComplex
        }, rest, this.uiBuilder, callback, this.context);
    }
    showResults(selectedText, config, ...rest) {
        if (config === undefined || !(config instanceof Object)) { // fallback to the old structure
            config = {
                // @ts-ignore
                sort: config,
                filters: rest[0],
                shouldPushState: rest[1],
                searchButton: rest[2],
                callback: rest[3],
                submitSource: rest[4],
                sbRef: rest[5],
                disableAutoCorrect: rest[6]
            };
        }
        this.lastActiveGuidedQuestions = config.activeGuidedQuestions;
        const randomFilterId = Math.round(100000 * Math.random());
        if (config.submitSource === 'filter') {
            this.lastSubmittedFilterRequestId = randomFilterId;
            this.context.emitEvent('filter', config.filters);
        }
        if (config.submitSource === 'order') {
            this.context.emitEvent('sort', config.sort);
        }
        const wasLandingPageSearch = this.isLandingPageSearch;
        this.isLandingPageSearch = config.submitSource === 'category-search' || config.submitSource === 'landing-page' || (this.isLandingPageSearch && (config.submitSource === 'filter' || config.submitSource === 'order' || config.submitSource === 'guided-question'));
        const categorySearchSettings = this.pluginConfiguration.results.categorySearch;
        if (this.isLandingPageSearch && config.submitSource === 'category-search' && (categorySearchSettings || {}).contentBlock !== undefined) {
            this.searchResultTypeCache = this.searchResultType;
            this.embedConfigCache = this.pluginConfiguration.results.embedConfig;
            this.searchResultType = SearchResultType.Embed;
            this.searchResultConfigCache = this.searchResultConfig;
            this.pluginConfiguration.results.embedConfig = {
                contentBlock: categorySearchSettings.contentBlock
            };
            this.searchResultConfig = this.pluginConfiguration.results.embedConfig;
            this.categoryDomElementCache = sxQuery(categorySearchSettings.contentBlock).children();
            if (!wasLandingPageSearch && categorySearchSettings._recreateParent) {
                UiHelper.recreateNode(sxQuery(categorySearchSettings.contentBlock));
            }
        }
        else if (this.searchResultTypeCache !== undefined) {
            this.searchResultType = this.searchResultTypeCache;
            this.pluginConfiguration.results.embedConfig = this.embedConfigCache;
            this.searchResultConfig = this.searchResultConfigCache;
        }
        const { pluginConfiguration, uiBuilder, resultsConfiguration, context, callbacksConfiguration, searchResultConfig, contentGroupsConfiguration } = this;
        uiBuilder.clearHasImagesCache();
        if (config.sbRef !== undefined) {
            this.selectedSearchBox = config.sbRef;
        }
        if (config.includeQueries !== undefined && config.includeQueries.length > 0 && (selectedText || '').trim().length === 0) {
            selectedText = config.includeQueries[0];
        }
        if ((selectedText || '').trim().length === 0 || (pluginConfiguration.searchSource === 'ZOE' && this.queryFromReload)) {
            selectedText = this.queryFromReload || '';
        }
        if (selectedText.trim() === (this.lastSearchTerm || '').trim() && pluginConfiguration.suggestions.triggersSearch && config.filters === undefined) {
            return; // don't retrigger the query
        }
        this.lastSearchTerm = selectedText;
        if (resultsConfiguration.embedConfig !== undefined && this.searchResultType === SearchResultType.Layover) {
            this.searchResultType = context.getSearchResultType(); // recheck if the block has been added
        }
        const queryModification = callbacksConfiguration.queryModification;
        if (queryModification !== undefined && typeof queryModification === 'function') {
            selectedText = queryModification(selectedText, config);
        }
        const preSearch = callbacksConfiguration.preSearch;
        const sort = config.sort !== undefined ? config.sort : resultsConfiguration.sorting;
        const keepGoing = (preSearch !== undefined && typeof preSearch === 'function' ? preSearch.call(this, selectedText, sort, this.selectedSearchBox, config.filters, config) : true)
            && selectedText.trim().length !== 0;
        if (!keepGoing) {
            if (selectedText.trim().length === 0 && this.selectedSearchBox !== undefined) {
                this.selectedSearchBox.showRequiredError();
            }
            return;
        }
        selectedText = selectedText.trim();
        const showSkeletonLoader = pluginConfiguration.style.loaderType === 'skeleton';
        if (!showSkeletonLoader) {
            UiHelper.showLoadingAnimation();
        }
        const hasSpecificResultPage = this.searchResultType === SearchResultType.Embed && searchResultConfig !== undefined && searchResultConfig.url !== undefined && searchResultConfig.url !== '';
        const checkShouldRedirect = (url, location) => {
            if (url === undefined) {
                return false;
            }
            return hasSpecificResultPage && (location.indexOf(url) === -1 || (url.indexOf('?') === -1 && searchResultConfig.url === '/' && window.location.pathname !== '/')) && !this.isLandingPageSearch;
        };
        let shouldRedirect = checkShouldRedirect(searchResultConfig ? searchResultConfig.url : undefined, document.location.href);
        if (shouldRedirect && !resultsConfiguration.semanticMode && searchResultConfig !== undefined && searchResultConfig.url.indexOf('?') !== -1) {
            let modifiedUrl = searchResultConfig.url;
            const queryParamsUrl = NavigatorHelper.createQueryDictFromString(modifiedUrl);
            delete queryParamsUrl[resultsConfiguration.searchQueryParamName];
            modifiedUrl = `${modifiedUrl.split('?')[0]}${Object.keys(queryParamsUrl).length > 0 ? '?' : ''}${Object.keys(queryParamsUrl).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParamsUrl[key])}`).join('&')}`;
            let modifiedHref = document.location.href;
            const queryParamsHref = NavigatorHelper.createQueryDictFromString(modifiedHref);
            delete queryParamsHref[resultsConfiguration.searchQueryParamName];
            modifiedHref = `${modifiedHref.split('?')[0]}${Object.keys(queryParamsHref).length > 0 ? '?' : ''}${Object.keys(queryParamsHref).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParamsHref[key])}`).join('&')}`;
            shouldRedirect = checkShouldRedirect(modifiedUrl, modifiedHref);
        }
        const hasJustBeenRedirected = hasSpecificResultPage && this.selectedSearchBox === undefined && config.shouldPushState !== true;
        const cookieQuery = this.pluginConfiguration.allowCookies ? this.context.readCookie('LastQuery') : selectedText;
        const shouldTrack = (this.context.hasInsights()) && (!hasJustBeenRedirected || this.is404 || this.isLandingPageSearch || cookieQuery !== selectedText);
        if (shouldTrack) {
            this.context.getInsights().trackSubmitSearch(selectedText, this.selectedSearchBox !== undefined ? sxQuery(this.selectedSearchBox).get()[0] : undefined, config.searchButton, config.submitSource);
        }
        // do we have to redirect?
        if (shouldRedirect) {
            NavigatorHelper.redirectToSearchResultPage(selectedText, searchResultConfig.url, context.pluginConfiguration, context);
            return;
        }
        if (this.uiBuilder && this.uiBuilder.comparison && this.uiBuilder.comparison.lastQuery !== selectedText) {
            this.uiBuilder.comparison.removeAll();
        }
        if (this.pluginConfiguration.allowCookies) { // reset cookie
            this.context.createCookie('LastQuery', '', -1);
        }
        if (context.getSearchResultType() === SearchResultType.Embed && this.embedDomElementCache === undefined) {
            this.embedDomElementCache = sxQuery(resultsConfiguration.embedConfig.contentBlock, true).children();
        }
        const pagingSize = resultsConfiguration.moreResultsPagingSize || 12;
        const maxResults = resultsConfiguration.num;
        let limitPerGroup = false;
        let isPlainQuery = config.submitSource !== 'filter' && config.submitSource !== 'order';
        if (isPlainQuery && (config.submitSource === 'popstate' || config.submitSource === 'pageload')) {
            isPlainQuery = config.filters === undefined || config.filters.length === 0;
        }
        const buildQueryUrl = (tracking) => {
            let limit = resultsConfiguration.moreResultsButton ? 2 * pagingSize : maxResults;
            limit = resultsConfiguration.limitPerGroup ? Math.min(limit, maxResults) : maxResults;
            const hasMoreResultsButton = !!resultsConfiguration.moreResultsButton;
            limitPerGroup = hasMoreResultsButton && resultsConfiguration.limitPerGroup
                && (contentGroupsConfiguration.include === undefined || contentGroupsConfiguration.include.length !== 1) && resultsConfiguration.group;
            let include = contentGroupsConfiguration.include;
            let exclude = contentGroupsConfiguration.exclude;
            const isPageLoad = config.submitSource === 'pageload';
            const isPopState = config.submitSource === 'popstate';
            if (this.selectedSearchBox !== undefined || isPageLoad || isPopState) {
                const customGroups = ContentGroupHelper.get(sxQuery(this.selectedSearchBox), false, isPageLoad, pluginConfiguration.allowCookies, this.context);
                if (customGroups.include !== undefined) {
                    include = customGroups.include;
                }
                if (customGroups.exclude !== undefined) {
                    exclude = customGroups.exclude;
                }
            }
            return this.buildQueryUrl({
                limit,
                sort,
                include,
                exclude,
                tracking,
                query: selectedText,
                offset: config.offset !== undefined ? config.offset : 0,
                limitPerGroup,
                filters: config.filters,
                isPlain: isPlainQuery,
                includeQueries: config.includeQueries,
                addQueries: config.addQueries,
                excludeQueries: config.excludeQueries,
                disableAutoCorrect: config.disableAutoCorrect,
                activeGuidedQuestions: config.activeGuidedQuestions
            });
        };
        const queryUrl = buildQueryUrl(context.reporter.shouldTrack(this.context));
        const escapedQuery = StringHelper.escapeHtml(selectedText);
        const isFilterSubmit = config.submitSource === 'filter';
        let skeletonTimeout = -1;
        if (showSkeletonLoader) {
            // @ts-ignore
            skeletonTimeout = setTimeout(() => {
                this.context.layer.get().detachNoResultsFrame();
                if (isFilterSubmit) {
                    this.context.layer.get().detachResults();
                }
                new Skelet({
                    searchResultType: this.searchResultType,
                    uiBuilder,
                    closeLayerHandler: () => {
                        this.closeLayer();
                    },
                    searchFieldRenderer: () => {
                        this.addSearchField(this.lastSearchTerm);
                    },
                    contentOnly: isFilterSubmit,
                    pluginConfig: context.pluginConfiguration
                }).show(this.context.layer.get(), context);
            }, 250);
        }
        if (pluginConfiguration.allowCookies && !this.isLandingPageSearch) {
            // @ts-ignore
            const storedQueries = this.context.readObject('unibox_search_history', []);
            const currentIndex = storedQueries.indexOf(selectedText);
            if (currentIndex !== -1) {
                storedQueries.splice(currentIndex, 1);
            }
            storedQueries.splice(0, 0, selectedText);
            const entrySizeOverflow = storedQueries.length - pluginConfiguration.suggestions.maxSearchHistoryEntries;
            if (entrySizeOverflow > 0) {
                storedQueries.splice(pluginConfiguration.suggestions.maxSearchHistoryEntries - 1, entrySizeOverflow);
            }
            this.context.storeObject('unibox_search_history', storedQueries);
        }
        const shouldTryReadQuery = config.submitSource === 'popstate' || config.submitSource === 'pageload';
        const resultsFetcher = shouldTryReadQuery ? Results.getOrFetch : Results.fetch;
        if (this.context.pluginConfiguration.filters.enabled) { // preload filter chunk to improve filter rendering time
            // @ts-ignore
            import(/* webpackChunkName: "filters" */ './components/Filter');
        }
        resultsFetcher(queryUrl, this.context, (data) => {
            if (config.submitSource === 'filter' && this.lastSubmittedFilterRequestId !== randomFilterId) { // race condition
                return;
            }
            this.requestFingerprint = data.plain.requestFingerprint;
            this.partialFilterMatch = data.partialFilterMatch;
            this.renderResults({
                data, skeletonTimeout, selectedText, escapedQuery, limitPerGroup, config, isPlainQuery, sort, submitSource: config.submitSource, activeGuidedQuestions: config.activeGuidedQuestions, requestOptions: config
            });
        }, (status, statusText) => {
            UiHelper.hideLoadingAnimation();
            clearTimeout(skeletonTimeout);
            if (pluginConfiguration.showErrors === true && callbacksConfiguration.searchError === undefined) {
                UiHelper.showError(`There is no siteId "${this.siteId}", so no results can be retrieved. Please update your ${this.context.getIsZoovu() ? 'zoovuSearchConfig' : 'ss360Config'} object.`);
            }
            else if (callbacksConfiguration.searchError !== undefined) {
                callbacksConfiguration.searchError();
            }
            ErrorScreen.show(status, statusText, context, () => {
                this.addSearchField(selectedText);
            }, this.showResults.bind(this, selectedText, config));
        }, pluginConfiguration.siteId, pluginConfiguration.callbacks.noResultsLoaded !== undefined ? (data) => {
            let result = false;
            try {
                result = pluginConfiguration.callbacks.noResultsLoaded(selectedText, SearchContext.SEARCH, data);
            }
            catch (err) {
                console.error(err);
            }
            return result ? buildQueryUrl(false) : undefined;
        } : undefined);
    }
    reloadResults(sessionId) {
        if (sessionId === undefined || sessionId === null) {
            sessionId = this.context.getInsights() ? this.context.getInsights().sessionId : undefined;
        }
        if (sessionId === undefined || sessionId === null) {
            throw new Error('Session id must be given');
        }
        const showSkeletonLoader = this.pluginConfiguration.style.loaderType === 'skeleton';
        if (!showSkeletonLoader) {
            UiHelper.showLoadingAnimation();
        }
        let skeletonTimeout;
        let timedOutAt = undefined;
        const SKELETON_TIMEOUT = isMiniPDPOpen() ? 10000 : 200;
        if (showSkeletonLoader) {
            // @ts-ignore
            skeletonTimeout = setTimeout(() => {
                timedOutAt = (new Date()).getTime();
                this.context.layer.get().detachNoResultsFrame();
                new Skelet({
                    searchResultType: this.searchResultType,
                    uiBuilder: this.uiBuilder,
                    closeLayerHandler: () => {
                        this.closeLayer();
                    },
                    searchFieldRenderer: () => {
                        this.addSearchField(this.lastSearchTerm);
                    },
                    contentOnly: false,
                    pluginConfig: this.context.pluginConfiguration
                }).show(this.context.layer.get(), this.context);
            }, SKELETON_TIMEOUT);
        }
        SxQueryUtils.get(this.context.urlBuilder.buildReloadResultsUrl(sessionId, this.lastActiveGuidedQuestions, this.requestFingerprint), (data) => {
            // @ts-ignore
            const isEmptyResponse = () => data === undefined || data === null || data === '';
            const dataSetHasChanged = !isEmptyResponse();
            if (timedOutAt !== undefined && isEmptyResponse()) {
                data = this.lastRenderedImmersiveData.plain;
            }
            if (isEmptyResponse()) { // result set not modified
                clearTimeout(skeletonTimeout);
                if (!showSkeletonLoader) {
                    UiHelper.hideLoadingAnimation();
                }
                return;
            }
            if (dataSetHasChanged) {
                this.context.notify('close-mini-pdp', null);
            }
            const timeDiff = ((new Date()).getTime() - timedOutAt);
            const toggleAnimation = dataSetHasChanged && (timedOutAt === undefined || timeDiff < 100); // only animate after data change if skeleton wasn't displayed or was displayed just for a short time
            this.requestFingerprint = data.requestFingerprint;
            const searchResponse = new SearchResponse(data, this.pluginConfiguration.results.sanitizeResults);
            this.queryFromReload = searchResponse.query;
            this.renderResults({ data: searchResponse, skeletonTimeout, selectedText: this.lastSearchTerm, escapedQuery: undefined, submitSource: 'reload', sort: searchResponse.sorting, activeGuidedQuestions: searchResponse.activeGuidedQuestions });
            if (toggleAnimation) {
                this.context.layer.get().animateProductSetChange();
            }
        }, (status, statusText) => {
            ErrorScreen.show(status, statusText, this.context, () => {
                this.addSearchField(this.lastSearchTerm);
            }, this.reloadResults.bind(this));
        }, undefined, false, this.pluginConfiguration.additionalHeaderParams);
    }
    startComparison(identifiers) {
        if ((identifiers === undefined || identifiers.length === 0) && this.lastRenderedImmersiveData !== undefined) {
            identifiers = [...this.lastRenderedImmersiveData.getFlatSuggests()].splice(0, 4).map((entry) => entry.getIdentifier()).filter((identifier) => identifier !== undefined && identifier !== null && identifier.trim().length > 0);
        }
        if (identifiers === undefined || identifiers.length === 0) {
            Logger.error('No identifiers given for comparison');
            return;
        }
        new Comparison(this.context, false).render(identifiers);
    }
    renderStaticData(data) {
        this.renderResults({ data: new SearchResponse(data), skeletonTimeout: -1, selectedText: '', escapedQuery: '', submitSource: 'static', sort: '', activeGuidedQuestions: [] });
    }
    renderResults({ data, skeletonTimeout, selectedText, escapedQuery, limitPerGroup = true, config = {}, isPlainQuery = true, sort, submitSource, activeGuidedQuestions, requestOptions }) {
        if (this.pluginConfiguration.searchSource === 'ZOE') {
            this.lastRenderedImmersiveData = data;
        }
        const layoutFrame = this.context.layer.get(false);
        if (this.isLandingPageSearch) {
            layoutFrame.addClass('ss360-layer--category');
        }
        if (escapedQuery === undefined) {
            escapedQuery = StringHelper.escapeHtml(selectedText);
        }
        clearTimeout(skeletonTimeout);
        layoutFrame.removeClass('ss360-layer--skeleton');
        sxQuery('window').off('resize.variants');
        sxQuery('window').off('resize.carousel');
        layoutFrame.attr('aria-busy', 'false');
        if (!submitSource || submitSource !== 'filter') {
            layoutFrame.detachFilterFrame(false);
            layoutFrame.detachFilterFrame(true);
        }
        const { resultsConfiguration, callbacksConfiguration, pluginConfiguration, uiBuilder, filtersConfiguration } = this;
        let filterIdToName = data.filterNameMapping;
        if (filterIdToName === undefined
            && resultsConfiguration.nameParsing && data.filterOptions !== undefined && data.filterOptions.length > 0) {
            // create map to prevent duplicate url entries
            filterIdToName = (data.filterOptions || []).reduce((acc, entry) => {
                acc[entry.key] = entry.name;
                return acc;
            }, {});
        }
        if (filterIdToName === undefined) {
            filterIdToName = {};
        }
        let totalResults = Helper.getTotalCount(data, limitPerGroup, pluginConfiguration);
        if (totalResults === undefined) {
            totalResults = 0;
        }
        const formattedTotal = 'Intl' in window ? new Intl.NumberFormat().format(totalResults) : totalResults.toString();
        if (!this.isLandingPageSearch && resultsConfiguration.documentTitle !== undefined && resultsConfiguration.documentTitle !== '' && this.searchResultType === SearchResultType.Embed) {
            let siteName = '';
            let currentTitleParts = document.title.split('|');
            if (currentTitleParts.length !== 2) {
                currentTitleParts = document.title.split('-');
            }
            if (currentTitleParts.length === 2) {
                siteName = currentTitleParts[1].trim();
            }
            document.title = resultsConfiguration.documentTitle.replace(/#QUERY#/g, StringHelper.unescapeHtml(escapedQuery)).replace(/#COUNT#/g, formattedTotal).replace(/#SITE_NAME#/g, siteName);
        }
        this.lastResultsAvailable = totalResults;
        if (callbacksConfiguration.searchResult !== undefined) { // user builds its own search result?
            try {
                callbacksConfiguration.searchResult.call(this, data.plain);
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
        else { // default layout
            // redirect?
            const red = data.redirect;
            const isRedirect = red !== undefined && red.length > 0;
            if (isRedirect) {
                if (resultsConfiguration.followRedirect) {
                    NavigatorHelper.handleRedirect(red, selectedText, escapedQuery, this.context);
                    return;
                }
            }
            this.context.layer.empty();
            const layerContent = layoutFrame.getLayerContent();
            this.addSearchField(this.lastSearchTerm);
            const { banner } = data;
            if (banner !== undefined && banner !== null) {
                const scripts = [];
                if (banner.indexOf('<script') !== -1) {
                    try {
                        sxQuery(banner).each((node) => {
                            const $node = sxQuery(node);
                            if ($node.get()[0].tagName === 'SCRIPT') {
                                scripts.push($node);
                            }
                            else {
                                $node.find('script').each((script) => {
                                    scripts.push(sxQuery(script));
                                });
                            }
                        });
                    }
                    catch (ex) {
                        // ccl
                    }
                }
                layoutFrame.appendToBannerFrame(sxQuery(`<div class="ss360-banner">${banner}</div>`));
                try {
                    scripts.forEach((script) => {
                        const e = document.createElement('script');
                        if (script.attr('src') !== undefined && script.attr('src') !== null) {
                            // @ts-ignore
                            e.src = script.attr('src');
                        }
                        else {
                            e.innerText = script.get()[0].innerText;
                        }
                        document.getElementsByTagName('body')[0].appendChild(e);
                    });
                }
                catch (ex) {
                    // ccl
                }
            }
            // are there parts of the content we show only on certain devices?
            UiHelper.updateLayerByHiddenParts(this.hiddenParts, layerContent);
            // build navigation
            const sortAsTabs = resultsConfiguration.orderOptionsAsTabs && data.getResultGroupNames().length === 1 && data.sortingOptions.length > 0;
            const sortingCallback = (query, c) => {
                if (c.filters !== undefined && resultsConfiguration.nameParsing) {
                    c.filters = c.filters.map((f) => {
                        const key = Object.keys(f)[0];
                        if (key !== undefined && filterIdToName[key] !== undefined) {
                            const obj = {};
                            obj[filterIdToName[key]] = f[key];
                            return obj;
                        }
                        return f;
                    });
                }
                this.showResults(query, c);
            };
            this.navigation = new Navigation(data, this.context, sortAsTabs);
            this.navigation.build(layoutFrame);
            if (sortAsTabs) {
                const sortOptionMap = {};
                const c = data.sortingOptions.length + 1;
                const relSafeKey = StringHelper.getSafeKey('_relevance');
                sortOptionMap[relSafeKey] = null;
                this.navigation.addEntry(resultsConfiguration.defaultOrderOptionLabel, relSafeKey, data.query, undefined, 0, relSafeKey);
                const activeSortingOption = data.activeSortingOption;
                let activeSortingIndex = 0;
                let activeSortingKey = '';
                if (activeSortingOption !== undefined && activeSortingOption !== null) {
                    activeSortingKey = StringHelper.getSafeKey(`${activeSortingOption.key}_${activeSortingOption.sort || activeSortingOption.direction || '_'}`);
                }
                else if (data.sorting !== undefined && data.sorting !== null) {
                    activeSortingKey = StringHelper.getSafeKey(`${data.sorting}__`);
                }
                data.sortingOptions.forEach((sortOption, idx) => {
                    const sortingOption = typeof sortOption === 'string' ? { key: sortOption, name: sortOption } : sortOption;
                    const safeKey = StringHelper.getSafeKey(`${sortingOption.key}_${sortingOption.sort || sortingOption.direction || '_'}`);
                    if (safeKey === activeSortingKey) {
                        activeSortingIndex = idx + 1;
                    }
                    this.navigation.addEntry(sortingOption.name, safeKey, data.query, undefined, idx + 1, safeKey);
                    sortOptionMap[safeKey] = sortingOption;
                });
                this.navigation.setSortingCallback((key) => {
                    const so = sortOptionMap[key];
                    let sort = '_relevance';
                    if (so !== null) {
                        if (resultsConfiguration.nameParsing && so.name !== undefined) {
                            sort = so.name;
                        }
                        else {
                            sort = JSON.stringify(sort);
                        }
                    }
                    sortingCallback(data.query, {
                        sort,
                        submitSource: 'order',
                        shouldPushState: true,
                        searchButton: undefined,
                        filters: data.activeFilterOptions,
                        activeGuidedQuestions: data.activeGuidedQuestions
                    });
                });
                this.navigation.highlightSortingEntry(activeSortingIndex);
            }
            let headlineNode = layoutFrame.getHeadlineNode();
            const plan = data.plan;
            const BRANDED_PLANS = [Plan.Free, Plan.Columbo, Plan.Columbo2, Plan.DudaFree, Plan.FirespringFree, Plan.FirespringColumbo, Plan.FilrespringHolmes];
            let shouldRenderLogo = BRANDED_PLANS.indexOf(plan) !== -1 || (plan === Plan.Custom && data.attribution === true);
            const forceBranding = pluginConfiguration.forceBranding;
            if (!shouldRenderLogo && forceBranding !== false && forceBranding !== undefined) {
                // @ts-ignore
                shouldRenderLogo = forceBranding === true || BRANDED_PLANS.indexOf(forceBranding.toUpperCase()) !== -1;
            }
            if (this.context.getIsZoovu()) {
                shouldRenderLogo = false;
            }
            const caption = resultsConfiguration.caption;
            if (caption !== undefined) {
                const query = escapedQuery || StringHelper.escapeHtml(data.query || '');
                let headingLabel = `${caption.replace('#QUERY#', query).replace('#COUNT#', formattedTotal)}`;
                if (this.isLandingPageSearch && this.pluginConfiguration.results.categorySearch && this.pluginConfiguration.results.categorySearch.showCategoryTitle) {
                    const queryParts = escapedQuery.replace('_#', '').split('#');
                    if (queryParts.length > 0) {
                        headingLabel = queryParts[queryParts.length - 1];
                        headingLabel = headingLabel.length > 0 ? `${headingLabel[0].toUpperCase()}${headingLabel.substring(1)}` : headingLabel;
                        layoutFrame.addClass('ss360-layer--show-category-title');
                    }
                }
                if (shouldRenderLogo) {
                    layerContent.addClass('ss360-layer__content--branded');
                }
                if (headlineNode.length > 0) {
                    headlineNode.html(`<a class="ss360-layer__heading-anchor" tabindex="-1" href="#">${headingLabel}</a>`);
                }
                else {
                    headlineNode = this.uiBuilder.buildHeadlineNode(headingLabel);
                    layoutFrame.appendToHeadingFrame(headlineNode, pluginConfiguration.layout.navigation.forceCaption && this.navigation.isTabbed());
                }
            }
            else if (headlineNode.length > 0) {
                headlineNode.remove();
                headlineNode = null;
            }
            if (resultsConfiguration.allowQueryCorrection && (data.plain.interpretedQuery === undefined || data.plain.interpretedQuery.intentMatched !== true)) {
                let confidence = data.plain.interpretedQuery !== undefined ? data.plain.interpretedQuery.confidence : undefined;
                if (confidence === undefined) {
                    confidence = 1;
                }
                if (data.queryCorrection !== undefined && data.queryCorrection != null && data.queryCorrection.length > 0 && confidence > 0) {
                    layoutFrame.appendToQueryCorrectionFrame(uiBuilder.buildQueryCorrectionNode(data.queryCorrection, this.context.queryCorrectionId, this.pluginConfiguration));
                }
                else if (data.queryCorrectionRewrite !== undefined && data.queryCorrectionRewrite !== null && data.queryCorrectionRewrite.length > 0 && confidence > 0) {
                    const originalQuery = data.plain.interpretedQuery !== undefined ? data.plain.interpretedQuery.original : undefined;
                    if (originalQuery !== data.queryCorrectionRewrite) {
                        layoutFrame.appendToQueryCorrectionFrame(uiBuilder.buildQueryCorrectionRewriteNode(data.queryCorrectionRewrite, pluginConfiguration, this.context.isEcom() && originalQuery !== undefined, originalQuery, this.context.queryCorrectionId));
                    }
                }
            }
            const totalResultsShown = uiBuilder.renderSearchResults(data, this.navigation, escapedQuery, totalResults, config.submitSource === 'popstate');
            const { categorySearch } = this.pluginConfiguration.results;
            if (totalResultsShown === 0 && this.isLandingPageSearch && config.submitSource === 'category-search' && !categorySearch.forceOnZeroResults) {
                const $contentBlock = sxQuery(categorySearch.contentBlock);
                $contentBlock.empty();
                (this.embedDomElementCache.length > 0 ? this.embedDomElementCache : this.categoryDomElementCache).each((el) => {
                    $contentBlock.append(el);
                });
                this.hideLoading();
                return;
            }
            if (totalResultsShown === 1 && resultsConfiguration.redirectOnSingle && isPlainQuery && NavigatorHelper.getFirstLink(data) !== 'https://www.sitesearch360.com') {
                const insights = this.context.getInsights();
                if (insights !== undefined) {
                    sxQuery(window).off(`beforeunload.${this.context.getInsightsEventKey()}`);
                    const firstResult = NavigatorHelper.getFirstResult(data);
                    insights.trackSerpShow(undefined, undefined, selectedText, 1, this.getSearchResultType(), undefined, 1);
                    insights.trackSerpClick(selectedText, undefined, undefined, 1, 0, 0, firstResult.getLink(), this.getSearchResultType(), firstResult.getIdentifier(), firstResult.getIdentifier(), 'singleResultRedirect');
                }
                NavigatorHelper.redirectToFirst(data);
                return;
            }
            if (this.navigation.getPosition() === 'top' && this.searchResultType !== SearchResultType.Embed) { // XXX CSS dependent (51px, height of navigation)
                layerContent.css('max-height', 'calc(100% - 80px)');
            }
            if (totalResultsShown === 0 && !isRedirect) {
                uiBuilder.renderNoResultsText(escapedQuery, data.relatedQueries);
            }
            // sitesearch360 watermark in search result pages
            if (shouldRenderLogo) {
                uiBuilder.renderWatermark(layerContent);
            }
            // sorting options
            if (data.sortingOptions !== undefined && data.sortingOptions.length > 0 && totalResults > 0 && !this.is404 && !sortAsTabs) {
                Sorting.render(this.context.layer.get(), data.sortingOptions, data.activeSortingOption !== undefined ? data.activeSortingOption : data.sorting, sortingCallback, selectedText, data.activeFilterOptions, data.activeGuidedQuestions, pluginConfiguration);
            }
            if (resultsConfiguration.showPageSizeSelector && resultsConfiguration.pageSizeOptions.length > 0) {
                new PageSizeSelector(this.context, () => {
                    this.showResults(selectedText, config);
                }).render();
            }
            if (isRedirect) {
                this.context.layer.get().detachHeading();
                const a = sxQuery('<a class="ss360-layer__redirect-link" target="_blank"></a>');
                a.attr('href', data.redirect);
                a.text(data.redirect);
                const wrap = sxQuery('<div class="ss360-layer__redirect"></div>');
                wrap.append('<span>Redirect:&nbsp;</span>');
                wrap.append(a);
                this.context.layer.get().appendToResultsFrame(wrap);
            }
        }
        UiHelper.hideLoadingAnimation();
        // lose focus on input to hide virtual keyboards
        if (!pluginConfiguration.suggestions.triggersSearch) {
            try {
                this.autoBlurTime = new Date().getTime();
                sxQuery(this.searchBoxConfiguration.selector).blur();
            }
            catch (e) {
                Logger.info(e);
            }
        }
        layoutFrame.removeClass('ss360-animated');
        layoutFrame.removeClass('ss360-layer--overlay');
        const filtersEnabled = filtersConfiguration.enabled && filtersConfiguration._render !== false;
        const flatSuggests = data.getFlatSuggests();
        let hasFilters = filtersEnabled && (flatSuggests.length > 1 || (filtersConfiguration.showOnSingleResult && flatSuggests.length > 0)) && (data.filterOptions || []).length > 0;
        if (!hasFilters && filtersEnabled && data.activeFilterOptions && data.activeFilterOptions.length > 0) {
            try {
                const availableFilters = (data.filterOptions || []).map((group) => group.key || group.name);
                const activeFilterOptions = data.activeFilterOptions.filter((option) => option.without !== true
                    // @ts-ignore
                    && (availableFilters.indexOf((option.key || '').toString()) !== -1 || availableFilters.indexOf(option.name) !== -1));
                hasFilters = availableFilters.length > 0 || activeFilterOptions.length > 0;
            }
            catch (err) {
                // ccl
            }
        }
        const renderFilter = () => {
            this.filterBlocks = [];
            // render filter
            if (hasFilters) {
                filtersConfiguration.headingLevel = uiBuilder.captionHeadingLevel + 1;
                const filterOptionNameMap = {};
                const optionViewNameMap = {};
                // @ts-ignore
                import(/* webpackChunkName: "filters" */ './components/Filter').then(({ default: Filter }) => {
                    let { activeFilterOptions } = data;
                    if (activeFilterOptions !== undefined && activeFilterOptions.length > 0) {
                        activeFilterOptions = activeFilterOptions.map((option) => {
                            if (option.without === true)
                                return undefined;
                            if ((option.min !== undefined && option.max === undefined) || (option.min === undefined && option.max !== undefined) && this.pluginConfiguration.searchSource === 'ZOE') {
                                try {
                                    const priceFilter = data.filterOptions.filter((f) => {
                                        return f.key.toString() === option.key.toString();
                                    })[0];
                                    if (priceFilter !== undefined) {
                                        option.min = option.min !== undefined ? option.min : priceFilter.min;
                                        option.max = option.max !== undefined ? option.max : priceFilter.max;
                                    }
                                }
                                catch (err) {
                                    console.error(err);
                                    // ccl
                                }
                            }
                            if (option.key === undefined || (option.values === undefined && option.min === undefined && option.booleanValue === undefined))
                                return undefined;
                            const obj = {};
                            if (option.booleanValue !== undefined) {
                                obj[option.key] = option.booleanValue;
                            }
                            else if (option.values !== undefined) {
                                obj[option.key] = option.values.map((val) => {
                                    if (val.value !== undefined && val.name !== undefined && val.value !== val.name) {
                                        optionViewNameMap[`${option.key}<#>${val.value}`] = val.name;
                                    }
                                    if (val.value !== undefined)
                                        return val.value;
                                    if (val.key !== undefined)
                                        return val.key;
                                    return val.name;
                                });
                            }
                            else {
                                const val = {
                                    min: option.min,
                                    max: option.max,
                                    shortName: option.shortName,
                                    unit: option.unit
                                };
                                obj[option.key] = val;
                            }
                            if (option.name) {
                                // @ts-ignore
                                filterOptionNameMap[option.key] = option.name;
                            }
                            return obj;
                        }).filter((option) => option !== undefined);
                    }
                    let filterOptions = this.isLandingPageSearch && selectedText !== '_#' && (selectedText || '').indexOf('#') !== -1 && (pluginConfiguration.results.categorySearch === undefined || pluginConfiguration.results.categorySearch.showCategoryFilter !== true) ?
                        data.filterOptions.filter((fg) => fg.type !== FilterType.CategoryTree && fg.filterType !== FilterType.CategoryTree && fg.type !== FilterType.Tree && fg.filterType !== FilterType.Tree)
                        : data.filterOptions;
                    filterOptions = filterOptions.filter((fg) => {
                        const { filterType, type, visible } = fg;
                        if (visible === false && !filtersConfiguration.showHiddenFilters) {
                            return false;
                        }
                        if (filterType === FilterType.CategoryTree || filterType === FilterType.Tree || type === FilterType.CategoryTree || type === FilterType.Tree) {
                            return (fg.categories || []).length > 0;
                        }
                        return true;
                    });
                    const filterChangeCallback = (activeFilterValues, activeGuidedQuestions) => {
                        const filterArr = Object.keys(activeFilterValues).reduce((acc, key) => {
                            const obj = {};
                            const name = filterIdToName[key];
                            const values = activeFilterValues[key];
                            obj.name = name;
                            obj.key = key;
                            if (values === true || values === false) {
                                obj.booleanValue = values;
                            }
                            else if (values instanceof Array) {
                                obj.values = values.map((val) => ({ value: val }));
                            }
                            else {
                                obj.min = values.min;
                                obj.max = values.max;
                                if (values.shortName) {
                                    obj.shortName = values.shortName;
                                }
                                if (values.unit) {
                                    obj.unit = values.unit;
                                }
                            }
                            acc.push(obj);
                            return acc;
                        }, []);
                        const activeQueryParts = {};
                        data.activeFilterOptions.forEach((filter) => {
                            if (filter.key && filter.values && filter.values.length > 0) {
                                filter.values.forEach((val) => {
                                    if (val.queryPart) {
                                        activeQueryParts[`${filter.key}<#>${val.value}`] = val.queryPart;
                                    }
                                });
                            }
                        });
                        const queryPartsToRemove = [];
                        if (Object.keys(activeQueryParts).length > 0) {
                            const newQueryParts = filterArr.reduce((acc, filter) => {
                                if (filter.key && filter.values && filter.values.length > 0) {
                                    filter.values.forEach((val) => {
                                        acc.push(`${filter.key}<#>${val.value}`);
                                    });
                                }
                                return acc;
                            }, []);
                            Object.keys(activeQueryParts).forEach((key) => {
                                if (newQueryParts.indexOf(key) === -1) {
                                    queryPartsToRemove.push(activeQueryParts[key]);
                                }
                            });
                        }
                        queryPartsToRemove.forEach((queryPart) => {
                            try {
                                const re = new RegExp(queryPart, 'gi');
                                selectedText = selectedText.replace(re, '').trim();
                                if (this.queryFromReload !== undefined) {
                                    this.queryFromReload = this.queryFromReload.replace(re, '').trim();
                                }
                            }
                            catch (err) {
                                // ccl
                            }
                        });
                        this.showResults(selectedText, {
                            sort,
                            filters: filterArr,
                            shouldPushState: true,
                            submitSource: 'filter',
                            activeGuidedQuestions
                        });
                    };
                    let topFilterOptions = undefined;
                    let leftFilterOptions = undefined;
                    let forceSlideIn = false;
                    if (filtersConfiguration.position === FilterPosition.Left) {
                        leftFilterOptions = [...filterOptions];
                        forceSlideIn = filtersConfiguration.forceSlideIn;
                        if (filtersConfiguration.highlightPopularFilters && !forceSlideIn) {
                            topFilterOptions = filtersConfiguration.duplicatePopularFilters ? [...leftFilterOptions].splice(0, 3) : leftFilterOptions.splice(0, 3);
                        }
                    }
                    else {
                        topFilterOptions = filterOptions;
                        if (topFilterOptions.length > filtersConfiguration.maxTopCount || (submitSource === 'filter' && this.filterSubmitLeft)) {
                            topFilterOptions = [...topFilterOptions].splice(0, filtersConfiguration.maxTopCount);
                            leftFilterOptions = [...filterOptions];
                            forceSlideIn = true;
                        }
                    }
                    const filterModificationMap = {};
                    const filterDeletionValueMap = {};
                    if (topFilterOptions !== undefined && topFilterOptions.length > 0) {
                        if (leftFilterOptions === undefined) {
                            this.context.layer.get().getMobileFilterToggle().remove();
                        }
                        const f = new Filter(this.context, topFilterOptions, activeFilterOptions, selectedText.trim(), (activeFilterValues, activeGuidedQuestions) => {
                            this.filterSubmitLeft = false;
                            filterChangeCallback(activeFilterValues, activeGuidedQuestions);
                        }, filterIdToName, data.totalResults, data.filterMapping, filterOptionNameMap, optionViewNameMap, data.activeGuidedQuestions, FilterPosition.Top, false, true, undefined, filterModificationMap, filterDeletionValueMap, filterOptions);
                        this.filterBlocks.push(f);
                    }
                    else {
                        this.context.layer.get().detachFilterFrame(true);
                    }
                    if (leftFilterOptions !== undefined && leftFilterOptions.length > 0) {
                        const f = new Filter(this.context, leftFilterOptions, activeFilterOptions, selectedText.trim(), (activeFilterValues, activeGuidedQuestions) => {
                            this.filterSubmitLeft = true;
                            filterChangeCallback(activeFilterValues, activeGuidedQuestions);
                        }, filterIdToName, data.totalResults, data.filterMapping, filterOptionNameMap, optionViewNameMap, data.activeGuidedQuestions, FilterPosition.Left, forceSlideIn, topFilterOptions !== undefined, topFilterOptions !== undefined ? filtersConfiguration.toggleAllButtonLabel : filtersConfiguration.toggleButtonLabel, filterModificationMap, filterDeletionValueMap, filterOptions, data.getFilterGroups());
                        this.filterBlocks.push(f);
                    }
                    else {
                        this.context.layer.get().detachFilterFrame(false);
                    }
                });
                // back click from search result
                if (Results.wasBackPressed(selectedText, this.context)) {
                    SxQueryUtils.requestAnimationFrame(() => {
                        Results.handleBackPress(this.navigation, selectedText, this.searchResultType, this.context);
                    });
                }
            }
            else {
                layoutFrame.detachFilterFrame(true);
                layoutFrame.detachFilterFrame(false);
            }
        };
        // layover vs embed
        if (this.searchResultType === SearchResultType.Layover) {
            // // layover
            UiHelper.prepareLayoverLayer(layoutFrame, () => {
                this.closeLayer();
            }, pluginConfiguration);
            renderFilter();
        }
        else {
            // // embed
            renderFilter();
            if (!layoutFrame.isVisible()) {
                layoutFrame.fadeIn();
            }
            if (this.searchResultType !== SearchResultType.Fullscreen) {
                layoutFrame.attachToContentBlock(this.searchResultConfig.contentBlock, pluginConfiguration.results._recreateParent);
            }
        }
        // focus layer header
        if (!this.is404 && !pluginConfiguration.suggestions.triggersSearch && resultsConfiguration.focusResultBlock && submitSource !== 'filter') {
            setTimeout(() => {
                layoutFrame.focusHeading();
            }, 5);
        }
        this.is404 = false;
        ImageHandler(this.context);
        // change the URL so that we can deep link or go back to this result page
        const queryDict = NavigatorHelper.buildQueryDict();
        if (resultsConfiguration.nameParsing && data.filterOptions !== undefined && data.filterOptions.length > 0) {
            // remove all filters
            Object.keys(filterIdToName).forEach((key) => {
                const filterName = filterIdToName[key];
                const qp = NavigatorHelper.escapeQueryParam(filterName || '');
                delete queryDict[qp];
                delete queryDict[decodeURIComponent(qp)];
            });
        }
        if (config.filters !== undefined && config.filters.length > 0) {
            // round up to two decimals
            config.filters.forEach((filter) => {
                const keys = Object.keys(filter);
                const obj = filter;
                if (keys.length > 0 && obj !== undefined && obj.min !== undefined && obj.max !== undefined && !Helper.isNaN(obj.min) && !Helper.isNaN(obj.max)) {
                    try {
                        const min = obj.min;
                        const max = obj.max;
                        if (min * 100 !== Math.round(min * 100)) {
                            obj.min = parseFloat(min.toFixed(2));
                        }
                        if (max * 100 !== Math.round(max * 100)) {
                            obj.max = parseFloat(max.toFixed(2));
                        }
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
            });
            // add filters to query
            if (resultsConfiguration.nameParsing) {
                // add filters to url
                const filters = config.filters.map((f) => {
                    const obj = {};
                    if (f.name === undefined) {
                        return undefined;
                    }
                    if (f.values !== undefined) {
                        obj[f.name] = f.values;
                    }
                    else if (f.booleanValue !== undefined) {
                        obj[f.name] = f.booleanValue;
                    }
                    else {
                        obj[f.name] = {
                            min: f.min,
                            max: f.max
                        };
                    }
                    return obj;
                }).filter((entry) => entry !== undefined);
                const toFixedNumber = (val) => {
                    // @ts-ignore
                    if (parseFloat(val) !== Math.round(parseFloat(val))) {
                        // @ts-ignore
                        return parseFloat(val).toFixed(2);
                    }
                    return val;
                };
                filters.forEach((filter) => {
                    const key = Object.keys(filter)[0];
                    if (key !== undefined) {
                        const originalVal = filter[key];
                        let val;
                        if (originalVal.min !== undefined && originalVal.max !== undefined && !(originalVal instanceof Array)) {
                            let unit = '';
                            const settings = filtersConfiguration.settings;
                            if (settings !== undefined && Object.keys(settings).length > 0) {
                                const filterName = key.toLowerCase();
                                const filterId = ((data.filterOptions || []).filter((entry) => (entry.name || '').toLowerCase() === filterName)[0] || {}).key;
                                const keys = Object.keys(settings).filter((k) => settings[k] !== undefined);
                                for (let i = 0; i < keys.length; i++) {
                                    const settingKey = keys[i];
                                    if (settingKey === filterId || settingKey.toLowerCase() === filterName) {
                                        unit = settings[settingKey].unit !== undefined ? `~${settings[settingKey].unit}` : '';
                                        break;
                                    }
                                }
                            }
                            val = `${toFixedNumber(originalVal.min)}-${toFixedNumber(originalVal.max)}${NavigatorHelper.escapeQueryParam(unit)}`;
                        }
                        else if (originalVal === true || originalVal === false) {
                            val = `~${originalVal}`;
                        }
                        else if (originalVal.filter) {
                            val = originalVal.filter((filterVal) => filterVal.value).map((filterVal) => NavigatorHelper.escapeQueryParam(filterVal.value)).join(',');
                        }
                        if (val) {
                            queryDict[NavigatorHelper.escapeQueryParam(key)] = val;
                        }
                    }
                });
            }
            else {
                queryDict['ss360Filter'] = JSON.stringify(config.filters);
            }
        }
        else {
            delete queryDict['ss360Filter'];
        }
        if (pluginConfiguration.layout.showListGridToggle && totalResults > 0) {
            ListGridToggle.render(this.context, data.getResultGroupNames(), selectedText);
        }
        if (pluginConfiguration.results.showGuidedQuestions && totalResults > 0) {
            GuidedQuestions.render(data.getGuidedQuestions(), this.context, data);
        }
        if (resultsConfiguration.nameParsing) {
            const sortingParamName = NavigatorHelper.escapeQueryParam(resultsConfiguration.sortingParamName);
            if (config.sort !== undefined && config.sort !== '_relevance') {
                queryDict[sortingParamName] = NavigatorHelper.escapeQueryParam(config.sort);
            }
            else {
                delete queryDict[sortingParamName];
            }
        }
        if (activeGuidedQuestions && activeGuidedQuestions.length > 0) {
            queryDict['ss360-qa-flow'] = encodeURIComponent(JSON.stringify(activeGuidedQuestions));
        }
        else {
            delete queryDict['ss360-qa-flow'];
        }
        if (config.shouldPushState !== false && NavigatorHelper.hasHistoryAPI(resultsConfiguration)) {
            if (resultsConfiguration.semanticMode) {
                NavigatorHelper.pushSemanticState(selectedText, queryDict, pluginConfiguration);
            }
            else {
                const qparam = resultsConfiguration.searchQueryParamName;
                if (qparam !== '') {
                    if (!this.isLandingPageSearch) {
                        queryDict[qparam] = selectedText;
                    }
                    const stateUrl = NavigatorHelper.pushState(queryDict, pluginConfiguration, this.isLandingPageSearch ? {
                        query: selectedText,
                        requestOptions
                    } : undefined);
                    this.context.storeObject(this.context.getIsZoovu() ? 'zoovu_last_query_result' : 'ss360_last_query_result', Object.assign({ siteId: this.siteId, locationHref: stateUrl, timestampMs: new Date().getTime() }, data.plain));
                }
            }
        }
        const clearQueryEventName = `keydown.${this.context.generateId('clearLastResult')}`;
        sxQuery(window).off(clearQueryEventName);
        sxQuery(window).on(clearQueryEventName, (e) => {
            if (e.ctrlKey && (e.key === 'F5' || e.code === 'F5')) {
                this.context.storeObject('ss360_last_query_result', null);
                this.context.storeObject('zoovu_last_query_result', null);
                sxQuery(window).off(clearQueryEventName);
            }
        });
        // back click from search result
        if (!hasFilters && Results.wasBackPressed(selectedText, this.context)) {
            Results.handleBackPress(this.navigation, selectedText, this.searchResultType, this.context);
        }
        // we might need to clean the container one more time
        const { embedConfig } = resultsConfiguration;
        if (this.searchResultType === SearchResultType.Embed && embedConfig !== undefined && embedConfig.contentBlock !== undefined) {
            const $contentBlock = sxQuery(embedConfig.contentBlock, true);
            if ($contentBlock.find(`#${this.context.layerId}`).length === 1 && $contentBlock.children().length > 1) {
                $contentBlock.children().each((child) => {
                    const $child = sxQuery(child);
                    if ($child.attr('id') !== 'ss360-layer') {
                        $child.remove();
                    }
                });
            }
        }
        // related searches
        if ((data.relatedQueries || []).length > 0 && resultsConfiguration.showRelatedQueries) {
            new RelatedQueries(data.relatedQueries, this.context).render(layoutFrame, resultsConfiguration.renderRelatedQueriesTitle);
        }
        if (callbacksConfiguration.postSearch !== undefined) {
            try {
                callbacksConfiguration.postSearch.call(this, data.plain, submitSource);
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
        if (config.callback !== undefined) {
            try {
                config.callback.call(this, data);
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
        if (pluginConfiguration.layout.mobile.type === LayoutType.Masonry || pluginConfiguration.layout.desktop.type === LayoutType.Masonry) {
            Masonry.init(this.context);
        }
        const customSearchBox = layoutFrame.getSearchBox();
        if (customSearchBox.length > 0 && (this.selectedSearchBox === undefined || customSearchBox.get()[0] !== this.selectedSearchBox.get()[0])) {
            customSearchBox.val(selectedText);
        }
        // log query for analytics
        this.context.reporter.reportSerp(selectedText, this.context);
        if (resultsConfiguration.showBackToTop && this.searchResultType === SearchResultType.Embed) {
            BackToTop.init(this.context.layer.get(), resultsConfiguration.backToTopLabel, resultsConfiguration.backToTopScrollNode);
        }
        this.areResultsVisible = true;
        if (this.context.hasInsights()) {
            let resultType = 'layover';
            if (resultsConfiguration.embedConfig !== undefined) {
                resultType = 'embed';
            }
            else if (resultsConfiguration.fullScreenConfig !== undefined) {
                resultType = 'fullscreen';
            }
            if (this.is404) {
                resultType = 'smart404';
            }
            const allItems = layoutFrame.getVisibleResults(undefined, false).get();
            sxQuery(window).off(`beforeunload.${this.context.getInsightsEventKey()}`);
            sxQuery(window).on(`beforeunload.${this.context.getInsightsEventKey()}`, () => {
                if (this.context.hasInsights()) {
                    const _layoutFrame = this.context.layer.get();
                    const allSuggestItems = _layoutFrame.getVisibleResults().get();
                    this.context.getInsights().trackSerpLeave(_layoutFrame.getLayerContent().get()[0], allSuggestItems[0], selectedText, allSuggestItems.length, resultType, totalResults);
                }
            });
            let filterData;
            if (filtersConfiguration.enabled && data.activeFilterOptions) {
                filterData = [];
                data.activeFilterOptions.forEach((filterGroup) => {
                    // @ts-ignore
                    filterData.push(`${filterGroup.key}<#>${filterGroup.name}`);
                });
                filterData = JSON.stringify(filterData);
            }
            this.context.getInsights().trackSerpShow(layoutFrame.getLayerContent().get()[0], allItems[0], selectedText, allItems.length, resultType, filterData, totalResults);
        }
    }
    getContext() {
        return this.context;
    }
    getSearchResultType() {
        return this.searchResultType;
    }
    showLoading() {
        UiHelper.showLoadingAnimation();
    }
    hideLoading() {
        UiHelper.hideLoadingAnimation();
    }
    setBaseUrl(url) {
        this.context.urlBuilder.setSearchBase(url);
    }
    setSuggestUrl(url) {
        this.context.urlBuilder.setSuggestBase(url);
        this.updateSuggestionUrl(this.buildSuggestionUrl());
    }
    getpluginSettings() {
        return this.pluginConfiguration;
    }
    getSettings() {
        return this.pluginConfiguration;
    }
    getUiBuilder() {
        return this.uiBuilder;
    }
    openTab(tabName) {
        if (this.navigation !== undefined) {
            this.navigation.focusTab(StringHelper.getSafeKey(tabName));
        }
    }
    destroy() {
        const $window = sxQuery(window);
        $window.off(`popstate.${this.context.generateId('ss360History')}`);
        $window.off(`beforeunload.${this.context.getInsightsEventKey()}`);
        const sliderEventName = this.context.generateId('ss360Slider');
        $window.off(`resize.${sliderEventName}`);
        $window.off(`mousemove.${sliderEventName}`);
        $window.off(`mouseup.${sliderEventName}`);
        $window.off(`touchmove.${sliderEventName}`);
        $window.off(`touchend.${sliderEventName}`);
        $window.off(`resize.${this.context.generateId('ss360GridPolyfill')}`);
        if (this.context.isDefaultInstance && ('SS360' in window || 'ZOOVU_SEARCH' in window)) {
            // @ts-ignore
            delete window.SS360;
            // @ts-ignore
            delete window.ZOOVU_SEARCH;
        }
        else if (!this.context.isDefaultInstance && this.context.alias in window) {
            // @ts-ignore
            delete window[this.context.alias];
        }
        else if (!this.context.isDefaultInstance && this.context.pluginConfiguration.alias in window) {
            // @ts-ignore
            delete window[this.context.pluginConfiguration.alias];
        }
        if (this.uiBuilder && this.uiBuilder.comparison) {
            this.uiBuilder.comparison.destroy();
        }
    }
    setIsZoovu(isZoovu) {
        this.context.setIsZoovu(isZoovu);
    }
    clearOldCachedResult() {
        const key = this.context.getIsZoovu() ? 'zoovu_last_query_result' : 'ss360_last_query_result';
        const storedResult = this.context.readObject(key);
        if (storedResult !== undefined && (storedResult.timestampMs + 5 * 60 * 1000) < new Date().getTime()) {
            this.context.storeObject(key, null);
        }
    }
    openMiniPDP(identifier) {
        let suggest;
        let targetNode;
        const readFromResponse = (data) => {
            const allResults = data.getFlatSuggests();
            for (let i = 0; i < allResults.length; i++) {
                const res = allResults[i];
                if (res.getIdentifier() === identifier) {
                    suggest = res;
                    break;
                }
                if (res.variants) {
                    for (let j = 0; j < res.variants.length; j++) {
                        if (res.variants[j].identifier === identifier) {
                            suggest = new SearchSuggest([res.variants[j], res.suggest, ...res.variants.filter((variant, idx) => idx !== j)]);
                            break;
                        }
                    }
                }
            }
            if (suggest !== undefined) {
                this.context.layer.get(true).getVisibleResults().each((el) => {
                    const $el = sxQuery(el);
                    if ($el.data('productIdentifier') === identifier) {
                        targetNode = $el;
                    }
                });
            }
        };
        if (this.lastRenderedImmersiveData) {
            readFromResponse(this.lastRenderedImmersiveData);
        }
        if (suggest !== undefined) {
            new MiniPDP(this.context, suggest, targetNode || new SxQueryObject([])).show();
        }
        else {
            const queryUrl = this.buildQueryUrl({
                query: identifier,
                limit: 1,
                sort: '',
                include: [],
                exclude: [],
                tracking: false,
                offset: 0,
                limitPerGroup: false,
                isPlain: false
            });
            Results.fetch(queryUrl, this.context, (data) => {
                readFromResponse(data);
                if (suggest !== undefined) {
                    new MiniPDP(this.context, suggest, targetNode || new SxQueryObject([])).show();
                }
                else {
                    Logger.error(`could not find product ${identifier}`);
                }
            }, () => {
                Logger.error(`could not find product ${identifier}`);
            });
        }
    }
    closeFilters() {
        this.filterBlocks.forEach((fb) => {
            // @ts-ignore
            fb.hideMobile();
        });
    }
    closeAllDrawers() {
        closeAllMiniPDPs();
        this.closeFilters();
    }
    invalidateZoe() {
        this.queryFromReload = undefined;
        this.context.storeObject('ss360_last_query_result', null);
        this.context.storeObject('zoovu_last_query_result', null);
        this.lastRenderedImmersiveData = undefined;
    }
}
export default SiteSearch360Core;
