'use strict';
import StringHelper from '../global/StringHelper';
import SxQueryShared from './SxQueryShared';
import Logger from '../global/Logger';
// @ts-ignore
const _callbacksByName = ('__ss360CallbacksMap' in window) ? window['__ss360CallbacksMap'] : {};
// @ts-ignore
window['__ss360CallbacksMap'] = _callbacksByName;
const CSS_TO_PIXEL = {
    width: true,
    height: true,
    minWidth: true,
    minHeight: true,
    maxWidth: true,
    maxHeight: true,
    padding: true,
    paddingLeft: true,
    paddingRight: true,
    paddingTop: true,
    paddingBottom: true,
    left: true,
    right: true,
    top: true,
    bottom: true,
    borderWidth: true
};
const makePureArray = (elems) => {
    if (elems.length === 0) {
        return [];
    }
    const pureArray = [];
    for (let g = 0; g < elems.length; g++) {
        pureArray.push(elems[g]);
    }
    return pureArray;
};
const canMatch = (node) => node.matches || node.matchesSelector || node.msMatchesSelector;
const match = (node, querySelector) => {
    try {
        // @ts-ignore
        if ((typeof querySelector) !== 'string' && (querySelector instanceof HTMLElement)) {
            const querySelectorHTML = querySelector;
            let selector = querySelectorHTML.nodeName.toLowerCase();
            if (querySelectorHTML.classList.length > 0) {
                selector = `${selector}.${[].slice.call(querySelectorHTML.classList).join('.')}`;
            }
            if (querySelectorHTML.id) {
                selector = `${selector}#${querySelectorHTML.id}`;
            }
            querySelector = selector;
        }
    }
    catch (ex) {
        // ccl
    }
    if (node.matches) {
        return node.matches(querySelector);
    }
    if (node.matchesSelector) {
        return node.matchesSelector(querySelector);
    }
    return node.msMatchesSelector(querySelector);
};
const _animate = (el, animationType, duration, cosParameter, setter, callback) => {
    let count = 0;
    let last = +new Date();
    const tick = function () {
        const current = new Date().getTime();
        count += Math.PI / (duration / (current - last));
        const val = cosParameter + cosParameter * Math.cos(count);
        setter(el, val);
        last = +new Date();
        if (count >= Math.PI) {
            if (callback !== undefined && typeof callback === 'function') {
                callback(el);
            }
        }
        else {
            SxQueryShared._notifyAnimation(el, animationType, SxQueryShared._requestAnimation(tick));
        }
    };
    SxQueryShared._notifyAnimation(el, animationType, SxQueryShared._requestAnimation(tick));
};
const _construct = (data, preventCreatingElement = false) => {
    if (data === undefined || data === null) {
        return new SxQueryObject([]);
    }
    let isWindow = false;
    try {
        isWindow = data instanceof Window || data === window;
    }
    catch (e) {
        isWindow = window.constructor ? data instanceof window.constructor : data === window;
    }
    if (typeof data === 'string') {
        const parsedHTML = !preventCreatingElement && data.indexOf('<') !== -1 ? SxQueryShared.parseHTML(data) : [];
        if (parsedHTML.length === 0) {
            try {
                return new SxQueryObject(querySelectorAll(data));
            }
            catch (ex) {
                console.error(ex);
                return new SxQueryObject([]);
            }
        }
        return new SxQueryObject(parsedHTML);
    }
    if (data instanceof Node || data === document || (data !== undefined && data.appendChild && data.cloneNode)) {
        return new SxQueryObject([data]);
    }
    if (data instanceof Array || data instanceof HTMLCollection || data instanceof NodeList) {
        if (data instanceof Array && data.reduce((acc, node) => acc && (typeof node === 'string'), true)) { // array of html strings
            return new SxQueryObject(data.map((item) => SxQueryShared.parseHTML(item)));
        }
        return new SxQueryObject(data);
    }
    if (isWindow) {
        return new SxQueryObject([data]);
    }
    if (data instanceof SxQueryObject) {
        return new SxQueryObject(data.get());
    }
    return new SxQueryObject([]); //
};
const querySelectorAll = (data, node) => {
    node = node || document;
    let matches = [];
    // get just first element for id-only query - same behavior as jQuery
    if (data.indexOf('#') === 0 && data.indexOf(' ') === -1 && data.indexOf('.') === -1 && data.indexOf(':') === -1 && data.indexOf('>') !== -1) {
        const found = node.getElementById ? node.getElementById(data.replace('#', '')) : node.querySelector(data);
        if (found) {
            matches.push(found);
        }
        return matches;
    }
    if (data.indexOf(':first') !== -1 || data.indexOf(':visible') !== -1) { // handle jQuery-like :first and :visible
        const partialQueries = data.split(' ');
        for (let i = 0; i < partialQueries.length; i++) {
            let query = partialQueries[i];
            let justFirst = false;
            let justVisible = false;
            if (query.indexOf(':first') !== -1) {
                justFirst = true;
                query = query.replace(':first', '');
            }
            else if (query.indexOf(':visible') !== -1) {
                justVisible = true;
                query = query.replace(':visible', '');
            }
            matches = matches.length === 0 ? _construct(node).find(query) : matches.find(query);
            if (justFirst && matches.length > 0) {
                matches = _construct(matches[0]);
            }
            else if (justVisible && matches.length > 0) {
                matches = matches.filter((m) => _construct(m).isVisible());
            }
        }
        matches = matches.get();
    }
    else if (data) {
        matches = node.querySelectorAll(data);
    }
    return matches;
};
class SxQueryObject {
    constructor(elems) {
        this._elems = makePureArray(elems);
        for (let i = 0; i < this._elems.length; i++) {
            // @ts-ignore
            this[i] = this._elems[i];
        }
        this.length = this._elems.length;
    }
    _it(callback) {
        const _elems = this._elems;
        for (let i = 0; i < _elems.length; i++) {
            const elem = _elems[i];
            if (elem && callback) {
                callback(elem, i === _elems.length - 1);
            }
        }
    }
    _addNode(content, append = true) {
        let result = [];
        if (typeof content === 'string') { // parseHtml and append
            result = result.concat(this._addNode(SxQueryShared.parseHTML(content), append));
            return result;
        }
        if (content instanceof Array || content instanceof HTMLCollection || content instanceof NodeList) { // append each element
            for (let i = append ? 0 : content.length - 1; (append ? i < content.length : i >= 0); (append ? i++ : i--)) {
                result = result.concat(this._addNode(content[i], append));
            }
            return result;
        }
        if (content instanceof SxQueryObject) { // get elements and append those
            const cInstance = content;
            result = result.concat(this._addNode(cInstance.get(), append));
            cInstance.clear();
            cInstance.push(result);
            return result;
        }
        if (content instanceof Node || (content !== undefined && content.appendChild && content.cloneNode)) { // finally really append
            this._it((elem, isLast) => {
                const node = !isLast ? content.cloneNode(true) : content;
                result.push(node);
                try {
                    if (append || !elem.firstChild) {
                        elem.appendChild(node);
                    }
                    else {
                        elem.insertBefore(node, elem.firstChild);
                    }
                }
                catch (ex) {
                    Logger.warn(ex);
                }
            });
            return result;
        }
        return result;
    }
    push(arr) {
        this._elems = this._elems || [];
        this._elems = this._elems.concat(arr);
        this.length = this._elems.length;
    }
    clear() {
        this._elems = [];
        this.length = 0;
    }
    get(idx) {
        if (idx !== undefined) {
            return this._elems[idx];
        }
        return this._elems;
    }
    remove() {
        this._it((elem) => {
            if (elem.parentNode) {
                elem.parentNode.removeChild(elem);
            }
        });
    }
    each(callback) {
        let idx = 0;
        this._it((elem) => {
            callback.call(elem, elem, idx);
            idx++;
        });
    }
    _trigger(eventName) {
        let e;
        if (window.CustomEvent) {
            try {
                e = new CustomEvent(eventName);
            }
            catch (ex) {
                // ccl
            }
        }
        if (e === undefined) {
            e = document.createEvent('CustomEvent');
            e.initCustomEvent(eventName, true, true, {});
        }
        this._it((elem) => {
            elem.dispatchEvent(e);
        });
    }
    on(event, selector, callback, args) {
        if (callback === undefined) {
            // @ts-ignore
            this.on(event, undefined, selector); // selector is actually callback
        }
        else if (!selector) {
            this._it((elem) => {
                const events = event.split(',');
                for (let i = 0; i < events.length; i++) {
                    let eventName = events[i];
                    if (eventName.indexOf('.') !== -1) {
                        const nameToKey = eventName.split('.');
                        if (nameToKey.length === 2) {
                            eventName = eventName.trim();
                            // save callback
                            if (!_callbacksByName[eventName]) {
                                _callbacksByName[eventName] = [];
                            }
                            _callbacksByName[eventName].push(callback);
                            eventName = nameToKey[0];
                        }
                    }
                    elem[`_ss360_${events[i].trim()}`] = true;
                    elem.addEventListener(eventName.trim(), callback, args);
                }
            });
        }
        else {
            const handler = function (e) {
                if (!e || !e.target) {
                    return;
                }
                if ((canMatch(e.target) && match(e.target, selector))) { // elem triggered the event
                    callback.bind(e.target, e).call();
                }
                else { // check whether elem child triggered the event
                    let node = e.target;
                    while (node.parentNode && canMatch(node.parentNode)) {
                        node = node.parentNode;
                        if (canMatch(node) && match(node, selector)) {
                            callback.bind(e.target, e).call();
                            break;
                        }
                    }
                }
            };
            this._it((elem) => {
                const events = event.split(',');
                for (let i = 0; i < events.length; i++) {
                    elem.addEventListener(events[i].trim(), handler);
                }
            });
        }
        return this;
    }
    off(event, listener) {
        const events = event.split(',');
        if (!events || events.length === 0) {
            return this;
        }
        events.forEach((e) => {
            const isNamedEvent = e.indexOf('.') !== -1;
            const eventName = isNamedEvent ? e.split('.')[0].trim() : undefined;
            const cleanEvent = e.trim();
            const namedCallbacks = isNamedEvent ? _callbacksByName[cleanEvent] : undefined;
            this._it((elem) => {
                if (!isNamedEvent) {
                    elem.removeEventListener(cleanEvent, listener);
                }
                else if (namedCallbacks && elem[`_ss360_${cleanEvent}`]) {
                    elem[`_ss360_${cleanEvent}`] = false;
                    namedCallbacks.forEach((callback) => {
                        elem.removeEventListener(eventName, callback);
                    });
                }
            });
        });
        return this;
    }
    mouseenter(callback) {
        return this.on('mouseenter', undefined, callback);
    }
    mousedown(callback) {
        return this.on('mousedown', undefined, callback);
    }
    mouseup(callback) {
        return this.on('mouseup', undefined, callback);
    }
    click(callback) {
        return this.on('click', undefined, callback);
    }
    scroll(callback) {
        return this.on('scroll', undefined, callback);
    }
    focus(callback) {
        if (callback === undefined) {
            this._it((elem) => {
                if (elem.focus) {
                    elem.focus();
                }
                else {
                    _construct(elem)._trigger('focus');
                }
            });
        }
        else {
            this.on('focus', undefined, callback);
        }
        return this;
    }
    blur(callback) {
        if (callback === undefined) {
            this._it((elem) => {
                if (elem.blur) {
                    elem.blur();
                }
                else {
                    _construct(elem)._trigger('blur');
                }
            });
        }
        else {
            this.on('blur', undefined, callback);
        }
        return this;
    }
    keydown(callback) {
        return this.on('keydown', undefined, callback);
    }
    keyup(callback) {
        return this.on('keyup', undefined, callback);
    }
    focusout(callback) {
        return this.on('focusout', undefined, callback);
    }
    find(querySelector) {
        const result = [];
        this._it((elem) => {
            const found = querySelectorAll(querySelector, elem);
            for (let i = 0; i < found.length; i++) {
                result.push(found[i]);
            }
        });
        return _construct(result);
    }
    findChildren(querySelector) {
        const result = [];
        this.children()._it((elem) => {
            if (elem && canMatch(elem) && (querySelector === undefined || match(elem, querySelector))) {
                result.push(elem);
            }
        });
        return _construct(result);
    }
    children() {
        return _construct(this.childrenArr());
    }
    childrenArr() {
        let result = [];
        if (this.length === 1) {
            return [].slice.call(this._elems[0].children);
        }
        this._it((elem) => {
            result = result.concat([].slice.call(elem.children));
        });
        return result;
    }
    is(node) {
        for (let i = 0; i < this._elems.length; i++) {
            const el = this._elems[i];
            if (el === node) {
                return true;
            }
        }
        return false;
    }
    matchesSelector(selector) {
        for (let i = 0; i < this._elems.length; i++) {
            const el = this._elems[i];
            if (canMatch(el) && match(el, selector)) {
                return true;
            }
        }
        return false;
    }
    text(text) {
        if (text === undefined) {
            let result = '';
            this._it((elem) => {
                result += elem.textContent || '';
            });
            return result;
        }
        this._it((elem) => {
            elem.innerText = text;
        });
        return this;
    }
    position() {
        const _elems = this._elems;
        if (_elems.length > 0) {
            const elem = _elems[0];
            const $elem = _construct(elem);
            let offset;
            if ($elem.css('position') === 'fixed') {
                offset = elem.getBoundingClientRect();
            }
            else {
                const offsetParent = elem.offsetParent;
                const $offsetParent = _construct(offsetParent);
                let parentOffset = {
                    top: 0,
                    left: 0
                };
                if (offsetParent === undefined || offsetParent === null) {
                    return parentOffset;
                }
                offset = $elem.offset();
                if (offsetParent.nodeName !== 'html') {
                    parentOffset = $offsetParent.offset();
                }
                // @ts-ignore
                parentOffset.top += parseFloat($offsetParent.css('borderTopWidth'));
                // @ts-ignore
                parentOffset.left += parseFloat($offsetParent.css('borderLeftWidth'));
                // @ts-ignore
                offset.top = offset.top - parentOffset.top - parseFloat($elem.css('marginTop'));
                // @ts-ignore
                offset.left = offset.left - parentOffset.left - parseFloat($elem.css('marginLeft'));
            }
            return offset;
        }
        return { left: -1, top: -1 };
    }
    attr(key, value) {
        const _elems = this._elems;
        if (value === undefined) {
            if (_elems.length > 0) {
                return _elems[0].getAttribute(key);
            }
            return undefined;
        }
        this._it((elem) => {
            if (value !== null) {
                elem.setAttribute(key, value);
            }
            else {
                elem.removeAttribute(key);
            }
        });
        return this;
    }
    removeAttribute(key) {
        if (key) {
            this._it((el) => {
                if (el.removeAttribute) {
                    el.removeAttribute(key);
                }
            });
        }
        return this;
    }
    hide() {
        this._it((elem) => {
            elem.style.display = 'none';
        });
        return this;
    }
    show() {
        this._it((elem) => {
            elem.style.display = '';
        });
        return this;
    }
    data(key, value) {
        if (value === undefined) {
            const _elems = this._elems;
            if (_elems.length > 0) {
                return _elems[0].dataset[key];
            }
            return undefined;
        }
        this._it((el) => {
            if (value === null) {
                delete el.dataset[key];
            }
            else {
                el.dataset[key] = value;
            }
        });
        return this;
    }
    addClass(className) {
        const classElems = className.split(' ');
        this._it((elem) => {
            for (let i = 0; i < classElems.length; i++) {
                if (classElems[i].trim().length > 0) {
                    if (elem.classList) {
                        elem.classList.add(classElems[i]);
                    }
                    else {
                        elem.className += ` ${classElems[i]}`;
                    }
                }
            }
        });
        return this;
    }
    removeClass(className) {
        const classElems = className.split(' ');
        this._it((elem) => {
            for (let i = 0; i < classElems.length; i++) {
                if (classElems[i].trim().length > 0) {
                    if (elem.classList) {
                        elem.classList.remove(classElems[i]);
                    }
                    else {
                        elem.className = elem.className.replace(new RegExp(`(^|\\b)${classElems[i].split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
                    }
                }
            }
        });
        return this;
    }
    toggleClass(className) {
        if (this.hasClass(className)) {
            this.removeClass(className);
        }
        else {
            this.addClass(className);
        }
        return this;
    }
    hasClass(className) {
        const _elems = this._elems;
        for (let i = 0; i < _elems.length; i++) { // not using _it to be able to break the loop
            const elem = _elems[i];
            if (elem) {
                if (elem.classList) {
                    if (elem.classList.contains(className)) {
                        return true;
                    }
                }
                else if (new RegExp(`(^| )${className}( |$)`, 'gi').test(elem.className)) {
                    return true;
                }
            }
        }
        return false;
    }
    /* Returns new SxQueryObject containing filtered elements */
    filter(validator) {
        return _construct(this.get().filter(validator));
    }
    val(value) {
        if (value !== undefined) {
            this._it((elem) => {
                elem.value = value;
            });
            return this;
        }
        if (this._elems.length > 0) {
            return this._elems[0].value;
        }
        return undefined;
    }
    css(key, value) {
        key = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        if (value !== undefined) {
            if (value !== null) {
                const valueString = value.toString();
                // @ts-ignore
                if (CSS_TO_PIXEL[key] && valueString !== 'auto' && valueString !== 'none' && valueString.indexOf('px') === -1 && valueString.indexOf('%') === -1 && valueString.indexOf('calc') === -1
                    && valueString.indexOf('em') === -1 && value !== 0 && value !== '') {
                    value = value.toString();
                    value += 'px';
                }
            }
            this._it((elem) => {
                elem.style[key] = value;
            });
            return this;
        }
        const _elems = this._elems;
        if (_elems.length > 0) {
            for (let i = 0; i < _elems.length; i++) {
                try {
                    // @ts-ignore
                    return window.getComputedStyle(_elems[i])[key];
                }
                catch (e) {
                    // ccl
                }
            }
        }
        return null;
    }
    append(content) {
        this._addNode(content, true);
        return this;
    }
    appendIfNeeded(content) {
        if (this.length === 0 || content.length === 0) {
            return this;
        }
        if (!content.isMounted() || content.length > 1 || this.length > 1 || this.get(0).lastElementChild !== content.get(0)) {
            return this.append(content);
        }
        return this;
    }
    prepend(content) {
        this._addNode(content, false);
        return this;
    }
    parent() {
        const result = [];
        this._it((elem) => {
            if (elem.parentNode !== null) {
                result.push(elem.parentNode);
            }
        });
        return _construct(result);
    }
    parents(querySelector, returnSxQueryInstance = false) {
        const result = [];
        this._it((elem) => {
            let parent = elem.parentNode;
            while (parent && canMatch(parent)) {
                if (querySelector === undefined || match(parent, querySelector)) {
                    result.push(parent);
                }
                parent = parent.parentNode;
            }
        });
        return returnSxQueryInstance === true ? _construct(result) : result;
    }
    prev() {
        const result = [];
        this._it((elem) => {
            result.push(elem.previousElementSibling);
        });
        return _construct(result);
    }
    next() {
        const result = [];
        this._it((elem) => {
            result.push(elem.nextElementSibling);
        });
        return _construct(result);
    }
    closest(querySelector) {
        const result = [];
        this._it((elem) => {
            let tested = elem;
            while (tested && canMatch(tested) && !match(tested, querySelector)) {
                tested = tested.parentNode;
            }
            if (!canMatch(tested)) {
                tested = undefined;
            }
            result.push(tested);
        });
        return _construct(result);
    }
    index(htmlNode) {
        const _elems = this._elems;
        for (let i = 0; i < _elems.length; i++) {
            if (_elems[i] === htmlNode) {
                return i;
            }
        }
        return -1;
    }
    offset() {
        const _elems = this._elems;
        if (_elems.length > 0) {
            const elem = _elems[0];
            if (!elem.getClientRects().length) {
                return {
                    top: 0,
                    left: 0
                };
            }
            const rect = elem.getBoundingClientRect();
            const win = elem.ownerDocument.defaultView;
            return {
                top: rect.top + win.pageYOffset,
                left: rect.left + win.pageXOffset
            };
        }
        return {
            top: undefined,
            left: undefined
        };
    }
    outerWidth() {
        const _elems = this._elems;
        if (_elems.length > 0) {
            return _elems[0].offsetWidth;
        }
        return undefined;
    }
    outerHeight() {
        const _elems = this._elems;
        if (_elems.length > 0) {
            return _elems[0].offsetHeight;
        }
        return undefined;
    }
    width(val) {
        if (val !== null && val !== undefined) {
            if (val.toString().indexOf('px') === -1 && val.toString().indexOf('%') === -1 && val !== 'auto') {
                val = `${val.toString()}px`;
            }
            this.css('width', val);
            return this;
        }
        // @ts-ignore
        return parseFloat(this.css('width'));
    }
    height(val) {
        if (val !== null && val !== undefined) {
            if (val.toString().indexOf('px') === -1 && val.toString().indexOf('%') === -1 && val !== 'auto') {
                val = `${val.toString()}px`;
            }
            this.css('height', val);
            return this;
        }
        // @ts-ignore
        return parseFloat(this.css('height'));
    }
    html(data) {
        if (data !== undefined) {
            this.empty();
            if ((typeof data) === 'string' && data.length > 0 && SxQueryShared.parseHTML(data).length === 0) { // we cannot parse the HTML String --> probably a simple innerHTML String with escaped entities, just inject it
                this._it((el) => {
                    el.innerHTML = data;
                });
            }
            else {
                this.append(data);
            }
            return this;
        }
        if (this._elems.length > 0) {
            return this._elems[0].innerHTML;
        }
        return undefined;
    }
    empty() {
        this._it((elem) => {
            while (elem.firstChild) {
                elem.removeChild(elem.firstChild);
            }
        });
    }
    scrollTop(val) {
        if (val !== undefined) {
            this._it((elem) => {
                if (elem.scrollTop !== undefined) {
                    elem.scrollTop = val;
                }
                else if (elem.scrollY !== undefined && elem.scrollTo !== undefined) {
                    elem.scrollTo(elem.scrollX, val);
                }
            });
            return this;
        }
        const _elems = this._elems;
        if (_elems.length > 0) {
            return _elems[0].scrollTop !== undefined ? _elems[0].scrollTop : _elems[0].scrollY;
        }
        return undefined;
    }
    scrollTopBy(val) {
        if (val === 0) {
            return;
        }
        this._it((elem) => {
            if (elem.scrollTop !== undefined) {
                elem.scrollTop = elem.scrollTop + val;
            }
            else if (elem.scrollY !== undefined && elem.scrollTo !== undefined) {
                elem.scrollTo(elem.scrollX, elem.scrollY + val);
            }
        });
        return this;
    }
    ready(fn) {
        this._it((elem) => {
            if (elem.attachEvent ? elem.readyState === 'complete' : elem.readyState !== 'loading') {
                fn();
            }
            else {
                elem.addEventListener('DOMContentLoaded', fn);
            }
        });
    }
    /**
     * ~ jQuery(elem).is(":visible");
     */
    isVisible() {
        const _elems = this._elems;
        if (_elems.length > 0) {
            return _construct(_elems[0]).css('display') !== 'none';
        }
        return false;
    }
    isMounted() {
        const _elems = this._elems;
        if (_elems.length === 0) {
            return false;
        }
        if (_elems[0] && _elems[0].isConnected === true) {
            return true;
        }
        return _construct(_elems[0]).parents('body', true).length > 0;
    }
    map(callback) {
        const result = [];
        let i = 0;
        this._it((elem) => {
            result.push(callback(i, elem));
            i++;
        });
        return result;
    }
    _fade(out, duration, callback) {
        const factor = out ? -1 : 1;
        const setter = function (el, val) {
            if (el === undefined || val === undefined) {
                return;
            }
            el.style.opacity = factor > 0 ? 1 - val : val;
        };
        const completeCallback = function (el) {
            const $el = _construct(el);
            $el.css('opacity', '');
            if (out) {
                $el.hide();
            }
            if (callback && typeof callback === 'function') {
                callback.bind($el).call();
            }
        };
        this._it((el) => {
            SxQueryShared._clearAnimation(el, 'fade');
            if (duration !== 0 && !SxQueryShared.prefersReducedMotion()) {
                _animate(el, 'fade', duration || 400, 0.5, setter, completeCallback);
            }
            else {
                completeCallback(el);
            }
        });
    }
    fadeIn(duration, callback, displayType) {
        if (displayType === undefined || displayType !== 'flex') {
            this.css('display', 'block');
        }
        else {
            this._it((el) => {
                const $el = _construct(el);
                // @ts-ignore
                let style = $el.attr('style') || '';
                if (style.length > 0 && style[style.length - 1] !== ';') {
                    style += ';';
                }
                style += 'display: -ms-flexbox;display: -webkit-flex;display: flex;';
                $el.attr('style', style);
            });
        }
        this._it((el) => {
            el.style.opacity = 0;
        });
        this._fade(false, duration, callback);
    }
    fadeOut(duration, callback) {
        this._it((el) => {
            el.style.opacity = 1;
        });
        this._fade(true, duration, callback);
    }
    _slide(up, duration, callback) {
        const completeCallback = function (el) {
            const $el = _construct(el);
            $el.css('height', '');
            if (up) {
                $el.hide();
            }
            if (callback && typeof callback === 'function') {
                callback.bind($el).call();
            }
        }; // .bind(this, up, callback);
        const setter = function (height, el, val) {
            if (el === undefined || val === undefined) {
                return;
            }
            const nHeight = up ? val : height - val;
            el.style.height = `${nHeight}px`;
        };
        this._it((el) => {
            SxQueryShared._clearAnimation(el, 'slide');
            if (duration !== 0 && !SxQueryShared.prefersReducedMotion()) {
                const $el = _construct(el);
                const height = $el.outerHeight();
                $el.css('height', 0);
                const cosParam = height / 2;
                _animate(el, 'slide', duration || 400, cosParam, (element, val) => {
                    setter(height, element, val);
                }, completeCallback);
            }
            else {
                completeCallback(el);
            }
        });
    }
    slideDown(duration, callback, display) {
        const allowedDisplayTypes = ['grid', 'flex', 'block', 'inline-block'];
        this.css('display', display !== undefined && allowedDisplayTypes.indexOf(display) !== -1 ? display : 'block');
        this._slide(false, duration, callback);
    }
    slideUp(duration, callback) {
        this._it((el) => {
            const $el = _construct(el);
            $el.css('height', $el.outerHeight());
        });
        this._slide(true, duration, callback);
    }
    _animateScroll(target, duration, property, callback) {
        duration = duration || 400;
        const setter = function (startVal, el, newVal) {
            if (target >= startVal) {
                el[property] = startVal + (Math.abs(startVal - target) - newVal);
            }
            else {
                el[property] = target + newVal;
            }
        };
        this._it(function (el) {
            const startVal = el[property];
            const range = Math.abs(startVal - target);
            SxQueryShared._clearAnimation(el, property);
            if (range < 1 || duration === 0 || SxQueryShared.prefersReducedMotion()) {
                el[property] = target;
                return;
            }
            const cosParameter = range / 2;
            _animate(el, property, duration || 400, cosParameter, setter.bind(this, startVal), callback);
        });
    }
    animateScrollTop(target, duration, callback) {
        this._animateScroll(target, duration, 'scrollTop', callback);
    }
    animateScrollLeft(target, duration, callback) {
        this._animateScroll(target, duration, 'scrollLeft', callback);
    }
    animateTop(target, duration) {
        const completeCallback = (el) => {
            _construct(el).css('top', target);
        };
        const setter = function (startVal, trgt, el, nVal) {
            let val;
            if (trgt >= startVal) {
                val = nVal;
                if (startVal < 0) {
                    val *= -1;
                }
            }
            else if (trgt < 0) {
                val = trgt + nVal;
            }
            else {
                val = nVal + startVal;
            }
            _construct(el).css('top', `${val}px`);
        };
        this._it(function (el) {
            SxQueryShared._clearAnimation(el, 'positionTop');
            if (duration === 0 || SxQueryShared.prefersReducedMotion()) {
                completeCallback(el);
                return;
            }
            const $el = _construct(el);
            // @ts-ignore
            const crnt = parseFloat($el.css('top'));
            const isInPercent = target.toString().indexOf('%') !== 0;
            let trgt;
            if (isInPercent) {
                let parentHeight;
                const position = $el.css('position');
                if (position === 'fixed') {
                    parentHeight = window.innerHeight;
                }
                else {
                    // @ts-ignore
                    parentHeight = parseFloat($el.parent().css('height'));
                }
                // @ts-ignore
                const multiplier = parseFloat(target) / 100;
                trgt = multiplier * parentHeight;
            }
            else {
                // @ts-ignore
                trgt = parseFloat(target);
            }
            const range = Math.abs(crnt - trgt);
            const cosParameter = range / 2;
            _animate(el, 'positionTop', duration || 400, cosParameter, setter.bind(this, crnt, trgt), completeCallback);
        });
    }
    highlight(pat, className) {
        const innerHighlight = (node, innerPattern) => {
            if (_construct(node).parents(`.${className}`).length !== 0) {
                return 1;
            }
            let skip = 0;
            if (node.nodeType === 3) {
                let pos = node.data.toUpperCase().indexOf(innerPattern);
                pos -= (node.data.substr(0, pos).toUpperCase().length - node.data.substr(0, pos).length);
                if (pos >= 0) {
                    const spannode = document.createElement('span');
                    spannode.className = className;
                    const middlebit = node.splitText(pos);
                    middlebit.splitText(innerPattern.length); //  don't delete this line!
                    const middleclone = middlebit.cloneNode(true);
                    spannode.appendChild(middleclone);
                    middlebit.parentNode.replaceChild(spannode, middlebit);
                    skip = 1;
                }
            }
            else if (node.nodeType === 1 && node.childNodes && !/(script|style)/i.test(node.tagName)) {
                for (let i = 0; i < node.childNodes.length; ++i) {
                    i += innerHighlight(node.childNodes[i], innerPattern);
                }
            }
            return skip;
        };
        if (this.length && pat && pat.length && pat.length > 2) {
            this._it((elem) => {
                innerHighlight(elem, StringHelper.unescapeHtml(pat.toUpperCase()));
            });
        }
        return this;
    }
    onScrolledIntoViewport(callback) {
        if (this.length === 0) {
            return;
        }
        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        callback(entry);
                        observer.unobserve(entry.target);
                    }
                });
            }, {
                rootMargin: `0px`
            });
            this._it((node) => {
                observer.observe(node);
            });
        }
        else {
            let active = false;
            const $document = _construct(document);
            const $window = _construct(window);
            const eventPostfix = Math.round(Math.random() * 100000).toString();
            const removeHandlers = () => {
                $document.off(`scroll.${eventPostfix}`);
                $window.off(`resize.${eventPostfix}`);
                $window.off(`scroll.${eventPostfix}`);
                $window.off(`orientationchange.${eventPostfix}`);
            };
            let nodes = [];
            this._it((node) => {
                nodes.push(node);
            });
            const check = function () {
                if (active === false) {
                    active = true;
                    setTimeout(() => {
                        nodes.forEach((node) => {
                            if ((node.getBoundingClientRect().top <= window.innerHeight && node.getBoundingClientRect().bottom >= 0) && getComputedStyle(node).display !== 'none') {
                                callback({
                                    target: node
                                });
                                nodes = nodes.filter((image) => image !== node);
                                if (nodes.length === 0) {
                                    removeHandlers();
                                }
                            }
                        });
                        active = false;
                    }, 200);
                }
            };
            removeHandlers();
            $document.on(`scroll.${eventPostfix}`, undefined, check, { passive: true });
            $window.on(`scroll.${eventPostfix}`, check);
            $window.on(`resize.${eventPostfix}`, check);
            $window.on(`orientationchange.${eventPostfix}`, check);
            check();
        }
    }
    ensureChildPosition(node, position) {
        const nodeList = this.childrenArr();
        if (node === nodeList[position]) {
            return;
        }
        if (nodeList[position + 1] !== undefined) {
            this.get(0).insertBefore(node, nodeList[position + 1]);
        }
        else {
            this.append(node);
        }
    }
    clone() {
        const arr = [];
        this._it((el) => {
            arr.push(el.cloneNode(true));
        });
        return _construct(arr);
    }
    showRequiredError() {
        try {
            const sb = this.get(0);
            const hasRequired = sb.required;
            if (!hasRequired) {
                this.attr('required', 'required');
            }
            SxQueryShared._requestAnimation(() => {
                sb.checkValidity();
                sb.reportValidity();
                if (!hasRequired) {
                    this.on('focusout.required', () => {
                        this.attr('required', null);
                        this.off('focusout.required');
                    });
                }
            });
        }
        catch (err) {
            // ccl
        }
    }
    getActiveElement() {
        if (this.length > 0) {
            const firstNode = this.get(0);
            if (firstNode.getRootNode && firstNode.getRootNode()) {
                // @ts-ignore
                return firstNode.getRootNode().activeElement;
            }
        }
        return document.activeElement;
    }
}
export default SxQueryObject;
export function _constructz(data, preventCreatingElement = false) {
    return _construct(data, preventCreatingElement);
}
export function querySelectorAllz(data, node) {
    return querySelectorAll(data, node);
}
