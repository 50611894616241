export default {
    THEME: 'var(--ss360-theme-color)',
    WHITE: 'var(--ss360-white)',
    WHITE_DARK: 'var(--ss360-white-dark)',
    DIM_GREY: 'var(--ss360-dim-grey)',
    FRENCH_GREY: 'var(--ss360-french-grey)',
    ACCENT: 'var(--ss360-accent-color)',
    ACCENT_LIGHT: 'var(--ss360-accent-color-light)',
    ACCENT_DARK: 'var(--ss360-accent-color-dark)',
    RED: 'var(--ss360-red)',
    GREEN: 'var(--ss360-green)',
    YELLOW: 'var(--ss360-yellow)',
    BLACK: 'var(--ss360-black)',
    GREY_TEXT: 'var(--ss360-grey-text)'
};
