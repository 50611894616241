'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import UiHelper from './UiHelper';
import Masonry from '../layout/Masonry';
import SearchResultType from '../enums/SearchResultType';
import NavigationPosition from '../enums/NavigationPosition';
import FilterPosition from '../enums/FilterPosition';
import LayoutType from '../enums/LayoutType';
const createSkeletonResult = (randomizeImageHeight) => {
    const header = '<header class="ss360-skeleton__result-heading ss360-shimmer"></header>';
    const paragraphLine = '<div class="ss360-skeleton__result-snippet-row ss360-shimmer"></div>';
    const paragraph = `<div class="ss360-skeleton__result-snippet">${paragraphLine}${paragraphLine}${paragraphLine
        .replace(/class="/g, 'class="ss360-skeleton__result-snippet-row--last ')}</div>`;
    const body = `<div class="ss360-skeleton__content-container">
        <div class="ss360-skeleton__result-image ss360-shimmer"${randomizeImageHeight ? ` style="height:${75 + Math.round(100 * Math.random())}px"` : ''}></div>${paragraph}</div>`;
    return sxQuery(`<li class="ss360-skeleton-part ss360-suggests ss360-skeleton__result"><article>${header}${body}<div class="ss360-ca"></div></article></li>`);
};
const createSkeletonHeading = () => sxQuery('<div class="ss360-skeleton-part ss360-skeleton__block-heading ss360-shimmer"></div>');
// eslint-disable-next-line max-len
const createSkeletonGroup = (layoutConfig, context) => sxQuery(`<section class="ss360-skeleton-part ss360-group ss360-skeleton__group"><ul class="ss360-list${UiHelper.getGridClassName(layoutConfig, undefined, context)}"></ul></section>`);
const navigationEntry = '<div class="ss360-skeleton__navigation-entry ss360-shimmer"></div>';
const createNavigation = (position) => sxQuery(`<section class="ss360-skeleton-part ss360-nav ss360-nav--${position} ss360-skeleton__navigation">${navigationEntry
    .replace(/class="/g, 'class="ss360-skeleton__navigation-entry--first ')}${navigationEntry}${navigationEntry.replace(/class="/g, 'class="ss360-skeleton__navigation-entry--last ')}</section>`);
const addHiddenClasses = (layer, layoutConfig) => {
    addSpecificHiddenClasses(layer, 'mobile', layoutConfig.mobile);
    addSpecificHiddenClasses(layer, 'desktop', layoutConfig.desktop);
};
const addSpecificHiddenClasses = (layer, postfix, config) => {
    if (!config.showTitle) {
        layer.addClass(`ss360-skeleton__hide-titles--${postfix}`);
    }
    if (!config.showImages) {
        layer.addClass(`ss360-skeleton__hide-images--${postfix}`);
    }
    if (!config.showSnippet) {
        layer.addClass(`ss360-skeleton__hide-snippets--${postfix}`);
    }
};
const createFilter = (position) => {
    const blockTemplate = '<div class="ss360-skeleton-part ss360-skeleton__filter-block ss360-shimmer"></div>';
    return sxQuery(`<section class="ss360-skeleton__filter ss360-skeleton__filter--${position}">${blockTemplate}${blockTemplate}${blockTemplate}</section>`);
};
/**
 *
 *
 * @param config The skelet configuration.
 * @param config.searchResultType The type of the search results ('fullscreen', 'embed' or 'layover')
 * @param config.uiBuilder The ui builder instance.
 * @param config.closeLayerHandler The close layer callback.
 * @param config.searchFieldRenderer A function that appends a search field to layover layer
 * @param config.contentOnly Whether to render only the content or the full layer.
 */
class Skelet {
    constructor(config) {
        const { pluginConfig } = config;
        this.pluginConfig = pluginConfig;
        this.contentBlock = (pluginConfig.results.embedConfig || {}).contentBlock;
        this.navigationPosition = pluginConfig.results.group ? pluginConfig.layout.navigation.position : NavigationPosition.None;
        this.filterPosition = pluginConfig.filters.enabled ? pluginConfig.filters.position : undefined;
        this.config = config;
    }
    show(wrapperBlock, context) {
        const group = createSkeletonGroup(this.pluginConfig.layout, context);
        const resultBlock = group.find('ul');
        const layoutConfig = this.pluginConfig.layout;
        const isMasonryLayout = layoutConfig.mobile.type === LayoutType.Masonry || layoutConfig.desktop.type === LayoutType.Masonry;
        const numberOfSkelets = isMasonryLayout ? 33 : 12;
        wrapperBlock.detachBackToTop();
        for (let i = 0; i < numberOfSkelets; i++) {
            resultBlock.append(createSkeletonResult(isMasonryLayout));
        }
        addHiddenClasses(group, layoutConfig);
        if (this.config.contentOnly) {
            wrapperBlock.detachResults();
            wrapperBlock.appendToResultsFrame(group);
            if (isMasonryLayout) {
                Masonry.init(context);
            }
            return;
        }
        wrapperBlock.clear();
        wrapperBlock.appendToResultsFrame(group);
        const showNavigation = this.filterPosition === undefined && this.navigationPosition !== 'none';
        this.config.searchFieldRenderer();
        if (this.filterPosition !== undefined) {
            wrapperBlock.appendToHeadingFrame(createSkeletonHeading());
            if (!this.pluginConfig.filters.forceSlideIn) {
                wrapperBlock.appendToFilterFrame(createFilter(this.filterPosition), this.filterPosition === FilterPosition.Top);
            }
        }
        else if (this.navigationPosition !== 'none') { // keep it simple, show navigation only if not prerendering filters
            wrapperBlock.appendToNavFrame(createNavigation(this.navigationPosition), this.navigationPosition === NavigationPosition.Left);
            wrapperBlock.appendToHeadingFrame(createSkeletonHeading(), true);
        }
        wrapperBlock.attr('aria-busy', 'true');
        if (this.config.searchResultType === SearchResultType.Layover) {
            UiHelper.prepareLayoverLayer(wrapperBlock, this.config.closeLayerHandler, this.pluginConfig);
            Masonry.init(context);
        }
        else {
            wrapperBlock.attachToContentBlock(this.contentBlock, context.pluginConfiguration.results._recreateParent);
            if (isMasonryLayout) {
                wrapperBlock.show();
                Masonry.init(context);
            }
            else {
                wrapperBlock.fadeIn(undefined, undefined, (this.filterPosition === 'left' && !this.pluginConfig.filters.forceSlideIn) || (showNavigation && this.navigationPosition === 'left') ? 'flex' : undefined);
            }
        }
        wrapperBlock.addClass('ss360-layer--skeleton');
        if (context.pluginConfiguration.callbacks.skeletonLoaderRendered) {
            try {
                context.pluginConfiguration.callbacks.skeletonLoaderRendered(sxQuery(`#${context.layerId}`).get(0));
            }
            catch (e) {
                console.error(e);
            }
        }
    }
}
export default Skelet;
