import DomElementBuilder from '../../../sxQuery/DomElementBuilder';
import AbstractResultRenderer from './AbstractResultRenderer';
class ContentResultRenderer extends AbstractResultRenderer {
    renderContent(wrapper, suggest, resultGroup) {
        const img = this.createImages(suggest);
        if (img !== undefined) {
            wrapper.appendChild(img);
        }
        const contentWrap = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__content']
        });
        wrapper.appendChild(contentWrap);
        this.renderHeader(contentWrap, suggest);
        this.renderLink(contentWrap, suggest);
        this.renderSnippet(contentWrap, suggest);
        this.renderDataPoints(contentWrap, suggest);
        this.renderCtas(contentWrap, suggest, resultGroup);
        return contentWrap;
    }
}
export { ContentResultRenderer };
