'use strict';
import DataSourceType from '../../insights/interface/DataSourceType';
import DisplayType from '../../relatedContent/enums/DisplayType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import Helper from '../utils/Helper';
let wasPreloaded = false;
const positionGroupCache = {};
export default {
    handleClick($trigger, $item, context, suggest, originalQuery, renderer) {
        const similarContentConfiguration = context.pluginConfiguration.similarContent;
        let $rowWrap;
        const appendToParent = similarContentConfiguration._appendToParent || similarContentConfiguration.displayType !== DisplayType.Inline;
        const offsetTop = $trigger.get(0).getBoundingClientRect().top;
        const id = `similar-content-${Math.round(Math.random() * 100000)}`;
        const configuration = context.pluginConfiguration;
        const $listWrap = $item.parents('.ss360-list', true);
        const hide = () => {
            const $removeParent = appendToParent ? $listWrap.parents('.ss360-group', true) : $listWrap;
            $removeParent.find('.ss360-similar-content-block').slideUp(configuration.style.animationSpeed, () => {
                $removeParent.find('.ss360-similar-content-block').remove();
            });
            sxQuery(window).off(`resize.${id}, orientationchange.${id}`);
        };
        $listWrap.find('.ss360-similar-content-block').remove();
        const $currentSuggest = $trigger.parents('.ss360-suggests', true);
        let positionHasChanged = true;
        const appendWrap = (recreate = true) => {
            if (appendToParent) {
                $rowWrap = sxQuery(`<section id="${id}" class="ss360-similar-content-block"></section>`);
                $currentSuggest.parents('.ss360-group', true).append($rowWrap);
            }
            else {
                const currentSuggest = $currentSuggest.get(0);
                const offsetTop = $currentSuggest.offset().top;
                let lastSuggestInRow = currentSuggest;
                let lastOneFound = false;
                $listWrap.find('.ss360-suggests').each((otherSuggest) => {
                    if (!lastOneFound) {
                        const otherOffsetTop = sxQuery(otherSuggest).offset().top;
                        if (otherOffsetTop === offsetTop) {
                            lastSuggestInRow = otherSuggest;
                        }
                        else if (otherOffsetTop > offsetTop) {
                            lastOneFound = true;
                        }
                    }
                });
                const groupKey = $listWrap.parents('.ss360-group', true).get()[0].className;
                $rowWrap = recreate ? sxQuery(`<li id="${id}" class="ss360-similar-content-block"></li>`) : $rowWrap;
                $rowWrap.data('identifier', suggest.getIdentifier());
                lastSuggestInRow.parentNode.insertBefore($rowWrap.get()[0], lastSuggestInRow.nextElementSibling);
                const position = Helper.getPositionInNodeList($listWrap.find('.ss360-suggests,.ss360-similar-content-block').get(), $rowWrap.get()[0]);
                positionHasChanged = positionGroupCache[groupKey] !== position;
                positionGroupCache[groupKey] = position;
            }
        };
        appendWrap();
        let isRecomputingStyles = false;
        if (configuration.similarContent.displayType === DisplayType.Inline) {
            sxQuery(window).on(`resize.${id}, orientationchange.${id}`, () => {
                if (isRecomputingStyles) {
                    return;
                }
                if ($rowWrap === undefined || !$rowWrap.isMounted()) {
                    sxQuery(window).off(`resize.${id}, orientationchange.${id}`);
                    return;
                }
                isRecomputingStyles = true;
                setTimeout(() => {
                    $rowWrap.hide();
                    SxQueryUtils.requestAnimationFrame(() => {
                        appendWrap(false);
                        $rowWrap.show();
                        isRecomputingStyles = false;
                    });
                }, 160);
            });
        }
        // @ts-ignore
        import(/* webpackChunkName: "relatedContent" */ '../../relatedContent/relatedContent').then(({ default: RelatedContent }) => {
            const similarContentConfiguration = configuration.similarContent;
            if (similarContentConfiguration.includeTag !== undefined && similarContentConfiguration.includeTag.title === undefined) {
                similarContentConfiguration.includeTag.title = similarContentConfiguration.title;
            }
            const relatedContent = new RelatedContent(Object.assign(Object.assign({}, similarContentConfiguration), { querySource: {
                    type: DataSourceType.Static,
                    value: similarContentConfiguration.isRelationSearch || similarContentConfiguration.isVisualSimilaritySearch ? suggest.getIdentifier() : suggest.getName()
                }, contentBlock: $rowWrap, themeColor: configuration.style.accentColor, siteId: configuration.siteId, ecom: configuration.ecom, ecomDev: configuration.ecomDev, ecomStage: configuration.ecomStage, baseUrl: configuration.baseUrl, _cacheQueries: configuration._cacheQueries, _logQueries: configuration.tracking.logQueries, showRelationTitle: false, showCloseIcon: true, showLoader: true, includeRelations: similarContentConfiguration.includeRelation !== undefined && similarContentConfiguration.includeTag === undefined ? [configuration.similarContent.includeRelation] : undefined, includeTags: similarContentConfiguration.includeTag !== undefined ? [configuration.similarContent.includeTag] : undefined, onClose: () => {
                    hide();
                }, resultTemplate: similarContentConfiguration.useResultTemplate ? context.pluginConfiguration.results.resultTemplate : similarContentConfiguration.resultTemplate, styleTarget: configuration.style.targetNode, renderer, product: suggest, priceDataPoint: context.pluginConfiguration.results.priceDataPoint, oldPriceDataPoint: context.pluginConfiguration.results.oldPriceDataPoint, addToCartLink: context.pluginConfiguration.results.addToCartLink, addToCartCallback: context.pluginConfiguration.callbacks.addToCart, addToCartLabel: context.pluginConfiguration.results.addToCartLabel }), context.getInsights(), context.getIsZoovu());
            relatedContent.loadAndRender(positionHasChanged ? configuration.style.animationSpeed : 0, originalQuery, similarContentConfiguration.displayType === DisplayType.Inline, true);
            if (similarContentConfiguration.displayType === DisplayType.Inline) {
                const offsetDiff = offsetTop - $trigger.get(0).getBoundingClientRect().top;
                if ('scrollTo' in window && offsetDiff !== 0) {
                    window.scrollTo(window.scrollX, window.scrollY - offsetDiff);
                }
            }
        });
    },
    preload() {
        if (!wasPreloaded) {
            // @ts-ignore
            import(/* webpackChunkName: "relatedContent" */ '../../relatedContent/relatedContent');
            wasPreloaded = true;
        }
    }
};
