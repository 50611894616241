import sxQuery from '../../sxQuery/sxQuery';
import Icons from '../components/Icons';
import FilterPosition from '../enums/FilterPosition';
import UiColors from '../styles/UiColors';
class PageSizeSelector {
    constructor(context, callback) {
        this.context = context;
        this.callback = callback;
    }
    render() {
        const pluginConfig = this.context.pluginConfiguration;
        const filtersConfiguration = pluginConfig.filters;
        const isLeft = filtersConfiguration.enabled && (filtersConfiguration.position === FilterPosition.Top || (filtersConfiguration.position === FilterPosition.Left && filtersConfiguration.forceSlideIn && !pluginConfig.layout.showListGridToggle));
        const select = sxQuery(`<select class="ss360-page-size__select"></select>`);
        select.on('change', (e) => {
            pluginConfig.results.moreResultsPagingSize = parseInt(e.target.value, 10);
            this.context.createCookie('pageSize', `${pluginConfig.results.moreResultsPagingSize}`, 30);
            this.callback();
        });
        pluginConfig.results.pageSizeOptions.forEach((option) => {
            const optionElement = sxQuery('<option></option>');
            optionElement.val(`${option}`);
            optionElement.text(pluginConfig.results.pageSizeLabel.replace(/#COUNT#/g, `${option}`));
            select.append(optionElement);
        });
        select.val(`${pluginConfig.results.moreResultsPagingSize}`);
        const selectIconSvg = Icons.getSvgIconNode(Icons.SIMPLE_ARROW, UiColors.THEME, undefined, 16, 16);
        const selectIconPlain = document.createElement('i');
        selectIconPlain.className = 'ss360-sorting__select-icon';
        selectIconPlain.appendChild(selectIconSvg);
        const selectIcon = sxQuery(selectIconPlain);
        this.context.layer.get().appendToPageSizeFrame(select, isLeft);
        this.context.layer.get().appendToPageSizeFrame(selectIcon, isLeft);
    }
}
export default PageSizeSelector;
