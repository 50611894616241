'use strict';
import sxQuery from '../../sxQuery/sxQuery';
let observer = null;
const CLASS_TO_REMOVE = 'ss360-suggests__image--lazy ss360-shimmer ss360-suggests__variant-image--lazy';
const hideImage = (node, placeholderImage, isVariantImage = false) => {
    const $parent = node.parents(isVariantImage ? '.ss360-suggests__variant' : '.ss360-suggests__image-wrap', true);
    if (placeholderImage !== null && placeholderImage !== undefined && placeholderImage.length > 0) {
        node.get(0).onload = () => { };
        node.attr('src', placeholderImage);
    }
    else if (placeholderImage !== null && !isVariantImage) {
        node.hide();
        $parent.addClass('ss360-suggests__image-wrap--empty');
    }
    else {
        node.hide();
        $parent.remove();
    }
};
const onError = (node, callbacks, placeholderImage, isVariantImage = false) => function () {
    const { resultImageError } = callbacks;
    node.onerror = function () { };
    const $node = sxQuery(node);
    let newImage;
    // @ts-ignore
    const fallbackSrc = $node.data('fallbackSrc');
    if (fallbackSrc !== undefined && fallbackSrc !== null) {
        newImage = fallbackSrc;
        const fallbackImage = new Image();
        fallbackImage.onerror = function () {
            hideImage($node, placeholderImage, isVariantImage);
        };
        fallbackImage.src = fallbackSrc;
    }
    else if (placeholderImage !== null && placeholderImage !== undefined && !isVariantImage) {
        newImage = placeholderImage;
    }
    else if (resultImageError !== undefined && !isVariantImage) {
        newImage = resultImageError($node.parents('.ss360-suggests', false)[0]);
    }
    if (!newImage) {
        hideImage($node, placeholderImage, isVariantImage);
    }
    else {
        $node.removeClass(CLASS_TO_REMOVE);
        $node.attr('src', newImage);
    }
};
const onImageLoaded = (node, pluginConfig) => {
    const { imageLoaded } = pluginConfig.callbacks;
    const { placeholderImage, checkImageQuality } = pluginConfig.results;
    const isVariantImage = node.hasClass('ss360-suggests__variant-image');
    // @ts-ignore
    const src = node.attr('src');
    const img = node.get(0);
    const isSvg = src.toLowerCase().indexOf('.svg') === src.length - 4;
    const width = img.naturalWidth || img.width;
    const height = img.naturalHeight || img.height;
    const aspectRatio = width / height;
    let polyfillBackgroundSize = height >= 100 ? 'contain' : undefined;
    if (!isSvg && checkImageQuality) {
        if (aspectRatio < 4) {
            // do nothing, this is fine for now
        }
        else if ((aspectRatio < 8 && height >= 150) || isVariantImage) {
            node.addClass(isVariantImage ? 'ss360-suggests__variant-image--cover' : 'ss360-suggests__image--cover');
            polyfillBackgroundSize = 'cover';
        }
        else if ((aspectRatio >= 6 && height < 150) || aspectRatio >= 8) {
            hideImage(node, placeholderImage);
            return;
        }
    }
    node.removeClass(CLASS_TO_REMOVE);
    node.attr('style', null);
    if (imageLoaded !== undefined && !isVariantImage) {
        imageLoaded(node.get()[0], src);
    }
};
export default (context) => {
    if (observer !== null) {
        observer.disconnect();
    }
    observer = null; // destruct existing reference
    const callbacks = context.pluginConfiguration.callbacks;
    const placeholderImage = context.pluginConfiguration.results.placeholderImage;
    const $layerContent = context.layer.get().getLayerContent();
    let images = $layerContent.find('.ss360-suggests__image,.ss360-suggests__variant-image').get();
    if (images.length === 0) {
        const fallbackImages = $layerContent.find('.ss360-suggests__image[data-fallback-src]:not([data-fallback-src=""])');
        if (fallbackImages.length > 0) {
            fallbackImages.each((node) => {
                node.onerror = onError(node, callbacks, placeholderImage);
            });
        }
        return;
    }
    images.forEach((node) => {
        const onLoad = () => {
            onImageLoaded(sxQuery(node), context.pluginConfiguration);
            node.removeEventListener('load', onLoad);
        };
        node.addEventListener('load', onLoad);
        node.onerror = onError(node, callbacks, placeholderImage, sxQuery(node).hasClass('ss360-suggests__variant-image'));
    });
};
