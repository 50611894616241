'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import NavigatorHelper from '../utils/NavigatorHelper';
import StringHelper from '../../global/StringHelper';
import UiHelper from './UiHelper';
import Handlers from './Handlers';
import ImageHandler from '../utils/ImageHandler';
import Masonry from '../layout/Masonry';
import InfiniteScrollObserver from '../utils/InfiniteScrollObserver';
import TemplateRenderer from '../../global/TemplateRenderer';
import Logger from '../../global/Logger';
import SearchSuggest from '../model/SearchSuggest';
import SearchResponse from '../model/SearchResponse';
import SearchIntentHelper from '../utils/SearchIntentHelper';
import Icons from '../components/Icons';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import SearchResultType from '../enums/SearchResultType';
import Carousel from './Carousel';
import Helper from '../utils/Helper';
import LayoutType from '../enums/LayoutType';
import NoResultsPage from '../components/NoResultsPage';
import Zoe from '../components/Zoe';
import Comparison from '../components/Comparison';
import SuggestType from '../enums/SuggestType';
import UiColors from '../styles/UiColors';
import { AllResultsStrategy, NavigationType } from '../interface/LayoutConfiguration';
import { ContentResultRenderer } from './resultRenderer/ContentResultRenderer';
import CustomResultRenderer from './resultRenderer/CustomResultRenderer';
import { ProductResultRenderer } from './resultRenderer/ProductResultRenderer';
import MiniPDP from '../components/MiniPDP';
const getSelectedContentGroup = (results, headingNode, parentGroup) => {
    let selectedContentGroup = '';
    if (results.group) {
        // @ts-ignore
        if (headingNode.length === 0 || (headingNode.attr('id') || '_').replace('ss360-heading-', '') === '_') {
            selectedContentGroup = '_';
        }
        else if (parentGroup.hasClass('ss360-group-ss360_all_results')) {
            selectedContentGroup = 'ss360_all_results';
        }
        else {
            // @ts-ignore
            selectedContentGroup = headingNode.text();
        }
    }
    return selectedContentGroup;
};
class UiBuilder {
    constructor(context) {
        this.urlNodeCache = {};
        this.context = context;
        // accessibility
        this.groupHasImageCache = {};
        this.captionHeadingLevel = context.pluginConfiguration.accessibility.resultTopHeadingLevel;
        this.contentGroupHeadingLevel = this.captionHeadingLevel + 1;
        this.searchResultHeadingLevel = this.contentGroupHeadingLevel + 1;
        this.hiddenParts = UiHelper.getHiddenParts(this.context.pluginConfiguration.layout);
        const originalCta = context.pluginConfiguration.results.cta;
        this.cta = originalCta;
        const resultTemplate = context.pluginConfiguration.results.resultTemplate;
        this.resultRenderer = resultTemplate !== undefined && resultTemplate.template !== undefined ? new TemplateRenderer(resultTemplate, context.pluginConfiguration.style.defaultCss) : undefined;
        this.comparison = context.pluginConfiguration.comparison.enabled ? new Comparison(context) : undefined;
        const callbacks = context.pluginConfiguration.callbacks;
        let customResultRendererClass = CustomResultRenderer;
        let contentResultRendererClass = ContentResultRenderer;
        let productResultRendererClass = ProductResultRenderer;
        if (callbacks.getCustomResultRenderer !== undefined) {
            try {
                customResultRendererClass = callbacks.getCustomResultRenderer(CustomResultRenderer);
            }
            catch (err) {
                //
            }
        }
        if (callbacks.getContentResultRenderer !== undefined) {
            try {
                contentResultRendererClass = callbacks.getContentResultRenderer(ContentResultRenderer);
            }
            catch (err) {
                //
            }
        }
        if (callbacks.getProductResultRenderer !== undefined) {
            try {
                productResultRendererClass = callbacks.getProductResultRenderer(ProductResultRenderer);
            }
            catch (err) {
                //
            }
        }
        this.contentResultRenderer = new contentResultRendererClass(context.pluginConfiguration, context);
        this.customResultRenderer = new customResultRendererClass(context.pluginConfiguration, context);
        this.productResultRenderer = new productResultRendererClass(context.pluginConfiguration, context);
    }
    buildSuggestItem(suggest, query, contentGroup, isHidden = false, groupHasImages, activeMiniPDPIdentifier, updateImagesFlag) {
        const { pluginConfiguration } = this.context;
        const { results } = pluginConfiguration;
        if (groupHasImages === undefined) {
            groupHasImages = this.groupHasImageCache[suggest.contentGroup] !== undefined ? this.groupHasImageCache[suggest.contentGroup] : true;
        }
        if (this.context.pluginConfiguration.callbacks.singleResultPreRenderCallback) {
            try {
                this.context.pluginConfiguration.callbacks.singleResultPreRenderCallback(suggest.suggest, suggest.variants);
            }
            catch (err) {
                Logger.error(err);
            }
        }
        const useCustomTemplate = this.resultRenderer !== undefined && (pluginConfiguration.results.resultTemplate.includeContentGroups === undefined || pluginConfiguration.results.resultTemplate.includeContentGroups.indexOf(suggest.getOriginalContentGroup()) !== -1);
        // bind events
        let $item;
        const cacheKey = (suggest.getLink() || suggest.getIdentifier()) && suggest.getType() !== SuggestType.Custom ? `${suggest.getLink()}#${suggest.getIdentifier()}` : undefined;
        const safeKey = StringHelper.getSafeKey(contentGroup);
        if (useCustomTemplate) {
            $item = this.resultRenderer.render(suggest, contentGroup, isHidden);
        }
        else if (cacheKey !== undefined && this.urlNodeCache[cacheKey] !== undefined && this.urlNodeCache[cacheKey].find('.ss360-cta--has-callback').length === 0) {
            $item = this.urlNodeCache[cacheKey].clone();
            if (isHidden) {
                $item.addClass('ss360-suggests--hidden');
            }
            else {
                $item.removeClass('ss360-suggests--hidden');
            }
            const originalContentGroup = suggest.getOriginalContentGroup();
            const originalGroup = contentGroup === '*' && originalContentGroup !== undefined ? `ss360-original-group-${StringHelper.getSafeKey(originalContentGroup)}` : `ss360-original-group-${safeKey}`;
            if (contentGroup === '*') {
                $item.addClass(originalGroup);
            }
            else {
                $item.removeClass(originalGroup);
            }
            const headingId = `ss360-heading-${Math.round(100000 * Math.random())}`;
            $item.find('.ss360-suggests__link-wrap').attr('id', headingId);
            $item.find('.ss360-suggests__wrap').attr('aria-labelledby', headingId);
        }
        else {
            let renderer = this.contentResultRenderer;
            suggest.resetRandomId();
            if (suggest.getType() === 'custom') {
                renderer = this.customResultRenderer;
            }
            if (suggest.getIdentifier() !== undefined && suggest.getIdentifier() !== '') {
                renderer = this.productResultRenderer;
            }
            if (updateImagesFlag) {
                renderer.setCurrentGroupHasImages(groupHasImages);
            }
            $item = sxQuery(renderer.render(suggest, isHidden, contentGroup));
            if (cacheKey !== undefined) {
                this.urlNodeCache[cacheKey] = $item;
            }
        }
        if (this.resultRenderer !== undefined && $item.find('#zoovu-assistant').length > 0) {
            $item.find('script').each((elem) => {
                this.customResultRenderer.getScripts().push(sxQuery(elem));
            });
        }
        const bindEvents = () => {
            if ($item.find('#zoovu-assistant').length > 0) {
                $item.addClass('ss360-suggests--assistant');
            }
            if (this.context.hasInsights() && !pluginConfiguration.miniPDP.enabled) {
                const isSmart404 = this.context.isSmart404();
                $item.find('a:not(.ss360-js-add-to-cart):not(.ss360-js-exclude-tracking)').on('click', () => {
                    const layoutFrame = this.context.layer.get();
                    sxQuery(window).off(`beforeunload.${this.context.generateId('ss360Insights')}`);
                    const cgItems = $item.parent().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
                    const allItems = layoutFrame.getVisibleResults().get();
                    const itemEl = $item.get()[0];
                    const contentGroupPosition = SxQueryUtils.indexInNodeList(itemEl, cgItems) + 1;
                    const position = SxQueryUtils.indexInNodeList(itemEl, allItems) + 1;
                    this.context.getInsights().trackSerpClick(query, itemEl, layoutFrame.getLayerContent().get()[0], allItems.length, position, contentGroupPosition, suggest.getLink(), isSmart404 ? SearchResultType.Smart404 : this.context.getSearchResultType(), undefined, undefined, undefined, contentGroup, suggest.getOriginalContentGroup());
                });
                $item.find('.ss360-js-add-to-cart').on('click', () => {
                    const $el = $item.find('.ss360-js-add-to-cart');
                    // @ts-ignore
                    const priceValue = $el.data('ss360Price') || $el.data('zoovuPrice');
                    // @ts-ignore
                    const priceUnit = $el.data('ss360PriceUnit') || $el.data('zoovuPriceUnit');
                    let unitPrice = parseFloat(priceValue);
                    if (Helper.isNaN(unitPrice)) {
                        unitPrice = parseFloat(priceValue.replace(/,/g, '.'));
                    }
                    if (Helper.isNaN(unitPrice)) {
                        unitPrice = undefined;
                    }
                    sxQuery(window).off(`beforeunload.${this.context.generateId('ss360Insights')}`);
                    this.context.getInsights().trackAddToCart(undefined, suggest.getIdentifier(), suggest.getLink(), 1, unitPrice, priceUnit, true);
                });
            }
            $item.find('a').on('click', (e) => {
                if (SxQueryUtils.linkOpensInNewTab(e)) {
                    return;
                }
                const $target = sxQuery(e.target);
                const $parentGroup = sxQuery($target.parents('.ss360-group')[0]);
                const headingNode = $parentGroup.find('.ss360-group__heading');
                let selectedContentGroup = getSelectedContentGroup(results, headingNode, $parentGroup);
                const liNode = $target.parents('li')[0];
                const liNodes = $parentGroup.find('li.ss360-suggests').get();
                const idx = liNodes.indexOf(liNode);
                if (pluginConfiguration.allowCookies) {
                    this.context.createCookie('-cg--c', selectedContentGroup, 1 / 24);
                    this.context.createCookie('-offset--c', idx, 1 / 24);
                    this.context.createCookie('-query--c', query, 1 / 24);
                }
                else if (NavigatorHelper.hasHistoryAPI(this.context.pluginConfiguration.results)) {
                    const searchParams = NavigatorHelper.buildQueryDict();
                    if (selectedContentGroup) {
                        searchParams[this.context.getContentGroupQueryParam()] = selectedContentGroup;
                    }
                    searchParams[this.context.getOffsetQueryParam()] = idx;
                    NavigatorHelper.pushState(searchParams, this.context.pluginConfiguration);
                }
            });
            Carousel.bindImageCarouselEvents($item, suggest.getImages());
            if (pluginConfiguration.zoe.showChat) {
                let zoe;
                $item.find('.ss360-actions__action--chat').on('click', () => {
                    if (pluginConfiguration.miniPDP.enabled) {
                        new MiniPDP(this.context, suggest, $item, query).show('chat');
                    }
                    else {
                        if (zoe === undefined) {
                            zoe = new Zoe(pluginConfiguration.zoe.region, pluginConfiguration.zoe.apiKey, pluginConfiguration.zoe.locale, pluginConfiguration.zoe.title, pluginConfiguration.zoe.context, suggest.getName(), suggest.getImage(true), suggest.getIdentifier(), $item, pluginConfiguration.ecomDev);
                        }
                        zoe.toggleChat();
                    }
                });
            }
            if (pluginConfiguration.comparison.enabled) {
                let isComparisonEnabled = this.comparison.isInComparison(suggest.getIdentifier());
                let isDisabled = false;
                const $compareInput = $item.find('.ss360-actions__compare-checkbox');
                const $compareLabel = $item.find('.ss360-actions__compare');
                const updateComparisonButton = () => {
                    if ($compareInput.get(0)) {
                        $compareInput.get(0).checked = isComparisonEnabled;
                    }
                    if (isComparisonEnabled) {
                        this.comparison.addProduct(suggest, query);
                    }
                    else {
                        this.comparison.remove(suggest.getIdentifier(), true);
                    }
                };
                if (isComparisonEnabled) {
                    updateComparisonButton();
                }
                $compareInput.on('input', () => {
                    isComparisonEnabled = !isComparisonEnabled;
                    updateComparisonButton();
                });
                this.comparison.onRemove((identifier) => {
                    if (!isComparisonEnabled) {
                        return;
                    }
                    if (identifier === suggest.getIdentifier() || identifier === '*') {
                        isComparisonEnabled = false;
                        updateComparisonButton();
                    }
                });
                const onDisabledChange = (state) => {
                    if (state && !isComparisonEnabled && !isDisabled) {
                        $compareInput.attr('disabled', 'disabled');
                        $compareLabel.addClass('ss360-actions__compare--disabled');
                        isDisabled = true;
                    }
                    else if (!state && isDisabled) {
                        $compareInput.attr('disabled', null);
                        $compareLabel.removeClass('ss360-actions__compare--disabled');
                        isDisabled = false;
                    }
                };
                if (!isComparisonEnabled && this.comparison.isComparisonDisabled()) {
                    onDisabledChange(true);
                }
                this.comparison.onDisableChange((state) => {
                    onDisabledChange(state);
                });
            }
            if (suggest.getIdentifier() && this.context.pluginConfiguration.miniPDP.enabled) {
                $item.off('click.miniPDP').on('click.miniPDP', (e) => {
                    const actionTargets = ['label', 'button', '.ss360-suggests__url'];
                    const $target = sxQuery(e.target);
                    let allowAction = false;
                    for (let i = 0; i < actionTargets.length; i++) {
                        if ($target.matchesSelector(actionTargets[i]) || $target.parents(actionTargets[i]).length > 0) {
                            allowAction = true;
                            break;
                        }
                    }
                    if (!allowAction) {
                        e.preventDefault();
                        e.stopPropagation();
                        NavigatorHelper.replaceStatePlain(undefined, 'open-mini-pdp');
                        new MiniPDP(this.context, suggest, $item, query).show();
                        if (this.context.hasInsights()) {
                            const cgItems = $item.parent().find('.ss360-suggests:not(.ss360-suggests--hidden)').get();
                            const layoutFrame = this.context.layer.get();
                            const allItems = layoutFrame.getVisibleResults().get();
                            const itemEl = $item.get()[0];
                            const contentGroupPosition = SxQueryUtils.indexInNodeList(itemEl, cgItems) + 1;
                            const position = SxQueryUtils.indexInNodeList(itemEl, allItems) + 1;
                            this.context.getInsights().trackMiniPDPOpen(query, itemEl, allItems.length, position, contentGroupPosition, suggest.getLink(), this.context.isSmart404() ? SearchResultType.Smart404 : this.context.getSearchResultType(), suggest.getIdentifier(), suggest.getIdentifier(), contentGroup, suggest.getOriginalContentGroup());
                        }
                    }
                });
            }
        };
        Carousel.bindVariantEvents($item, suggest.getIdentifier() !== undefined ? this.productResultRenderer : this.contentResultRenderer, bindEvents, this.context, suggest.plain, contentGroup);
        bindEvents();
        if (!suggest.hasImage() && this.resultRenderer === undefined) {
            $item.addClass('ss360-no-img');
        }
        if (activeMiniPDPIdentifier && suggest.getAllIdentifiers().indexOf(activeMiniPDPIdentifier) !== -1) {
            let activeSuggest = suggest;
            if (suggest.getIdentifier() !== activeMiniPDPIdentifier) {
                const variants = [suggest.suggest, ...suggest.variants];
                variants.sort((a, b) => {
                    if (a.identifier === activeMiniPDPIdentifier) {
                        return -1;
                    }
                    if (b.identifier === activeMiniPDPIdentifier) {
                        return 1;
                    }
                    return 0;
                });
                activeSuggest = new SearchSuggest(variants);
            }
            NavigatorHelper.replaceStatePlain(undefined, 'open-mini-pdp');
            new MiniPDP(this.context, activeSuggest, $item, query).show();
        }
        return $item;
    }
    appendSearchConsole() {
        this.context.layer.get().prepareFullscreenLayer(this.context.pluginConfiguration, this.captionHeadingLevel);
    }
    buildSearchField(lastSearchTerm, bindEvents = true, applyStylingColors = false, idPostfix = '') {
        const ss360SearchWrapper = sxQuery(`<section role="search" id="${this.context.customSearchId}${idPostfix}" class="ss360-flex ss360-n-section ss360-custom-search">`);
        const ss360SearchInputContainer = sxQuery('<div class="ss360-custom-search__input-container"></div>');
        const searchBoxId = this.context.customSearchBoxId + idPostfix;
        const { accessibility, searchBox, callbacks } = this.context.pluginConfiguration;
        const searchBoxSelector = searchBox.selector;
        const searchFieldLabel = accessibility.searchFieldLabel;
        const defaultPlaceholder = searchBox.placeholder;
        const enterCallback = callbacks.enter;
        if (searchFieldLabel !== undefined) {
            const ss360Label = sxQuery(`<label style="${SxQueryUtils.srOnlyCss}" for="${searchBoxId}" class="ss360-sr-only">${searchFieldLabel}</label>`);
            ss360SearchWrapper.append(ss360Label);
        }
        const ss360SearchField = sxQuery(`<input autocomplete="off" type="search" id="${searchBoxId}" class="ss360-custom-search__searchbox ss360-n-input">`);
        if (searchBoxSelector !== undefined) {
            const placeholder = sxQuery(searchBoxSelector).attr('placeholder') || defaultPlaceholder || 'Search';
            ss360SearchField.attr('placeholder', placeholder);
            ss360SearchField.val(lastSearchTerm);
            ss360SearchField.on('change', (e) => {
                sxQuery(searchBoxSelector).val(e.target.value);
            });
        }
        const ss360SearchClearButton = sxQuery(`<button class="ss360-n-button ss360-custom-search__clear-button" type="button">${Icons.getSvgIcon(Icons.CROSS, UiColors.WHITE, 'ss360-custom-search__clear-button-icon', 16, 16)}</button>`);
        ss360SearchClearButton.attr('aria-label', this.context.pluginConfiguration.searchBox.clearLabel);
        const ss360SearchButton = sxQuery(`<button type="button" id="${this.context.customSearchButtonId}${idPostfix}" class="ss360-custom-search__searchbutton ss360-n-button">
			</button>`);
        const searchBoxConfig = this.context.pluginConfiguration.searchBox;
        if (searchBoxConfig.searchButtonLabel !== undefined) {
            ss360SearchButton.html(searchBoxConfig.searchButtonLabel);
            ss360SearchButton.addClass('ss360-custom-search__searchbutton--text');
        }
        else {
            ss360SearchButton.html(Icons.getSvgIcon(Icons.MAGNIFIER, applyStylingColors ? UiHelper.getSearchButtonIconColor(this.context.pluginConfiguration) : UiColors.WHITE));
            ss360SearchButton.attr('aria-label', 'Search');
        }
        if (bindEvents) {
            const performSearch = (query, searchButton) => {
                if (query === undefined || query.length === 0) {
                    ss360SearchField.showRequiredError();
                    return;
                }
                if (enterCallback !== undefined && typeof enterCallback === 'function') {
                    enterCallback(query, undefined, undefined, searchButton);
                }
                else {
                    this.context.core.showResults(query, {
                        searchButton
                    });
                }
            };
            ss360SearchField.on('keyup', (e) => {
                if (e.keyCode === 13) {
                    performSearch(e.target.value);
                }
            });
            ss360SearchButton.on('click', (e) => {
                performSearch(ss360SearchField.val(), e.target);
            });
        }
        ss360SearchField.on('keyup', (e) => {
            const voiceSearchButton = ss360SearchWrapper.find('.ss360-voice-search');
            const hasVoiceSearch = voiceSearchButton.length > 0;
            if (e.target.value.length > 0) {
                if (hasVoiceSearch) {
                    ss360SearchField.css('paddingRight', '70px');
                    ss360SearchClearButton.addClass('ss360-custom-search__clear-button--with-voice');
                }
                SxQueryUtils.requestAnimationFrame(() => {
                    ss360SearchClearButton.show();
                });
            }
            else {
                if (hasVoiceSearch) {
                    ss360SearchField.css('paddingRight', '35px');
                    ss360SearchClearButton.removeClass('ss360-custom-search__clear-button--with-voice');
                }
                ss360SearchClearButton.hide();
            }
        });
        ss360SearchClearButton.on('click', () => {
            const voiceSearchButton = ss360SearchWrapper.find('.ss360-voice-search');
            const hasVoiceSearch = voiceSearchButton.length > 0;
            ss360SearchField.val('');
            ss360SearchField.focus();
            ss360SearchClearButton.hide();
            if (hasVoiceSearch) {
                ss360SearchField.css('paddingRight', '35px');
                ss360SearchClearButton.removeClass('ss360-custom-search__clear-button--with-voice');
            }
        });
        ss360SearchInputContainer.append(ss360SearchField);
        ss360SearchInputContainer.append(ss360SearchClearButton);
        ss360SearchWrapper.append(ss360SearchInputContainer);
        ss360SearchWrapper.append(ss360SearchButton);
        return ss360SearchWrapper;
    }
    buildHeadlineNode(label) {
        const headlineNode = sxQuery(`<h${this.captionHeadingLevel} id="${this.context.searchResultHeadingId}" class="ss360-layer__heading"><a class="ss360-layer__heading-anchor" tabindex="-1" href="#">
			${label}</a></h${this.captionHeadingLevel}>`);
        if (this.context.isSmart404()) {
            headlineNode.addClass('ss360-layer__heading--padded');
        }
        headlineNode.find('.ss360-layer__heading-anchor').click((e) => {
            e.preventDefault();
            e.stopPropagation();
        });
        return headlineNode;
    }
    buildQueryCorrectionNode(correction, id, pluginConfig) {
        const caption = this.context.pluginConfiguration.results.queryCorrectionText;
        const queryCorrectionText = caption.replace('#CORRECTION#', `<a id="${id}" href="#">${StringHelper.escapeHtml(correction)}</a>`);
        const didYouMeanNode = sxQuery(`<div class="ss360-query-correction">${queryCorrectionText}</div>`);
        didYouMeanNode.on('click', (e) => {
            Handlers.queryCorrection(e, correction, pluginConfig);
        });
        return didYouMeanNode;
    }
    buildQueryCorrectionRewriteNode(correction, pluginConfig, allowOverride = false, originalQuery, id) {
        const caption = this.context.pluginConfiguration.results.queryCorrectionRewrite;
        let queryCorrectionText = caption.replace('#CORRECTION#', StringHelper.escapeHtml(correction));
        if (allowOverride) {
            queryCorrectionText = `${queryCorrectionText}. ${this.context.pluginConfiguration.results.queryCorrectionOverride
                .replace('#ORIGINAL_QUERY#', `<a id="${id}" href="#">${StringHelper.escapeHtml(originalQuery)}</a>`)}.`;
        }
        const didYouMeanNode = sxQuery(`<div class="ss360-query-correction ss360-query-correction--rewrite">${queryCorrectionText}</div>`);
        if (allowOverride) {
            didYouMeanNode.find(`#${id}`).on('click', (e) => {
                Handlers.queryOverride(e, originalQuery, pluginConfig);
            });
        }
        return didYouMeanNode;
    }
    renderSearchResults(data, navigation, query, totalResults, isPopState = false) {
        const { pluginConfiguration } = this.context;
        const layoutConfig = pluginConfiguration.layout;
        const searchResultConfig = pluginConfiguration.results;
        const { callbacks } = pluginConfiguration;
        let moreResultsButton = searchResultConfig.moreResultsButton;
        let totalCounter = 0;
        if (moreResultsButton === undefined) {
            moreResultsButton = null;
        }
        // Prerender callback
        if (callbacks.preRender !== undefined && (typeof callbacks.preRender) === 'function') {
            try {
                const callbackResult = callbacks.preRender(data.plain.suggests, data);
                if (callbackResult !== undefined && callbackResult !== null) {
                    data.setSuggests(callbackResult);
                }
                else { // still set suggests, because copy has been created --> modifications on the original object would not get applied
                    data.setSuggests(data.plain.suggests);
                }
                totalResults = Helper.getTotalCount(data, pluginConfiguration.results.limitPerGroup, pluginConfiguration);
            }
            catch (err) {
                Logger.error(err);
            }
        }
        // prerender callback might have changed the entry count, make sure to rebuild the navigation if necessary
        const groupCount = data.suggests.length;
        if (callbacks.preRender !== undefined && groupCount >= 2 && navigation.entryCount < 2) {
            navigation.entryCount = groupCount;
            navigation.build(this.context.layer.get());
        }
        // Make sure navigation with one tab/item is not shown
        const shouldRenderNav = navigation.getPosition() !== 'none'
            && (groupCount > 1 || navigation.isTabbed()); // don't show scroll or dropdown navigation for one-item entries
        let showAllResultsTab = false;
        let idx = -1;
        let isGroupedAllResults = false;
        if (shouldRenderNav && navigation.isTabbed() && !navigation.isSorting() && layoutConfig.navigation.showAllResultsTab && data.showAllResults && groupCount > 1) {
            showAllResultsTab = true;
            if (this.context.pluginConfiguration.layout.navigation.allResultsStrategy === AllResultsStrategy.Mixed) {
                SearchIntentHelper.buildAllResultsTab(data);
            }
            else {
                idx++;
                navigation.addEntry(layoutConfig.navigation.allResultsTabName, 'ss360_all_results', query, totalResults, 0, '*');
                isGroupedAllResults = true;
            }
        }
        const allResultsFirst = this.context.pluginConfiguration.layout.navigation.allResultsFirst;
        const keys = data.getResultGroupNames().sort((a, b) => {
            if (a === '*') {
                return allResultsFirst ? -1 : 1;
            }
            if (b === '*') {
                return allResultsFirst ? 1 : -1;
            }
            return 0;
        });
        const maxResultCounts = {};
        const preloadedResults = {};
        this.urlNodeCache = {};
        keys.forEach((key) => {
            idx++;
            totalCounter += this.renderResultGroup({
                data,
                showAllResultsTab,
                key,
                keys,
                totalResults,
                idx,
                query,
                navigation,
                shouldRenderNav,
                moreResultsButton
            }, maxResultCounts, preloadedResults);
        });
        if (isGroupedAllResults) {
            this.context.layer.get().getResultGroups().addClass('ss360-group--active');
        }
        this.urlNodeCache = {};
        navigation.onResultsRendered(isPopState);
        if (this.comparison) {
            this.comparison.show();
        }
        return totalCounter;
    }
    renderResultGroup(settings, maxResultsCounts, preloadedResults) {
        const { key, showAllResultsTab, data, keys, totalResults, navigation, shouldRenderNav, idx, moreResultsButton, query } = settings;
        const { pluginConfiguration } = this.context;
        const contentGroupsConfig = pluginConfiguration.contentGroups;
        const moreResultsCallback = pluginConfiguration.callbacks.moreResults;
        const searchResultConfig = pluginConfiguration.results;
        const layoutConfig = pluginConfiguration.layout;
        const { callbacks } = pluginConfiguration;
        const pagingSize = searchResultConfig.moreResultsPagingSize;
        const highlight = searchResultConfig.highlightQueryTerms;
        const isAllResultsTab = key === '*' && showAllResultsTab;
        let values = data.getSuggestsArray(key);
        const safeKey = StringHelper.getSafeKey(isAllResultsTab ? 'ss360_all_results' : key);
        if (values === null || values === undefined) {
            values = [];
        }
        // the view key
        let viewKey = key;
        if (viewKey === '_') {
            viewKey = contentGroupsConfig.otherName;
            if (keys.length > 1 && !contentGroupsConfig.ignoreOther && (viewKey === undefined || viewKey === '')) {
                viewKey = 'Other';
            }
        }
        else if (isAllResultsTab) {
            viewKey = layoutConfig.navigation.allResultsTabName;
        }
        if (contentGroupsConfig.viewNames[viewKey] !== undefined) {
            viewKey = contentGroupsConfig.viewNames[viewKey];
        }
        if (viewKey === undefined) {
            viewKey = '';
        }
        // result count
        const maxResultsCount = UiHelper.getResultCount(key, values, showAllResultsTab, searchResultConfig, data, totalResults);
        maxResultsCounts[key] = maxResultsCount;
        if (shouldRenderNav && !navigation.isSorting()) {
            navigation.addEntry(viewKey, safeKey, query, maxResultsCount, idx, key);
        }
        // all results that belong under the given caption
        const headingId = this.context.generateId(`ss360-heading-${safeKey}`);
        const labelledBy = safeKey !== '_' || contentGroupsConfig.otherName !== '' ? `aria-labelledby="${headingId}"` : '';
        const groupedNode = sxQuery(`<section class="ss360-n-section ss360-group ss360-group-${safeKey}" ${labelledBy}></section>`);
        if (pluginConfiguration.contentGroups.classMap[safeKey]) {
            groupedNode.addClass(pluginConfiguration.contentGroups.classMap[safeKey]);
        }
        if (navigation.isTabbed() && idx === 0) {
            groupedNode.addClass('ss360-group--active');
        }
        if (viewKey.length > 0 && values.length > 0 && searchResultConfig.showContentGroupHeadings) {
            const keyNode = sxQuery(`<h${this.contentGroupHeadingLevel} id="${headingId}" class="ss360-group__heading">${viewKey}</h${this.contentGroupHeadingLevel}>`);
            if (layoutConfig.navigation.type === NavigationType.Tabs && keys.length === 1) {
                keyNode.addClass('ss360-sr-only');
            }
            groupedNode.append(keyNode);
        }
        const list = sxQuery(`<ul class="ss360-list${UiHelper.getGridClassName(layoutConfig, key, this.context)}"></ul>`);
        groupedNode.append(list);
        let resultCounter = 0;
        let shadowResultCounter = 0;
        let hasHiddenResults = false;
        const placeholderImage = this.context.pluginConfiguration.results.placeholderImage;
        if (this.groupHasImageCache[key] === undefined && placeholderImage !== undefined && placeholderImage !== null && placeholderImage.length > 0) {
            this.groupHasImageCache[key] = true;
        }
        const groupHasImages = this.groupHasImageCache[key] === undefined ? values.reduce((acc, res) => {
            if (acc) {
                return true;
            }
            return res.hasImage();
        }, false) : this.groupHasImageCache[key];
        this.groupHasImageCache[key] = groupHasImages;
        this.customResultRenderer.resetScripts();
        const queryTags = data.getQueryTags();
        [this.customResultRenderer, this.contentResultRenderer, this.productResultRenderer].forEach((renderer) => {
            renderer.setQuery(query, queryTags);
            renderer.setCurrentGroupHasImages(groupHasImages);
        });
        const activeMiniPdp = pluginConfiguration.miniPDP.enabled ? NavigatorHelper.buildQueryDict()[this.context.getMiniPDPQueryParamName()] : undefined;
        SxQueryUtils.each(values, (index, suggest) => {
            if (this.resultRenderer !== undefined || UiHelper.hasVisibleContent(suggest, this.hiddenParts)) {
                const isVisible = moreResultsButton !== null ? (resultCounter < pagingSize) : true;
                const suggestNode = this.buildSuggestItem(suggest, query, key, !isVisible, groupHasImages, activeMiniPdp);
                if (callbacks.resultLine !== undefined) {
                    try {
                        callbacks.resultLine(suggest.suggest, suggestNode.get()[0]);
                    }
                    catch (ex) {
                        console.warn(ex);
                        // ccl
                    }
                }
                list.append(suggestNode);
                hasHiddenResults = hasHiddenResults || !isVisible;
                resultCounter++;
            }
            else {
                shadowResultCounter++;
            }
        });
        if (list.hasClass('ss360-grid') && list.find('.ss360-suggests__variants-count').length > 0) {
            list.addClass('ss360-grid--has-variants-label');
        }
        if (values.length > pagingSize && shadowResultCounter !== 0) {
            hasHiddenResults = true;
            groupedNode.data('skippedResults', shadowResultCounter);
        }
        if (values.length > 0) {
            // highlight stuff
            if (highlight) {
                const highlightContext = this.context.pluginConfiguration.results.resultTemplate.highlightContext;
                UiHelper.highlightQueryTermsInResult(query, groupedNode, highlightContext);
                if (data.queryCorrectionRewrite && data.queryCorrectionRewrite !== query) {
                    UiHelper.highlightQueryTermsInResult(data.queryCorrectionRewrite, groupedNode, highlightContext);
                }
            }
            this.context.layer.get().appendToResultsFrame(groupedNode);
            let offsetIncrement = 0;
            const isInifniteScroll = searchResultConfig.infiniteScroll && (navigation.isTabbed() || Object.keys(data.suggests).length === 1);
            if ((moreResultsButton !== null || isInifniteScroll) && hasHiddenResults) { // render search button if defined and results left
                const isMasonryLayout = layoutConfig.desktop.type === LayoutType.Masonry || layoutConfig.mobile.type === LayoutType.Masonry;
                const brokenImageHandler = (toggledGroup) => {
                    // handle the case where all images failed
                    if (searchResultConfig.hideResultsWithoutImage && callbacks.resultImageError === undefined) {
                        const toObserve = toggledGroup !== undefined ? sxQuery(toggledGroup).find('img') : groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden):not(.ss360-custom-result) img');
                        let active = false;
                        const brokenArr = [];
                        const errorCallback = (result) => {
                            brokenArr.push(result);
                            offsetIncrement++; // add this to the search offset
                            if (active)
                                return;
                            active = true;
                            setTimeout(() => {
                                brokenArr.forEach((target) => {
                                    if (toggledGroup !== undefined) {
                                        toggledGroup.splice(toggledGroup.indexOf(target.get()[0]), 1);
                                    }
                                    target.remove();
                                });
                                const isEmpty = toggledGroup === undefined ? groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden):not(.ss360-masonry-break)').length === 0 : toggledGroup.length === 0;
                                if (isEmpty && groupedNode.find('.ss360-suggests.ss360-suggests--hidden').length > 0) {
                                    showMoreCallback();
                                }
                                active = false;
                            }, 50);
                        };
                        groupedNode.find('.ss360-suggests.ss360-no-img:not(.ss360-custom-result)').each((entry) => {
                            errorCallback(sxQuery(entry));
                        });
                        toObserve.on('error', (e) => { errorCallback(sxQuery(e.target).parents('.ss360-suggests', true)); });
                    }
                };
                const formatNumber = (num) => 'Intl' in window ? new Intl.NumberFormat().format(num) : num.toString();
                const getPaginationLabel = (count, total) => searchResultConfig.pageDescriptionLabel ? `${StringHelper.escapeHtml(searchResultConfig.pageDescriptionLabel)
                    .replace(/#COUNT#/g, `<strong class="ss360-pagination__visible">${formatNumber(count)}</strong>`).replace(/#TOTAL#/g, `<strong class="ss360-pagination__total" data-num="${total}">${formatNumber(total)}</strong>`)}` : '';
                const showMoreCallback = (e) => {
                    const hidden = groupedNode.find('.ss360-suggests.ss360-suggests--hidden');
                    const offset = groupedNode.find('.ss360-suggests').length + offsetIncrement;
                    const preloaded = offset - this.context.layer.get().getVisibleResults(safeKey).length;
                    if (hidden.length > 0 && !isInifniteScroll) { // focus first
                        const $toFocus = sxQuery(hidden.get(0)).find('.ss360-suggests__link');
                        const $html = sxQuery('html');
                        const modifyScrollBehavior = $html.css('scrollBehavior') === 'smooth';
                        const scrollBehavior = ($html.attr('style') || '').toLowerCase().indexOf('scroll-behavior') !== -1 ? $html.css('scrollBehavior') : null;
                        if (modifyScrollBehavior) {
                            $html.css('scrollBehavior', 'initial');
                        }
                        setTimeout(() => {
                            $toFocus.focus();
                            if (modifyScrollBehavior) {
                                $html.css('scrollBehavior', scrollBehavior);
                            }
                        }, 5);
                    }
                    let visibleCount = groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden)').length;
                    const toggleCount = Math.min(pagingSize, hidden.length);
                    const toggledGroup = [];
                    for (let i = 0; i < toggleCount; i++) {
                        const elem = hidden.get(i);
                        toggledGroup.push(elem);
                        visibleCount++;
                        if (isMasonryLayout) {
                            sxQuery(elem).removeClass('ss360-suggests--hidden').show();
                        }
                        else if (!('ss360IsBeingShown' in elem)) {
                            elem.ss360IsBeingShown = true;
                            sxQuery(elem).fadeIn(30, ((element) => {
                                const $elem = sxQuery(element);
                                $elem.removeClass('ss360-suggests--hidden');
                            }).bind(this, elem));
                            const $paginationLabel = groupedNode.find('.ss360-pagination__label');
                            if ($paginationLabel.length > 0) {
                                setTimeout(() => {
                                    const visibleResults = groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden)').length;
                                    $paginationLabel.get(0).innerHTML = `${getPaginationLabel(visibleResults, maxResultsCount)}`;
                                    Helper.updatePaginationProgress(groupedNode.find('.ss360-pagination__progress'), visibleResults, maxResultsCount);
                                }, 100);
                            }
                        }
                    }
                    if (isMasonryLayout) {
                        Masonry.init(this.context); // redraw layer
                    }
                    if (moreResultsCallback !== undefined && typeof moreResultsCallback === 'function') {
                        try {
                            moreResultsCallback.bind((e || {}).target || this, visibleCount, maxResultsCount, viewKey, data.plain).call();
                        }
                        catch (ex) {
                            Logger.error(ex);
                        }
                    }
                    // highlight stuff
                    if (highlight) {
                        UiHelper.highlightQueryTermsInResult(query, groupedNode);
                    }
                    ImageHandler(this.context);
                    brokenImageHandler(toggledGroup);
                    const moreResultsPagingSize = this.context.pluginConfiguration.results.moreResultsPagingSize;
                    // prefetch
                    if (offset < maxResultsCount || preloaded > moreResultsPagingSize) {
                        const callback = () => {
                            const hiddenPrefetched = groupedNode.find('.ss360-suggests.ss360-suggests--hidden').get().filter((item) => hidden.get().indexOf(item) === -1);
                            if (hiddenPrefetched.length === 0 && !isAllResultsTab) {
                                sxQuery(moreResultsButton).remove();
                                groupedNode.find('.ss360-pagination').remove();
                            }
                            else if (hiddenPrefetched.length === 0 && isAllResultsTab
                                && Object.keys(preloadedResults).reduce((acc, resultGroupKey) => preloadedResults[resultGroupKey].length + acc, 0) === 0
                                && groupedNode.find('.ss360-suggests.ss360-suggests--hidden').get().filter((item) => toggledGroup.indexOf(item) === -1).length === 0) {
                                sxQuery(moreResultsButton).remove();
                                sxQuery((e || {}).target).remove();
                                groupedNode.find('.ss360-pagination').remove();
                            }
                            ImageHandler(this.context);
                            if (isMasonryLayout) {
                                Masonry.init(this.context); // force to redraw
                            }
                            if (isInifniteScroll) {
                                new InfiniteScrollObserver(showMoreCallback).observe(groupedNode, this.context.layer.get());
                            }
                        };
                        let sort = data.sorting;
                        let sortComplex = undefined;
                        if (sort === undefined && data.activeSortingOption !== undefined) {
                            try {
                                const sortingOptionMap = Helper.getSortingOptionMap(data.sortingOptions);
                                if (this.context.isEcom() && data.activeSortingOption.key && sortingOptionMap[`${data.activeSortingOption.key}_${data.activeSortingOption.sort || data.activeSortingOption.direction}`] === undefined) {
                                    // @ts-ignore
                                    sortComplex = data.activeSortingOption;
                                }
                                else {
                                    sort = this.context.pluginConfiguration.results.nameParsing ? data.activeSortingOption.name : data.activeSortingOption.key;
                                }
                            }
                            catch (ex) {
                                // ccl
                            }
                        }
                        if (!isAllResultsTab) {
                            this.context.core.prefetchResults({
                                offset,
                                contentGroup: key,
                                query: StringHelper.unescapeHtml(query),
                                callback: (suggestions) => {
                                    if (preloadedResults[key] === undefined) {
                                        preloadedResults[key] = suggestions;
                                    }
                                    else {
                                        preloadedResults[key] = preloadedResults[key].concat(suggestions);
                                    }
                                    callback();
                                },
                                filters: data.activeFilterOptions,
                                preloaded,
                                sort,
                                sortComplex
                            });
                        }
                        else {
                            const groupKeys = Object.keys(maxResultsCounts).filter((groupKey) => groupKey !== '*');
                            const loadOffsets = {};
                            let ignoredGroups = 0;
                            groupKeys.forEach((groupKey) => {
                                const loadedOffset = sxQuery(`.ss360-group-${StringHelper.getSafeKey(groupKey)} .ss360-suggests`).length;
                                if (loadedOffset < maxResultsCounts[groupKey]) {
                                    loadOffsets[groupKey] = loadedOffset;
                                }
                                else {
                                    ignoredGroups++;
                                }
                            });
                            let loaded = 0;
                            const loadCallback = (groupKey, suggestions) => {
                                if (groupKey !== undefined && suggestions !== undefined) {
                                    if (preloadedResults[groupKey] === undefined) {
                                        preloadedResults[groupKey] = suggestions;
                                    }
                                    else {
                                        preloadedResults[groupKey] = preloadedResults[groupKey].concat(suggestions);
                                    }
                                }
                                loaded++;
                                if ((groupKey === undefined && suggestions === undefined) || loaded + ignoredGroups === groupKeys.length) {
                                    const searchResults = Object.keys(preloadedResults).map((entryKey) => {
                                        const arr = preloadedResults[entryKey].splice(0, moreResultsPagingSize);
                                        const results = arr.map((s) => (s.plain instanceof Array ? s.plain : [s.plain]));
                                        return {
                                            results,
                                            type: entryKey,
                                            name: entryKey,
                                            totalResults: maxResultsCounts[entryKey]
                                        };
                                    });
                                    const fakeResponse = new SearchResponse({
                                        searchResults,
                                        totalResultsPerContentGroup: maxResultsCounts,
                                        sorting: data.sorting,
                                        sortingOptions: data.sortingOptions,
                                        activeSortingOption: data.activeSortingOption,
                                        sortingOrder: data.sortingOrder
                                    });
                                    SearchIntentHelper.buildAllResultsTab(fakeResponse);
                                    const suggestArray = fakeResponse.getSuggestsArray('*');
                                    const renderTarget = groupedNode.find('.ss360-list');
                                    suggestArray.forEach((suggest) => {
                                        if (UiHelper.hasVisibleContent(suggest, this.hiddenParts)) {
                                            const $item = this.buildSuggestItem(suggest, StringHelper.unescapeHtml(query), '*', true, undefined);
                                            renderTarget.append($item);
                                        }
                                    });
                                    callback();
                                }
                            };
                            if (groupKeys.length > ignoredGroups && moreResultsPagingSize >= groupedNode.find('.ss360-suggests--hidden').length) {
                                groupKeys.forEach((groupKey) => {
                                    if (loadOffsets[groupKey] === undefined) {
                                        return;
                                    }
                                    if (preloadedResults[groupKey] !== undefined && preloadedResults[groupKey].length >= moreResultsPagingSize) {
                                        loadCallback(groupKey, []); // do not append, the results are already there
                                    }
                                    else {
                                        this.context.core.prefetchResults({
                                            offset: loadOffsets[groupKey],
                                            contentGroup: groupKey,
                                            query: StringHelper.unescapeHtml(query),
                                            callback: (suggests) => {
                                                loadCallback(groupKey, suggests);
                                            },
                                            filters: data.activeFilterOptions,
                                            sort,
                                            preloaded: 100000 // hacky (like the whole thing over here...), make results always hidden
                                        });
                                    }
                                });
                            }
                            else if (moreResultsPagingSize >= groupedNode.find('.ss360-suggests--hidden').length) {
                                loadCallback();
                            }
                            else {
                                callback();
                            }
                        }
                    }
                    else if ((searchResultConfig.limitPerGroup || hidden.length - toggleCount <= 0) && e !== undefined) { // results already rendered and all shown
                        sxQuery(e.target).remove();
                        groupedNode.find('.ss360-pagination').remove();
                    }
                };
                brokenImageHandler();
                if (isInifniteScroll) {
                    new InfiniteScrollObserver(showMoreCallback).observe(groupedNode, this.context.layer.get());
                }
                else {
                    const $moreResultsButton = sxQuery(`<button type="button" class="ss360-more-results ss360-n-button ss360-cta ss360-cta--button ss360-cta--primary">${moreResultsButton}</button>`);
                    const paginationWrap = sxQuery('<section role="navigation" aria-label="Pagination" class="ss360-pagination"></section>');
                    if (searchResultConfig.pageDescriptionLabel) {
                        const visibleResults = groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden)').length;
                        paginationWrap.append(`<span class="ss360-pagination__label">${getPaginationLabel(visibleResults, maxResultsCount)}</span>`);
                        paginationWrap.append(`<div role="presentation" class="ss360-pagination__progress">
													<div class="ss360-pagination__progress-bar ss360-pagination__progress-bar--active"></div>
													<div class="ss360-pagination__progress-bar ss360-pagination__progress-bar--total"></div>
												</div>`);
                        Helper.updatePaginationProgress(paginationWrap.find('.ss360-pagination__progress'), visibleResults, maxResultsCount);
                    }
                    paginationWrap.append($moreResultsButton);
                    $moreResultsButton.on('click', showMoreCallback);
                    if (searchResultConfig.showCopyLinkToPositionButton && ('clipboard' in window.navigator) && window.navigator.clipboard.writeText) {
                        const label = StringHelper.escapeHtml(searchResultConfig.copyLinkToPositionButtonLabel);
                        const copiedLabel = StringHelper.escapeHtml(searchResultConfig.copiedLinkToPositionButtonLabel);
                        let copyTimeout;
                        const copyLinkToPositionButton = sxQuery(`<button class="ss360-pagination__copy ss360-n-button">
								${Icons.getSvgIcon(Icons.LINK, UiColors.GREY_TEXT, 'ss360-pagination__copy-icon ss360-pagination__copy-icon--inactive')}
								${Icons.getSvgIcon(Icons.LINK, pluginConfiguration.style.accentColor, 'ss360-pagination__copy-icon ss360-pagination__copy-icon--active')}
								<span class="ss360-pagination__copy-label">${label}</span>
							</button>`);
                        const $label = copyLinkToPositionButton.find('.ss360-pagination__copy-label');
                        copyLinkToPositionButton.on('click', () => {
                            const linkBase = window.location.href.split('?')[0];
                            const queryDict = NavigatorHelper.buildQueryDict();
                            queryDict[this.context.getPageLinkQueryParam()] = '1';
                            queryDict[this.context.getOffsetQueryParam()] = (groupedNode.find('.ss360-suggests:not(.ss360-suggests--hidden)').length - 1).toString();
                            const headingNode = groupedNode.find('.ss360-group__heading');
                            let selectedContentGroup = getSelectedContentGroup(searchResultConfig, headingNode, groupedNode);
                            if (selectedContentGroup !== '') {
                                queryDict[this.context.getContentGroupQueryParam()] = selectedContentGroup;
                            }
                            window.navigator.clipboard.writeText(`${linkBase}?${Object.keys(queryDict).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryDict[key])}`).join('&')}`).then(() => {
                                $label.html(copiedLabel);
                                clearTimeout(copyTimeout);
                                copyTimeout = setTimeout(() => {
                                    $label.html(label);
                                }, 2000);
                            });
                        });
                        paginationWrap.append(copyLinkToPositionButton);
                        paginationWrap.addClass('ss360-pagination--has-copy-button');
                    }
                    groupedNode.append(paginationWrap);
                }
            }
            try {
                this.customResultRenderer.getScripts().forEach((script) => {
                    const e = document.createElement('script');
                    if (script.attr('src') !== undefined && script.attr('src') !== null) {
                        e.src = script.attr('src');
                    }
                    else {
                        e.innerText = script.get()[0].innerText;
                    }
                    document.getElementsByTagName('body')[0].appendChild(e);
                });
                this.customResultRenderer.resetScripts();
            }
            catch (ex) {
                // ccl
            }
        }
        return resultCounter;
    }
    renderNoResultsText(query, relatedQueries) {
        const { results } = this.context.pluginConfiguration;
        if (results.noResultsRedirect === undefined) {
            new NoResultsPage(this.context, query, this, relatedQueries).render(this.context.layer.get());
        }
        else {
            window.location.href = results.noResultsRedirect;
        }
    }
    renderWatermark(layerContent) {
        if (this.context.getIsZoovu()) {
            return;
        }
        if (this.waterMarkNode !== undefined) {
            this.waterMarkNode.remove();
        }
        this.waterMarkNode = sxQuery(`<div style="display:block!important;visibility:visible!important;opacity:1!important;"><a href="https://sitesearch360.com" target="_blank" rel="sponsored" style="display:inline!important;visibility:visible!important;opacity:1!important;">
			<img role="presentation" style="max-width:120px!important;float:right!important;visibility:visible!important;position:absolute!important;top:0!important;right:5px!important;opacity:1!important;display:block!important;height:auto!important" src="${UiHelper.getLogoSrc(this.context.pluginConfiguration.allowCookies)}"></a></div>`);
        this.waterMarkNode.find('img').attr('alt', '');
        this.waterMarkNode.find('a').attr('aria-label', this.context.pluginConfiguration.results.watermarkLabel);
        layerContent.append(this.waterMarkNode);
    }
    renderLoader() {
        const { style } = this.context.pluginConfiguration;
        // ss360 spinner
        let loader = sxQuery('.ss360-loader');
        if (loader.length === 0) {
            loader = sxQuery(`<div class="ss360-loader"><div class="ss360-loader ss360-loader__bounce"></div>
			   <div class="ss360-loader ss360-loader__bounce ss360-loader__bounce--delayed"></div></div>`);
            sxQuery('body').append(loader);
        }
        const animationDurationS = SxQueryUtils.prefersReducedMotion() ? 0 : parseInt(style.animationSpeed.toString(), 10) / 1000;
        const loaderType = style.loaderType;
        if (loaderType === 'square') {
            loader.html('');
            loader.addClass('ss360-loader--square');
            loader.css('animationDuration', `${1 + animationDurationS}s`);
        }
        else if (loaderType === 'none') {
            loader.html('');
        }
        else {
            loader.find('.ss360-loader__bounce').css('animationDuration', `${2 + animationDurationS}s`);
        }
    }
    clearHasImagesCache() {
        Object.keys(this.groupHasImageCache).forEach((key) => {
            delete this.groupHasImageCache[key];
        });
    }
}
export default UiBuilder;
