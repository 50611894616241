import SxQueryUtils from '../../sxQuery/SxQueryUtils';
class AnimatedPlaceholder {
    constructor(context, searchBox, queries = [], maxRotations, firstTimeVisitOnly, suggestUrl) {
        this.index = 0;
        this.rotations = 0;
        this.maxRotations = 10;
        this.isStopped = false;
        this.searchBox = searchBox;
        this.placeholderCache = context.pluginConfiguration.searchBox.placeholder || searchBox.attr('placeholder') || '';
        this.queries = queries;
        this.maxRotations = maxRotations;
        if (this.searchBox.length === 0 || this.searchBox.val().length > 0) {
            return;
        }
        if (firstTimeVisitOnly) {
            const cookieKey = 'animated-placeholder-seen';
            const val = context.readCookie(cookieKey);
            if (val === '1') {
                return;
            }
            context.createCookie(cookieKey, '1', 30);
        }
        if (queries.length > 0) {
            this.start();
        }
        else {
            this.loadTopQueriesAndStart(suggestUrl, context.pluginConfiguration.siteId);
        }
        this.searchBox.on('focus.animatedPlaceholder', () => {
            this.pause();
        });
        this.searchBox.on('focusout.animatedPlaceholder', () => {
            this.resume();
        });
    }
    start() {
        const query = this.queries[this.index];
        this.animateQuery(query);
    }
    pause() {
        if (this.queries.length === 0) {
            return;
        }
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        if (!this.isStopped) {
            this.searchBox.attr('placeholder', '');
        }
    }
    resume() {
        if (this.queries.length === 0) {
            return;
        }
        if (this.searchBox.val().length > 0) {
            this.finish();
        }
        else {
            this.timeout = setTimeout(() => {
                this.animateQuery(this.queries[this.index]);
            }, 1000);
        }
    }
    finish() {
        this.searchBox.off('focus.animatedPlaceholder,focusout.animatedPlaceholder');
        this.searchBox.attr('placeholder', this.placeholderCache);
        this.isStopped = true;
    }
    loadTopQueriesAndStart(suggestUrl, siteId) {
        SxQueryUtils.get(`${suggestUrl}&site=${siteId}&query=&log=false`, (data) => {
            let queries = [];
            if (data.searchResults) {
                const querySuggestions = data.searchResults.filter((resultGroup) => resultGroup.type === 'queries')[0];
                if (querySuggestions) {
                    // @ts-ignore
                    queries = (querySuggestions.results || []).map((entry) => entry.name).filter((val) => val !== undefined && val !== null && val.length > 0);
                }
            }
            else {
                if (data.suggests && data.suggests.ss360QuerySuggestions) {
                    // @ts-ignore
                    queries = (data.suggests.ss360QuerySuggestions || []).map((entry) => entry.name).filter((val) => val !== undefined && val !== null && val.length > 0);
                }
            }
            queries = queries.map((q) => `${q.substring(0, 1).toUpperCase()}${q.substring(1)}`);
            if (queries.length > 0) {
                this.queries = queries;
                this.start();
            }
        });
    }
    animateQuery(query) {
        query = `${query}   `; // make it stick a little longer
        this.timeout = setTimeout(() => {
            let dir = 1;
            let offset = -1;
            let isFirstCall = true;
            this.interval = setInterval(() => {
                if (isFirstCall) {
                    isFirstCall = false;
                    return;
                }
                offset += dir;
                const placeholder = query.substring(0, offset + 1);
                this.searchBox.attr('placeholder', placeholder);
                if (placeholder.length === query.length) {
                    dir = -1;
                }
                else if (placeholder.length === 0) {
                    clearInterval(this.interval);
                    this.index++;
                    if (this.index >= this.queries.length) {
                        this.index = 0;
                        this.rotations++;
                    }
                    if (this.rotations < this.maxRotations) {
                        this.animateQuery(this.queries[this.index]);
                    }
                    else {
                        this.finish();
                    }
                }
            }, 200);
        }, 1000);
    }
}
export default AnimatedPlaceholder;
