/* eslint no-restricted-globals: 0 */
'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import Helper from './Helper';
const hasHistoryAPI = (results) => 'history' in window && (typeof history.pushState !== 'undefined') && results.allowHistoryApi;
const encodeParam = (val, pluginConfiguration) => {
    if (pluginConfiguration.results.nameParsing && pluginConfiguration.filters.enabled) {
        return val.replace(/#/g, '%23').replace(/&/g, '%26').replace(/\?/g, '%3F');
    }
    return encodeURIComponent(val);
};
const createQueryString = (queryDict, pluginConfiguration) => {
    if (Object.keys(queryDict).length === 0) {
        return '';
    }
    return `?${Object.keys(queryDict).reduce((acc, key) => `${acc}&${encodeParam(key, pluginConfiguration)}=${encodeParam(queryDict[key], pluginConfiguration)}`, '').substring(1)}`;
};
const buildState = (queryDict, pluginConfiguration) => {
    const searchString = createQueryString(queryDict, pluginConfiguration);
    const newUrl = window.location.href.split('?')[0].replace(window.location.hash, '').replace(/#/, '') + searchString + window.location.hash;
    const obj = {
        Page: document.title,
        Url: newUrl
    };
    return obj;
};
const createQueryDict = () => window.location.search.substring(1).split('&').reduce((acc, item) => {
    if (item && item.split && item.split('=').length === 2) {
        acc[decodeURIComponent(item.split('=')[0])] = decodeURIComponent(item.split('=')[1]);
    }
    return acc;
}, {});
const createQueryDictFromString = (str) => {
    const queryDict = {};
    if (str.indexOf('?') !== -1) {
        str.substring(str.indexOf('?') + 1).split('&').forEach((entry) => {
            const kv = entry.split('=');
            queryDict[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
        });
    }
    return queryDict;
};
const buildSemanticPathPart = (query, results) => {
    const spaceCharacter = results.semanticModeSpaceCharacter;
    let escapedQuery = encodeURIComponent(query.split(spaceCharacter).join('~').replace(/ /g, spaceCharacter));
    escapedQuery = escapedQuery.split(encodeURIComponent(spaceCharacter)).join(spaceCharacter); // don't encode the space character, if you're dumb enough to set dumb char, just gtfo
    return results.semanticModeParamName + escapedQuery;
};
const pushSemanticState = (query, queryDict, pluginConfiguration) => {
    const { results } = pluginConfiguration;
    let newSemanticPathPart = buildSemanticPathPart(query, results);
    const baseUrl = (results.embedConfig || {}).url !== undefined ? results.embedConfig.url : '/';
    if (newSemanticPathPart.indexOf('?') !== -1) {
        const semanticPartQueryDict = createQueryDictFromString(newSemanticPathPart);
        if (Object.keys(semanticPartQueryDict).length > 0) {
            Object.keys(semanticPartQueryDict).forEach((key) => {
                queryDict[key] = semanticPartQueryDict[key];
            });
            newSemanticPathPart = newSemanticPathPart.split('?')[0];
        }
    }
    const spaceCharacter = results.semanticModeSpaceCharacter;
    const queryPart = createQueryString(queryDict, pluginConfiguration).split(encodeURIComponent(spaceCharacter)).join(spaceCharacter);
    window.history.pushState(null, null, `${baseUrl}${newSemanticPathPart}${queryPart}`);
};
const getSemanticModeQuery = (embedUrl, semanticModeParamName, results) => {
    if (embedUrl === undefined) {
        embedUrl = (results.embedConfig || {}).url;
    }
    if (embedUrl === undefined) {
        embedUrl = '/';
    }
    if (semanticModeParamName === undefined) {
        semanticModeParamName = results.semanticModeParamName;
    }
    let currentLocation = window.location.href;
    if (currentLocation[currentLocation.length - 1] === '/') {
        currentLocation = currentLocation.substring(0, currentLocation.length - 1);
    }
    else if (!results.semanticModeKeepTrailingSlash && currentLocation.indexOf('/?') !== -1) {
        currentLocation = currentLocation.replace('/?', '?');
    }
    if (embedUrl === '/') {
        currentLocation = currentLocation.replace(`${window.location.protocol}//`, '').replace(window.location.host, '');
    }
    const embedIdx = currentLocation.indexOf(embedUrl);
    if (embedIdx === -1) {
        return undefined;
    }
    if (currentLocation.indexOf(semanticModeParamName) === -1) {
        return undefined;
    }
    let queryPart = currentLocation.substring(embedIdx + embedUrl.length);
    if (queryPart.indexOf('?') !== -1 && semanticModeParamName.indexOf('?') === -1) {
        queryPart = queryPart.substring(0, queryPart.indexOf('?'));
    }
    else if (queryPart.indexOf('?') !== -1 && semanticModeParamName.indexOf('?') !== -1) {
        const queryDict = createQueryDict();
        const semanticModeQueryDict = createQueryDictFromString(semanticModeParamName);
        const queryEntry = Object.keys(semanticModeQueryDict).reduce((acc, key) => {
            if (acc !== undefined) {
                return acc;
            }
            if (queryDict[key] !== undefined) {
                return `${key}=${queryDict[key]}`;
            }
            return undefined;
        }, undefined);
        if (queryEntry !== undefined) {
            queryPart = queryPart.substring(0, queryPart.indexOf('?'));
            queryPart = `${queryPart}?${queryEntry}`;
        }
    }
    if (queryPart.length > 0) {
        const spaceCharacter = results.semanticModeSpaceCharacter;
        return decodeURIComponent(queryPart.replace(semanticModeParamName, '').split(spaceCharacter).join(encodeURIComponent(spaceCharacter)))
            .split(spaceCharacter).join(' ')
            .replace(/~/g, spaceCharacter);
    }
    return undefined;
};
const escapeQueryParam = (val) => `${val}`.toLowerCase().replace(/_/g, '__').replace(/ /g, '_').replace(/%/g, '%25')
    .replace(/\//g, '%2F')
    .replace(/,/g, ';;')
    .replace(/&/g, '%26')
    .replace(/\|/g, '%7C');
const unescapeQueryParam = (val) => val.replace(/#/g, '.IAMHASH.').replace(/__/g, '###').replace(/_/g, ' ').replace(/###/g, '_').replace(/%25/g, '%').replace(/\.IAMHASH\./g, '#')
    .replace(/%2F/g, '/')
    .replace(/;;/g, ',')
    .replace(/%26/g, '&')
    .replace(/%7C/g, '|');
const replaceState = (queryDict, operation) => {
    queryDict = queryDict || createQueryDict();
    if (window.history.replaceState) {
        let searchPath = Object.keys(queryDict).reduce((acc, qpName) => `${acc}&${encodeURIComponent(qpName)}=${encodeURIComponent(queryDict[qpName])}`, '');
        if (searchPath.length > 0) {
            searchPath = `?${searchPath.substring(1)}`;
        }
        const obj = {};
        if (operation !== undefined) {
            obj.operation = operation;
        }
        window.history.replaceState(obj, document.title, document.location.pathname + searchPath + window.location.hash);
    }
};
const NavigatorHelper = {
    removeQueryParam(results, paramName) {
        paramName = paramName === undefined ? results.searchQueryParamName : paramName;
        try {
            if (window.history.replaceState && document.location.search.indexOf(`${paramName}=`) > -1 && hasHistoryAPI(results)) {
                const queryDict = createQueryDict();
                if (paramName in queryDict) {
                    delete queryDict[paramName];
                    replaceState(queryDict);
                }
            }
        }
        catch (e) {
            // ccl
        }
    },
    buildQueryDict() {
        return createQueryDict();
    },
    hasHistoryAPI(results) {
        return hasHistoryAPI(results);
    },
    pushState(queryDict, pluginConfiguration, landingPageOptions, options) {
        if (!hasHistoryAPI(pluginConfiguration.results)) {
            return undefined;
        }
        const obj = buildState(queryDict, pluginConfiguration);
        if (landingPageOptions !== undefined) {
            obj.query = landingPageOptions.query;
            obj.requestOptions = landingPageOptions.requestOptions;
        }
        if (options !== undefined) {
            obj.options = options;
        }
        try {
            history.pushState(obj, obj.Page, obj.Url);
        }
        catch (ex) {
            Logger.warn(ex);
        }
        return obj.Url;
    },
    replaceState(queryDict, pluginConfiguration) {
        if (!hasHistoryAPI(pluginConfiguration.results)) {
            return;
        }
        const obj = buildState(queryDict, pluginConfiguration);
        try {
            history.replaceState(obj, obj.Page, obj.Url);
        }
        catch (ex) {
            Logger.warn(ex);
        }
    },
    redirectToSearchResultPage(selectedText, redirectUrl, pluginConfiguration, context) {
        const { results, allowCookies } = pluginConfiguration;
        const paramName = results.searchQueryParamName;
        if (allowCookies) {
            context.createCookie('LastQuery', selectedText, 1);
        }
        if (results.semanticMode) {
            document.location.href = redirectUrl + buildSemanticPathPart(selectedText, pluginConfiguration.results);
        }
        else {
            const queryDict = createQueryDictFromString(redirectUrl);
            delete queryDict[paramName];
            queryDict[paramName] = selectedText;
            redirectUrl = redirectUrl.split('?')[0];
            redirectUrl = `${redirectUrl}?${Object.keys(queryDict).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryDict[key])}`).join('&')}`;
            document.location.href = redirectUrl;
        }
    },
    handleRedirect(redirect, selectedText, escapedQuery, context) {
        const pluginConfiguration = context.pluginConfiguration;
        const { highlight, highlightOnRedirect, scrollOnRedirect } = pluginConfiguration.queryTerm;
        const redirectCallback = pluginConfiguration.callbacks.redirect;
        let hashPart = '';
        if (redirect.indexOf('#') !== -1) {
            const urlParts = redirect.split('#');
            redirect = urlParts[0];
            hashPart = `#${urlParts[1]}`;
        }
        if (highlight && highlightOnRedirect) {
            if (redirect.indexOf('?') > -1) {
                redirect += '&';
            }
            else {
                redirect += '?';
            }
            const term = context.getIsZoovu() ? 'zSearchTerm' : 'ss360SearchTerm';
            const sourceParamName = context.getIsZoovu() ? 'zSource' : 'ss360Source';
            const sourceParam = scrollOnRedirect ? '' : `&${sourceParamName}=redirect`;
            redirect += `${term}=${escapedQuery}${sourceParam}`;
        }
        redirect = `${redirect}${hashPart}`;
        sxQuery(window).off('beforeunload.ss360Insights');
        if (context.hasInsights()) {
            context.getInsights().trackSerpClick(selectedText, undefined, undefined, undefined, undefined, undefined, redirect, 'redirect');
        }
        if (redirectCallback !== undefined && typeof redirectCallback === 'function') {
            redirectCallback.call(this, redirect);
        }
        else {
            window.location.href = redirect;
        }
    },
    getFirstResult(data) {
        const firstKey = data.getResultGroupNames()[0];
        return (data.getSuggestsArray(firstKey) || [])[0];
    },
    getFirstLink(data) {
        const firstResult = NavigatorHelper.getFirstResult(data);
        return firstResult !== undefined ? firstResult.getLink() : undefined;
    },
    redirectToFirst(data) {
        window.location.href = NavigatorHelper.getFirstLink(data);
    },
    getSemanticModeQuery,
    pushSemanticState,
    getFilters(results, queryDict, ignoredQueryParams) {
        if (queryDict === undefined) {
            queryDict = createQueryDict();
        }
        if (ignoredQueryParams === undefined) {
            ignoredQueryParams = results.ignoredFilterQueryParams || [];
        }
        let result;
        if (queryDict['ss360Filter'] !== undefined && !results.nameParsing) {
            try {
                result = JSON.parse(queryDict['ss360Filter']);
            }
            catch (ex) {
                // ccl
            }
        }
        else if (results.nameParsing) {
            const filterKeys = Object.keys(queryDict).filter((key) => key !== 'ss360Filter'
                && key !== results.searchQueryParamName && key !== 'ss360SearchTerm' && key !== 'zSearchTerm' && ignoredQueryParams.indexOf(key) === -1
                && key !== escapeQueryParam(results.sortingParamName) && key !== 'ss360-qa-flow');
            result = filterKeys.map((key) => {
                const val = queryDict[key];
                let filterVal;
                const minMax = val.indexOf('-') !== -1 && val.split('-').length === 2 && val.indexOf(',') === -1 ? val.split('-') : undefined;
                if (minMax !== undefined && minMax.length === 2 && minMax[1] !== undefined) { // remove unit
                    minMax[1] = minMax[1].split('~')[0];
                }
                const min = minMax !== undefined && !Helper.isNaN(parseFloat(minMax[0])) ? parseFloat(minMax[0]) : undefined;
                const max = minMax !== undefined && !Helper.isNaN(parseFloat(minMax[1])) ? parseFloat(minMax[1]) : undefined;
                if (min !== undefined && max !== undefined) {
                    filterVal = {
                        min,
                        max
                    };
                }
                else if (val === '~true') {
                    filterVal = {
                        booleanValue: true
                    };
                }
                else if (val === '~false') {
                    filterVal = {
                        booleanValue: false
                    };
                }
                else {
                    filterVal = val.split(',').map((part) => unescapeQueryParam(part));
                    filterVal = {
                        values: filterVal.map((singleVal) => ({ value: singleVal }))
                    };
                }
                if (filterVal === undefined)
                    return undefined;
                let obj = {};
                obj = filterVal;
                obj.name = unescapeQueryParam(key);
                return obj;
            }).filter((param) => param !== undefined);
        }
        return result;
    },
    escapeQueryParam,
    unescapeQueryParam,
    createQueryDictFromString,
    addQueryParam(key, value) {
        const queryDict = createQueryDict();
        if (queryDict[key] === value) {
            return;
        }
        queryDict[key] = value;
        replaceState(queryDict);
    },
    replaceStatePlain: replaceState
};
export default NavigatorHelper;
