'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import Logger from '../../global/Logger';
import CallbackEnhanceType from '../enums/CallbackEnhanceType';
import ResultIntegrationType from '../enums/ResultIntegrationType';
import SearchResultType from '../enums/SearchResultType';
const isUsdOrPound = (unit) => {
    const lcUnit = (unit || '').toLowerCase().trim();
    return lcUnit === '$' || lcUnit === 'usd' || lcUnit === 'gbp' || lcUnit === '£';
};
const colorToRgb = (hexOrRgb) => {
    try {
        if (hexOrRgb.indexOf('var(') === 0) {
            const el = document.createElement('span');
            el.style.color = hexOrRgb;
            document.body.appendChild(el);
            hexOrRgb = window.getComputedStyle(el).color || hexOrRgb;
            document.body.removeChild(el);
        }
    }
    catch (err) {
    }
    try {
        hexOrRgb = hexOrRgb.trim();
    }
    catch (ex) {
        // ccl
    }
    if ((hexOrRgb.indexOf('rgba(') === 0 || (hexOrRgb.indexOf('rgb(')) === 0 && hexOrRgb.indexOf(')') === hexOrRgb.length - 1)) {
        const rgbaParts = hexOrRgb.substring(0, hexOrRgb.length - 1).replace(/rgba?\(/, '').split(',').map((part) => part.trim());
        if (rgbaParts.length >= 3) {
            return {
                r: parseInt(rgbaParts[0], 10),
                g: parseInt(rgbaParts[1], 10),
                b: parseInt(rgbaParts[2], 10)
            };
        }
    }
    if (hexOrRgb.length === 4) {
        hexOrRgb = `#${Array(3).join(hexOrRgb[1])}${Array(3).join(hexOrRgb[2])}${Array(3).join(hexOrRgb[3])}`;
    }
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexOrRgb);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
const darkenColorHex = (hex, percent) => {
    try {
        if (hex.length === 4) {
            hex = `#${Array(3).join(hex[1])}${Array(3).join(hex[2])}${Array(3).join(hex[3])}`;
        }
        const rgb = Helper.colorToRgb(hex);
        if (rgb === null) {
            return rgb;
        }
        let { r, g, b } = rgb;
        r = Math.round(Math.max(0, Math.min((r * ((100 - percent) / 100)), 255)));
        g = Math.round(Math.max(0, Math.min((g * ((100 - percent) / 100)), 255)));
        b = Math.round(Math.max(0, Math.min((b * ((100 - percent) / 100)), 255)));
        let rString = r.toString(16);
        let gString = g.toString(16);
        let bString = b.toString(16);
        if (rString.length === 1)
            rString = `0${rString}`;
        if (gString.length === 1)
            gString = `0${gString}`;
        if (bString.length === 1)
            bString = `0${bString}`;
        return `#${rString}${gString}${bString}`;
    }
    catch (err) {
        return hex;
    }
};
const shouldSearchPost = (context, url) => {
    if (!context.isEcom()) {
        return false;
    }
    return url.length > 4000;
};
const isNaN = (number) => {
    // eslint-disable-next-line no-restricted-properties
    const checker = ('isNaN' in window.Number && (typeof window.Number.isNaN === 'function')) ? window.Number.isNaN : window.isNaN;
    return checker(number);
};
const convertToPost = (url) => {
    const urlParts = url.split('?');
    const urlBase = urlParts[0];
    const queryParams = [];
    const data = urlParts[1].split('&').reduce((acc, entry) => {
        const kv = entry.split('=');
        const key = decodeURIComponent(kv[0]);
        const val = decodeURIComponent(kv[1]);
        try {
            acc[key] = JSON.parse(val);
        }
        catch (err) {
            acc[key] = val;
        }
        if (key === 'query') {
            acc[key] = (acc[key] || '').toString();
        }
        if (key === 'site' || key === 'projectId' || key === 'cache' || key === 'caching' || key === 'internal' || key === 'userGroup') {
            queryParams.push(entry);
        }
        return acc;
    }, {});
    return {
        url: `${urlBase}${queryParams.length > 0 ? '?' : ''}${queryParams.join('&')}`,
        data
    };
};
const lightenColorHex = (hex, percent) => darkenColorHex(hex, percent * -1);
const deepCopyArray = (arr) => {
    return arr.map((val) => {
        if (val instanceof Function) {
            return val;
        }
        else if (val instanceof Array) {
            return deepCopyArray(val);
        }
        else if (val instanceof Object) {
            return deepCopyObject(val);
        }
        return val;
    });
};
const deepCopyObject = (obj, target = {}) => {
    Object.keys(obj).forEach((key) => {
        const val = obj[key];
        if (val === undefined) {
            return;
        }
        if (val instanceof Function) {
            target[key] = val;
        }
        else if (val instanceof Array) {
            target[key] = deepCopyArray(val);
        }
        else if (val instanceof Object) {
            target[key] = deepCopyObject(val);
        }
        else {
            target[key] = val;
        }
    });
    return target;
};
const Helper = {
    getInitializationErrors(pluginConfig, is404, isCategorySearch, context, isFullscreen = false, isZoe = false) {
        const res = [];
        if (document.querySelectorAll('script[src*=sitesearch360-v]:not([type="module"])').length > 1 || document.querySelectorAll('script[src*=sitesearch360-v][type="module"]').length > 1) {
            res.push('There is more than one sitesearch360 script on this page. Please remove one.');
        }
        const searchBoxSelector = pluginConfig.searchBox.selector;
        const suggestionTrigger = pluginConfig.suggestions.trigger;
        if (!is404 && !isCategorySearch && !isFullscreen && !context.pluginConfiguration.searchBox._preventBind && !isZoe
            && pluginConfig.results.layoverTrigger === undefined && (suggestionTrigger === undefined || sxQuery(suggestionTrigger).length === 0) && sxQuery(searchBoxSelector).length === 0
            && Object.keys(pluginConfig.tracking.external || {}).length === 0) {
            res.push(`There is no input element for the searchBox.selector "${searchBoxSelector}". Please update your ${context.getIsZoovu() ? 'zoovuSearchConfig' : 'ss360Config'} object.`);
        }
        return res;
    },
    enhanceCallback(callback, expected, enhanceType, context) {
        if (callback === undefined) {
            return expected;
        }
        if (enhanceType === CallbackEnhanceType.EnterResult || enhanceType === CallbackEnhanceType.Type) {
            return callback;
        }
        if (callback !== expected) {
            if (enhanceType === CallbackEnhanceType.Enter) {
                return function (text) {
                    try {
                        callback.call(this, text);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
            if (enhanceType === CallbackEnhanceType.Focus) {
                return function (event, selectedText) {
                    context.core.focus(event, selectedText);
                    try {
                        callback.call(this, event, selectedText);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
            if (enhanceType === CallbackEnhanceType.Blur) {
                return function (event, selectedText) {
                    context.core.blur(event, selectedText);
                    try {
                        callback.call(this, event, selectedText);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                };
            }
        }
        return callback;
    },
    copyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    deepCopyObject,
    getTotalCount(data, limitPerGroup, pluginConfig) {
        if (data.groupedResultsAvailable !== undefined) {
            return data.groupedResultsAvailable;
        }
        const groupResults = pluginConfig.results.group;
        const { ignoreOther, exclude } = pluginConfig.contentGroups;
        const limit = pluginConfig.results.num;
        let { totalResults } = data;
        const limitThroughInclude = pluginConfig.results.limitPerGroup && groupResults && (pluginConfig.contentGroups.include || []).length === 1;
        if (!limitPerGroup && groupResults && !limitThroughInclude) {
            totalResults = data.suggests.reduce((acc, resultGroup) => acc + resultGroup.suggests.length, 0);
        }
        else if (data.totalResultsPerContentGroup && Object.keys(data.totalResultsPerContentGroup).length > 0) {
            totalResults = Object.keys(data.totalResultsPerContentGroup).reduce((acc, key) => {
                if ((key === '_' && ignoreOther && !limitThroughInclude && pluginConfig.results.group) || (key !== '_' && exclude !== undefined && exclude.indexOf(key) !== -1)) {
                    return acc;
                }
                if (key === '*' && Object.keys(data.suggests).length <= 1) {
                    return acc;
                }
                return acc + Math.min(limit, data.totalResultsPerContentGroup[key]);
            }, 0);
            if (totalResults === 0 && data.suggests.length === 1 && data.suggests[0] !== undefined && data.suggests[0].name === '*') {
                totalResults = (data.suggests[0].suggests || []).length;
            }
        }
        else if (!groupResults) {
            totalResults = Math.min(totalResults, limit);
        }
        if (totalResults === 0) {
            totalResults = ((((data.searchResults || []).filter((group) => group.type === 'all' && group.placements !== undefined))[0] || {}).placements || []).filter((placement) => {
                // @ts-ignore
                const type = placement.type;
                if (type === 'custom') {
                    return true;
                }
                if (type === 'PRODUCT') {
                    // @ts-ignore
                    return placement.name !== undefined || placement.identifier !== undefined;
                }
                if (type === 'HTML') {
                    // @ts-ignore
                    return placement.name !== undefined || placement.content !== undefined || placement.image !== undefined;
                }
                return true;
            }).length;
        }
        return totalResults;
    },
    isNaN,
    colorToRgb,
    getPositionInNodeList(nodeList, node) {
        for (let i = 0; i < nodeList.length; i++) {
            if (nodeList[i] === node) {
                return i + 1;
            }
        }
        return -1;
    },
    getIntegrationType(results) {
        const { integrationType } = results;
        if (results.fullScreenConfig !== undefined && results.fullScreenConfig.trigger !== undefined && (integrationType === undefined || integrationType === ResultIntegrationType.Fullscreen)) {
            return SearchResultType.Fullscreen;
        }
        if (results.embedConfig !== undefined && (integrationType === undefined || integrationType === ResultIntegrationType.Embed)) {
            const embedConfig = results.embedConfig;
            const willRequireRedirect = embedConfig.url !== undefined && (document.location.href.indexOf(embedConfig.url) === -1 || (embedConfig.url === '/' && window.location.pathname !== '/'));
            if (willRequireRedirect) { // keep embed if we need to redirect to a search result page
                return SearchResultType.Embed;
            }
            // fallback to layover if 'contentBlock' not present on the page
            return sxQuery(embedConfig.contentBlock, true).length > 0 ? SearchResultType.Embed : SearchResultType.Layover;
        }
        return SearchResultType.Layover;
    },
    updatePaginationProgress(node, visible, total) {
        const $bar = node.find('.ss360-pagination__progress-bar--active');
        const num = Math.min(100, Math.max(Math.ceil(visible / total * 100), 5));
        $bar.css('width', `${num}%`);
    },
    darkenColorHex,
    lightenColorHex,
    shouldSearchPost,
    convertToPost,
    getPriceAndCurrency(priceDataPoint) {
        let price;
        let priceUnit;
        if (priceDataPoint.parsedValue !== undefined && priceDataPoint.parsedValue !== null) {
            price = priceDataPoint.parsedValue;
        }
        else if (priceDataPoint.value !== undefined && priceDataPoint.value !== null) {
            let parsedPrice = parseFloat(priceDataPoint.value);
            if (isNaN(parsedPrice)) {
                parsedPrice = parseFloat(priceDataPoint.value.replace(/,/g, '.').replace(/\$/g, '').replace(/€/g, ''));
            }
            if (!isNaN(parsedPrice)) {
                price = parsedPrice;
            }
        }
        if (priceDataPoint.unit !== undefined && priceDataPoint.unit !== null) {
            priceUnit = priceDataPoint.unit;
        }
        else if (priceDataPoint.value !== undefined && priceDataPoint.value !== null) {
            priceUnit = priceDataPoint.value.replace(/[0-9.,]/g, '').replace(/\./g, '').replace(/,/g, '').trim();
        }
        if (price) {
            price = Math.round(price * 100) / 100; // get rid of weird rounding
        }
        return { price, currency: priceUnit };
    },
    getDataPoint(suggest, dataPointName, settingName) {
        const settingsFallback = dataPointName === undefined && settingName !== undefined;
        const dataPoints = suggest.getDataPoints();
        for (let i = 0; i < dataPoints.length; i++) {
            const dataPoint = dataPoints[i];
            if (!settingsFallback && dataPoint.key === dataPointName) {
                return dataPoint;
            }
            if (settingsFallback && (dataPoint.settings || []).indexOf(settingName) !== -1) {
                return dataPoint;
            }
        }
        return undefined;
    },
    getSortingOptionMap(sortingOptions, appendName = false) {
        return sortingOptions.reduce((acc, option) => {
            if ((typeof option) === 'string') {
                // @ts-ignore
                const so = option;
                acc[so] = so;
            }
            else {
                // @ts-ignore
                const so = option;
                let key = so.key;
                if (so.sort !== undefined) {
                    key = `${key}_${so.sort}`;
                }
                if (appendName && so.name !== undefined) {
                    key = `${key}_${so.name}`;
                }
                acc[key] = option;
            }
            return acc;
        }, {});
    },
    isUsdOrPound,
    addUnitToNumber(num, unit = '') {
        if (isUsdOrPound(unit)) {
            return `${unit}${num}`.trim();
        }
        return `${num} ${unit}`.trim();
    }
};
export default Helper;
