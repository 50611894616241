'use strict';
var FullScreenTransition;
(function (FullScreenTransition) {
    FullScreenTransition["Fade"] = "fade";
})(FullScreenTransition || (FullScreenTransition = {}));
var CtaDirection;
(function (CtaDirection) {
    CtaDirection["Column"] = "column";
    CtaDirection["Row"] = "row";
})(CtaDirection || (CtaDirection = {}));
export var GuidedQuestionImageSize;
(function (GuidedQuestionImageSize) {
    GuidedQuestionImageSize["Icon"] = "icon";
    GuidedQuestionImageSize["Thumbnail"] = "thumbnail";
    GuidedQuestionImageSize["Banner"] = "banner";
    GuidedQuestionImageSize["None"] = "none";
})(GuidedQuestionImageSize || (GuidedQuestionImageSize = {}));
export var GuidedQuestionPosition;
(function (GuidedQuestionPosition) {
    GuidedQuestionPosition["Top"] = "top";
    GuidedQuestionPosition["Left"] = "left";
})(GuidedQuestionPosition || (GuidedQuestionPosition = {}));
