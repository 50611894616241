'use strict';
import Helper from '../utils/Helper';
import sxQuery from '../../sxQuery/sxQuery';
import NavigationPosition from '../enums/NavigationPosition';
import ResultIntegrationType from '../enums/ResultIntegrationType';
const Smart404 = {
    checkAndHandle(context) {
        const { pluginConfiguration } = context;
        const smart404Config = pluginConfiguration.smart404;
        let is404 = smart404Config.cssIdentifier !== undefined
            ? sxQuery(smart404Config.cssIdentifier, true).length > 0 // is a 404 selector present?
            : document.querySelector('title').text.toLowerCase().indexOf(smart404Config.identifier.toLowerCase()) > -1; // does the title contain identifier?
        if (is404) {
            is404 = window.location.search.indexOf(`${encodeURIComponent(pluginConfiguration.results.searchQueryParamName)}=`) === -1; // we already have a query param query - don't use smart404 as ss360 is currently single instance and this causes conflicts
        }
        if (is404) {
            context.setIsSmart404(true);
            const pluginSettings = pluginConfiguration;
            const configCache = {
                showErrors: pluginSettings.showErrors,
                results: Helper.copyObject(pluginConfiguration.results),
                layout: Helper.copyObject(pluginConfiguration.layout),
                contentGroups: Helper.copyObject(pluginConfiguration.contentGroups),
                filters: Helper.copyObject(pluginConfiguration.filters)
            };
            const integrationType = pluginConfiguration.results.integrationType;
            pluginSettings.showErrors = false;
            context.core.changeConfig('results.embedConfig', {
                contentBlock: smart404Config.resultSelector
            });
            context.core.changeConfig('results.integrationType', sxQuery(smart404Config.resultSelector, true).length > 0 ? ResultIntegrationType.Embed : ResultIntegrationType.Layover);
            const resultConfig = pluginSettings.results;
            const layoutConfig = pluginSettings.layout;
            resultConfig.caption = smart404Config.caption;
            resultConfig.group = false;
            resultConfig.queryCorrectionText = '';
            resultConfig.queryCorrectionRewrite = '';
            pluginSettings.contentGroups.otherName = '';
            const num = Math.min(smart404Config.num, 12);
            resultConfig.num = num;
            resultConfig.moreResultsPagingSize = num;
            resultConfig.highlightQueryTerms = true;
            layoutConfig.desktop.showDataPoints = false;
            layoutConfig.desktop.showUrl = true;
            layoutConfig.mobile.showDataPoints = false;
            layoutConfig.mobile.showUrl = true;
            layoutConfig.navigation.position = NavigationPosition.None;
            pluginSettings.filters.enabled = false;
            // get and clean up URL to form search query
            const pathParts = document.location.pathname.split('/').filter((part) => part !== '');
            if (pathParts.length > 0) {
                const pathQuery = pathParts[Math.max(0, pathParts.length - 1)].replace(/\.html?s?|\.php3?|\.aspx|\.jsp/, '').replace('404', '').replace(/[/-]/gi, ' ');
                // show and set config to previous settings
                setTimeout(() => {
                    context.core.showResults(pathQuery, {
                        sort: '_relevance',
                        shouldPushState: false,
                        callback: () => {
                            Object.keys(configCache).forEach((key) => {
                                pluginSettings[key] = configCache[key];
                            });
                            context.core.changeConfig('results.embedConfig', configCache.results.embedConfig);
                            context.core.changeConfig('results.integrationType', integrationType);
                            context.setIsSmart404(false);
                        },
                        submitSource: 'smart404'
                    });
                }, 0);
            }
            return true;
        }
        return false;
    }
};
export default Smart404;
