import MediaQueryMatchType from "../../sxQuery/MediaQueryMatchType";
import SxQueryUtils from "../../sxQuery/SxQueryUtils";
import sxQuery from "../../sxQuery/sxQuery";
import FilterPosition from "../enums/FilterPosition";
import LayoutType from "../enums/LayoutType";
import NavigationPosition from "../enums/NavigationPosition";
import RelatedQueriesPosition from "../enums/RelatedQueriesPosition";
import SearchResultType from "../enums/SearchResultType";
import { AllResultsStrategy, NavigationType } from "../interface/LayoutConfiguration";
import UiHelper from "../ui/UiHelper";
import Helper from "../utils/Helper";
import StyleApplier from "./StyleApplier";
const Frame = {
    SEARCH_BAR: 'ss360-frame--search-bar',
    RESULT_HEADING: 'ss360-frame--result-heading',
    RESULT_HEADING_IN_CONTENT: 'ss360-frame--content-result-heading',
    NAV: 'ss360-frame--nav',
    NAV_LEFT: 'ss360-frame--nav-left',
    FILTER_LEFT: 'ss360-frame--filter-left',
    FILTER_TOP: 'ss360-frame--filter-top',
    RESULTS: 'ss360-frame--results',
    RELATED_QUERIES: 'ss360-frame--related-queries',
    RELATED_QUERIES_TOP: 'ss360-frame--related-queries-top',
    RELATED_QUERIES_BOTTOM: 'ss360-frame--related-queries-bottom',
    FILTER_DELETE: 'ss360-frame--delete-bar',
    FILTER_DELETE_BOTTOM: 'ss360-frame--delete-bar-bottom',
    SORT_LEFT: 'ss360-frame--sort-left',
    SORT_RIGHT: 'ss360-frame--sort-right',
    LAYOUT_SWITCH_RIGHT: 'ss360-frame--layout-right',
    LAYOUT_SWITCH_LEFT: 'ss360-frame--layout-left',
    SORT_AND_FILTER: 'ss360-frame--sort-and-filter',
    SORT_AND_LAYOUT: 'ss360-frame--sort-and-layout',
    GUIDED_QUESTIONS: 'ss360-frame--guided-questions',
    GUIDED_QUESTIONS_LEFT: 'ss360-frame--guided-questions-left',
    BANNER: 'ss360-frame--banner',
    NO_RESULTS: 'ss360-frame--no-results',
    QUERY_CORRECTION: 'ss360-frame--query-correction',
    COMPARISON: 'ss360-frame--comparison',
    MINI_PDP: 'ss360-frame--mini-pdp',
    PAGE_SIZE_LEFT: 'ss360-frame--page-size-left',
    PAGE_SIZE_RIGHT: 'ss360-frame--page-size-right',
    BACK_TO_TOP: 'ss360-frame--back-to-top'
};
const frameDependencies = {};
frameDependencies[Frame.SORT_LEFT] = Frame.SORT_AND_FILTER;
frameDependencies[Frame.PAGE_SIZE_LEFT] = Frame.SORT_AND_FILTER;
frameDependencies[Frame.LAYOUT_SWITCH_RIGHT] = Frame.SORT_AND_FILTER;
frameDependencies[Frame.FILTER_TOP] = Frame.SORT_AND_FILTER;
frameDependencies[Frame.SORT_RIGHT] = Frame.SORT_AND_LAYOUT;
frameDependencies[Frame.PAGE_SIZE_RIGHT] = Frame.SORT_AND_LAYOUT;
frameDependencies[Frame.LAYOUT_SWITCH_LEFT] = Frame.SORT_AND_LAYOUT;
const nonClearableFrames = [];
Object.values(frameDependencies).forEach((frame) => {
    if (nonClearableFrames.indexOf(frame) === -1) {
        nonClearableFrames.push(frame);
    }
});
const needsSidebar = (frame) => frame === Frame.NAV_LEFT || frame === Frame.FILTER_LEFT || frame === Frame.GUIDED_QUESTIONS_LEFT;
class LayoutFrame {
    constructor(context) {
        this.attachedLayerClassNames = [];
        sxQuery(`#${context.layerId}`).remove();
        const pluginConfiguration = context.pluginConfiguration;
        const filtersConfiguration = pluginConfiguration.filters;
        const tagName = pluginConfiguration.accessibility.isMainContent ? 'main' : 'section';
        const layout = pluginConfiguration.layout;
        const integrationType = Helper.getIntegrationType(pluginConfiguration.results);
        const isFullscreen = integrationType === SearchResultType.Fullscreen;
        const layerClass = ['ss360-layer'];
        if (isFullscreen) {
            layerClass.push('ss360-layer--fullscreen');
        }
        const layerId = context.layerId;
        const layerContentStyle = integrationType === SearchResultType.Layover ? ' tabindex="-1" style="overflow-x:auto;overflow-y:auto;max-height:calc(100%-25px)"' : '';
        const layerContentClass = [];
        if (layout.desktop.type !== LayoutType.Grid) {
            layerContentClass.push(`ss360-${layout.desktop.type}--lg`);
        }
        if (layout.mobile.type !== LayoutType.Grid) {
            layerContentClass.push(`ss360-${layout.mobile.type}--sm`);
        }
        if (layout.navigation.showAllResultsTab && layout.navigation.type === NavigationType.Tabs && layout.navigation.allResultsStrategy === AllResultsStrategy.Grouped) {
            layerClass.push('ss360-layer--grouped-all-results');
        }
        this.filterLayerId = context.filterLayerId;
        this.topFilterLayerId = context.filterTopLayerId;
        this.frame = sxQuery(`
            <${tagName} id="${layerId}" class="${layerClass.join(' ')} ss360-n-section" style="display:none;">
                <section role="presentation" style="display:none" class="ss360-flex ss360-n-section ss360-custom-search ${Frame.SEARCH_BAR}"></section>
                <div role="presentation" style="display:none" class="${Frame.RESULT_HEADING}"></div>
                <nav role="presentation" style="display:none" class="ss360-nav ss360-nav--top ss360-n-section ${Frame.NAV}"></nav>
                <section role="presentation" style="display:none" class="ss360-related-queries ss360-n-section ${Frame.RELATED_QUERIES_TOP}"></section>
                <section class="ss360-layer__content ss360-n-section ${layerContentClass.join(' ')}" aria-labelledby="${context.searchResultHeadingId}"${layerContentStyle}>
                    <div class="ss360-layer__sidebar" style="display:none">
                        <section role="presentation" style="display:none" class="ss360-n-section ss360-guided-questions ss360-guided-questions--left ${Frame.GUIDED_QUESTIONS_LEFT}"></section>
                        <nav role="presentation" style="display:none" class="ss360-nav ss360-nav--left ss360-n-section ${Frame.NAV_LEFT}"></nav>
                        <section role="presentation" style="display:none" id="${this.filterLayerId}" class="ss360-n-section ss360-filter ss360-filter--left ${Frame.FILTER_LEFT}"></section>
                    </div>
                    <div class="ss360-layer__main">
                        <div role="presentation" style="display:none" class="${Frame.RESULT_HEADING_IN_CONTENT}"></div>
                        <section role="presentation" style="display:none" class="ss360-related-queries ss360-n-section ${Frame.RELATED_QUERIES}"></section>
                        <section role="presentation" style="display:none" class="ss360-n-section ss360-guided-questions ${Frame.GUIDED_QUESTIONS}"></section>
                        <section role="presentation" style="display:none" class="ss360-n-section ss360-filter__delete-bar ${Frame.FILTER_DELETE}"></section>
                        <div role="presentation" style="display:none" class="${Frame.SORT_AND_FILTER}">
                            <div class="ss360-filter-top-wrapper">
                                <section role="presentation" style="display:none" id="${this.topFilterLayerId}" class="ss360-n-section ss360-filter ss360-filter--top ${Frame.FILTER_TOP}"></section>
                                <div class="ss360-top-sort-and-layout">
                                    <section role="presentation" style="display:none" class="ss360-n-section ss360-sorting ${Frame.SORT_LEFT}"></section>
                                    <section role="presentation" style="display:none" class="ss360-n-section ss360-page-size ${Frame.PAGE_SIZE_LEFT}"></section>
                                    <fieldset role="presentation" style="display:none" class="ss360-list-grid-toggle ${Frame.LAYOUT_SWITCH_RIGHT}"></fieldset>
                                </div>
                            </div>
                        </div>
                        <section role="presentation" style="display:none" class="ss360-n-section ss360-filter__delete-bar ${Frame.FILTER_DELETE_BOTTOM}"></section>
                        <div role="presentation" style="display:none" class="${Frame.SORT_AND_LAYOUT}">
                            <section role="presentation" style="display:none" class="ss360-n-section ss360-sorting ${Frame.SORT_RIGHT}"></section>
                            <section role="presentation" style="display:none" class="ss360-n-section ss360-page-size ${Frame.PAGE_SIZE_RIGHT}"></section>
                            <fieldset role="presentation" style="display:none" class="ss360-list-grid-toggle ${Frame.LAYOUT_SWITCH_LEFT}"></fieldset>
                        </div>
                        <div role="presentation" class="ss360-filter__backdrop"></div>
                        <div role="presentation" style="display:none" class="ss360-banner ${Frame.BANNER}"></div>
                        <div role="presentation" style="display:none" class="${Frame.QUERY_CORRECTION}"></div>
                        <div role="presentation" style="display:none" class="${Frame.RESULTS}"></div>
                        <div role="presentation" style="display:none" id="${context.noResultsLayerId}" class="ss360-no-results ${Frame.NO_RESULTS}"></div>
                        <div role="presentation" style="display:none" class="${Frame.COMPARISON}"></div>
                        <div role="presentation" style="display:none" class="${Frame.MINI_PDP}"></div>
                        <div role="presentation" style="display:none" class="${Frame.BACK_TO_TOP}"></div>
                        <section role="presentation" style="display:none" class="ss360-related-queries ss360-n-section ${Frame.RELATED_QUERIES_BOTTOM}"></section>
                    </div>
                </section>
            </${tagName}>
        `);
        this.frame.attr('aria-label', pluginConfiguration.results.searchResultsLayerLabel);
        this.frame.find(`.${Frame.NAV},.${Frame.NAV_LEFT}`).attr('aria-label', pluginConfiguration.layout.navigation.navigationLabel);
        this.frame.find(`.${Frame.FILTER_LEFT}`).attr('aria-label', pluginConfiguration.filters.label);
        this.frame.find(`.${Frame.RELATED_QUERIES}`).attr('aria-label', pluginConfiguration.results.relatedQueriesTitle);
        this.frame.find(`.${Frame.FILTER_DELETE}`).attr('aria-label', pluginConfiguration.filters.selectedFilterOptionsLabel);
        this.frame.find(`.${Frame.FILTER_DELETE_BOTTOM}`).attr('aria-label', pluginConfiguration.filters.selectedFilterOptionsLabel);
        this.frame.find(`.${Frame.SORT_LEFT},.${Frame.SORT_RIGHT}`).attr('aria-label', pluginConfiguration.results.sortingLabel);
        this.frame.find(`.${Frame.PAGE_SIZE_LEFT},.${Frame.PAGE_SIZE_RIGHT}`).attr('aria-label', pluginConfiguration.results.pageSizeSectionLabel);
        this.removeFrameIfFalse(filtersConfiguration.enabled, [Frame.FILTER_LEFT, Frame.FILTER_TOP]);
        this.removeFrameIfFalse(pluginConfiguration.results.showRelatedQueries, [Frame.RELATED_QUERIES_TOP, Frame.RELATED_QUERIES, Frame.RELATED_QUERIES_BOTTOM]);
        this.removeFrameIfFalse(pluginConfiguration.layout.showListGridToggle, [Frame.LAYOUT_SWITCH_LEFT, Frame.LAYOUT_SWITCH_RIGHT]);
        this.removeFrameIfFalse(pluginConfiguration.results.showGuidedQuestions, [Frame.GUIDED_QUESTIONS]);
        this.removeFrameIfFalse(pluginConfiguration.layout.navigation.position !== NavigationPosition.None, [Frame.NAV, Frame.NAV_LEFT]);
        this.removeFrameIfFalse(pluginConfiguration.miniPDP.enabled, [Frame.MINI_PDP]);
    }
    removeFrameIfFalse(condition, frameTypes) {
        if (!condition) {
            frameTypes.forEach((frameType) => this.frame.find(`.${frameType}`).remove());
        }
    }
    appendToFrame(frameType, content, role = null) {
        const frame = this.frame.find(`.${frameType}`);
        if (frame.length === 0) {
            return;
        }
        frame.append(content);
        frame.css('display', null);
        frame.attr('role', role);
        if (needsSidebar(frameType)) {
            this.frame.find('.ss360-layer__sidebar').css('display', null);
            this.frame.find('.ss360-layer__content').addClass('ss360-layer__content--has-sidebar');
        }
        const parentFrameType = frameDependencies[frameType];
        if (parentFrameType !== undefined) {
            const parentFrame = this.frame.find(`.${parentFrameType}`);
            parentFrame.css('display', null);
            parentFrame.attr('role', null);
        }
    }
    detachFrame(frameType) {
        const frame = this.frame.find(`.${frameType}`);
        if (frame.length === 0) {
            return;
        }
        if (nonClearableFrames.indexOf(frameType) === -1) {
            frame.html('');
        }
        frame.css('display', 'none');
        frame.attr('role', 'presentation');
    }
    appendToSearchBarFrame(content, noMargin = false) {
        this.appendToFrame(Frame.SEARCH_BAR, content, 'search');
        if (noMargin) {
            this.frame.find(`.${Frame.SEARCH_BAR}`).addClass('ss360-custom-search--no-margin');
        }
    }
    appendToHeadingFrame(content, aboveContent = false) {
        this.appendToFrame(aboveContent ? Frame.RESULT_HEADING : Frame.RESULT_HEADING_IN_CONTENT, content);
    }
    appendToNavFrame(content, left = false) {
        this.appendToFrame(left ? Frame.NAV_LEFT : Frame.NAV, content, 'navigation');
    }
    appendToFilterFrame(content, top = false) {
        this.appendToFrame(top ? Frame.FILTER_TOP : Frame.FILTER_LEFT, content, 'search');
    }
    detachFilterFrame(top = false) {
        this.detachFrame(top ? Frame.FILTER_TOP : Frame.FILTER_LEFT);
        this.hideSidebarIfNotAvailable();
    }
    appendToResultsFrame(content) {
        this.appendToFrame(Frame.RESULTS, content);
    }
    detachResults() {
        this.detachFrame(Frame.RESULTS);
    }
    appendToRelatedQueriesFrame(content, position) {
        let frame = Frame.RELATED_QUERIES;
        if (position === RelatedQueriesPosition.AboveResultLayer) {
            frame = Frame.RELATED_QUERIES_TOP;
        }
        else if (position === RelatedQueriesPosition.BelowResultLayer) {
            frame = Frame.RELATED_QUERIES_BOTTOM;
        }
        this.appendToFrame(frame, content, 'search');
    }
    appendToFilterDeleteBar(content, bottom = false) {
        this.appendToFrame(bottom ? Frame.FILTER_DELETE_BOTTOM : Frame.FILTER_DELETE, content, 'search');
    }
    appendToLayoutSwitchFrame(content, isRight = true) {
        this.appendToFrame(isRight ? Frame.LAYOUT_SWITCH_RIGHT : Frame.LAYOUT_SWITCH_LEFT, content);
    }
    appendToSortingFrame(content, isLeft = true) {
        this.appendToFrame(isLeft ? Frame.SORT_LEFT : Frame.SORT_RIGHT, content, 'search');
    }
    appendToPageSizeFrame(content, isLeft = true) {
        this.appendToFrame(isLeft ? Frame.PAGE_SIZE_LEFT : Frame.PAGE_SIZE_RIGHT, content, 'search');
    }
    appendToGuidedQuestionsFrame(content, top = true) {
        this.appendToFrame(top ? Frame.GUIDED_QUESTIONS : Frame.GUIDED_QUESTIONS_LEFT, content, 'search');
    }
    appendToBannerFrame(content) {
        this.appendToFrame(Frame.BANNER, content);
    }
    appendToNoResultsFrame(content) {
        this.appendToFrame(Frame.NO_RESULTS, content);
    }
    appendToQueryCorrectionFrame(content) {
        this.appendToFrame(Frame.QUERY_CORRECTION, content);
    }
    appendToComparisonFrame(content) {
        this.appendToFrame(Frame.COMPARISON, content);
        this.frame.find(`.${Frame.COMPARISON}`).addClass(`${Frame.COMPARISON}--active`);
    }
    appendToMiniPDPFrame(content) {
        this.appendToFrame(Frame.MINI_PDP, content);
    }
    appendToBackToTopFrame(content) {
        this.appendToFrame(Frame.BACK_TO_TOP, content);
    }
    detachGuidedQuestionsFrame(top = true) {
        this.detachFrame(top ? Frame.GUIDED_QUESTIONS : Frame.GUIDED_QUESTIONS_LEFT);
    }
    detachFilterDeleteBar() {
        this.detachFrame(Frame.FILTER_DELETE);
        this.detachFrame(Frame.FILTER_DELETE_BOTTOM);
    }
    detachTopHeadingFrame() {
        this.detachFrame(Frame.RESULT_HEADING);
    }
    detachSearchBarFrame() {
        this.detachFrame(Frame.SEARCH_BAR);
    }
    detachNoResultsFrame() {
        this.detachFrame(Frame.NO_RESULTS);
    }
    detachNavigation() {
        this.detachFrame(Frame.NAV);
        this.detachFrame(Frame.NAV_LEFT);
        this.hideSidebarIfNotAvailable();
    }
    detachSorting() {
        this.detachFrame(Frame.SORT_LEFT);
        this.detachFrame(Frame.SORT_RIGHT);
    }
    detachPageSize() {
        this.detachFrame(Frame.PAGE_SIZE_LEFT);
        this.detachFrame(Frame.PAGE_SIZE_RIGHT);
    }
    detachLayoutSwitch() {
        this.detachFrame(Frame.LAYOUT_SWITCH_LEFT);
        this.detachFrame(Frame.LAYOUT_SWITCH_RIGHT);
    }
    detachGuidedQuestions() {
        this.detachFrame(Frame.GUIDED_QUESTIONS);
        this.detachFrame(Frame.GUIDED_QUESTIONS_LEFT);
    }
    detachRelatedQueries() {
        this.detachFrame(Frame.RELATED_QUERIES);
        this.detachFrame(Frame.RELATED_QUERIES_TOP);
        this.detachFrame(Frame.RELATED_QUERIES_BOTTOM);
    }
    detachHeading() {
        this.detachFrame(Frame.RESULT_HEADING);
        this.detachFrame(Frame.RESULT_HEADING_IN_CONTENT);
        this.detachFrame(Frame.QUERY_CORRECTION);
    }
    detachBanner() {
        this.detachFrame(Frame.BANNER);
    }
    detachComparison() {
        this.detachFrame(Frame.COMPARISON);
        this.frame.find(`.${Frame.COMPARISON}`).removeClass(`${Frame.COMPARISON}--active`);
    }
    detachMiniPDP() {
        this.detachFrame(Frame.MINI_PDP);
    }
    detachBackToTop() {
        this.detachFrame(Frame.BACK_TO_TOP);
    }
    hideSidebarIfNotAvailable() {
        const sidebar = this.frame.find('.ss360-layer__sidebar');
        let allHidden = true;
        sidebar.children()._it((child) => {
            allHidden = allHidden && sxQuery(child).css('display') === 'none';
        });
        if (allHidden) {
            sidebar.hide();
        }
    }
    clear() {
        [...this.attachedLayerClassNames].forEach((className) => this.removeClass(className));
        // @ts-ignore
        Object.keys(Frame).forEach((frame) => this.detachFrame(Frame[frame]));
        this.frame.find('.ss360-layer__sidebar').hide();
        this.frame.find('.ss360-layer__content').removeClass('ss360-layer__content--has-sidebar');
    }
    getHeadingFrame() {
        return this.frame.find(`.${Frame.RESULT_HEADING},.${Frame.RESULT_HEADING_IN_CONTENT}`);
    }
    getSearchBox() {
        return this.frame.find(`.${Frame.SEARCH_BAR}`).find('.ss360-custom-search__searchbox:not(.ss360-no-results__search-box)');
    }
    getResultGroups(groupKey) {
        return this.frame.find(`.${Frame.RESULTS}`).find(`.ss360-group${groupKey ? `-${groupKey}` : ''}`);
    }
    addClass(className) {
        this.attachedLayerClassNames.push(className);
        this.frame.addClass(className);
    }
    removeClass(className) {
        this.frame.removeClass(className);
        const idx = this.attachedLayerClassNames.indexOf(className);
        if (idx !== -1) {
            this.attachedLayerClassNames.splice(idx, 1);
        }
    }
    getLayerContent() {
        return this.frame.find('.ss360-layer__content');
    }
    getVisibleResults(resultGroupKey, includeAll = true) {
        let $node = resultGroupKey ? this.getResultGroups(resultGroupKey) : this.frame;
        if (resultGroupKey === undefined && !includeAll) {
            $node = this.frame.find('.ss360-group:not(.ss360-group-ss360_all_results)');
        }
        return $node.find('.ss360-suggests:not(.ss360-suggests--hidden)');
    }
    getFocusableElement() {
        return this.frame.find('a, button');
    }
    getTopNavigation() {
        return this.frame.find('.ss360-nav--top');
    }
    focusFirstResult() {
        let $groupNodes = this.frame.find('.ss360-group--active');
        if ($groupNodes.length === 0) {
            $groupNodes = $groupNodes.find('.ss360-group');
        }
        sxQuery($groupNodes.find('.ss360-suggests:not(.ss360-suggests--hidden):first').find('.ss360-suggests__link').get(0)).focus();
    }
    focusHeading() {
        this.frame.find('.ss360-layer__heading-anchor').focus();
    }
    getDeleteBarChildCount(includeDeleteAll = true) {
        const parentSelector = `.${Frame.FILTER_DELETE}, .${Frame.FILTER_DELETE_BOTTOM}`;
        if (!includeDeleteAll) {
            return this.frame.find(parentSelector).find('.ss360-filter__delete-button:not(.ss360-filter__delete-button--all)').length;
        }
        return this.frame.find(parentSelector).children().length;
    }
    isVisible() {
        return this.frame.isVisible();
    }
    show() {
        this.frame.show();
    }
    off(event, listener) {
        this.frame.off(event, listener);
    }
    on(event, selector, listener, args) {
        this.frame.on(event, selector, listener, args);
    }
    scrollTop(offset) {
        this.frame.scrollTop(offset);
    }
    contains(el) {
        return this.frame.get(0).contains(el);
    }
    addCloseLayerButton(callback) {
        if (this.getCloseLayerButton().length === 0) {
            UiHelper.prependCloseButton(this.frame, callback);
        }
    }
    getCloseLayerButton() {
        return this.frame.find('.ss360-close-button--layer');
    }
    fadeIn(duration, callback, displayType) {
        this.frame.fadeIn(duration, callback, displayType);
    }
    attachToContentBlock(contentBlock, recreateParent = false) {
        if (this.frame.parents(contentBlock, true).length === 0) {
            if (recreateParent) {
                UiHelper.recreateNode(sxQuery(contentBlock, true));
            }
            sxQuery(contentBlock, true).html(this.frame);
        }
    }
    getMobileFilterToggle() {
        return this.frame.find('.ss360-filter__mobile-toggle');
    }
    getFilterBackdrop() {
        return this.frame.find('.ss360-filter__backdrop');
    }
    getActiveNavItem() {
        return this.frame.find('.ss360-nav__menu-item--active');
    }
    getHeadlineNode() {
        return this.frame.find('.ss360-layer__heading');
    }
    css(property) {
        return this.frame.css(property);
    }
    fadeOut() {
        this.frame.fadeOut();
    }
    attr(key, value) {
        this.frame.attr(key, value);
    }
    isMounted() {
        return this.frame.isMounted();
    }
    appendToBody() {
        const htmlBody = sxQuery('body');
        htmlBody.append(this.frame);
    }
    getFilterLayer(position) {
        const selector = position === FilterPosition.Top ? `#${this.topFilterLayerId}` : `#${this.filterLayerId}`;
        return this.frame.find(selector);
    }
    hasClass(className) {
        return this.frame.hasClass(className);
    }
    getListGridToggle() {
        return this.frame.find('ss360-list-grid-toggle');
    }
    removeSkeletAndError() {
        this.frame.find('.ss360-skeleton-part').remove();
        this.frame.find('.ss360-error').remove();
    }
    collapseSidebar() {
        this.frame.find('.ss360-layer__sidebar').addClass('ss360-layer__sidebar--collapsed');
    }
    uncollapseSidebar() {
        this.frame.find('.ss360-layer__sidebar').removeClass('ss360-layer__sidebar--collapsed');
    }
    ensureSidebarVisibility() {
        this.frame.find('.ss360-layer__sidebar').css('display', null);
    }
    setFullscreenTitle(val) {
        this.frame.find('._ss360-layer__fullscreen-heading').html(val);
    }
    prepareFullscreenLayer(config, captionHeadingLevel) {
        const searchConsole = this.frame;
        this.show();
        const { fullScreenConfig } = config.results;
        if (fullScreenConfig.transition === 'fade') {
            this.addClass('ss360-layer--fade');
        }
        if (fullScreenConfig.caption !== undefined) {
            searchConsole.prepend(`<h${captionHeadingLevel} class="ss360-layer__fullscreen-heading">${fullScreenConfig.caption}</h${captionHeadingLevel}>`);
        }
        if (fullScreenConfig.caption !== undefined) {
            this.getSearchBox().addClass('ss360-custom-search--no-margin');
        }
        UiHelper.prependCloseButton(searchConsole);
        this.appendToBody();
    }
    showFullscreenLayer(config) {
        const { results, style } = config;
        if ((results.fullScreenConfig || {}).transition === 'fade') {
            this.frame.fadeIn(style.animationSpeed);
            StyleApplier.apply(SearchResultType.Fullscreen, config.searchBox, config.style);
        }
        else {
            this.frame.animateTop('0%', style.animationSpeed);
        }
        this.getSearchBox().focus();
        const closeLayerButton = this.getCloseLayerButton();
        closeLayerButton.css('position', 'fixed');
        if (SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 968)) {
            closeLayerButton.css('right', '20px');
        }
        this.frame.addClass('ss360-layer--fullscreen-open');
        sxQuery('body').css('overflow', 'hidden');
    }
    hideFullscreenLayer(config) {
        const { results, style } = config;
        const hide = () => {
            this.frame.removeClass('ss360-layer--fullscreen-open');
            const closeLayerButton = this.getCloseLayerButton();
            closeLayerButton.css('position', 'absolute');
            closeLayerButton.css('right', '8px');
            sxQuery('body').css('overflow', 'initial');
        };
        if ((results.fullScreenConfig || {}).transition === 'fade') {
            this.frame.fadeOut(style.animationSpeed, hide);
        }
        else {
            this.frame.animateTop('-100%', style.animationSpeed);
            hide();
        }
    }
    getProductItem(suggestItem) {
        const identifiers = [suggestItem.getIdentifier()];
        suggestItem.variants.forEach((variant) => {
            if (variant.identifier !== undefined) {
                identifiers.push(variant.identifier);
            }
        });
        const $items = this.frame.find('.ss360-suggests[data-product-identifier]');
        for (let i = 0; i < $items.length; i++) {
            const item = sxQuery($items.get(i));
            if (identifiers.indexOf(item.data('productIdentifier')) !== -1) {
                return item;
            }
        }
        return undefined;
    }
    animateProductSetChange() {
        const $nodes = this.frame.find('.ss360-suggests:not(.ss360-suggests--hidden), .ss360-filter__button');
        $nodes.addClass('u-ss360-animate-change');
        setTimeout(() => {
            $nodes.removeClass('u-ss360-animate-change');
        }, 1500);
    }
}
export default LayoutFrame;
