'use strict';
import ConfigurationHelper from '../configuration/ConfigurationHelper';
const SubConfigApplier = {
    init(context) {
        const config = context.pluginConfiguration;
        const defaults = context.configDefaults;
        Object.keys(config.subConfigs).forEach((subConfigName) => {
            const subConfig = config.subConfigs[subConfigName];
            Object.keys(subConfig).forEach((key) => {
                if (defaults[key] === undefined) {
                    defaults[key] = ConfigurationHelper.getPart(config, key);
                }
            });
        });
    },
    apply(context) {
        const config = context.pluginConfiguration;
        const activeId = config.activeSubConfigId;
        if (activeId === undefined && context.areConfigDefaults)
            return;
        const defaults = context.configDefaults;
        if (!context.areConfigDefaults) { // reset defaults first, not all settings have to be overriden
            Object.keys(defaults).forEach((key) => {
                context.core.changeConfig(key, defaults[key]);
            });
        }
        context.areConfigDefaults = activeId === undefined;
        const subConfig = config.subConfigs[activeId];
        if (subConfig === undefined)
            return;
        Object.keys(subConfig).forEach((key) => {
            context.core.changeConfig(key, subConfig[key]);
        });
    }
};
export default SubConfigApplier;
