'use strict';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import LayoutType from '../enums/LayoutType';
import Helper from '../utils/Helper';
const resizeGroup = ($group) => {
    let minY = Number.POSITIVE_INFINITY;
    let maxY = -1;
    $group.find('.ss360-suggests:not(.ss360-masonry-break):not(.ss360-suggests--hidden)').each((entry) => {
        const $entry = sxQuery(entry);
        const offset = $entry.offset();
        minY = Math.min(offset.top, minY);
        maxY = Math.max(offset.top + $entry.outerHeight(), maxY);
    });
    const height = Math.ceil(maxY - minY) + 35;
    $group.height(height);
};
const getColumnCount = (gridConfig) => {
    const breakpointsDesc = Object.keys(gridConfig).map((key) => parseInt(key, 10)).sort((a, b) => b - a);
    for (let i = 0; i < breakpointsDesc.length; i++) {
        if (SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, breakpointsDesc[i])) {
            return gridConfig[breakpointsDesc[i]];
        }
    }
    return 3; // fallback, should never happen
};
const computeExpectedHeight = (group, columnCount) => {
    const heightSums = [];
    group.find('.ss360-suggests:not(.ss360-suggests--hidden)').each((el, idx) => {
        const arrIdx = idx % columnCount;
        if (heightSums[arrIdx] === undefined) {
            heightSums[arrIdx] = 0;
        }
        heightSums[arrIdx] += sxQuery(el).outerHeight();
    });
    const maxHeight = heightSums.reduce((acc, heightSum) => Math.max(acc, heightSum), 0);
    return maxHeight * 1.1;
};
const updateStyles = (gridConfig) => {
    sxQuery('#ss360-masonry-style').remove();
    const breakpointsAsc = Object.keys(gridConfig).map((key) => parseInt(key, 10)).sort((a, b) => a - b);
    let styleContent = '';
    breakpointsAsc.forEach((breakpoint, idx) => {
        const cols = gridConfig[breakpoint];
        const gaps = cols - 1;
        const columnWidth = Math.floor(100 / cols);
        let breakpointStyle = `.ss360-masonry--#BP# .ss360-suggests{width: calc(${columnWidth}% - ${gaps} * var(--ss360-spacing-sm))}`; // column width
        for (let i = 0; i < cols; i++) { // column order
            const order = i === 0 ? cols : i;
            breakpointStyle += `.ss360-masonry--#BP# .ss360-suggests:nth-of-type(${cols}n+${i}){order: ${order}}`;
        }
        const nextBreakpointQuery = idx < breakpointsAsc.length - 1 ? `and (max-width: ${(breakpointsAsc[idx + 1] - 1)}px)` : '';
        styleContent += `@media(min-width: ${breakpoint}px)${nextBreakpointQuery} and (max-width: 767px){${breakpointStyle.replace(/#BP#/g, 'sm')}}`;
        styleContent += `@media(min-width: ${breakpoint}px)${nextBreakpointQuery} and (min-width: 768px){${breakpointStyle.replace(/#BP#/g, 'lg')}}`;
    });
    sxQuery('head').append(`<style id="ss360-masonry-style">${styleContent}</style>`);
};
class Masonry {
    constructor(context) {
        const layoutConfig = context.pluginConfiguration.layout;
        const eventNamePostfix = context.generateId('ss360Masonry');
        this.groups = context.layer.get().getResultGroups();
        this.alwaysApply = layoutConfig.mobile.type === LayoutType.Masonry && layoutConfig.desktop.type === LayoutType.Masonry;
        this.layoutConfig = layoutConfig;
        let shouldApply = this.shouldApplyInCurrentContext();
        this.wasLayoutReset = false;
        this.handlers = [];
        const gridConfig = Helper.copyObject(layoutConfig.masonryCols);
        this.groups.each((group) => {
            let isRunning = false;
            const $group = sxQuery(group).find('ul.ss360-list');
            const handler = () => {
                if (isRunning)
                    return;
                if (!shouldApply) {
                    if (!this.wasLayoutReset) {
                        $group.height('auto');
                        this.wasLayoutReset = true;
                    }
                    return;
                }
                this.wasLayoutReset = false;
                $group.height(computeExpectedHeight($group, getColumnCount(gridConfig)));
                isRunning = true;
                $group.find('.ss360-suggests.ss360-masonry-break').remove();
                for (let i = 0; i < getColumnCount(gridConfig); i++) {
                    $group.append('<li class="ss360-suggests ss360-masonry-break"></li>');
                }
                setTimeout(() => {
                    $group.find('.ss360-suggests.ss360-masonry-break').remove();
                    for (let i = 0; i < getColumnCount(gridConfig); i++) {
                        $group.append('<li class="ss360-suggests ss360-masonry-break"></li>');
                    }
                    $group.height(computeExpectedHeight($group, getColumnCount(gridConfig)));
                    resizeGroup($group);
                    isRunning = false;
                }, 150);
            };
            $group.find('img').off(`load.${eventNamePostfix}, error.${eventNamePostfix}`).on(`load.${eventNamePostfix}, error.${eventNamePostfix}`, handler);
            handler();
            this.handlers.push(handler);
        });
        sxQuery(window).off(`resize.${eventNamePostfix}, orientationchange.${eventNamePostfix}`).on(`resize.${eventNamePostfix}, orientationchange.${eventNamePostfix}`, () => {
            shouldApply = this.shouldApplyInCurrentContext();
            this.handlers.forEach((handler) => { handler(); });
        });
        context.subscribe('navigationChange', 'masonry', () => {
            shouldApply = this.shouldApplyInCurrentContext();
            this.handlers.forEach((handler) => { handler(); });
        });
        this.handlers.forEach((handler) => { handler(); });
    }
    shouldApplyInCurrentContext() {
        if (this.alwaysApply) {
            return true;
        }
        if (this.layoutConfig.mobile.type === LayoutType.Masonry) {
            return SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 991);
        }
        return SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 992);
    }
}
export default {
    init(context) {
        new Masonry(context);
    },
    notifyConfigChange(layoutConfig) {
        if (layoutConfig.mobile.type === LayoutType.Masonry || layoutConfig.desktop.type === LayoutType.Masonry) {
            const gridConfig = Helper.copyObject(layoutConfig.masonryCols);
            updateStyles(gridConfig);
        }
        else {
            sxQuery('#ss360-masonry-style').remove();
        }
    }
};
