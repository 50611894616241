'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryObject from '../../sxQuery/SxQueryObject';
class InfiniteScrollObserver {
    constructor(callback) {
        this.callback = callback;
    }
    observe(group, layoutFrame) {
        const results = group.find('.ss360-suggests:not(.ss360-suggests--hidden):not(.ss360-masonry-break)');
        if (results.length === 0) { // nothing to observe
            return;
        }
        // observe the last visible result
        const observedEntry = results.get()[results.length - 1];
        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        observer.disconnect();
                        this.callback();
                    }
                });
            }, {
                rootMargin: '250px'
            });
            observer.observe(observedEntry);
        }
        else {
            let active = false;
            const $document = sxQuery(document);
            const $window = sxQuery(window);
            const $overlay = layoutFrame.hasClass('.ss360-layer--overlay') ? layoutFrame.getLayerContent() : new SxQueryObject([]);
            const eventId = `ss360InifniteScroll_${Math.round(Math.random() * 1000)}`; // to support multiple observers
            const scrollHandler = () => {
                if (active === false) {
                    active = true;
                    setTimeout(() => {
                        if ((observedEntry.getBoundingClientRect().top <= (window.innerHeight + 150) && observedEntry.getBoundingClientRect().bottom >= 0) && getComputedStyle(observedEntry).display !== 'none') {
                            $overlay.off(`scroll.${eventId}`, scrollHandler);
                            $document.off(`scroll.${eventId}`, scrollHandler);
                            $window.off(`scroll.${eventId}`, scrollHandler);
                            $window.off(`scroll.${eventId}`, scrollHandler);
                            this.callback();
                        }
                        active = false;
                    }, 200);
                }
            };
            $overlay.on(`scroll.${eventId}`, scrollHandler);
            $document.on(`scroll.${eventId}`, scrollHandler);
            $window.on(`scroll.${eventId}`, scrollHandler);
            $window.on(`scroll.${eventId}`, scrollHandler);
            scrollHandler();
        }
    }
}
export default InfiniteScrollObserver;
