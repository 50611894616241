'use strict';
import StringHelper from '../../global/StringHelper';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
const getAttributeGroupingKey = (product, key) => {
    if (key === undefined || key === null) {
        return undefined;
    }
    const values = [];
    (product.dataPoints || []).forEach((dp) => {
        if (dp.key === key && dp.value) {
            const val = `${dp.value}`.toLowerCase();
            if (values.indexOf(val) === -1) {
                values.push(val);
            }
        }
        if (dp.key === key && dp.values) {
            dp.values.forEach((val) => {
                val = `${val}`.toLowerCase();
                ;
                if (values.indexOf(val) === -1) {
                    values.push(val);
                }
            });
        }
    });
    if (values.length > 0) {
        values.sort((a, b) => {
            return a.localeCompare(b);
        });
        return values.join('_');
    }
    return undefined;
};
export default class SearchSuggest {
    constructor(data) {
        this.isRelation = false;
        this.variants = [];
        this.plain = data;
        try {
            if (data instanceof Array) {
                this.variants = JSON.parse(JSON.stringify(data));
                this.suggest = this.variants.splice(0, 1)[0];
                this.suggest.variants = this.variants;
            }
            else {
                this.suggest = data;
                this.suggest.variants = [];
            }
            this.pinned = this.suggest.pinned;
            this.relevance = this.suggest.relevance;
        }
        catch (ex) {
            // ccl
        }
    }
    setOriginalContentGroup(group) {
        this.suggest._originalGroup = group;
    }
    getOriginalContentGroup() {
        return this.suggest._originalGroup;
    }
    getLink(highlightMatchedContent = false, query, isZoovuBranding = false) {
        let link = this.suggest.link;
        if (highlightMatchedContent && link !== undefined && link.indexOf('ss360SearchTerm=') === -1 && link.indexOf('zSearchTerm=') === -1) {
            const linkParts = link.split('#');
            const linkBase = linkParts[0];
            const linkHash = linkParts.length === 2 ? `#${linkParts[1]}` : '';
            const paramChar = linkBase.indexOf('?') !== -1 ? '&' : '?';
            const term = isZoovuBranding ? 'zSearchTerm' : 'ss360SearchTerm';
            link = `${linkBase}${paramChar}${term}=${encodeURIComponent(StringHelper.unescapeHtml(query))}${linkHash}`;
        }
        return link;
    }
    getName() {
        return this.suggest.name;
    }
    getType() {
        return this.suggest.type;
    }
    getHtml() {
        return this.suggest.html;
    }
    getImage(optimized = false) {
        if (optimized) {
            const originalImage = this.getOriginalImage();
            const images = (this.suggest.images || []);
            for (let i = 0; i < images.length; i++) {
                const entry = images[i];
                if (entry !== undefined && (entry.minUrl || '').length > 0 && entry.url === originalImage) {
                    return entry.minUrl;
                }
            }
            // fallback
            const dataPoints = this.getDataPoints();
            for (let i = 0; i < dataPoints.length; i++) {
                const dp = dataPoints[i];
                if (dp.key === 'resultImageUrl' && dp.value !== undefined && dp.value !== null && dp.value.length > 0) {
                    return dp.value;
                }
            }
        }
        return this.suggest.image;
    }
    getOriginalImage() {
        return this.suggest.image;
    }
    getDataPoints(contentDataPointName) {
        const dataPoints = contentDataPointName !== undefined && !this.hasContent() ? (this.suggest.dataPoints || []).filter((dp) => dp.key !== contentDataPointName) : (this.suggest.dataPoints || []);
        return dataPoints.reduce((acc, dataPoint) => {
            if (dataPoint.values === undefined || dataPoint.values.length === 0) {
                acc.push(dataPoint);
            }
            else {
                dataPoint.values.forEach((value) => {
                    const dp = Object.assign(Object.assign({}, dataPoint), { value });
                    dp.values = undefined;
                    acc.push(dp);
                });
            }
            return acc;
        }, []);
    }
    hasImage() {
        const { image } = this.suggest;
        return image !== undefined && image !== null && image.length > 0;
    }
    setPlaceholderImage(image) {
        if (this.hasImage() || image === undefined || image === null || image.length === 0)
            return;
        this.suggest.image = image;
    }
    hasContent() {
        const content = this.suggest.content;
        return content !== undefined && content !== null && content.length > 0;
    }
    getContent(contentDataPointName) {
        if (contentDataPointName !== undefined && !this.hasContent()) {
            const dp = this.getDataPoints().filter((dp) => dp.key === contentDataPointName)[0];
            if (dp !== undefined) {
                return dp.value;
            }
        }
        return this.suggest.content;
    }
    getId() {
        return this.suggest.identifier !== undefined ? this.suggest.identifier : this.suggest.link;
    }
    getGroupId() {
        return this.suggest.groupId;
    }
    getIdentifier() {
        return this.suggest.identifier;
    }
    getAllIdentifiers() {
        const identifiers = [this.getIdentifier()];
        if (this.variants !== undefined) {
            identifiers.push(...this.variants.map((variant) => variant.identifier));
        }
        return identifiers;
    }
    getAllVariantImages(variantGroupingAttribute) {
        let result = this.variants.reduce((acc, variant) => {
            let optimizedImage;
            if (variant.images !== undefined && (variant.images || []).length > 0) {
                optimizedImage = variant.images[0].minUrl;
            }
            if ((optimizedImage || '').length === 0) {
                optimizedImage = ((variant.dataPoints || []).filter((dp) => dp.key === 'resultImageUrl' && dp.value !== undefined && dp.value !== null && dp.value.length > 0)[0] || {}).value;
            }
            if ((optimizedImage || '').length === 0) {
                optimizedImage = undefined;
            }
            acc.push({
                name: variant.name,
                image: variant.image,
                optimizedImage,
                identifier: variant.identifier || variant.name,
                link: variant.link,
                variantKey: getAttributeGroupingKey(variant, variantGroupingAttribute)
            });
            return acc;
        }, [{
                name: this.getName(),
                image: this.getOriginalImage() || this.getImage(false),
                optimizedImage: this.getImage(true),
                identifier: this.getIdentifier() || this.getName(),
                link: this.getLink(),
                variantKey: getAttributeGroupingKey(this.suggest, variantGroupingAttribute)
            }]);
        if (variantGroupingAttribute !== undefined) {
            const existingKeys = [];
            result = result.reduce((acc, entry) => {
                if (entry.variantKey === undefined || existingKeys.indexOf(entry.variantKey) === -1) {
                    if (entry.variantKey !== undefined) {
                        existingKeys.push(entry.variantKey);
                    }
                    acc.push(entry);
                }
                return acc;
            }, []);
        }
        if (this.variantsOrder !== undefined) {
            result.sort((a, b) => {
                const aIndex = this.variantsOrder.indexOf(a.identifier);
                const bIndex = this.variantsOrder.indexOf(b.identifier);
                if (aIndex === bIndex) {
                    return 0;
                }
                return aIndex - bIndex;
            });
        }
        return result;
    }
    getUniqueVariantImageCount(variantGroupingAttribute) {
        return Object.keys(this.getAllVariantImages(variantGroupingAttribute).reduce((acc, entry) => {
            acc[entry.image] = true;
            return acc;
        }, {})).length;
    }
    getImages() {
        const originalImage = this.getOriginalImage() || this.getImage(false);
        const image = this.getImage(true);
        return (this.suggest.images || []).reduce((acc, altImg) => {
            const img = altImg.url;
            if (img !== undefined && img !== null && img.length > 0 && acc.indexOf(img) === -1 && img !== originalImage && img !== image) {
                if ((altImg.minUrl || '').length > 0 && acc.indexOf(altImg.minUrl) === -1) {
                    acc.push(altImg.minUrl);
                }
                else {
                    acc.push(img);
                }
            }
            return acc;
        }, [image || originalImage]).filter((img) => img !== null && img !== undefined && img.length > 0);
    }
    equals(other) {
        if (other === this) {
            return true;
        }
        if (this.getName() !== other.getName()) {
            return false;
        }
        if (this.getImage() !== other.getImage()) {
            return false;
        }
        if (this.getContent() !== other.getContent()) {
            return false;
        }
        if (this.getLink() !== other.getLink()) {
            return false;
        }
        const thisDataPoints = this.getDataPoints();
        const otherDataPoints = other.getDataPoints();
        if (thisDataPoints.length !== otherDataPoints.length) {
            return false;
        }
        for (let i = 0; i < thisDataPoints.length; i++) {
            const a = thisDataPoints[i];
            const b = otherDataPoints[i];
            if (a.key !== b.key || a.show !== b.show || a.value !== b.value) {
                return false;
            }
        }
        return true;
    }
    isResizedImage() {
        return this.getImage(true) !== this.getImage(false);
    }
    getRelationTypes() {
        return this.suggest.relationTypes;
    }
    getColSpan(layoutConfiguration) {
        if (this.suggest.colsXs === undefined && this.suggest.colsSm === undefined && this.suggest.colsLg === undefined && this.suggest.colsXl === undefined) {
            return undefined;
        }
        const breakpoints = [{
                cols: this.suggest.colsXs,
                breakpoint: 767,
                max: layoutConfiguration.mobile.gridColsSm
            }, {
                cols: this.suggest.colsSm,
                breakpoint: 991,
                max: layoutConfiguration.mobile.gridColsMd
            }, {
                cols: this.suggest.colsLg,
                breakpoint: 1199,
                max: layoutConfiguration.desktop.gridColsLg
            }, {
                cols: this.suggest.colsXl,
                breakpoint: 9999,
                max: layoutConfiguration.desktop.gridColsXl
            }];
        for (let i = 0; i < breakpoints.length; i++) {
            const bp = breakpoints[i];
            if (bp.cols && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, bp.breakpoint)) {
                return Math.min(bp.cols, bp.max);
            }
        }
        return undefined;
    }
    getRandomId() {
        if (this.randomId === undefined) {
            this.randomId = `${Math.round(100000 * Math.random())}`;
        }
        return this.randomId;
    }
    resetRandomId() {
        this.randomId = undefined;
    }
    setVariantsOrder(order) {
        this.variantsOrder = order;
    }
    getVariantsOrder() {
        return this.variantsOrder;
    }
    getReviewData() {
        return this.suggest.reviewData;
    }
    getRatingDataPoint() {
        const reivewData = this.getReviewData();
        if (reivewData && reivewData.count > 0) {
            return {
                key: 'reviewAvg',
                value: `${reivewData.avg}`,
                show: false,
                parsedValue: reivewData.avg
            };
        }
        return undefined;
    }
    getRatingCountDataPoint() {
        const reivewData = this.getReviewData();
        if (reivewData && reivewData.count > 0) {
            return {
                key: 'reviewCount',
                value: `${reivewData.count}`,
                show: false,
                parsedValue: reivewData.count
            };
        }
        return undefined;
    }
}
