'use strict';
import SearchSuggest from './SearchSuggest';
class ResultGroup {
    constructor(name, suggests, searchSuggests) {
        this.name = name;
        if (suggests !== undefined) {
            this.suggests = suggests.map((suggest) => new SearchSuggest(suggest));
        }
        else {
            this.suggests = searchSuggests;
        }
    }
}
export default ResultGroup;
