'use strict';
import LayoutFrame from './LayoutFrame';
class Layer {
    get(preventMountedCheck = true) {
        if (this.layer === undefined || (!preventMountedCheck && !this.layer.isMounted())) {
            this.layer = new LayoutFrame(this.context);
            this.layer.appendToBody();
        }
        return this.layer;
    }
    empty() {
        if (this.layer !== undefined) {
            this.layer.detachNavigation();
            this.layer.detachResults();
            this.layer.detachNoResultsFrame();
            this.layer.detachSorting();
            this.layer.detachPageSize();
            this.layer.detachLayoutSwitch();
            this.layer.detachGuidedQuestions();
            this.layer.detachRelatedQueries();
            this.layer.detachHeading();
            this.layer.detachBanner();
            this.layer.removeSkeletAndError();
        }
    }
    setContext(context) {
        this.context = context;
    }
}
export default Layer;
