import StringHelper from "../../global/StringHelper";
import MediaQueryMatchType from "../../sxQuery/MediaQueryMatchType";
import SxQueryUtils from "../../sxQuery/SxQueryUtils";
import sxQuery from "../../sxQuery/sxQuery";
import { GuidedQuestionImageSize, GuidedQuestionPosition } from "../interface/ResultConfiguration";
import UiColors from "../styles/UiColors";
import Icons from "./Icons";
export default {
    render(guidedQuestions, context, data, preventReload = false, loadStartTs = new Date().getTime()) {
        guidedQuestions = guidedQuestions.filter((question) => question.answers.length > 0);
        const { guidedQuestionImageSize } = context.pluginConfiguration.results;
        let { guidedQuestionPosition } = context.pluginConfiguration.results;
        if (guidedQuestionPosition === GuidedQuestionPosition.Left && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 991)) {
            guidedQuestionPosition = GuidedQuestionPosition.Top;
        }
        const guidedQuestionsTicketId = data.getGuidedQuestionsTicketId();
        if (guidedQuestions.length === 0 && (guidedQuestionsTicketId === undefined || guidedQuestionsTicketId === null || preventReload)) {
            return;
        }
        const layoutFrame = context.layer.get();
        let isPlaceholder = false;
        if (guidedQuestions.length === 0) {
            isPlaceholder = true;
            guidedQuestions = [{
                    question: '',
                    answers: new Array(4).join(',').split(',').map(() => ({ answer_text: '', id: -1 }))
                }];
            const baseUrl = context.getSearchBase();
            context.setLastRequestedGuidedQuestionTicketId(guidedQuestionsTicketId);
            const isSameContext = () => context.getLastRequestedGuidedQuestionTicketId() === guidedQuestionsTicketId;
            SxQueryUtils.get(`${baseUrl}/search/questions/${guidedQuestionsTicketId}?projectId=${context.pluginConfiguration.siteId}`, (res) => {
                if (!isSameContext()) {
                    return;
                }
                if (res && res.length > 0) {
                    this.render(res, context, data, true, loadStartTs);
                }
                else {
                    layoutFrame.detachGuidedQuestionsFrame(guidedQuestionPosition === GuidedQuestionPosition.Top);
                }
            }, () => {
                if (isSameContext()) {
                    layoutFrame.detachGuidedQuestionsFrame(guidedQuestionPosition === GuidedQuestionPosition.Top);
                }
            });
        }
        guidedQuestions.forEach((question) => {
            const rowWrapper = sxQuery(`<div class="ss360-guided-questions__row ss360-guided-questions__row--${guidedQuestionPosition}"></div>`);
            if (isPlaceholder) {
                rowWrapper.attr('aria-busy', 'true');
            }
            const $title = sxQuery(`<div class="ss360-guided-questions__header"><span class="ss360-guided-questions__question">
                    ${isPlaceholder || guidedQuestionPosition === GuidedQuestionPosition.Left ? '' : Icons.getZoeIcon('', UiColors.ACCENT)}
                    ${StringHelper.escapeHtml(question.question)}
                </span></div>`);
            if (isPlaceholder) {
                $title.addClass('ss360-guided-questions__question--placeholder ss360-shimmer');
            }
            rowWrapper.append($title);
            const answerGrid = sxQuery('<div class="ss360-guided-questions__answer-grid"></div>');
            const viewId = Math.round(Math.random() * 1000000);
            let skipButton;
            question.answers.forEach((answer, idx) => {
                const imgNode = answer.image && guidedQuestionImageSize !== GuidedQuestionImageSize.None ?
                    sxQuery(`<img src="${answer.image}" class="ss360-guided-questions__answer-image ss360-guided-questions__answer-image--${guidedQuestionImageSize}" role="presentation">`)
                    : undefined;
                const tagName = isPlaceholder ? 'span' : 'button';
                const button = sxQuery(`<${tagName} class="ss360-n-button ss360-guided-questions__answer" type="button"></${tagName}>`);
                if (imgNode) {
                    button.addClass(`ss360-guided-questions__answer--image-${guidedQuestionImageSize}`);
                }
                if (imgNode && guidedQuestionImageSize === GuidedQuestionImageSize.Banner) {
                    button.append(imgNode);
                }
                const header = sxQuery('<div class="ss360-guided-questions__answer-header"></div>');
                if (imgNode && guidedQuestionImageSize === GuidedQuestionImageSize.Icon) {
                    header.append(imgNode);
                }
                header.append(`<span class="ss360-guided-questions__answer-text">${StringHelper.escapeHtml(answer.answer_text)}</span>`);
                const hintNode = answer.explanation ? sxQuery(`<span class="ss360-guided-questions__answer-hint">${StringHelper.escapeHtml(answer.explanation)}</span>`) : undefined;
                if (imgNode === undefined || guidedQuestionImageSize !== GuidedQuestionImageSize.Thumbnail) {
                    button.append(header);
                    if (hintNode) {
                        button.append(hintNode);
                    }
                }
                else {
                    button.append(imgNode);
                    const innerWrap = sxQuery('<div class="ss360-guided-questions__answer-text-container"></div>');
                    innerWrap.append(header);
                    if (hintNode) {
                        innerWrap.append(hintNode);
                    }
                    button.append(innerWrap);
                }
                if (isPlaceholder) {
                    button.addClass('ss360-guided-questions__answer--placeholder ss360-shimmer');
                }
                if (answer.id === null) {
                    button.addClass('ss360-guided-questions__answer--skip');
                }
                button.on('click', () => {
                    if (isPlaceholder) {
                        return;
                    }
                    if (context.getInsights()) {
                        context.getInsights().trackGuidedQuestionClicked(data.query, viewId, idx, answer.id);
                    }
                    const { id, short_name } = answer;
                    let filters = data.plain.activeFilterOptions;
                    const activeGuidedQuestions = data.activeGuidedQuestions || [];
                    activeGuidedQuestions.push({
                        questionId: question.id,
                        answerId: id,
                        name: short_name
                    });
                    context.core.showResults(data.query, {
                        filters,
                        sort: data.plain.sorting,
                        submitSource: 'guided-question',
                        activeGuidedQuestions
                    });
                });
                if (answer.id === null) {
                    skipButton = button;
                }
                else {
                    answerGrid.append(button);
                }
            });
            rowWrapper.append(answerGrid);
            if (skipButton !== undefined) {
                $title.append(skipButton);
            }
            if (!isPlaceholder && context.getInsights()) {
                context.getInsights().trackGuidedQuestionLoaded(data.query, question._id || question.id, viewId, new Date().getTime() - loadStartTs);
                rowWrapper.onScrolledIntoViewport(() => {
                    context.getInsights().trackGuidedQuestionViewed(data.query, viewId);
                });
            }
            layoutFrame.appendToGuidedQuestionsFrame(rowWrapper, guidedQuestionPosition === GuidedQuestionPosition.Top);
        });
    }
};
