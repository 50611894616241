'use strict';
import Logger from '../../global/Logger';
import sxQuery from '../../sxQuery/sxQuery';
import NavigatorHelper from '../utils/NavigatorHelper';
const CategorySearch = {
    checkAndHandle(context) {
        const { pluginConfiguration } = context;
        const { categorySearch } = pluginConfiguration.results;
        if (categorySearch !== undefined && categorySearch.active !== false) {
            try {
                let matchesCategoryPage = false;
                if (categorySearch.pageCssIdentifier !== undefined) {
                    matchesCategoryPage = document.querySelector(categorySearch.pageCssIdentifier) !== null;
                }
                let match = [];
                if (!matchesCategoryPage && categorySearch.urlPattern !== undefined) {
                    const regex = new RegExp(categorySearch.urlPattern);
                    match = window.location.href.match(regex);
                    matchesCategoryPage = match !== null && match[1] !== undefined;
                }
                if (!matchesCategoryPage) {
                    return false;
                }
                let filters;
                if (pluginConfiguration.filters.enabled) {
                    filters = NavigatorHelper.getFilters(pluginConfiguration.results, undefined, categorySearch.ignoredQueryParams);
                    if (filters.length === 0) {
                        filters = undefined;
                    }
                }
                let sort;
                const sortParamName = NavigatorHelper.escapeQueryParam(pluginConfiguration.results.sortingParamName);
                const queryDict = NavigatorHelper.buildQueryDict();
                if (queryDict[sortParamName] !== undefined) {
                    sort = NavigatorHelper.unescapeQueryParam(queryDict[sortParamName]);
                }
                let query = match[1];
                let domCategory = false;
                if (categorySearch.breadcrumbElementSelector !== undefined) {
                    query = sxQuery(categorySearch.breadcrumbElementSelector).get().map((el) => sxQuery(el).text()).join('#');
                    domCategory = true;
                }
                else if (categorySearch.breadcrumbSelector !== undefined) {
                    // @ts-ignore
                    query = sxQuery(categorySearch.breadcrumbSelector).text();
                    domCategory = true;
                }
                else if (categorySearch.spaceCharacter !== undefined) {
                    query = query.replace(new RegExp(categorySearch.spaceCharacter, 'g'), ' ');
                }
                if (!domCategory && query && categorySearch.queryPathSeparator !== undefined) {
                    query = query.split(categorySearch.queryPathSeparator).join('#');
                }
                if (query === undefined) {
                    return false;
                }
                if (!domCategory && query && categorySearch.queryPathSeparator !== undefined) {
                    query = query.split(categorySearch.queryPathSeparator).join('#');
                }
                if (categorySearch.replacementPatterns !== undefined) {
                    Object.keys(categorySearch.replacementPatterns).forEach((pattern) => {
                        const replacement = categorySearch.replacementPatterns[pattern];
                        query = query.replace(new RegExp(pattern, 'g'), replacement);
                    });
                }
                query = query.trim();
                const searchQuery = query.toLowerCase() === (categorySearch.allIdentifier || '').toLowerCase() ? '_#' : `_#${query}`;
                context.core.showResults(searchQuery, {
                    shouldPushState: false,
                    submitSource: 'category-search',
                    filters,
                    sort
                });
                return true;
            }
            catch (err) {
                Logger.error(err);
            }
        }
        return false;
    }
};
export default CategorySearch;
