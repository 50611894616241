'use strict';
import Logger from './Logger';
const getByKey = (sb, key) => {
    // @ts-ignore
    let data = sb.data(`ss360${key}`) || sb.data(`zoovu${key}`);
    if (data === undefined)
        return undefined;
    if (data.length === 0)
        return [];
    try {
        if (data.indexOf('"') === -1) {
            data = data.replace(/'/g, '"');
        }
        return JSON.parse(data);
    }
    catch (ex) {
        Logger.warn(ex);
    }
    return undefined;
};
const ContentGroupHelper = {
    get(sb, isSuggest = false, isPageLoad = false, allowCookies = false, context) {
        if (sb === undefined || sb.length === 0) {
            if (isPageLoad && !isSuggest) {
                const cookieVal = context.readCookie('CGResults');
                if (cookieVal !== null) {
                    try {
                        return JSON.parse(cookieVal);
                    }
                    catch (ex) {
                        // ccl
                    }
                }
            }
            return {};
        }
        const includeKey = 'Include';
        const excludeKey = 'Exclude';
        const result = {
            include: getByKey(sb, includeKey),
            exclude: getByKey(sb, excludeKey)
        };
        if (isSuggest) {
            const suggestInclude = getByKey(sb, `${includeKey}Suggest`);
            const suggestExclude = getByKey(sb, `${excludeKey}Suggest`);
            if (suggestInclude !== undefined) {
                result.include = suggestInclude;
            }
            if (suggestExclude !== undefined) {
                result.exclude = suggestExclude;
            }
        }
        if (allowCookies && !isSuggest) {
            context.createCookie('CGResults', JSON.stringify(result), 1 / 24);
        }
        return result;
    }
};
export default ContentGroupHelper;
