'use strict';
class ApiConfig {
    constructor(base, ecom = false, siteId, isFullUrl = false, suggestBaseUrl) {
        if (!ecom) {
            this.baseUrl = isFullUrl ? base : `${base}/sites`;
            this.suggestBaseUrl = isFullUrl && suggestBaseUrl !== undefined ? suggestBaseUrl : `${base}/sites/suggest`;
        }
        else {
            this.baseUrl = isFullUrl ? base : `${base}/search?projectId=${siteId}`;
            this.suggestBaseUrl = isFullUrl && suggestBaseUrl !== undefined ? suggestBaseUrl : `${base}/search/suggestions?projectId=${siteId}`;
        }
    }
}
export default ApiConfig;
export const SEARCH_BASE = 'https://global.sitesearch360.com';
export const ECOM_SEARCH_BASE = 'https://ecom.sitesearch360.com';
export const ECOM_ZOOVU_SEARCH_BASE = 'https://api.search.zoovu.com';
export const ECOM_DEV_SEARCH_BASE = 'https://dev-api-v3.semknox.com';
export const ECOM_STAGE_SEARCH_BASE = 'https://stage-api-v3.semknox.com';
