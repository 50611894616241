'use strict';
import sxQuery from '../sxQuery/sxQuery';
import StringHelper from './StringHelper';
import Logger from './Logger';
const BLOCK_START_RE = /{{#[^{}#]+}}/g;
const INVERTED_BLOCK_START_RE = new RegExp(/{{!#[^{}!#]+}}/g);
const BLOCK_CONTENT_RE = new RegExp(/{{#([^{}]+)}}/);
const INVERTED_BLOCK_CONTENT_RE = new RegExp(/{{!#([^{}]+)}}/);
const INLINE_BLOCK_RE = new RegExp(/{{#[^{}#]+#}}/);
const INLINE_BLOCK_CONTENT_RE = new RegExp(/{{#([^{}#]+)#}}/);
const INVERTED_INLINE_BLOCK_RE = new RegExp(/{{!#[^{}#!]+#!}}/);
const INVERTED_INLINE_BLOCK_CONTENT_RE = new RegExp(/{{!#([^{}#!]+)#!}}/);
const ESCAPED_VARIABLE_CONTENT_RE = /{{([^{}]+)}}/;
const UNESCAPED_VARIABLE_CONTENT_RE = /{{{([^{}]+)}}}/;
const ESCAPED_VARIABLE_RE = /{{([^{}]+)}}/g;
const UNESCAPED_VARIABLE_RE = /{{{([^{}]+)}}}/g;
const interpolate = (templateString, context) => {
    templateString = replaceBlocks(templateString, context);
    templateString = replaceInlineBlocks(templateString, context);
    templateString = replaceVariables(templateString, context, undefined);
    return templateString;
};
const replaceBlocks = (templateString, context) => {
    templateString = replaceBlock(templateString, context, BLOCK_START_RE, BLOCK_CONTENT_RE, false);
    templateString = replaceBlock(templateString, context, INVERTED_BLOCK_START_RE, INVERTED_BLOCK_CONTENT_RE, true);
    return templateString;
};
const replaceBlock = (templateString, context, re, keyRe, isInverted) => {
    const blockStarts = templateString.match(re);
    if (blockStarts !== null) {
        for (let i = 0; i < blockStarts.length; i++) {
            try {
                const match = blockStarts[i];
                const blockStart = templateString.indexOf(match);
                if (blockStart !== -1) { // this was a nested block
                    const blockKey = match.match(keyRe)[1];
                    const blockEndTemplate = `{{/${blockKey}}}`;
                    const blockEnd = blockStart + templateString.substring(blockStart).indexOf(blockEndTemplate) + blockEndTemplate.length;
                    const fullBlock = templateString.substring(blockStart, blockEnd);
                    const block = fullBlock.replace(match, '').replace(blockEndTemplate, '');
                    let innerContext = getContextPart(context, blockKey);
                    const shouldDoReplacements = isInverted ? isFalsy(innerContext) : !isFalsy(innerContext);
                    if (shouldDoReplacements) {
                        let content = '';
                        if ((innerContext instanceof Array) && !isInverted) { // not inverted, as this is an empty list
                            const isPlainArray = block.indexOf('@value') !== -1 // check for @value, just to make sure you don't break anything (and make it possibly quicker)
                                && innerContext.reduce((acc, prop) => {
                                    if (!acc)
                                        return acc;
                                    const type = (typeof prop);
                                    return type === 'string' || type === 'number';
                                }, true);
                            if (isPlainArray) {
                                innerContext = innerContext.map((prop) => ({ '@value': prop }));
                            }
                            for (let j = 0; j < innerContext.length; j++) {
                                content += interpolate(block, innerContext[j]);
                            }
                        }
                        else if ((innerContext instanceof Object)) {
                            content = interpolate(block, innerContext);
                        }
                        else {
                            content = interpolate(block, context);
                        }
                        templateString = templateString.replace(fullBlock, content);
                    }
                    else {
                        templateString = templateString.replace(fullBlock, '');
                    }
                }
            }
            catch (err) {
                Logger.warn(err);
            }
        }
    }
    return templateString;
};
const replaceInlineBlocks = (templateString, context) => {
    templateString = replaceInlineBlock(templateString, context, INLINE_BLOCK_RE, INLINE_BLOCK_CONTENT_RE, false);
    templateString = replaceInlineBlock(templateString, context, INVERTED_INLINE_BLOCK_RE, INVERTED_INLINE_BLOCK_CONTENT_RE, true);
    return templateString;
};
const replaceInlineBlock = (templateString, context, re, innerRe, isInverted) => {
    let matcherRe;
    try {
        matcherRe = new RegExp(re, 'g');
    }
    catch (ex) { // IE11
        // eslint-disable-next-line prefer-template
        matcherRe = re + 'g';
    }
    const matches = templateString.match(matcherRe);
    if (matches !== null) {
        for (let i = 0; i < matches.length; i++) {
            try {
                const match = matches[i];
                const inner = match.match(innerRe)[1].split(':');
                const pathPart = inner.splice(0, 1)[0].trim();
                const valuePart = inner.join(':').trim();
                const contextPart = getContextPart(context, pathPart);
                const value = (isInverted ? isFalsy(contextPart) : !isFalsy(contextPart)) ? valuePart : '';
                templateString = templateString.replace(match, value);
            }
            catch (err) {
                Logger.warn(err);
            }
        }
    }
    return templateString;
};
const isFalsy = (condition) => condition === undefined || condition === '' || condition === false || condition === null || condition === 'FALSE' || condition === 'false' || ((condition instanceof Array) && condition.length === 0);
const getContextPart = (context, pathString) => {
    const pathParts = pathString.split('.');
    let tempContext = context;
    for (let i = 0; i < pathParts.length; i++) {
        let pathPart = pathParts[i].trim();
        const idx = pathPart[pathPart.length - 1] === ']' && pathPart.indexOf('[') !== -1 ? parseInt((pathPart.match(/\[(\d)\]/) || [])[1], 10) : undefined;
        if (idx !== undefined) {
            pathPart = pathPart.split('[')[0];
        }
        tempContext = (tempContext instanceof Object) ? tempContext[pathPart] : undefined;
        if (tempContext === undefined) {
            return undefined;
        }
        if (idx !== undefined && (tempContext instanceof Array)) {
            tempContext = tempContext[idx];
        }
        else if (idx !== undefined) {
            return undefined;
        }
    }
    return tempContext;
};
const replaceVariables = (templateString, context, escaped) => {
    if (escaped === undefined) {
        templateString = replaceVariables(templateString, context, false); // unescaped first!
        templateString = replaceVariables(templateString, context, true);
        return templateString;
    }
    const re = escaped ? ESCAPED_VARIABLE_RE : UNESCAPED_VARIABLE_RE;
    const matchingParts = templateString.match(re);
    if (matchingParts !== null) {
        for (let i = 0; i < matchingParts.length; i++) {
            const matchingPart = matchingParts[i];
            templateString = templateString.split(matchingPart).join(getStringValue(matchingPart.match(escaped ? ESCAPED_VARIABLE_CONTENT_RE : UNESCAPED_VARIABLE_CONTENT_RE)[1], context, escaped));
        }
    }
    return templateString;
};
const getStringValue = (path, context, escape) => {
    let value = getContextPart(context, path);
    value = value !== undefined && !(value instanceof Object) ? value : '';
    if (escape) {
        value = StringHelper.escapeHtml(value);
    }
    return value;
};
class TemplateRenderer {
    constructor(templateConfig, defaultCss = true) {
        this.templateConfig = templateConfig;
        this.templateString = templateConfig.template;
        this.preRenderCallback = templateConfig.preRenderCallback;
        this.templateBuiltCallback = templateConfig.templateBuiltCallback;
        this.postRenderCallback = templateConfig.postRenderCallback;
        this.variableReplacementPattern = templateConfig.variableReplacementPattern;
        this.dataPointDefaults = templateConfig.dataPointDefaults || {};
        this.GlobalStore = {};
        this.defaultCss = defaultCss;
    }
    render(suggest, contentGroup, isHidden = false, isSuggestion = false, isRelatedContent = false) {
        let itemTemplate = this.templateString;
        const suggestObj = Object.assign(Object.assign({}, suggest.suggest), { variants: suggest.variants });
        const { preRenderCallback } = this.templateConfig;
        try {
            if (preRenderCallback !== undefined) {
                preRenderCallback(suggestObj, this.GlobalStore, contentGroup);
            }
        }
        catch (err) {
            Logger.warn(err);
        }
        // data point hash
        suggestObj.dataPointHash = suggest.getDataPoints().reduce((acc, dataPoint) => {
            if (dataPoint.key === undefined || dataPoint.key === null || dataPoint.value === undefined || dataPoint.value === null)
                return acc;
            const hashKey = dataPoint.key.split(' ').map((keyPart, idx) => {
                if (idx === 0 && keyPart.length > 0) {
                    return keyPart.toLowerCase();
                }
                if (keyPart.length > 0) {
                    return `${keyPart[0].toUpperCase()}${keyPart.substring(1).toLowerCase()}`;
                }
                return '';
            }).join('');
            if (acc[hashKey] === undefined) {
                acc[hashKey] = [];
            }
            acc[hashKey].push(dataPoint.value);
            return acc;
        }, {});
        suggestObj.isResultTypeCustom = suggest.getType() === 'custom';
        suggestObj.image = suggest.getImage(true);
        suggestObj.fallbackImage = suggest.getImage(false);
        Object.keys(this.dataPointDefaults).forEach((key) => {
            if (suggestObj.dataPointHash[key] === undefined) {
                suggestObj.dataPointHash[key] = [this.dataPointDefaults[key]];
            }
        });
        const identifierAttr = suggest.getIdentifier() !== undefined ? ` data-product-identifier="${suggest.getIdentifier()}"` : '';
        const inner = interpolate(itemTemplate, suggestObj);
        if (isSuggestion) {
            let dataTag = '';
            const link = suggestObj.link || suggest.getLink();
            const name = suggestObj.name || suggest.getName();
            if (link !== undefined) {
                dataTag += `data-href="${link}"`;
            }
            if (name !== undefined) {
                dataTag += ` data-content="${name}"`;
            }
            itemTemplate = `<article class="unibox__selectable" ${dataTag}${identifierAttr}>${inner}</article>`;
        }
        else if (!isRelatedContent) {
            const styleAttr = !isHidden || this.defaultCss ? '' : ' style="display:none;"';
            itemTemplate = `<li class="ss360-suggests${isHidden ? ' ss360-suggests--hidden' : ''}"${styleAttr}${identifierAttr}>${inner}</li>`;
        }
        else {
            itemTemplate = `<article class="ss360-related-content__item">${inner}</article>`;
        }
        if (this.templateBuiltCallback !== undefined) {
            try {
                const result = this.templateBuiltCallback(itemTemplate, suggestObj, this.GlobalStore, contentGroup);
                if (result !== undefined) {
                    itemTemplate = result;
                }
            }
            catch (err) {
                Logger.warn(err);
            }
        }
        if (this.variableReplacementPattern !== undefined) {
            try {
                itemTemplate = itemTemplate.replace(new RegExp(this.variableReplacementPattern, 'g'), '');
            }
            catch (err) {
                // ccl
            }
        }
        const item = sxQuery(itemTemplate);
        if (this.postRenderCallback !== undefined) {
            try {
                this.postRenderCallback(item, suggestObj, this.GlobalStore, contentGroup);
            }
            catch (err) {
                Logger.warn(err);
            }
        }
        return item;
    }
}
export default TemplateRenderer;
