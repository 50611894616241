'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import NavigatorHelper from '../utils/NavigatorHelper';
import Logger from '../../global/Logger';
import SearchResultType from '../enums/SearchResultType';
const Handlers = {
    onSearchButtonClick(event, unibox, callbacks) {
        try {
            let searchQuery = unibox.getText();
            let searchButton = sxQuery(event.target).get()[0];
            if ((searchButton.tagName || '').toLowerCase() === 'svg' && sxQuery(searchButton).parent().hasClass('ss360-custom-search__searchbutton')) {
                searchButton = sxQuery(searchButton).parent().get()[0];
            }
            // does the button reference a search field it should take the query from?
            let selectedSearchBox;
            const searchBoxReference = searchButton.getAttribute('ss360-search-box-id');
            if (searchBoxReference !== null && searchBoxReference !== undefined) {
                selectedSearchBox = sxQuery(`#${searchBoxReference}`);
            }
            if (selectedSearchBox !== undefined && selectedSearchBox.length > 0) {
                // @ts-ignore
                searchQuery = selectedSearchBox.val();
            }
            const enterCallback = callbacks.enter;
            if (enterCallback !== undefined) {
                enterCallback.call(searchButton, searchQuery, {
                    shouldPushState: true,
                    searchButton,
                    sbRef: selectedSearchBox !== undefined && selectedSearchBox.length > 0 ? selectedSearchBox : undefined
                });
            }
        }
        catch (ex) {
            Logger.warn(ex);
        }
        event.preventDefault();
        event.stopPropagation();
    },
    onBodyKeyDown(e, areResultsVisible, fullScreenOpen, searchResultType, context) {
        if (e.keyCode === 27 || e.which === 27 || e.key === 'Escape' || e.code === 'Escape') {
            if (areResultsVisible || fullScreenOpen) {
                e.preventDefault();
                e.stopPropagation();
            }
            if ((searchResultType === SearchResultType.Fullscreen && fullScreenOpen) || searchResultType === SearchResultType.Layover) {
                context.core.closeLayer();
            }
        }
    },
    onBodyMouseDown(e, areResultsVisible, context) {
        if (e.which !== 1 || e.buttons !== 1)
            return; // only lmb
        if (!context.pluginConfiguration.results.hideLayerOnBodyClick || !areResultsVisible) {
            return;
        }
        const searchBoxSelector = context.pluginConfiguration.searchBox.selector;
        if (searchBoxSelector && sxQuery(searchBoxSelector).is(e.target)) { // don't hide on search box focus
            return;
        }
        const $target = sxQuery(e.target);
        if ($target.attr('id') === 'unibox-mobile-search-btn') { // don't hide on mobile suggestions submit
            return;
        }
        context.setIsClosing(true);
    },
    onLayerMouseUp(context) {
        context.setIsClosing(false);
    },
    onBodyMouseUp(context) {
        if (context.isClosing) {
            context.core.closeLayer();
        }
        context.setIsClosing(false);
    },
    popstate(e, searchResultType, cachedDomElement, context) {
        const pluginConfig = context.pluginConfiguration;
        const searchBoxSelector = pluginConfig.searchBox.selector;
        const qparam = pluginConfig.results.searchQueryParamName;
        const stateUrl = e.state !== undefined && e.state !== null && e.state.Url ? e.state.Url : window.location.href;
        let hasQuery = false;
        let semanticModeQuery;
        if (pluginConfig.results.semanticMode) {
            const embedConfig = pluginConfig.results.embedConfig || { contentBlock: undefined };
            semanticModeQuery = NavigatorHelper.getSemanticModeQuery(embedConfig.contentBlock, pluginConfig.results.semanticModeParamName, pluginConfig.results);
            hasQuery = semanticModeQuery !== undefined;
        }
        else {
            hasQuery = qparam && stateUrl.indexOf(`${qparam}=`) !== -1;
        }
        const isLayover = searchResultType === 'layover';
        const $customSearchBox = context.layer.get().getSearchBox();
        if (!hasQuery && isLayover) {
            context.core.closeLayer();
        }
        else if (!hasQuery) {
            context.layer.get().fadeOut();
            if (cachedDomElement !== undefined) {
                const $contentBlock = sxQuery(pluginConfig.results.embedConfig.contentBlock, true);
                $contentBlock.append(cachedDomElement);
            }
        }
        else if (hasQuery) {
            const queryDict = NavigatorHelper.buildQueryDict();
            const searchQuery = pluginConfig.results.semanticMode ? semanticModeQuery : queryDict[qparam];
            const filters = NavigatorHelper.getFilters(pluginConfig.results, queryDict);
            context.core.showResults(searchQuery, {
                filters,
                shouldPushState: false,
                submitSource: 'popstate'
            });
            $customSearchBox.val(searchQuery);
            sxQuery(searchBoxSelector).val(searchQuery);
        }
        $customSearchBox.val('');
        sxQuery(searchBoxSelector).val('');
        return hasQuery;
    },
    searchBoxBlur(event, selectedText, logAbandon, autoBlurTime, lbctGetter, context) {
        sxQuery('#ss360Darken-input').remove();
        if (!logAbandon || (new Date().getTime() - autoBlurTime <= 200)) {
            return;
        }
        let delay = 200;
        const searchButtonSelector = context.pluginConfiguration.searchBox.searchButton;
        // was search button clicked? increase the log delay
        if (event.relatedTarget && searchButtonSelector !== undefined) {
            const searchButtons = sxQuery(searchButtonSelector, true).get();
            const relatedSb = searchButtons.filter((sb) => sb === event.relatedTarget);
            if (relatedSb.length > 0) {
                delay = 1000;
            }
        }
        // delay logging
        const startTs = new Date().getTime();
        setTimeout(() => {
            const lbct = lbctGetter();
            if ((lbct !== -1)
                && (startTs < lbct && startTs + delay > lbct)) { // check whether search button was clicked in recent time
                return;
            }
            if (context.hasInsights()) {
                context.getInsights().trackSearchBoxAbandon(selectedText, sxQuery(`#${context.uniboxId} .unibox-selectable`).length, event.target);
            }
        }, delay);
    },
    layoverResize(layoutFrame) {
        // update layover height to fixed value - ie11 fix
        const lCs = layoutFrame.getLayerContent();
        lCs.each((elem) => {
            const lC = sxQuery(elem);
            let bufferHeight = 0;
            let marginTop = 0;
            const $topNav = layoutFrame.getTopNavigation();
            const hasTopNavigation = $topNav.length > 0;
            const hasSearchField = layoutFrame.getSearchBox().length > 0;
            const navigationHeight = $topNav.outerHeight();
            if (hasTopNavigation && hasSearchField) {
                bufferHeight = 60 + navigationHeight + 10;
            }
            else if (hasTopNavigation) {
                bufferHeight = navigationHeight;
            }
            else if (hasSearchField) {
                bufferHeight = 65;
            }
            else {
                bufferHeight = 25;
                marginTop = 40;
            }
            let targetSize = lC.parents('.ss360-layer', true).height() - bufferHeight; // - bannerHeight;
            targetSize = Math.max(200, targetSize);
            if (targetSize) {
                lC.css('max-height', `${targetSize}px`);
            }
            if (marginTop) {
                lC.css('margin-top', `${marginTop}px`);
            }
        });
    },
    layoverScroll(e) {
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignore
        e.target.scrollTop = 0;
    },
    queryCorrection(event, correctedQuery, pluginConfig) {
        try {
            pluginConfig.callbacks.enter(correctedQuery);
            sxQuery(pluginConfig.searchBox.selector).val(correctedQuery);
        }
        catch (ex) {
            Logger.warn(ex);
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
    },
    queryOverride(event, correctedQuery, pluginConfig) {
        try {
            pluginConfig.callbacks.enter(correctedQuery, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
        }
        catch (ex) {
            Logger.warn(ex);
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
    }
};
export default Handlers;
