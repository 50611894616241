'use strict';
var FilterType;
(function (FilterType) {
    FilterType["Date"] = "DATE";
    FilterType["CategoryTree"] = "CATEGORY_TREE";
    FilterType["Tree"] = "TREE";
    FilterType["Color"] = "COLOR";
    FilterType["Boolean"] = "BOOLEAN";
    FilterType["NumericValues"] = "NUMERIC_VALUES";
})(FilterType || (FilterType = {}));
export default FilterType;
