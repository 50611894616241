'use strict';
import { ECOM_DEV_SEARCH_BASE, ECOM_SEARCH_BASE, ECOM_STAGE_SEARCH_BASE, ECOM_ZOOVU_SEARCH_BASE, SEARCH_BASE } from './api/ApiConfig';
import UrlBuilder from './api/UrlBuilder';
import Reporter from './reporter/Reporter';
import SS360Insights from '../insights/insights';
import Logger from '../global/Logger';
import StringHelper from '../global/StringHelper';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
import Helper from './utils/Helper';
class Context {
    constructor(urlBuilder, configuration, core, layer, isDefaultInstance = true) {
        this.filterViewGroupCache = {
            groupByKey: {},
            activeGroups: [],
            layer: undefined,
            searchQueries: {},
            expandedGroups: {},
            lastInteractedGroupKey: undefined,
            viewGroupByKey: {}
        };
        this.filterViewGroupCacheTop = {
            groupByKey: {},
            activeGroups: [],
            layer: undefined,
            searchQueries: {},
            expandedGroups: {},
            lastInteractedGroupKey: undefined,
            viewGroupByKey: {}
        };
        this.urlBuilder = urlBuilder;
        this.pluginConfiguration = configuration;
        this._isSmart404 = false;
        this.core = core;
        this.layer = layer;
        this.reporter = new Reporter(this.pluginConfiguration);
        this.isClosing = false;
        this.configDefaults = {};
        this.areConfigDefaults = true;
        this.listeners = {};
        this.isDefaultInstance = isDefaultInstance;
        this.alias = StringHelper.getSafeKey(configuration.alias || Math.round(Math.random() * 1000).toString());
        this.layerId = this.generateId('ss360-layer');
        this.filterLayerId = this.generateId('ss360-filter');
        this.filterTopLayerId = this.generateId('ss360-filter-top');
        this.customSearchBoxId = this.generateId('ss360-custom-searchbox');
        this.errorLayerId = this.generateId('ss360-error-layer');
        this.customSearchId = this.generateId('ss360-custom-search');
        this.customSearchButtonId = this.generateId('ss360-custom-searchbutton');
        this.resultsId = this.generateId('ss360-results');
        this.noResultsLayerId = this.generateId('ss360-no-results');
        this.searchResultHeadingId = this.generateId('ss360-search-result-heading');
        this.queryCorrectionId = this.generateId('ss360-query-correction');
        this.uniboxId = this.generateId('unibox-suggest-box');
        this.uniboxDarkenLayerId = this.generateId('unibox-darken-layer');
        this.uniboxControlsId = this.generateId('unibox-controls-description');
        this.uniboxStatusMessageId = this.generateId('unibox-status-message');
        this.isZoovu = false;
    }
    generateId(prefix) {
        return (this.isDefaultInstance ? prefix : `${prefix}--${StringHelper.getSafeKey(this.alias)}`);
    }
    getSearchResultType() {
        return Helper.getIntegrationType(this.pluginConfiguration.results);
    }
    setIsSmart404(val) { this._isSmart404 = val; }
    isSmart404() { return this._isSmart404; }
    setIsClosing(val) {
        this.isClosing = val;
    }
    hasInsights() {
        return this.insights !== undefined;
    }
    createInsights() {
        const cookiePostFix = this.isDefaultInstance ? '' : `--${this.alias}`;
        this.insights = new SS360Insights(this.pluginConfiguration.siteId, this.pluginConfiguration.allowCookies, this.pluginConfiguration.tracking, cookiePostFix, this.getIsZoovu() || this.pluginConfiguration.tracking.addZoovuCid);
        if (this.isDefaultInstance && !this.getIsZoovu()) {
            window.SS360Insights = this.insights;
        }
        else if (this.isDefaultInstance) {
            window.ZoovuSearchInsights = this.insights;
        }
        const { external } = this.pluginConfiguration.tracking;
        if (external !== undefined) {
            if (external.checkout !== undefined || external.productDetailPage !== undefined) {
                this.insights.setHasCheckoutTracking(true);
            }
            // @ts-ignore
            import(/* webpackChunkName: "externalTracker" */ '../insights/externalTracker').then(({ default: ExternalTracker }) => {
                if (this.externalTracker !== undefined) {
                    this.externalTracker.unbindAll();
                }
                this.externalTracker = new ExternalTracker(this.insights, this.pluginConfiguration.tracking.external);
            });
        }
    }
    getInsights() {
        return this.insights;
    }
    disableInsights() {
        if (this.isDefaultInstance) {
            delete window.SS360Insights;
            delete window.ZoovuSearchInsights;
        }
        this.insights = undefined;
        if (this.externalTracker !== undefined) {
            try {
                this.externalTracker.unbindAll();
            }
            catch (err) {
                // ccl
            }
        }
    }
    notify(event, value) {
        const { listeners } = this;
        if (listeners[event] !== undefined) {
            listeners[event].forEach((listener) => {
                try {
                    listener.handler(value);
                }
                catch (ex) {
                    Logger.warn(ex);
                }
            });
        }
    }
    subscribe(event, listenerKey, handler) {
        const listener = { key: listenerKey, handler };
        const { listeners } = this;
        let listenerFound = false;
        if (listeners[event] === undefined) {
            listeners[event] = [];
        }
        else {
            listeners[event].forEach((existingListener) => {
                if (existingListener.key === listenerKey) {
                    existingListener.handler = handler;
                    listenerFound = true;
                }
            });
        }
        if (!listenerFound) {
            listeners[event].push(listener);
        }
    }
    unsubscribe(event, listenerKey) {
        const { listeners } = this;
        if (listeners[event] !== undefined) {
            listeners[event] = listeners[event].filter((listener) => listener.key !== listenerKey);
        }
    }
    createCookie(baseKey, value, days) {
        const prefix = this.getIsZoovu() ? 'zoovu' : 'ss360';
        const cookieKey = this.generateId(`${prefix}${baseKey}`);
        SxQueryUtils.createCookie(cookieKey, value, days);
    }
    readCookie(baseKey) {
        const prefixes = this.getIsZoovu() ? ['zoovu', 'ss360'] : ['ss360', 'zoovu'];
        for (let i = 0; i < prefixes.length; i++) {
            const cookieKey = this.generateId(`${prefixes[i]}${baseKey}`);
            const value = SxQueryUtils.readCookie(cookieKey);
            if (value !== null) {
                return value;
            }
        }
        return null;
    }
    storeObject(baseKey, value) {
        const key = this.generateId(baseKey);
        SxQueryUtils._storeObject(key, value);
    }
    readObject(baseKey, defaultValue) {
        const key = this.generateId(baseKey);
        return SxQueryUtils._readObject(key, defaultValue);
    }
    getInsightsEventKey() {
        return this.generateId('ss360Insights');
    }
    getLastOpenTab() {
        return this.lastOpenTab;
    }
    setLastOpenTab(tab) {
        this.lastOpenTab = tab;
    }
    readLastOpenTab() {
        let lastOpenTab = this.readCookie('-open-tab');
        if (lastOpenTab === null && !this.pluginConfiguration.allowCookies) {
            lastOpenTab = this.getLastOpenTab();
        }
        return lastOpenTab;
    }
    setIsZoovu(isZoovu) {
        this.isZoovu = isZoovu;
    }
    getIsZoovu() {
        return this.isZoovu;
    }
    isUseCookiesForOffsets() {
        return this.pluginConfiguration.allowCookies && window.location.search.indexOf(`${this.getPageLinkQueryParam()}=1`) === -1;
    }
    isEcom() {
        return this.pluginConfiguration.ecom || this.pluginConfiguration.ecomDev || this.pluginConfiguration.ecomStage;
    }
    getPageLinkQueryParam() {
        return this.isEcom() ? 'searchPageLink' : 'ss360PageLink';
    }
    getOffsetQueryParam() {
        return this.isEcom() ? 'searchOffset' : 'ss360Offset';
    }
    getContentGroupQueryParam() {
        return this.isEcom() ? 'searchContentGroup' : 'ss360ContentGroup';
    }
    getSearchBase() {
        let baseUrl = SEARCH_BASE;
        if (this.pluginConfiguration.baseUrl !== undefined) {
            try {
                return this.pluginConfiguration.baseUrl.match(/^(https?:\/\/[^\/]+)/)[0];
            }
            catch (err) {
                // ccl
            }
        }
        if (this.pluginConfiguration.ecom) {
            baseUrl = this.getIsZoovu() ? ECOM_ZOOVU_SEARCH_BASE : ECOM_SEARCH_BASE;
        }
        else if (this.pluginConfiguration.ecomDev) {
            baseUrl = ECOM_DEV_SEARCH_BASE;
        }
        else if (this.pluginConfiguration.ecomStage) {
            baseUrl = ECOM_STAGE_SEARCH_BASE;
        }
        return baseUrl;
    }
    getLastRequestedGuidedQuestionTicketId() {
        return this.lastRequestedGuidedQuestionTicketId;
    }
    setLastRequestedGuidedQuestionTicketId(ticketId) {
        this.lastRequestedGuidedQuestionTicketId = ticketId;
    }
    getFilterViewGroupCache(top = false) {
        return top ? this.filterViewGroupCacheTop : this.filterViewGroupCache;
    }
    emitEvent(event, data) {
        if (this.pluginConfiguration.eventListener) {
            try {
                this.pluginConfiguration.eventListener(event, data);
            }
            catch (err) {
                console.error(err);
            }
        }
    }
    getMiniPDPQueryParamName() {
        return this.getIsZoovu() ? 'zSelectedProduct' : 'ss360SelectedProduct';
    }
}
export function createContext(apiConfig, configuration, core, layer, isDefaultInstance = true) {
    return new Context(new UrlBuilder(apiConfig, configuration), configuration, core, layer, isDefaultInstance);
}
export default Context;
