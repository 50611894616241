'use strict';
import StringHelper from '../../global/StringHelper';
import MediaQueryMatchType from '../../sxQuery/MediaQueryMatchType';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import FilterPosition from '../enums/FilterPosition';
import LayoutType from '../enums/LayoutType';
import UiColors from '../styles/UiColors';
let lastToggledState;
let lastToggledGroup;
const COOKIE_NAME = '-toggled-view-state';
export default {
    COOKIE_NAME,
    render(context, contentGroups = [], query = '') {
        const filters = context.pluginConfiguration.filters;
        const isRight = filters.enabled && filters.position === FilterPosition.Top;
        const layoutFrame = context.layer.get();
        layoutFrame.appendToLayoutSwitchFrame(sxQuery('<legend class="ss360-sr-only">Change display type</legend>'), isRight);
        const { accentColor } = context.pluginConfiguration.style;
        const inactiveColor = UiColors.THEME;
        const randomId = Math.round(Math.random() * 10000);
        const $listInput = sxQuery(`<input id="ss360-list-grid-toggle--list-${randomId}" class="ss360-list-grid-toggle__toggle ss360-sr-only" type="radio" name="ss360-list-grid" value="list" tabindex="-1">`);
        const $gridInput = sxQuery(`<input id="ss360-list-grid-toggle--grid-${randomId}" class="ss360-list-grid-toggle__toggle ss360-sr-only" type="radio" name="ss360-list-grid" value="grid" tabindex="-1">`);
        const $listLabel = sxQuery(`<label for="ss360-list-grid-toggle--list-${randomId}" title="List" class="ss360-list-grid-toggle__label" tabindex="0"><span class="ss360-sr-only">List</span></label>`);
        const $gridLabel = sxQuery(`<label for="ss360-list-grid-toggle--grid-${randomId}" title="Grid" class="ss360-list-grid-toggle__label" tabindex="0"><span class="ss360-sr-only">Grid</span></label>`);
        $listLabel.append(`<i role="presentation" class="ss360-list-grid-toggle__icon ss360-list-grid-toggle__icon--list"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.4 16.8a.6.6 0 0 1 .6-.6h10.7a.6.6 0 0 1 0 1.1H9a.6.6 0 0 1-.6-.5Zm0-4.8a.6.6 0 0 1 .6-.6h10.7a.6.6 0 1 1 0 1.2H9a.6.6 0 0 1-.6-.6Zm0-4.7a.6.6 0 0 1 .6-.6h10.7a.6.6 0 0 1 0 1.2H9a.6.6 0 0 1-.6-.6ZM5 8.4a1.2 1.2 0 1 0 0-2.3 1.2 1.2 0 0 0 0 2.3Zm0 4.8a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4Zm0 4.7a1.2 1.2 0 1 0 0-2.3 1.2 1.2 0 0 0 0 2.3Z" fill="${UiColors.THEME}"/></svg></i>`);
        $gridLabel.append(`<i role="presentation" class="ss360-list-grid-toggle__icon ss360-list-grid-toggle__icon--grid"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path d="M6.3 7.1a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM6.3 12.8a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM6.3 18.5a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Zm5.7 0a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6Z" stroke="${UiColors.THEME}" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></svg></i>`);
        layoutFrame.appendToLayoutSwitchFrame($listInput, isRight);
        layoutFrame.appendToLayoutSwitchFrame($listLabel, isRight);
        layoutFrame.appendToLayoutSwitchFrame($gridInput, isRight);
        layoutFrame.appendToLayoutSwitchFrame($gridLabel, isRight);
        const { layout } = context.pluginConfiguration;
        const activeLayoutConfiguration = SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 992) ? layout.desktop : layout.mobile;
        const defaultLayoutType = activeLayoutConfiguration.type;
        const isMixed = defaultLayoutType === LayoutType.Mixed;
        let activeLayoutType = defaultLayoutType;
        if (context.pluginConfiguration.allowCookies && context.readCookie(COOKIE_NAME) !== null && !isMixed) {
            activeLayoutType = context.readCookie(COOKIE_NAME);
        }
        else if (lastToggledState !== undefined && !isMixed) {
            activeLayoutType = lastToggledState;
        }
        const updateColors = (type) => {
            if (type === 'grid') {
                $gridLabel.find('svg path').attr('stroke', accentColor);
                $listLabel.find('svg path').attr('fill', inactiveColor);
            }
            else {
                $gridLabel.find('svg path').attr('stroke', inactiveColor);
                $listLabel.find('svg path').attr('fill', accentColor);
            }
        };
        const updateViewState = (type) => {
            if (type === LayoutType.Grid) {
                $gridInput.attr('checked', 'checked');
                $gridInput.get(0).checked = true;
                $listInput.removeAttribute('checked');
                $listInput.get(0).checked = false;
                $gridLabel.addClass('ss360-list-grid-toggle__label--active');
                $listLabel.removeClass('ss360-list-grid-toggle__label--active');
                updateColors('grid');
            }
            else {
                $listInput.attr('checked', 'checked');
                $listInput.get(0).checked = true;
                $gridInput.removeAttribute('checked');
                $gridInput.get(0).checked = false;
                $listLabel.addClass('ss360-list-grid-toggle__label--active');
                $gridLabel.removeClass('ss360-list-grid-toggle__label--active');
                updateColors('list');
            }
        };
        const getGroupLayout = (resultGroupName) => {
            if (!isMixed) {
                return defaultLayoutType;
            }
            if (context.pluginConfiguration.allowCookies) {
                const val = context.readCookie(`${COOKIE_NAME}--${resultGroupName}`);
                if (val !== null) {
                    return val === 'grid' ? LayoutType.Grid : LayoutType.List;
                }
            }
            return (activeLayoutConfiguration.gridContentGroups || []).indexOf(resultGroupName) !== -1 ? LayoutType.Grid : LayoutType.List;
        };
        updateViewState(activeLayoutType);
        const $layerContent = layoutFrame.getLayerContent();
        let $groupTarget = $layerContent;
        let cookiePostfix = '';
        const toggleState = (type) => {
            if (type === 'grid') {
                $groupTarget.find('.ss360-list').addClass('ss360-grid ss360-grid--sm ss360-grid--lg');
                $gridLabel.addClass('ss360-list-grid-toggle__label--active');
                $listLabel.removeClass('ss360-list-grid-toggle__label--active');
                $gridInput.attr('checked', 'checked');
                $listInput.removeAttribute('checked');
            }
            else {
                $groupTarget.find('.ss360-list').removeClass('ss360-grid ss360-grid--sm ss360-grid--lg');
                $listLabel.addClass('ss360-list-grid-toggle__label--active');
                $gridLabel.removeClass('ss360-list-grid-toggle__label--active');
                $gridInput.attr('checked', 'checked');
                $listInput.removeAttribute('checked');
            }
            updateColors(type);
            if (context.pluginConfiguration.allowCookies) {
                context.createCookie(`${COOKIE_NAME}${cookiePostfix}`, type, 7);
            }
            else {
                lastToggledState = type;
            }
        };
        $gridInput.on('change', () => {
            toggleState('grid');
        });
        $listInput.on('change', () => {
            toggleState('list');
        });
        $listLabel.on('keydown', (e) => {
            const keyCode = e.keyCode || e.which;
            if (keyCode === 32 || keyCode === 13) {
                e.stopPropagation();
                e.preventDefault();
                toggleState('list');
            }
            else if (keyCode >= 37 && keyCode <= 40) {
                e.stopPropagation();
                e.preventDefault();
                $gridLabel.focus();
                toggleState('grid');
            }
        });
        $gridLabel.on('keydown', (e) => {
            const keyCode = e.keyCode || e.which;
            if (keyCode === 32 || keyCode === 13) {
                e.stopPropagation();
                e.preventDefault();
                toggleState('grid');
            }
            else if (keyCode >= 37 && keyCode <= 40) {
                e.stopPropagation();
                e.preventDefault();
                $listLabel.focus();
                toggleState('list');
            }
        });
        if (activeLayoutType !== defaultLayoutType && !isMixed) { // make sure the view is properly switched based on the last selected option
            toggleState(activeLayoutType);
        }
        else if (isMixed) {
            const { allResultsFirst } = context.pluginConfiguration.layout.navigation;
            contentGroups.sort((a, b) => {
                if (a === b) {
                    return 0;
                }
                if (a === '*') {
                    return allResultsFirst ? -1 : 1;
                }
                if (b === '*') {
                    return allResultsFirst ? 1 : -1;
                }
                return 0;
            });
            contentGroups.forEach((group) => {
                const layout = getGroupLayout(group);
                const groupName = group !== '*' ? group : 'ss360_all_results';
                $groupTarget = $layerContent.find(`.ss360-group-${StringHelper.getSafeKey(groupName)}`);
                toggleState(layout);
            });
            const lastOpenTab = context.readLastOpenTab();
            let activeGroup = contentGroups[0];
            if (lastOpenTab !== null && lastOpenTab.split('<#>')[0] === query) {
                activeGroup = lastOpenTab.split('<#>')[1];
            }
            else if (lastOpenTab !== null && lastToggledGroup !== undefined) {
                activeGroup = lastToggledGroup;
            }
            if (activeGroup === 'ss360_all_results') {
                activeGroup = '*';
            }
            if (contentGroups.indexOf(activeGroup) === -1) {
                const safeKeyMap = contentGroups.reduce((acc, contentGroup) => {
                    acc[StringHelper.getSafeKey(contentGroup)] = contentGroup;
                    return acc;
                }, {});
                activeGroup = safeKeyMap[activeGroup] || activeGroup;
            }
            const updateActiveGroup = (group) => {
                $groupTarget = $layerContent.find(`.ss360-group-${StringHelper.getSafeKey(group === '*' ? 'ss360_all_results' : group)}`);
                cookiePostfix = `--${group}`;
                lastToggledGroup = group;
                updateViewState(getGroupLayout(group));
            };
            updateActiveGroup(activeGroup);
            context.subscribe('navigationChange', 'listGridToggle', (data) => {
                updateActiveGroup(data);
            });
        }
        const hidden = [];
        if (layout.mobile.gridColsSm === 1) {
            hidden.push('sm');
        }
        if (layout.mobile.gridColsMd === 1) {
            hidden.push('md');
        }
        if (layout.desktop.gridColsLg === 1) {
            hidden.push('lg');
        }
        if (layout.desktop.gridColsXl === 1) {
            hidden.push('xl');
        }
        const frameToggle = layoutFrame.getListGridToggle();
        hidden.forEach((val) => {
            frameToggle.addClass(`ss360-list-grid-toggle--hide-${val}`);
        });
    }
};
