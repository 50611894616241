import DomElementBuilder from "../../sxQuery/DomElementBuilder";
import Helper from "../utils/Helper";
export default {
    createScore(suggest, dataPoints) {
        const children = dataPoints.map((score) => {
            const dp = Helper.getDataPoint(suggest, score.dataPoint);
            if (dp === undefined) {
                return undefined;
            }
            const parsedValue = dp.parsedValue || parseInt(dp.value, 10);
            const maxScore = 5;
            let normalizedScore = parsedValue;
            if (score.max !== undefined && score.max !== 5) {
                const factor = maxScore / score.max;
                normalizedScore = parsedValue * factor;
            }
            const scoreBars = [];
            for (let i = 0; i < 5; i++) {
                const filledClass = i < normalizedScore ? 'ss360-score__bar--filled' : '';
                scoreBars.push(DomElementBuilder.createElement({
                    tag: 'span',
                    classList: ['ss360-score__bar', filledClass],
                    attrs: {
                        role: 'presentation'
                    }
                }));
            }
            return DomElementBuilder.createElement({
                tag: 'div',
                classList: ['ss360-score__entry'],
                children: [
                    DomElementBuilder.createElement({
                        tag: 'span',
                        classList: ['ss360-score__label'],
                        textContent: score.label
                    }),
                    DomElementBuilder.createElement({
                        tag: 'span',
                        classList: ['ss360-score__value'],
                        attrs: {
                            'aria-label': `${parsedValue}/${score.max}`
                        },
                        children: scoreBars
                    })
                ]
            });
        }).filter((child) => child !== undefined);
        if (children.length === 0) {
            return undefined;
        }
        return DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-score'],
            children
        });
    }
};
