'use strict';
import sxQuery from '../../sxQuery/sxQuery';
import SxQueryUtils from '../../sxQuery/SxQueryUtils';
import SearchSuggest from '../model/SearchSuggest';
const bindVariantEvents = (node, resultRenderer, changeCallback, context, data, resultGroup) => {
    let currentImage;
    const image = node.find('.ss360-suggests__image');
    // @ts-ignore
    const originalImage = image.attr('src') || image.data('ss360Src');
    const $variantsWrap = node.find('.ss360-suggests__variants');
    if ($variantsWrap.length === 0) {
        return;
    }
    let isBindingEvents = false;
    let lastScrollLeft;
    const bindArrowEvents = () => {
        if (isBindingEvents)
            return;
        isBindingEvents = true;
        SxQueryUtils.requestAnimationFrame(() => {
            try {
                const parentWidth = $variantsWrap.outerWidth();
                const variantsWrap = $variantsWrap.get()[0];
                const wrapScrollWidth = variantsWrap.scrollWidth;
                const $prevArrowWrap = $variantsWrap.find('.ss360-suggests__variant-arrow-wrap--prev');
                const $nextArrowWrap = $variantsWrap.find('.ss360-suggests__variant-arrow-wrap--next');
                if (parentWidth + $prevArrowWrap.outerWidth() + $nextArrowWrap.outerWidth() < wrapScrollWidth) {
                    const scrollMovement = 3 * $variantsWrap.find('.ss360-suggests__variant-button').outerWidth();
                    let isUpdating = false;
                    const onScrollChanged = (scrollLeft) => {
                        if (isUpdating)
                            return;
                        isUpdating = true;
                        SxQueryUtils.requestAnimationFrame(() => {
                            if (scrollLeft === 0) {
                                $prevArrowWrap.hide();
                            }
                            else {
                                $prevArrowWrap.css('display', 'block');
                            }
                            if (scrollLeft + parentWidth + $nextArrowWrap.outerWidth() + $prevArrowWrap.outerWidth() >= wrapScrollWidth) {
                                $nextArrowWrap.hide();
                            }
                            else {
                                $nextArrowWrap.css('display', 'block');
                            }
                            isUpdating = false;
                        });
                        lastScrollLeft = scrollLeft;
                    };
                    onScrollChanged(variantsWrap.scrollLeft);
                    $variantsWrap.off('scroll.variants').on('scroll.variants', () => {
                        onScrollChanged(variantsWrap.scrollLeft);
                    });
                    $prevArrowWrap.off('click.variants').on('click.variants', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        variantsWrap.scrollLeft = Math.max(variantsWrap.scrollLeft - scrollMovement, 0);
                    });
                    $nextArrowWrap.off('click.variants').on('click.variants', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let newScroll = variantsWrap.scrollLeft + scrollMovement;
                        const maxScroll = variantsWrap.wrapScrollWidth - parentWidth;
                        if (newScroll > maxScroll || (maxScroll - newScroll) < (scrollMovement / 2)) { // move completely to the end if almost there, give some offset to compensate for the sticky arrows
                            newScroll = maxScroll + (scrollMovement / 2);
                        }
                        variantsWrap.scrollLeft = newScroll;
                    });
                }
            }
            catch (err) {
                console.error(err);
            }
            isBindingEvents = false;
        });
    };
    bindArrowEvents();
    sxQuery(window).on('resize.variants', () => {
        bindArrowEvents();
    });
    $variantsWrap.find('.ss360-suggests__variant-image').each((el) => {
        const $el = sxQuery(el);
        $el.on('load.variants', () => {
            const groupParent = node.parents('.ss360-grid', true);
            groupParent.addClass('ss360-grid--has-variants');
            bindArrowEvents();
            $el.off('load.variants');
        });
    });
    node.find('.ss360-suggests__variant-button').each((el) => {
        const $el = sxQuery(el);
        const $variantImage = $el.find('.ss360-suggests__variant-image');
        // @ts-ignore
        const variantImage = $variantImage.attr('src') || $variantImage.data('ss360Src');
        if (variantImage === null || variantImage === undefined) {
            return;
        }
        $el.off('mouseover.variants,focus.variants,mouseleave.variants,focusout.variants,click.variants').on('mouseover.variants,focus.variants', () => {
            if (currentImage !== variantImage) {
                setTimeout(() => {
                    currentImage = variantImage;
                    image.attr('src', variantImage);
                }, 0);
            }
        }).on('mouseleave.variants,focusout.variants', () => {
            if (originalImage !== undefined && originalImage !== null) {
                currentImage = undefined;
                image.attr('src', originalImage);
            }
        });
        $el.on('click.variants', (e) => {
            const identifier = $el.data('productIdentifier');
            // @ts-ignore
            const link = $el.data('link');
            if (link !== undefined && SxQueryUtils.linkOpensInNewTab(e)) {
                window.open(link, '_blank');
            }
            if (data instanceof Array) {
                // match by identifier, title as fallback, open link in new tab if neither matches any variant
                let idx = data.reduce((acc, entry, entryIdx) => (entry.identifier === identifier ? entryIdx : acc), -1);
                if (idx === -1) {
                    idx = data.reduce((acc, entry, entryIdx) => (entry.name === identifier ? entryIdx : acc), -1);
                }
                if (idx === -1) {
                    if (link !== undefined) {
                        window.open(link, '_blank');
                    }
                    return;
                }
                // create new suggestions with a different main variant
                const entry = data.splice(idx, 1)[0];
                data.splice(0, 0, entry);
                const suggest = new SearchSuggest(data);
                if (context.pluginConfiguration.callbacks.singleResultPreRenderCallback) {
                    try {
                        context.pluginConfiguration.callbacks.singleResultPreRenderCallback(suggest.suggest, suggest.variants);
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
                const $newSuggest = sxQuery(resultRenderer.render(suggest, false, resultGroup));
                // keep variants in the same order
                const $newSuggestVariants = $newSuggest.find('.ss360-suggests__variants');
                $newSuggestVariants.children().remove();
                $newSuggestVariants.append($variantsWrap.children());
                // only hold image size as long as necessary, prevent layout jumps
                const $newSuggestImage = $newSuggest.find('.ss360-suggests__image');
                try {
                    $newSuggestImage.css('width', `${Math.round(image.get()[0].width)}px`);
                    $newSuggestImage.css('height', `${Math.round(image.get()[0].height)}px`);
                }
                catch (err) {
                    // ccl
                }
                $newSuggestImage.on('load', () => {
                    $newSuggestImage.attr('style', null);
                    $newSuggestImage.removeClass('ss360-shimmer');
                });
                // replace content
                node.children().remove();
                node.append($newSuggest.children());
                // update ss360 identifier
                if (suggest.getIdentifier() !== undefined) {
                    node.data('productIdentifier', suggest.getIdentifier());
                }
                if (lastScrollLeft !== undefined) {
                    $newSuggestVariants.css('scroll-behavior', 'initial');
                    $newSuggestVariants.get()[0].scrollLeft = lastScrollLeft;
                    $newSuggestVariants.css('scroll-behavior', null);
                }
                // set checked property
                $newSuggestVariants.find('.ss360-suggests__variant-button').each((vb) => {
                    const $vb = sxQuery(vb);
                    if ($vb.data('productIdentifier') === identifier) {
                        $vb.attr('aria-checked', 'true');
                        $vb.addClass('ss360-suggests__variant-button--active');
                    }
                    else {
                        $vb.attr('aria-checked', 'false');
                        $vb.removeClass('ss360-suggests__variant-button--active');
                    }
                });
                // rebind variants component
                bindVariantEvents(node, resultRenderer, changeCallback, context, data, resultGroup);
                changeCallback();
            }
            else if (link !== undefined) {
                window.open(link, '_blank');
            }
        });
    });
};
const bindImageCarouselEvents = (node, allImages) => {
    const $carousel = node.find('.ss360-suggests__carousel');
    if ($carousel.length === 0) {
        return;
    }
    let width = $carousel.outerWidth();
    let height = $carousel.outerHeight();
    let activeIndex = 0;
    let isLoaded = false;
    const navButtons = node.find('.ss360-suggests__carousel-nav-entry');
    const firstImage = $carousel.find('.ss360-suggests__image:first');
    const loadedImages = [];
    const preloadImages = () => {
        // @ts-ignore
        if (SxQueryUtils.getConnectionEffectiveType() === undefined || SxQueryUtils.getConnectionEffectiveType() === '4g') { // only load on slow connections to ensure smoother transitions
            return;
        }
        if (SxQueryUtils.saveData()) { // don't preload if save data flag is on 
            return;
        }
        const prev = activeIndex - 1 < 0 ? allImages.length - 1 : activeIndex - 1;
        const next = activeIndex + 1 < allImages.length ? activeIndex + 1 : 0;
        const prevImg = allImages[prev];
        const nextImg = allImages[next];
        if (loadedImages.indexOf(prevImg) === -1) {
            loadedImages.push(prevImg);
            const img = new Image();
            img.src = prevImg;
        }
        if (loadedImages.indexOf(nextImg) === -1) {
            loadedImages.push(nextImg);
            const img = new Image();
            img.src = nextImg;
        }
    };
    const setDimensions = () => {
        width = $carousel.outerWidth();
        height = $carousel.outerHeight();
        if (width !== 0 && height !== 0) { // freeze dimensions
            $carousel.css('width', `${width}px`);
            $carousel.css('height', `${height}px`);
        }
    };
    firstImage.on('load.carousel', () => {
        firstImage.off('load.carousel');
        preloadImages();
        setTimeout(() => {
            isLoaded = true;
            setDimensions();
        }, 0);
    });
    $carousel.find('.ss360-suggests__image').on('load.carousel', (e) => {
        const $img = sxQuery(e.target);
        $img.off('load.carousel');
        // @ts-ignore
        const src = $img.attr('src');
        if (loadedImages.indexOf(src) === -1) {
            loadedImages.push(src);
        }
    });
    if (width !== 0 && height !== 0) { // freeze dimensions
        $carousel.css('width', `${width}px`);
        $carousel.css('height', `${height}px`);
    }
    let isResizing = false;
    sxQuery(window).on('resize.carousel', () => {
        if (isResizing || !isLoaded)
            return;
        isResizing = true;
        SxQueryUtils.requestAnimationFrame(() => {
            $carousel.css('width', null);
            $carousel.css('height', null);
            SxQueryUtils.requestAnimationFrame(() => {
                width = $carousel.outerWidth();
                height = $carousel.outerHeight();
                if (width !== 0 && height !== 0) { // freeze dimensions
                    $carousel.css('width', `${width}px`);
                    $carousel.css('height', `${height}px`);
                }
                isResizing = false;
            });
        });
    });
    const moveTo = (selectedButton, index) => {
        if (width === 0 || height === 0) {
            setDimensions();
        }
        if (index === activeIndex) {
            return;
        }
        navButtons.removeClass('ss360-suggests__carousel-nav-entry--active');
        navButtons.attr('aria-selected', 'false');
        selectedButton.addClass('ss360-suggests__carousel-nav-entry--active');
        selectedButton.attr('aria-selected', 'true');
        const preventSmoothScrol = Math.abs(index - activeIndex) !== 1; // only smooth scroll to the neighboring image
        if (preventSmoothScrol) {
            $carousel.css('scroll-behavior', 'initial');
        }
        $carousel.get()[0].scrollLeft = width * index;
        if (preventSmoothScrol) {
            $carousel.css('scroll-behavior', null);
        }
        activeIndex = index;
        preloadImages();
    };
    navButtons.on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const selectedButton = sxQuery(e.target);
        // @ts-ignore
        const selectedIndex = parseInt(selectedButton.data('idx'), 10);
        moveTo(selectedButton, selectedIndex);
    });
    navButtons.on('keydown', (e) => {
        const keyCode = e.keyCode || e.which;
        if (keyCode >= 37 && keyCode <= 40) {
            const dir = keyCode === 37 || keyCode === 38 ? -1 : 1;
            let selectedIndex = activeIndex + dir;
            if (selectedIndex >= navButtons.length) {
                selectedIndex = 0;
            }
            else if (selectedIndex < 0) {
                selectedIndex = navButtons.length - 1;
            }
            moveTo(sxQuery(navButtons.get(selectedIndex)), selectedIndex);
        }
    });
    const moveBy = (by) => {
        let selectedIndex = activeIndex + by;
        if (selectedIndex < 0) {
            selectedIndex = allImages.length - 1;
        }
        if (selectedIndex > allImages.length - 1) {
            selectedIndex = 0;
        }
        const selectedButton = node.find(`.ss360-suggests__carousel-nav-li:nth-child(${selectedIndex + 1}) .ss360-suggests__carousel-nav-entry`);
        moveTo(selectedButton, selectedIndex);
    };
    node.find('.ss360-suggests__carousel-pager--prev').on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        moveBy(-1);
    });
    node.find('.ss360-suggests__carousel-pager--next').on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        moveBy(1);
    });
};
export default {
    bindVariantEvents,
    bindImageCarouselEvents
};
