import StringHelper from "../../../global/StringHelper";
import DomElementBuilder from "../../../sxQuery/DomElementBuilder";
import sxQuery from "../../../sxQuery/sxQuery";
import Icons from "../../components/Icons";
import LayoutType from "../../enums/LayoutType";
import SuggestType from "../../enums/SuggestType";
import UiColors from "../../styles/UiColors";
import UiHelper from "../UiHelper";
const cleanTrackingLink = (link) => {
    const domains = ['ssp-dev.search.zoovu.com', 'ssp-stage.search.zoovu.com', 'ssp.search.zoovu.com'];
    let contains = false;
    domains.forEach((domain) => {
        contains = contains || link.indexOf(domain) !== -1;
    });
    if (!contains) {
        return link;
    }
    const queryPart = link.split('?')[1];
    if (queryPart === undefined) {
        return link;
    }
    const params = queryPart.split('&');
    for (let i = 0; i < params.length; i++) {
        const kv = params[i].split('=');
        if (kv.length === 2 && kv[0] === 'r') {
            return decodeURIComponent(kv[1]);
        }
    }
    return link;
};
const ICON_MAP = {
    'ss360:shopping-cart': Icons.SHOPPING_CART,
    'ss360:arrow': Icons.TRIANGLE_ARROW,
    'zoovu:shopping-cart': Icons.SHOPPING_CART,
    'zoovu:arrow': Icons.TRIANGLE_ARROW
};
const VIEW_BOX_MAP = {
    'ss360:shopping-cart': '0 0 17 16',
    'zoovu:shopping-cart': '0 0 17 16'
};
class AbstractResultRenderer {
    constructor(pluginConfiguration, context) {
        this.currentGroupHasImages = true;
        this.pluginConfiguration = pluginConfiguration;
        this.context = context;
        this.hiddenParts = UiHelper.getHiddenParts(pluginConfiguration.layout);
    }
    setQuery(query, queryTags = []) {
        this.query = query;
        this.queryTags = queryTags;
    }
    setCurrentGroupHasImages(groupHasImages) {
        this.currentGroupHasImages = groupHasImages;
    }
    getWrapperClassList() {
        return [];
    }
    createWrapperElement(suggest, isHidden, resultGroup) {
        const { pluginConfiguration } = this;
        const colspan = suggest.getColSpan(pluginConfiguration.layout);
        const originalContentGroup = suggest.getOriginalContentGroup();
        const originalGroup = resultGroup === '*' && originalContentGroup !== undefined ? `ss360-original-group-${StringHelper.getSafeKey(originalContentGroup)}` : '';
        return DomElementBuilder.createElement({
            tag: 'li',
            classList: ['ss360-suggests', isHidden ? 'ss360-suggests--hidden' : undefined, originalGroup, ...this.getWrapperClassList(), pluginConfiguration.results.card ? 'ss360-suggests--card' : undefined],
            data: {
                productIdentifier: suggest.getIdentifier() // TODO: only for products
            },
            style: {
                display: isHidden && !pluginConfiguration.style.defaultCss ? 'none' : undefined,
                'grid-column': colspan ? `span ${colspan}` : undefined
            }
        });
    }
    createResultNode(suggest) {
        const children = suggest.getLink() ? [
            DomElementBuilder.createElement({
                tag: 'meta',
                attrs: {
                    itemprop: 'url',
                    content: suggest.getLink()
                }
            })
        ] : [];
        return DomElementBuilder.createElement({
            tag: 'article',
            classList: ['ss360-suggests__wrap', 'ss360-n-section'],
            attrs: {
                'aria-labelledby': `ss360-heading-${suggest.getRandomId()}`,
                itemscope: 'itemscope',
                itemtype: 'https://schema.org/Article'
            },
            children
        });
    }
    render(suggest, isHidden, resultGroup) {
        const el = this.createWrapperElement(suggest, isHidden, resultGroup);
        const article = this.createResultNode(suggest);
        el.appendChild(article);
        this.renderContent(article, suggest, resultGroup);
        return el;
    }
    isLinksOpenNewTab(suggest) {
        return this.pluginConfiguration.results.linksOpenNewTab || suggest.getType() === SuggestType.YOUTUBE_VIDEO;
    }
    getLink(suggest) {
        return suggest.getLink(this.pluginConfiguration.queryTerm.highlightMatchedContent, this.query, this.context.getIsZoovu());
    }
    getReadableLink(suggest) {
        let link = this.getLink(suggest);
        if (link && link.indexOf('ssp') !== -1) {
            link = cleanTrackingLink(link);
        }
        try {
            link = decodeURI(link);
        }
        catch (err) {
            console.error(`Malformed URL ${link}`);
        }
        if (this.pluginConfiguration.results.stripHttp && link) {
            if (link.toLowerCase().indexOf('https://') === 0) {
                link = link.substring(8);
            }
            else if (link.toLowerCase().indexOf('http://') === 0) {
                link = link.substring(7);
            }
        }
        return link;
    }
    getImage(suggest) {
        return suggest.getImage(true) || '';
    }
    getImages(suggest) {
        const images = [];
        const image = this.getImage(suggest);
        if (image) {
            images.push(image);
        }
        if (this.pluginConfiguration.results.showAlternativeImages) {
            suggest.getImages().forEach((img) => {
                if (images.indexOf(img) === -1) {
                    images.push(img);
                }
            });
        }
        return images;
    }
    getMaxNumberOfBadges() {
        return this.pluginConfiguration.results.maxBadges;
    }
    getBadges(suggest) {
        const maxNumberOfBadges = this.getMaxNumberOfBadges();
        const { badgeDataPoints } = this.pluginConfiguration.results;
        if (badgeDataPoints.length === 0 || maxNumberOfBadges === 0) {
            return [];
        }
        const badges = [];
        const badgeMap = badgeDataPoints.reduce((acc, badge) => {
            acc[badge.dataPoint] = badge;
            return acc;
        }, {});
        const sortedDataPointKeys = badgeDataPoints.map((badge) => badge.dataPoint);
        const dataPoints = suggest.getDataPoints(this.pluginConfiguration.results.contentDataPoint);
        if (sortedDataPointKeys.length > 0) {
            dataPoints.sort((a, b) => {
                const aIdx = sortedDataPointKeys.indexOf(a.key);
                const bIdx = sortedDataPointKeys.indexOf(b.key);
                if (aIdx === bIdx) {
                    return 0;
                }
                if (aIdx === -1) {
                    return 1;
                }
                if (bIdx === -1) {
                    return -1;
                }
                return aIdx < bIdx ? -1 : 1;
            });
        }
        const addedBadges = [];
        for (let i = 0; i < dataPoints.length; i++) {
            const dp = dataPoints[i];
            if (dp.show !== true && dp.type !== 'PASS_ON' && dp.type !== 'FILTER' && dp.type !== 'SORT') {
                continue;
            }
            const configuredBadge = badgeMap[dp.key];
            const badgeValue = `${dp.key}_${dp.value}`;
            if (configuredBadge !== undefined && addedBadges.indexOf(badgeValue) === -1) {
                addedBadges.push(badgeValue);
                badges.push({
                    value: dp.value,
                    icon: configuredBadge.icon,
                    dataPoint: dp.key
                });
            }
            if (badges.length >= maxNumberOfBadges) {
                break;
            }
        }
        return badges;
    }
    getDataPoints(suggest) {
        let dataPoints = suggest.getDataPoints(this.pluginConfiguration.results.contentDataPoint);
        const { sort, showOnlyPassOns, exclude, include, single, unique, showOnly } = this.pluginConfiguration.dataPoints;
        if ((sort || []).length > 0) {
            dataPoints.sort((a, b) => {
                const aIdx = sort.indexOf(a.key);
                const bIdx = sort.indexOf(b.key);
                if (aIdx === bIdx) {
                    return 0;
                }
                if (aIdx === -1) {
                    return 1;
                }
                if (bIdx === -1) {
                    return -1;
                }
                return aIdx < bIdx ? -1 : 1;
            });
        }
        const valuesByKey = {};
        if (showOnly.length > 0) {
            dataPoints = dataPoints.filter((dp) => {
                const { key } = dp;
                return showOnly.indexOf(key) !== -1;
            });
        }
        else {
            dataPoints = dataPoints.filter((dp) => {
                const { key, value, type } = dp;
                if (unique && valuesByKey[key] !== undefined && valuesByKey[key].indexOf(value) === -1) {
                    return false;
                }
                else if (unique) {
                    if (valuesByKey[key] === undefined) {
                        valuesByKey[key] = [];
                    }
                    valuesByKey[key].push(value);
                }
                if (exclude.indexOf(key) !== -1) {
                    return false;
                }
                if (dp.show === false) {
                    return false;
                }
                const show = dp.show !== undefined ? dp.show : type === 'PASS_ON';
                const isIncluded = show || include.indexOf(key) !== -1;
                if (!isIncluded) {
                    return false;
                }
                if ((!showOnlyPassOns || type === undefined || type === 'PASS_ON')) {
                    return true;
                }
                return false;
            });
        }
        if (single.length > 0) {
            const addedKeys = [];
            dataPoints = dataPoints.filter((dp) => {
                const { key } = dp;
                if (single.indexOf(key) === -1) {
                    return true;
                }
                if (addedKeys.indexOf(key) === -1) {
                    addedKeys.push(key);
                    return true;
                }
                return false;
            });
        }
        return dataPoints;
    }
    isHasVariantsLabel(_suggest) {
        return false;
    }
    isHasVariantImages(_suggest) {
        return false;
    }
    getVariantImages(_suggest) {
        return [];
    }
    getCtas(suggest, resultGroup) {
        let ctas = this.pluginConfiguration.results.cta || [];
        if (resultGroup === '*') {
            resultGroup = suggest.getOriginalContentGroup();
        }
        ctas = ctas.filter((cta) => {
            if (cta.includeContentGroups !== undefined && cta.includeContentGroups.indexOf(resultGroup) === -1) {
                return false;
            }
            if (cta.excludeContentGroups !== undefined && cta.excludeContentGroups.indexOf(resultGroup) !== -1) {
                return false;
            }
            return true;
        });
        return ctas;
    }
    getTitle(suggest) {
        let cleanName = suggest.getName() || '';
        if (cleanName.indexOf(' ') !== -1) { // &nbsp;
            cleanName = cleanName.split(' ').join(' ');
        }
        return cleanName;
    }
    getSnippet(suggest) {
        return suggest.getContent(this.pluginConfiguration.results.contentDataPoint);
    }
    createCarouselPaginationButton(modifier, label) {
        const button = DomElementBuilder.createElement({
            tag: 'button',
            classList: ['ss360-n-button', 'ss360-suggests__carousel-pager', `ss360-suggests__carousel-pager--${modifier}`],
            attrs: {
                type: 'button',
                title: label
            }
        });
        const icon = Icons.getSvgIconNode(Icons.SIMPLE_ARROW, UiColors.THEME, 'ss360-suggests__carousel-pager-icon');
        button.appendChild(icon);
        return button;
    }
    createCarousel(suggest, images) {
        images = images || this.getImages(suggest);
        if (images.length <= 1) {
            return undefined;
        }
        const carouselWrap = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__carousel-wrap']
        });
        carouselWrap.appendChild(this.createCarouselPaginationButton('prev', 'Show Previous Image'));
        carouselWrap.appendChild(DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__carousel']
        }));
        carouselWrap.appendChild(this.createCarouselPaginationButton('next', 'Show Next Image'));
        const carouselNav = DomElementBuilder.createElement({
            tag: 'nav',
            classList: ['ss360-suggests__carousel-nav', 'ss360-n-section'],
            attrs: {
                role: 'navigation',
                'aria-label': 'Browse through images'
            }
        });
        const carouselUl = DomElementBuilder.createElement({
            tag: 'ul',
            classList: ['ss360-suggests__carousel-nav-ul'],
            attrs: {
                role: 'tablist'
            }
        });
        images.forEach((img, idx) => {
            carouselUl.appendChild(DomElementBuilder.createElement({
                tag: 'li',
                classList: ['ss360-n-li', 'ss360-suggests__carousel-nav-li'],
                attrs: {
                    role: 'none'
                },
                children: [
                    DomElementBuilder.createElement({
                        tag: 'button',
                        classList: ['ss360-suggests__carousel-nav-entry', 'ss360-n-button', idx === 0 ? ' ss360-suggests__carousel-nav-entry--active' : undefined],
                        attrs: {
                            type: 'button',
                            role: 'tab',
                            'aria-label': `${idx + 1}. image`,
                            'aria-selected': `${idx === 0}`
                        },
                        data: {
                            idx: `${idx}`
                        }
                    })
                ]
            }));
        });
        carouselNav.appendChild(carouselUl);
        carouselWrap.appendChild(carouselNav);
        return carouselWrap;
    }
    createImageNode(suggest, image, idx = 0) {
        const isDocIcon = UiHelper.isDocIcon(image);
        const isPdfIcon = isDocIcon && image.indexOf('pdf.svg') !== -1;
        const isYoutubeVideo = suggest.getType() === SuggestType.YOUTUBE_VIDEO;
        const lazyLoadImages = this.pluginConfiguration.results.lazyLoadImages;
        const a = DomElementBuilder.createElement({
            tag: 'a',
            classList: ['ss360-suggests__image-wrap', image === null && idx === 0 ? 'ss360-suggests__image-wrap--empty' : undefined,
                isYoutubeVideo && idx === 0 ? 'ss360-suggests__image-wrap--video' : undefined],
            attrs: {
                'aria-hidden': 'true',
                'tabindex': '-1',
                href: this.getLink(suggest) || '#',
                target: this.isLinksOpenNewTab(suggest) ? '_blank' : undefined
            }
        });
        if (image !== null) {
            const img = DomElementBuilder.createElement({
                tag: 'img',
                attrs: {
                    loading: lazyLoadImages ? 'lazy' : undefined,
                    src: image,
                    alt: '',
                    'aria-hidden': 'true',
                    role: 'presentation',
                    'aria-label': suggest.getName(),
                    itemprop: 'image'
                },
                data: {
                    fallbackSrc: suggest.isResizedImage() && idx === 0 ? suggest.getOriginalImage() : undefined
                },
                classList: ['ss360-suggests__image', isDocIcon && idx === 0 ? 'ss360-suggests__image--doc' : undefined,
                    lazyLoadImages ? 'ss360-suggests__image--lazy ss360-shimmer' : undefined, isPdfIcon && idx === 0 ? 'ss360-suggests__image--pdf' : undefined]
            });
            a.appendChild(img);
        }
        if (image !== null && isYoutubeVideo) {
            a.appendChild(DomElementBuilder.createElement({
                tag: 'i',
                attrs: {
                    role: 'presentation',
                    'aria-hidden': 'true'
                },
                classList: ['ss360-icon', 'ss360-icon--play']
            }));
        }
        return a;
    }
    createVariantThumbnails(suggest) {
        const resultSettings = this.pluginConfiguration.results;
        const lazyLoadImages = resultSettings.lazyLoadImages;
        const variantUl = DomElementBuilder.createElement({
            tag: 'ul',
            classList: ['ss360-suggests__variants'],
            attrs: {
                'aria-label': resultSettings.variantsLabel
            }
        });
        const createVariantWrap = (modifier, label) => {
            const icon = Icons.getSvgIconNode(Icons.SIMPLE_ARROW, UiColors.THEME, 'ss360-suggests__variant-arrow-icon');
            return DomElementBuilder.createElement({
                tag: 'li',
                classList: ['ss360-suggests__variant-arrow-wrap', `ss360-suggests__variant-arrow-wrap--${modifier}`],
                children: [
                    DomElementBuilder.createElement({
                        tag: 'button',
                        classList: ['ss360-n-button', 'ss360-suggests__variant-arrow', `ss360-suggests__variant-arrow--${modifier}`],
                        attrs: {
                            type: 'button',
                            'aria-label': label
                        },
                        children: [icon]
                    })
                ]
            });
        };
        variantUl.appendChild(createVariantWrap('prev', 'Scroll Left'));
        this.getVariantImages(suggest).forEach((entry, idx) => {
            const img = DomElementBuilder.createElement({
                tag: 'img',
                classList: ['ss360-suggests__variant-image', lazyLoadImages ? 'ss360-suggests__variant-image--lazy' : undefined],
                attrs: {
                    loading: lazyLoadImages ? 'lazy' : undefined,
                    src: entry.optimizedImage || entry.image,
                    alt: '',
                    'aria-hidden': 'true',
                    role: 'presentation'
                },
                data: {
                    fallbackSrc: entry.optimizedImage && entry.optimizedImage !== entry.image ? entry.image : undefined
                }
            });
            const activeClass = entry.identifier === suggest.getIdentifier() ? 'ss360-suggests__variant-button--active' : undefined;
            const btn = DomElementBuilder.createElement({
                tag: 'button',
                classList: ['ss360-suggests__variant-button', 'ss360-n-button', activeClass],
                attrs: {
                    type: 'button',
                    title: entry.name,
                    role: 'radio',
                    'aria-checked': `${idx === 0}`
                },
                data: {
                    productIdentifier: entry.identifier,
                    link: entry.link
                },
                children: [img]
            });
            variantUl.appendChild(DomElementBuilder.createElement({
                tag: 'li',
                classList: ['ss360-suggests__variant'],
                children: [btn]
            }));
        });
        variantUl.appendChild(createVariantWrap('next', 'Scroll Right'));
        return variantUl;
    }
    createBadge(badge) {
        const children = [];
        const icon = badge.icon;
        if (icon !== undefined) {
            const iconPath = ICON_MAP[badge.icon];
            let innerIconNode = undefined;
            if (iconPath !== undefined) {
                innerIconNode = Icons.getSvgIconNode(iconPath, UiColors.ACCENT_DARK, 'ss360-suggests__badge-icon', 16, 16, VIEW_BOX_MAP[icon]);
            }
            else if (icon.indexOf('<svg') !== -1 && icon.indexOf('</svg') !== -1) {
                innerIconNode = sxQuery(icon).get(0);
            }
            else {
                innerIconNode = DomElementBuilder.createElement({
                    tag: 'img',
                    classList: ['ss360-suggests__badge-icon'],
                    attrs: {
                        src: icon
                    }
                });
            }
            children.push(innerIconNode);
        }
        children.push(DomElementBuilder.createElement({
            tag: 'span',
            classList: ['ss360-suggests__badge-text'],
            textContent: badge.value
        }));
        return DomElementBuilder.createElement({
            tag: 'span',
            classList: ['ss360-suggests__badge', badge.dataPoint ? `ss360-suggests__badge--${StringHelper.getSafeKey(badge.dataPoint)}` : undefined],
            children
        });
    }
    createBadges(suggest) {
        const badges = this.getBadges(suggest);
        if (badges.length === 0) {
            return undefined;
        }
        const badgeWrap = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__badges']
        });
        badges.forEach((badge) => {
            badgeWrap.appendChild(this.createBadge(badge));
        });
        return badgeWrap;
    }
    createImages(suggest) {
        const hasImage = suggest.hasImage();
        const resultSettings = this.pluginConfiguration.results;
        if (this.hiddenParts.images === 'all' || !this.currentGroupHasImages || (!hasImage && resultSettings.placeholderImage === null)) {
            return undefined;
        }
        if (!hasImage) {
            suggest.setPlaceholderImage(resultSettings.placeholderImage);
        }
        const images = this.getImages(suggest);
        const hasVariants = this.isHasVariantImages(suggest);
        const hasMultipleImages = images.length > 1;
        const wrap = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__image-container', hasVariants ? 'ss360-suggests__image-container--has-variants' : undefined,
                hasMultipleImages ? 'ss360-suggests__image-container--has-multiple-images' : undefined, images.length === 0 ? ' ss360-suggests__image-container--empty' : undefined],
        });
        let imageTarget = wrap;
        const carouselWrap = this.createCarousel(suggest, images);
        if (carouselWrap !== undefined) {
            wrap.appendChild(carouselWrap);
            imageTarget = carouselWrap.querySelector('.ss360-suggests__carousel') || imageTarget;
        }
        if (!hasImage && images.length === 0) {
            images.push(null);
        }
        images.forEach((image, idx) => {
            const imageNode = this.createImageNode(suggest, image, idx);
            if (hasMultipleImages) {
                const imageWrap = DomElementBuilder.createElement({
                    tag: 'div',
                    classList: ['ss360-suggests__carousel-entry']
                });
                imageWrap.appendChild(imageNode);
                imageTarget.appendChild(imageWrap);
            }
            else {
                imageTarget.appendChild(imageNode);
            }
        });
        const badges = this.createBadges(suggest);
        if (badges !== undefined) {
            wrap.appendChild(badges);
        }
        if (hasVariants) {
            const variantWrap = this.createVariantThumbnails(suggest);
            wrap.appendChild(variantWrap);
        }
        return wrap;
    }
    renderHeader(wrapper, suggest) {
        if (this.hiddenParts.title === 'all') {
            return;
        }
        const title = this.getTitle(suggest);
        const link = this.getLink(suggest);
        const hasVariantsLabel = this.isHasVariantsLabel(suggest);
        const header = DomElementBuilder.createElement({
            tag: 'header',
            classList: ['ss360-n-section', 'ss360-suggests__header', hasVariantsLabel ? 'ss360-suggests__header--has-variants-label' : undefined]
        });
        const heading = DomElementBuilder.createElement({
            tag: 'span',
            classList: ['ss360-suggests__link-wrap'],
            attrs: {
                role: 'heading',
                id: `ss360-heading-${suggest.getRandomId()}`,
                title,
                'aria-level': `${this.pluginConfiguration.accessibility.suggestHeadingLevel}`,
                itemprop: 'name'
            },
            children: [
                DomElementBuilder.createElement({
                    tag: 'a',
                    classList: ['ss360-suggests__link'],
                    attrs: {
                        target: this.isLinksOpenNewTab(suggest) ? '_blank' : undefined,
                        href: link !== undefined && link !== null && link.length > 0 ? link : undefined
                    },
                    html: title
                })
            ]
        });
        header.appendChild(heading);
        wrapper.appendChild(header);
        return header;
    }
    renderLink(wrapper, suggest) {
        if (this.hiddenParts.url === 'all') {
            return;
        }
        const link = this.getReadableLink(suggest);
        if (link === undefined || link === null || link.length === 0) {
            return;
        }
        const linkNode = DomElementBuilder.createElement({
            tag: 'a',
            classList: ['ss360-suggests__url'],
            attrs: {
                tabindex: '-1',
                href: this.getLink(suggest),
                'aria-hidden': 'true',
                target: this.isLinksOpenNewTab(suggest) ? '_blank' : undefined
            },
            textContent: link
        });
        wrapper.appendChild(linkNode);
        return linkNode;
    }
    renderSnippet(wrapper, suggest) {
        if (this.hiddenParts.snippet === 'all') {
            return;
        }
        const snippet = this.getSnippet(suggest);
        if (snippet === undefined || snippet === null || snippet.length === 0) {
            return;
        }
        const snippetNode = DomElementBuilder.createElement({
            tag: 'p',
            classList: ['ss360-suggests__snippet'],
            html: snippet,
            attrs: {
                itemprop: 'description'
            }
        });
        wrapper.appendChild(snippetNode);
        return snippetNode;
    }
    createDataPointsRows(dataPoints) {
        if (dataPoints === undefined) {
            return [];
        }
        const { collapseBy, addTypeClass, displayType, showNames, namePostfix } = this.pluginConfiguration.dataPoints;
        const isBreak = (val) => {
            if (val && val.length >= 19) {
                const words = val.split(' ');
                for (let i = 0; i < words.length; i++) {
                    if (words[i].length >= 19) {
                        return true;
                    }
                }
            }
            return false;
        };
        const buildRow = (key, value, isLast = false) => {
            const tr = DomElementBuilder.createElement({
                tag: 'tr',
                classList: ['ss360-data-point', isLast ? ' ss360-data-point--last' : undefined, `ss360-dp__${StringHelper.getSafeKey((key || '').toLowerCase())}`],
                attrs: {
                    'aria-label': key
                }
            });
            if (showNames) {
                const nameValue = `${key || ''}${namePostfix}`;
                tr.appendChild(DomElementBuilder.createElement({
                    tag: 'td',
                    classList: ['ss360-data-point__cell', 'ss360-data-point__cell--key', isBreak(nameValue) ? 'ss360-data-point__cell--break' : undefined],
                    textContent: nameValue
                }));
            }
            let hasLongValue = false;
            const valueNodes = value.reduce((acc, dp, idx) => {
                const val = dp.value;
                const values = [];
                if (val && val.toString().indexOf('<') !== -1) {
                    const nodes = sxQuery(val);
                    if (nodes.length === 0) {
                        hasLongValue = isBreak(val);
                        values.push(document.createTextNode(val));
                    }
                    else {
                        nodes.get().forEach((node) => {
                            values.push(node);
                        });
                    }
                }
                else {
                    values.push(document.createTextNode(val));
                }
                values.forEach((val) => {
                    const typeClass = [];
                    if (addTypeClass && dp.type !== undefined) {
                        typeClass.push(`ss360-data-point__single-value--${dp.type.toLowerCase()}`);
                    }
                    let queryTag = undefined;
                    try {
                        queryTag = this.queryTags !== undefined && this.queryTags.indexOf(dp.value.toLowerCase().trim()) !== -1;
                    }
                    catch (err) {
                        // ccl
                    }
                    if (queryTag) {
                        typeClass.push(`ss360-data-point__single-value--tag`);
                    }
                    acc.push(DomElementBuilder.createElement({
                        tag: 'span',
                        classList: [`ss360-data-point__single-value`, ...typeClass],
                        children: [val]
                    }));
                });
                if (displayType && dp.type !== undefined) {
                    acc.push(DomElementBuilder.createElement({
                        tag: 'span',
                        classList: ['ss360-data-point__type'],
                        textContent: ` [${dp.type}]`
                    }));
                }
                if (collapseBy && idx !== value.length - 1) {
                    const isHTMLCollapse = collapseBy.indexOf('<') !== -1 && collapseBy.indexOf('>') !== -1;
                    if (isHTMLCollapse) {
                        sxQuery(collapseBy).get().forEach((tag) => acc.push(tag));
                    }
                    else {
                        acc.push(document.createTextNode(collapseBy));
                    }
                }
                return acc;
            }, []);
            tr.appendChild(DomElementBuilder.createElement({
                tag: 'td',
                classList: ['ss360-data-point__cell', 'ss360-data-point__cell--value', hasLongValue ? 'ss360-data-point__cell--break' : undefined],
                children: valueNodes
            }));
            return tr;
        };
        if (collapseBy) {
            return [buildRow(dataPoints[0].key, dataPoints, true)];
        }
        return dataPoints.map((dp, idx) => buildRow(dp.key, [dp], idx === dataPoints.length - 1));
    }
    createDataPointsTable(groupedDataPoints, keys) {
        const { direction } = this.pluginConfiguration.dataPoints;
        return DomElementBuilder.createElement({
            tag: 'table',
            classList: ['ss360-data-points', `ss360-data-points--${direction}`],
            children: [
                DomElementBuilder.createElement({
                    tag: 'tbody',
                    classList: ['ss360-data-points__body'],
                    children: keys.reduce((acc, key) => {
                        const row = this.createDataPointsRows(groupedDataPoints[key]);
                        row.forEach((entry) => {
                            acc.push(entry);
                        });
                        return acc;
                    }, [])
                })
            ]
        });
    }
    renderDataPoints(wrapper, suggest) {
        if (this.hiddenParts.dataPoints === 'all') {
            return;
        }
        const layout = this.pluginConfiguration.layout;
        const isMobileGrid = layout.mobile.type === LayoutType.Grid || layout.mobile.type === LayoutType.Mixed;
        const isDesktopGrid = layout.desktop.type === LayoutType.Grid || layout.desktop.type === LayoutType.Mixed;
        const dataPoints = this.getDataPoints(suggest);
        if (dataPoints.length === 0 && !isMobileGrid && !isDesktopGrid) {
            return;
        }
        const keys = []; // iteration order
        const groupedDataPoints = dataPoints.reduce((acc, dp) => {
            const { key } = dp;
            if (keys.indexOf(key) === -1) {
                keys.push(key);
            }
            if (acc[key] === undefined) {
                acc[key] = [];
            }
            acc[key].push(dp);
            return acc;
        }, {});
        const dataPointsTable = this.createDataPointsTable(groupedDataPoints, keys);
        wrapper.appendChild(dataPointsTable);
        return dataPointsTable;
    }
    renderCta(wrapper, suggest, cta) {
        let iconNode = undefined;
        const renderAsButton = cta.renderAsButton !== undefined ? cta.renderAsButton : true;
        const icon = renderAsButton ? cta.icon || 'ss360:arrow' : 'none';
        const position = cta.position !== undefined ? cta.position : 'left';
        const classList = ['ss360-n-button', 'ss360-cta', `ss360-cta--${position}`, icon !== undefined && icon !== 'none' ? undefined : 'ss360-cta--noicon'];
        if (!renderAsButton) {
            classList.push('ss360-cta--plain');
        }
        else {
            classList.push('ss360-cta--button');
            if (cta.primary) {
                classList.push('ss360-cta--primary');
            }
        }
        if (icon !== 'none') {
            iconNode = DomElementBuilder.createElement({
                tag: 'i',
                classList: ['ss360-cta__icon'],
                attrs: {
                    role: 'presentation'
                }
            });
            let innerIconNode = undefined;
            const fill = cta.primary ? UiColors.WHITE : UiColors.BLACK;
            const iconPath = ICON_MAP[icon];
            if (iconPath !== undefined) {
                innerIconNode = Icons.getSvgIconNode(iconPath, fill, 'ss360-cta__img', undefined, undefined, VIEW_BOX_MAP[icon]);
                classList.push('ss360-cta--ss360-icon');
            }
            else if (icon.indexOf('<svg') !== -1 && icon.indexOf('</svg') !== -1) {
                innerIconNode = sxQuery(icon).get(0);
            }
            else {
                innerIconNode = DomElementBuilder.createElement({
                    tag: 'img',
                    classList: ['ss360-cta__img'],
                    attrs: {
                        src: icon
                    }
                });
            }
            iconNode.appendChild(innerIconNode);
        }
        const text = cta.text !== undefined ? DomElementBuilder.createElement({
            tag: 'span',
            html: cta.text
        }) : undefined;
        let link;
        if (cta.getLink !== undefined) {
            link = cta.getLink(suggest.suggest);
        }
        if (link === undefined) {
            link = cta.link;
        }
        if (link === undefined && !renderAsButton) {
            link = '#';
        }
        if (cta.clickCallback !== undefined) {
            classList.push('ss360-cta--has-callback');
        }
        const hasLink = link !== undefined && link !== null;
        const suggestLink = this.getLink(suggest);
        const ctaNode = DomElementBuilder.createElement({
            tag: hasLink ? 'a' : 'button',
            classList,
            attrs: {
                href: hasLink ? link.replace(/#RESULT_URL#/g, suggestLink).replace(/#RESULT_LINK#/g, suggestLink) : undefined,
                type: hasLink ? undefined : 'button'
            },
            children: [
                iconNode, text
            ]
        });
        if (cta.clickCallback !== undefined) {
            ctaNode.addEventListener('click', (e) => {
                cta.clickCallback(e, suggest.suggest);
            });
        }
        wrapper.appendChild(ctaNode);
        return ctaNode;
    }
    renderCtas(wrapper, suggest, resultGroup) {
        let ctas = this.getCtas(suggest, resultGroup);
        if (ctas.length === 0) {
            return;
        }
        const ctaDirection = this.pluginConfiguration.results.ctaDirection;
        let ctaAdditionalClass = undefined;
        if (ctaDirection === 'row') {
            const firstDirection = ctas[0].position || 'left';
            if (firstDirection === 'left') {
                ctaAdditionalClass = ' ss360-flex--justify-start';
            }
            else if (firstDirection === 'center') {
                ctaAdditionalClass = ' ss360-flex--justify-center';
            }
            else if (firstDirection === 'right') {
                ctaAdditionalClass = ' ss360-flex--justify-end';
            }
        }
        const ctaWrap = DomElementBuilder.createElement({
            tag: 'div',
            classList: ['ss360-suggests__cta-wrap', `ss360-suggests__cta-wrap--${ctaDirection}`, ctaAdditionalClass]
        });
        ctas.forEach((cta) => {
            this.renderCta(ctaWrap, suggest, cta);
        });
        wrapper.appendChild(ctaWrap);
        return ctaWrap;
    }
}
export default AbstractResultRenderer;
