'use strict';
const loggers = {
    info: (param, ...rest) => { console.log(param, ...rest); },
    warn: (param, ...rest) => { console.warn(param, ...rest); },
    error: (param, ...rest) => { console.error(param, ...rest); }
};
var LoggerLevel;
(function (LoggerLevel) {
    LoggerLevel["Info"] = "info";
    LoggerLevel["Warn"] = "warn";
    LoggerLevel["Error"] = "error";
})(LoggerLevel || (LoggerLevel = {}));
const Logger = {
    info: loggers.info,
    warn: loggers.warn,
    error: loggers.error,
    set(level, handler) {
        Logger[level] = handler;
    }
};
export default Logger;
