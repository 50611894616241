'use strict';
import Logger from '../global/Logger';
import { querySelectorAllz } from './SxQueryObject';
import SxQueryShared from './SxQueryShared';
const parseHTML = SxQueryShared.parseHTML;
const createCookie = (name, value, days) => {
    let expires;
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }
    else {
        expires = '';
    }
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}; path=/; SameSite=Strict`;
};
const readCookie = (name) => {
    const nameEQ = `${encodeURIComponent(name)}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
};
const extend = (out, ...args) => {
    const output = out || {};
    for (let i = 0; i < args.length; i++) {
        const input = args[i];
        if (input) {
            Object.keys(input).forEach((key) => {
                if (input[key] !== undefined) {
                    if (isObject(output[key]) && isObject(input[key])) {
                        output[key] = extend(output[key], input[key]);
                    }
                    else {
                        output[key] = input[key];
                    }
                }
            });
        }
    }
    return output;
};
const isObject = (el) => el !== undefined && typeof el === 'object' && !(el instanceof Array);
const each = (data, callback) => {
    if (callback && typeof callback === 'function') {
        if (data instanceof Array) { // array
            data.forEach((item, i) => {
                callback(i, item, i);
            });
        }
        else { // object
            Object.keys(data).forEach((key, idx) => {
                callback(key, data[key], idx);
            });
        }
    }
    return data;
};
var Method;
(function (Method) {
    Method["GET"] = "GET";
    Method["POST"] = "POST";
})(Method || (Method = {}));
const ajax = (dataObject, retry = true, disableAdditionalHeaders = false) => {
    dataObject = dataObject || { url: '' };
    const method = dataObject.method || 'GET';
    const dataType = dataObject.dataType;
    const url = dataObject.url;
    const success = dataObject.success || function () { };
    const error = dataObject.error || function () { };
    const forceXDR = 'XDomainRequest' in window;
    // eslint-disable-next-line no-undef
    // @ts-ignore
    const request = forceXDR ? new XDomainRequest() : new XMLHttpRequest();
    request.open(method, forceXDR ? url.replace('https://', '//') : url, true);
    request.onload = function () {
        if (forceXDR || (request.status >= 200 && request.status < 400)) {
            const response = request.responseText;
            if (request.status === 204 || request.status === 304) {
                success();
            }
            else if (!dataType || dataType === 'json' || dataType === 'application/json') {
                let result = null;
                try {
                    result = JSON.parse(response);
                }
                catch (e) {
                    Logger.warn(e);
                    success(response);
                }
                if (result !== null) {
                    success(result);
                }
            }
        }
        else {
            error(request.status, request.responseText || request.statusText);
            // mmmmm...
        }
    };
    request.onerror = function () {
        if (retry && !disableAdditionalHeaders) {
            ajax(dataObject, false, true);
        }
        else {
            error(request.status, request.statusText);
        }
    };
    const send = function () {
        try {
            if (!disableAdditionalHeaders) { // some browsers do not understand the Access-Control-Allow-Headers: '*' flag
                const versionParts = SS360_VERSION.split('.');
                const major = versionParts.splice(0, 1)[0];
                const minor = versionParts.join('.');
                request.setRequestHeader('SEARCHINTEGRATION', 'JAVASCRIPT');
                request.setRequestHeader('SEARCHINTEGRATIONVER', major);
                request.setRequestHeader('EXTVER', minor);
            }
            const requestHeaders = dataObject.headers || {};
            Object.keys(requestHeaders).forEach((key) => {
                request.setRequestHeader(key, requestHeaders[key]);
            });
            if (method !== 'POST') {
                request.send();
            }
            else {
                let data = '';
                if (dataType === 'application/json') {
                    data = JSON.stringify(dataObject.data || {});
                    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
                }
                else {
                    each(dataObject.data, (key, value) => {
                        if (value !== undefined || value !== null) {
                            data += `${key}=${value}&`;
                        }
                    });
                    if (data.length > 0) {
                        data = data.substring(0, data.length - 1);
                    }
                    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                }
                request.send(data);
            }
        }
        catch (e) {
            error(request.status, request.statusText, e);
        }
    };
    if (forceXDR) {
        setTimeout(send, 0);
    }
    else {
        send();
    }
};
const querySelectorAll = querySelectorAllz;
const prefersReducedMotion = SxQueryShared.prefersReducedMotion;
const srOnlyCss = 'position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0';
const requestAnimationFrame = SxQueryShared._requestAnimation;
export default {
    each,
    indexInNodeList(el, arr) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === el) {
                return i;
            }
        }
        return -1;
    },
    createCookie,
    readCookie,
    linkOpensInNewTab(event) {
        return event.ctrlKey // CTRL
            || (event.which === 2 || event.button === 4) // middle mouse click
            || (event.target && event.target.target && event.target.target === '_blank'); // target="_blank"
    },
    matchesMediaQuery(type, breakpoint) {
        if (window.matchMedia) {
            const mQuery = type === 'max' ? `(max-width: ${breakpoint}px)` : `(min-width: ${breakpoint}px)`;
            return window.matchMedia(mQuery).matches;
        }
        const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (type === 'max') {
            return w <= breakpoint;
        }
        return w >= breakpoint;
    },
    parseHTML,
    extend,
    isObject,
    ajax,
    get(url, success, error, dataType, includeShopSysHeaders = false, headers = {}) {
        ajax({
            url,
            success,
            error,
            dataType,
            headers
        }, true, !includeShopSysHeaders);
    },
    post(url, data, success, dataType, includeShopSysHeaders = false, error, headers = {}) {
        ajax({
            url,
            success,
            dataType,
            method: Method.POST,
            data,
            error,
            headers
        }, true, !includeShopSysHeaders);
    },
    querySelectorAll,
    prefersReducedMotion,
    srOnlyCss,
    requestAnimationFrame,
    _storeObject(key, value, cookieExpirationDays = 365) {
        try {
            const valueString = JSON.stringify(value);
            if ('localStorage' in window) {
                if (value !== null) {
                    window.localStorage.setItem(key, valueString);
                }
                else {
                    window.localStorage.removeItem(key);
                }
            }
            else {
                createCookie(key, valueString, cookieExpirationDays);
            }
        }
        catch (err) {
            // ccl
        }
    },
    _readObject(key, defaultValue) {
        let valueString;
        if ('localStorage' in window) {
            try {
                valueString = window.localStorage.getItem(key);
            }
            catch (err) {
                return defaultValue; // access denied
            }
        }
        else {
            valueString = readCookie(key);
        }
        if (valueString === null || valueString === undefined) {
            return defaultValue;
        }
        try {
            return JSON.parse(valueString);
        }
        catch (err) {
            return defaultValue;
        }
    },
    saveData() {
        // @ts-ignore
        return ('NetworkInformation' in window) && window.NetworkInformation.saveData === true;
    },
    getConnectionEffectiveType() {
        // @ts-ignore
        if ('connection' in navigator && navigator.connection.effectiveType !== undefined) {
            // @ts-ignore
            return navigator.connection.effectiveType;
        }
        return undefined;
    },
    isElementActive(node) {
        if (document.activeElement === node) {
            return true;
        }
        if (node.getRootNode && node.getRootNode().activeElement === node) {
            return true;
        }
        return false;
    }
};
