import SearchSuggest from "../model/SearchSuggest";
import UiHelper from "../ui/UiHelper";
import sxQuery from "../../sxQuery/sxQuery";
import StringHelper from "../../global/StringHelper";
import Icons from "./Icons";
import Helper from "../utils/Helper";
import UiColors from "../styles/UiColors";
import { ZoeApiConfigurationRegion } from "../interface/ZoeApiConfigurationRegion";
import NavigatorHelper from "../utils/NavigatorHelper";
const MAX_PRODUCTS = 4;
class Comparison {
    constructor(context, accessStore = true) {
        this.products = [];
        this.dev = false;
        this.logQueries = true;
        this.removeListeners = [];
        this.disableStateChangeListeners = [];
        this.isRendered = false;
        this.storeKey = 'ss360-comparison';
        this.queryParamName = 'ss360Comparison';
        this.lastQuery = '';
        this.accessStore = true;
        this.id = context.generateId('ss360-comparison-bar');
        this.context = context;
        const { siteId, ecomDev, comparison } = context.pluginConfiguration;
        this.logQueries = context.getInsights() !== undefined;
        this.siteId = siteId;
        this.dev = ecomDev;
        this.configuration = comparison;
        this.accessStore = accessStore;
        if (comparison._storePrefix) {
            this.storeKey = `ss360-comparison_${comparison._storePrefix}`;
        }
        this.storeKey = `${this.storeKey}_${siteId}`;
        this.products = accessStore ? (context.readObject(this.storeKey) || []).map((val) => new SearchSuggest(val)) : [];
        this.lastQuery = context.pluginConfiguration.allowCookies && accessStore ? context.readCookie(`${this.storeKey}_lastQuery`) || '' : '';
        if (this.context.getIsZoovu()) {
            this.queryParamName = 'zComparison';
        }
        const queryDict = NavigatorHelper.buildQueryDict();
        if (queryDict[this.queryParamName] !== undefined) {
            const identifiers = queryDict[this.queryParamName].split(',');
            if (identifiers.length > 1) {
                this.render(identifiers);
            }
        }
    }
    show() {
        if (this.isRendered) {
            return;
        }
        this.renderBar();
        this.isRendered = true;
        return;
    }
    addProduct(product, query) {
        if (this.isInComparison(product.getIdentifier())) {
            return;
        }
        if (this.context.pluginConfiguration.allowCookies && this.accessStore) {
            this.lastQuery = query;
            this.context.createCookie(`${this.storeKey}_lastQuery`, query);
        }
        const wasDisabled = this.isComparisonDisabled();
        this.products.push(product);
        this.saveProducts();
        this.renderBar();
        if (!wasDisabled && this.products.length >= MAX_PRODUCTS) {
            this._notifyDisableStateChanged(true);
        }
        this.context.emitEvent('comparison-add', {
            identifier: product.getIdentifier(),
            products: this.products
        });
    }
    remove(identifier, fromSerp = false) {
        const wasDisabled = this.isComparisonDisabled();
        let length = this.products.length;
        this.products = this.products.filter((p) => p.getIdentifier() !== identifier);
        this.saveProducts();
        this.renderBar();
        this._notifyRemove(identifier);
        if (wasDisabled) {
            this._notifyDisableStateChanged(false);
        }
        if (length !== this.products.length) {
            this.context.emitEvent('comparison-remove', {
                identifier,
                products: this.products
            });
        }
    }
    isComparisonDisabled() {
        return this.products.length >= MAX_PRODUCTS;
    }
    isInComparison(identifier) {
        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].getIdentifier() === identifier) {
                return true;
            }
        }
        return false;
    }
    saveProducts() {
        if (this.accessStore) {
            this.context.storeObject(this.storeKey, this.products.map((p) => p.plain));
        }
    }
    onRemove(removeListener) {
        this.removeListeners.push(removeListener);
    }
    removeAll() {
        const wasDisabled = this.isComparisonDisabled();
        this.products = [];
        this.saveProducts();
        this.renderBar();
        this._notifyRemove('*');
        if (wasDisabled) {
            this._notifyDisableStateChanged(false);
        }
    }
    onDisableChange(listener) {
        this.disableStateChangeListeners.push(listener);
    }
    _notifyRemove(identifier) {
        this.removeListeners.forEach((listener) => listener(identifier));
    }
    _notifyDisableStateChanged(state) {
        this.disableStateChangeListeners.forEach((listener) => listener(state));
    }
    renderBar() {
        this.context.layer.get().detachComparison();
        if (this.products.length === 0) {
            return;
        }
        const wrap = sxQuery(`<section class="ss360-n-section ss360-comparison-bar" id="${this.id}" role="section" aria-label="Compared Products"></section>`);
        const list = sxQuery('<ul class="ss360-comparison-bar__list"></ul>');
        this.products.forEach((product) => {
            const $li = sxQuery('<li class="ss360-comparison-bar__list-item"></li>');
            const $deleteButton = sxQuery(`<button class="ss360-n-button ss360-comparison-bar__delete-item" aria-label="Remove" type="button">${Icons.getSvgIcon(Icons.CROSS, UiColors.WHITE, undefined, 24, 24)}</button>`);
            $deleteButton.on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.remove(product.getIdentifier());
            });
            const $a = sxQuery(`<a href="${product.getLink()}" target="_blank" class="ss360-comparison-bar__item"></a>`);
            $a.append(`<img class="ss360-comparison-bar__item-image" src="${product.getImage(true)}" loading="lazy">`);
            $a.append(`<span class="ss360-comparison-bar__item-name">${StringHelper.escapeHtml(product.getName())}</span>`);
            $li.append($a);
            $li.append($deleteButton);
            list.append($li);
        });
        for (let i = 0; i < MAX_PRODUCTS - this.products.length; i++) {
            const $li = sxQuery('<li class="ss360-comparison-bar__list-item ss360-comparison-bar__list-item--empty" role="presentation"></li>');
            list.append($li);
        }
        wrap.append(list);
        const buttonsWrap = sxQuery('<div class="ss360-comparison-bar__actions"></div>');
        if (this.products.length > 4) {
            buttonsWrap.append(`<span class="ss360-comparison-bar__limit-warning">${this.configuration.tooManyProductsLabel}</span>`);
        }
        const compareButton = sxQuery(`<button class="ss360-n-button ss360-comparison-bar__button ss360-comparison-bar__button--compare" type="button">${this.configuration.buttonLabel}</button>`);
        if (this.products.length === 1) {
            compareButton.addClass('ss360-comparison-bar__button--disabled');
            compareButton.attr('disabled', 'disabled');
        }
        compareButton.on('click', () => {
            this.render();
        });
        buttonsWrap.append(compareButton);
        wrap.append(buttonsWrap);
        this.context.layer.get().appendToComparisonFrame(wrap);
    }
    render(identifiers) {
        if (identifiers === undefined || identifiers.length === 0) {
            identifiers = this.products.map((p) => p.getIdentifier());
        }
        UiHelper.renderZoeScript(false, this.context.isEcom() && this.context.pluginConfiguration.ecomDev);
        const { configuration } = this;
        sxQuery('zoovu-comparison').remove();
        sxQuery('zoovu-comparison-styles').remove();
        const element = document.createElement('zoovu-comparison');
        element.setAttribute('project-id', this.siteId);
        element.setAttribute('skus', identifiers.join(','));
        element.setAttribute('show-cta', `${configuration.showCta}`);
        element.setAttribute('table-style', `${configuration.tableStyle}`);
        if (this.dev) {
            element.setAttribute('dev', 'true');
        }
        if (this.context.pluginConfiguration.searchSource === 'ZOE' && this.context.hasInsights()) {
            element.setAttribute('version', '2');
            element.setAttribute('communication-id', this.context.getInsights().getSessionId());
        }
        if (!this.logQueries) {
            element.setAttribute('prevent-tracking', 'true');
        }
        const onClose = () => {
            document.body.removeChild(element);
            NavigatorHelper.removeQueryParam(this.context.pluginConfiguration.results, this.queryParamName);
            this.context.emitEvent('comparison-close', { products: this.products });
            sxQuery('body').off('keydown.miniPDP');
        };
        element.addEventListener('close', () => {
            onClose();
        });
        sxQuery('body').on('keydown.miniPDP', (e) => {
            if (e.keyCode === 27 || e.which === 27 || e.key === 'Escape' || e.code === 'Escape') {
                e.stopPropagation();
                onClose();
            }
        });
        element.addEventListener('remove-product', (event) => {
            this.remove(event.detail.identifier);
        });
        if (configuration.ctaText) {
            element.setAttribute('cta-text', configuration.ctaText);
        }
        if (configuration.heading) {
            element.setAttribute('heading', configuration.heading);
        }
        if (configuration.ctaCallback) {
            element.addEventListener('cta-click', (event) => {
                configuration.ctaCallback(event.detail);
                this.context.emitEvent('comparison-cta', event.detail);
            });
        }
        else if (this.context.pluginConfiguration.callbacks.addToCart) {
            element.addEventListener('cta-click', (event) => {
                this.context.pluginConfiguration.callbacks.addToCart(event.detail.identifier, event.detail);
                this.context.emitEvent('comparison-cta', event.detail);
            });
        }
        if (this.context.pluginConfiguration.userGroup) {
            element.setAttribute('user-group', this.context.pluginConfiguration.userGroup);
        }
        if (configuration.showDifferencesLabel) {
            element.setAttribute('show-differences-label', configuration.showDifferencesLabel);
        }
        if (configuration.zoeApiKey) {
            element.setAttribute('zoe-api-key', configuration.zoeApiKey);
            element.setAttribute('zoe-locale', configuration.zoeLocale || 'en-US');
            element.setAttribute('zoe-region', configuration.zoeRegion || ZoeApiConfigurationRegion.ORCA);
        }
        if (configuration.context) {
            element.setAttribute('context', configuration.context);
        }
        document.body.appendChild(element);
        const s = document.createElement('style');
        s.setAttribute('id', 'zoovu-comparison-styles');
        const accentColor = this.context.pluginConfiguration.style.accentColor;
        s.innerText = `zoovu-comparison {--product-title-color:${accentColor};--cta-background-color:${accentColor};--cta-hover-background-color:${Helper.darkenColorHex(accentColor, 16)};}zoovu-comparison::part(toggle){--toggle-track-active-color:${accentColor};}`;
        document.body.appendChild(s);
        NavigatorHelper.addQueryParam(this.queryParamName, identifiers.join(','));
        this.context.emitEvent('comparison-open', { products: this.products });
    }
    destroy() {
        sxQuery(`#${this.id}`).remove();
    }
}
export default Comparison;
